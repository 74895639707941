import { HttpClient, HttpHeaders } from '@angular/common/http';
import { Inject, Injectable } from '@angular/core';
import { CompanyResponse } from '../models/Company';
import { Email, EmailContactUs,Lead, QuotationRequest } from '../models/EmailContactUs';
import { SimpleResponse } from '../models/genericResponse.model';
import { delayedRetryHttp } from '../shared/delayedRetry';

@Injectable({ providedIn: 'root' })
export class ContactusService {
  private baseUrl: string;
  private api: string = "api/Contactsus";
  constructor(private httpClient: HttpClient, @Inject('BASE_URL') private _baseUrl: string) {
    this.baseUrl = this._baseUrl + this.api;
  }

  async ContactUsSubmit(emailContactUs: EmailContactUs): Promise<boolean | void> {
    const a = await this.httpClient
      .post<boolean>(`${this.baseUrl}/SendContactEmail`, emailContactUs, {
        headers: new HttpHeaders({
          'Content-Type': 'application/json',
          'Authorization': 'Basic '
        })
      }).pipe(delayedRetryHttp()).toPromise().catch(err => { console.log('default error'); });

    return a;
  }

  async SenemailSubmit(email: Email): Promise<SimpleResponse | void> {
    const a = await this.httpClient
      .post<SimpleResponse>(`${this.baseUrl}/SendEmailsAndRegister`, email, {
        headers: new HttpHeaders({
          'Content-Type': 'application/json',
          'Authorization': 'Basic '
        })
      }).pipe(delayedRetryHttp()).toPromise().catch(err => { console.log('default error'); });

    return a;
  }

  async SenemailSubmitNew(email: Email): Promise<any | void> {
    const a = await this.httpClient
      .post<any>(`${this.baseUrl}/dGenerateLeadAndPay`, email, {
        headers: new HttpHeaders({
          'Content-Type': 'application/json',
          'Authorization': 'Basic '
        })
      }).pipe(delayedRetryHttp()).toPromise().catch(err => { console.log('default error'); });

    return a;
  }

  async SendQuotation(request: QuotationRequest): Promise<any | void> {
    const a = await this.httpClient
      .post<any>(`${this.baseUrl}/SendQuotation`, request, {
        headers: new HttpHeaders({
          'Content-Type': 'application/json',
          'Authorization': 'Basic '
        })
      }).pipe(delayedRetryHttp()).toPromise().catch(err => { console.log('default error'); });

    return a;
  }

  async DownloadQuotation(filePath: string): Promise<void> {
    try {
      const response = await this.httpClient
      .post(`${this.baseUrl}/DownloadQuotation`, { filePath }, {
        responseType: 'blob', 
        headers: new HttpHeaders({
          'Content-Type': 'application/json',
          'Authorization': 'Basic ' 
        })
      }).pipe(delayedRetryHttp()).toPromise();
  
      // Create a blob from the response
      const blob = new Blob([response]);
      const url = window.URL.createObjectURL(blob);
  
      // Create a temporary link element for the download
      const a = document.createElement('a');
      a.href = url;
      a.download = 'quotation.pdf'; // You can make this dynamic if needed
      document.body.appendChild(a);
      a.click();
      document.body.removeChild(a);
  
      // Clean up
      window.URL.revokeObjectURL(url);
      
      console.log('Downloaded');
    } catch (err) {
      console.log('Error downloading file:', err);
    }
  }

  async GetLeadInfo(leadId: string, currentLang: string): Promise<Lead | void> {
    const a = await this.httpClient
      .get<Lead>(`${this.baseUrl}/GetLeadInfo/${leadId}/${currentLang}`, {
        headers: new HttpHeaders({
          'Content-Type': 'application/json',
          'Authorization': 'Basic '
        })
      }).pipe(delayedRetryHttp()).toPromise().catch(err => { console.log('default error'); });

    return a;
  }

  async InitiatePG(branchID: number, bookingID: number, nationalID: string): Promise<any | void> {
    const a = await this.httpClient
      .post<any>(this._baseUrl + `api/Payment/InitiatePG`, {
        branchID,
        bookingID,
        PGTYPE: 1,
        nationalID,
        amount: 1
      }, {
        headers: new HttpHeaders({
          'Content-Type': 'application/json',
          'Authorization': 'Basic '
        })
      }).pipe(delayedRetryHttp()).toPromise().catch(err => { console.log('default error'); });

    return a;
  }

  async GetCompany(branchId: number, currentLang: string): Promise<CompanyResponse | void> {
    const a = await this.httpClient
      .get<CompanyResponse>(`${this.baseUrl}/GetCompanyInfo/${branchId}/${currentLang}`, {
        headers: new HttpHeaders({
          'Content-Type': 'application/json',
          'Authorization': 'Basic '
        })
      }).pipe(delayedRetryHttp()).toPromise().catch(err => { console.log('default error'); });

    return a;
  }
  
}
