import { SimpleResponse } from "./genericResponse.model";


export interface ContractsDetails {

  iD: string,

  startDate: string,

  endDate: string,

  description: string,

  paymentTerm: string,

  amount: string,

  view: string,
}

export interface DashBoardDetails
    {
        creditDays:string,

        creditLimit:string,

        totalOutstanding:string,

        dueAmount:string,

        propID:string,

        paymentDueID:string,

        paymentPendingID:string,

        conrtactDueID:string,

        receivableAmount:string,

        unbilledAmount:string,

        advanceAmount:string,

        propID_link:string,

        paymentDueID_link:string,

        paymentPendingID_link:string,

        paymentHistory_link:string,

        conrtactDueID_link:string,

        contactID_link:string,
    }

export interface OrderDetails
    {
        orderNo:string,

        orderDate:string,

        expectedDelivery:string,

        status:string,

        orderAmount:string,

        view:string
    }

export interface DashboardResponse {
  contractDetails: ContractsDetails[],

  dashBoardDetails: DashBoardDetails[],

  orderDetails: OrderDetails[]
}

export interface FormDetailsResponse {
  formDetailsList: FormDetailsList[]
}

export interface FormDetailsList {
  title: string
  productID: string
  formID: string
  seqNo: string
  fromControls: FromControl[]
}

export interface FromControl {
  clptlId: string
  clCatId: string
  cliId: string
  category: string
  itemName: string
  dataType: string
  controlType: string
  dt: string
  publish: string
  editable: string
  clpId: string
  parentSection: any
  classes: any
  controlsDetailsIDs: any
}

export interface CustomerResponse {
  customerFormsList: CustomerFormsList[]
}

export interface CustomerFormsList {
  id: string
  title: string
  parentID: string
  followupCatID: string
  add: boolean
  edit: boolean
  delete: boolean
}

export interface DisplayDetailsResponse {
  displayDetailsList: DisplayDetailsList[]
}

export interface DisplayDetailsList {
  id: number
  requestName: string
  caption: string
  value: string
  dateModified: string
  status: number
}

export interface loginResponse {
  customerName: string
  customerZipCode: string
  customerEmail: string
  customerPhone: string
  customerCityID: string
  customerCountryCode: string
  customerCity: string
  customerCountry: string
  customerCountryID: string
  customerAddress: string
  loginNameE: string
  loginNameA: string
  billAddress: string
  billCity: string
  billCountry: string
  billZipCode: string
  contactEmail: string
  contactMobile: string
  billNotes: string
  cont_CPR: string
  loginID: string
  user: string
  customerLogin: string
  loginTime: string
  customerGroup: string
  customer: string
  vendorID: string
  loginName: string
  employeeName: string
  customerType: string
  contactID: number
  superAdmin: string
  moduleID: string
  customerDecimal: string
  contactName: string
  customerCRID: string
  caid: string
  hasToUpdateCompanyProfile: string
  companyID: string
  arSystemDate: string
  salesman: string
  regionDate: string
  baseDecimal: string
  accOff: string
  status: boolean
  messageString: string
  messageInt: number
  messageBool: boolean
  filter: any
  token: string
  listOfString: any
  statusCode: number
  errorMsg: any
}

export interface UpdateValues {
  datavalues: string[],
  controlNames: string[],
  cLCatId: string[],
  seqNo: string,
  uploadFiles: UploadFiles[];
 /* file: File*/
}

export interface UploadFiles {
  file: string;
  fileName: string;
    }

export interface FormControls {
  clptlId: string;
  clCatId: string;
  cliId: string;
  category: string;
  itemName: string;
  dataType: string;
  controlType: string;
  dt: string;
  publish: string;
  editable: string;
  clpId: string;
  parentSection: string;
  classes: string;
  controlsDetailsIDs: string;
}

export interface StatusMessage {
  stType: string;
  stMessage: string;
}

export interface PaymentMethods {
  iD: string,
  img: string,
  pG_Name: string
}

export enum ePaymentMethod {
  Credit = 'credit',
  Debit = 'debit'
}

export interface Payment {
  contractNo: string,
  BankCode: string,
  customerName: string,
  property: string,
  place: string,
  contractEndDate: string,
  invoiceNo: string,
  invoiceDate: string,
  invoiceAmt: string,
  outstandingAmt: string,
  paid: string,
  invStatus: string,
  totalOutstanding: string,
  amountToPay: string,
  headerID: string,
  invoiceID: string,
  invoiceDetails: string

}

export interface Invoice {
  id: string,
  docNo: string,
  docDate: string,
  docAmount: string,
  description: string,
  docType: string
}
export interface PaymentResponseAQ extends SimpleResponse
{
  paymentMethods: PaymentMethods[],
  payment: Payment[] 

}

export interface JSONObjectModel {
  //id: string,
  name: string,
}

export interface JSONObjectModelResponse {
  jsonObjectModelList: JSONObjectModel[]
}
