import { Component, OnDestroy, OnInit, Renderer2, ViewChild } from '@angular/core';
import { Router, ActivatedRoute, NavigationEnd } from '@angular/router';
import { isNullOrUndefined } from 'util';
import { SimpleResponse } from '../../models/genericResponse.model';
import { currencyDetails, InitialValue, InitialValueResponse } from '../../models/initialValue';
import { DbStats, PropertiesByTypeResponse, Property, PropertyCity, PropertyElectricity, PropertyFeature, PropertyFiltersResponse, PropertyFurnicher, PropertyResponse, PropertySearchParams, PropertySearchResults, PropertySearchResultsResponse, PropertyTransactionType, PropertyType, PropertyTypeSort, QuickSummaryItem } from '../../models/property';
import { DefaultService } from '../../services/default.service';
import { UtilityService } from '../../shared/utility.service';
import { Options } from 'select2';
import { Select2OptionData } from 'ng-select2';
import { PropertyService } from '../../services/property.service';
import * as jq from 'jquery';
import { Employee } from '../../models/Employee';
import { Company1Response, CompanyResponse, Compare } from '../../models/Company';
import { CompareService } from '../../services/compare.service';
import { ConfirmBoxInitializer, DialogLayoutDisplay } from '@costlydeveloper/ngx-awesome-popup';
import { DataService } from '../../services/data.service';
import { FormBuilder, FormGroup, Validators } from '@angular/forms';
import { AgentsService } from '../../services/agents.service';
import { ContactusService } from '../../services/contactus.service';
import { ConfigDetails, ThemeColorModel, ThemeColorResponseModel } from '../../models/appConfig.model';
import { AppconfigService } from '../../shared/appconfig.service';
import { HttpErrorResponse } from '@angular/common/http';
import { isNull } from '@angular/compiler/src/output/output_ast';
import { NotificationService } from '../../shared/notification.service';
import { Email } from '../../models/EmailContactUs';
import { CountryISO, SearchCountryField } from 'ngx-intl-tel-input';
import { Title } from '@angular/platform-browser';
import { ConfigurationService } from 'src/app/shared/configuration.service';
import { convertToObject } from 'typescript/lib/tsserverlibrary';
import { FavoriteService } from 'src/app/services/favorite.service';
import { DomSanitizer, Meta, SafeHtml,SafeUrl  } from '@angular/platform-browser';
import { NgbModal } from '@ng-bootstrap/ng-bootstrap';
declare var $: any;

import { SwiperComponent } from "swiper/angular";
import Swiper from "swiper/types/swiper-class";

import SwiperCore , {
  Navigation,
  Autoplay,
  Controller,
} from 'swiper';
import { ApiResponse, ApiResponseModel } from 'src/app/models/ApiResponseModel';
SwiperCore.use([
  Navigation,
  Autoplay,
  Controller
]);

@Component({
    selector: 'app-agencydefault',
    templateUrl: './agencydefault.component.html',
    styleUrls: ['./agencydefault.component.css']
})
/** agencydefault component*/
export class AgencydefaultComponent implements OnInit, OnDestroy {
  @ViewChild('swiperRef', { static: false }) swiperRef?: SwiperComponent;
  initialValueResponse: InitialValueResponse;
  langId: number = 1;
  token: string;
  isShowDiv: boolean = false;
  isShowproperty: boolean = false;
  userid: string;
  public optionstrans: Options;
  public optionstransmx: Options;
  agentList: Array<Employee>=[];
  latestProperties2: Property[];
  public price: Select2OptionData[] = [];
  public pricemx: Select2OptionData[] = [];
  public currencyCode: string = 'BHD';
  public countryCode = '973';
  latestProperties: Property[];
  slideImages: any;
  isSpecificTheme: boolean = this._configurationService.isSpecificTheme;
  isLuban: boolean = this._configurationService.isLuban;
  SearchCountryField = SearchCountryField;
  propertyResponce: PropertyResponse;
  initialValue: InitialValue;
  themeColorResponse:ThemeColorResponseModel;
  themeColor:ThemeColorModel;
  CountryISO = CountryISO;
  preferredCountries: CountryISO[] = [CountryISO.Bahrain];
  buildingProperties: Property[];
  compoundProperties: Property[];
  officeSpaceProperties: Property[];
  resortsSpaceProperties: Property[];
  propertySearchParams: PropertySearchParams;
  propertyFiltersResponse: PropertyFiltersResponse;
  types: PropertyTransactionType[];
  private _CityFinalVal: string = '';
  currencyDetail: currencyDetails;
  private _CityVal: any;
  submitted = false;
  simpleResponseemil: SimpleResponse;
  dbStats: DbStats;
  propertyTypesSort: PropertyTypeSort[];
  sliderProperties: Property[];
  projectlist: Property[];
  otherRealState: Property[];
  normalsliderProperties: Property[];
  promosliderProperties: Property[];
  isLoLoding: boolean = true;
  public optionsPf: Options;
  public optionsPE: Options;
  public optionsCity: Options;
  public optionsct: Options;
  public optiontypes: Options;
  public optionsct1: Options;
  public optionspTTs: Options;
  branchId: number;
  propertyTypes2: PropertyType[];
  availableTypesOfType1: PropertyType[];
  propertyCity: PropertyCity[];
  propertyFurnicher: PropertyFurnicher[];
  propertyElectricity: PropertyElectricity[];
  availableTransactions: PropertyTransactionType[];
  pTTs: PropertyTransactionType[];
  private _TypesSortVal: string;
  public _typesValue: string;
  private _Types2Val: string;
  private _minTransactionsVal: string;
  private _maxTransactionsVal: string;
  private _ElectricityVal: string;
  private _FurnicherVal: string;
  formSearch: FormGroup;
  formpop: FormGroup;
  email: Email;
  isAboutUsVideo:boolean = true;
  isLoadFotter: boolean = true;
  disableSubmit:boolean = false;

  propertyByTypeResponce: PropertiesByTypeResponse;

  breakpoints = {
    650: { slidesPerView: 1, spaceBetween: 10 },
    760: { slidesPerView: 2, spaceBetween: 20 },
    1024: { slidesPerView: 3, spaceBetween: 20 },
  };


  public PriceResult = (state: Select2OptionData): JQuery | string => {
    if (state.text == 'Select Min Price')
      return jQuery('<span>' + state.text + '</span>');
    else
      return jQuery('<span>' + this.currencyCode + '  ' + state.text + '</span>');
  }
  public PriceSelection = (state: Select2OptionData): JQuery | string => {
    return jQuery('<span>' + state.text + '</span>');
  }

  public PriceResultmx = (state: Select2OptionData): JQuery | string => {
    if (state.text == 'Select Max Price')
      return jQuery('<span>' + state.text + '</span>');
    else
      return jQuery('<span>' + this.currencyCode + '  ' + state.text + '</span>');
  }
  public PriceSelectionmx = (state: Select2OptionData): JQuery | string => {
    return jQuery('<span>' + state.text + '</span>');
  }

  public FurnicherResult = (state: Select2OptionData): JQuery | string => {
    return jQuery('<span>' + state.text + '</span>');
  }
  public FurnicherSelection = (state: Select2OptionData): JQuery | string => {
    return jQuery('<span>' + state.text + '</a></span>');
  }

  isDetailsIcon: boolean = true;
  latestPropertiespop: Property[];
  quikSummary: QuickSummaryItem[];
  simpleResponse: SimpleResponse;
  propertySearchResultsResponse: PropertySearchResultsResponse;
  propertySearchResults: PropertySearchResults[];
  propertySearchitem: PropertySearchResults[];
  propertySearchResultsResponsefav: PropertySearchResultsResponse;
  propertySearchResultsfav: PropertySearchResults[];
  propertySearchitemfav: PropertySearchResults[];
  favoriteval: Compare[];
  Compareval: Compare[];
  propertyFeature: PropertyFeature[];
  hostName: string = '';
  setHostName: string = '';
  aboutUsText: SafeHtml = '';
  aboutUsIMG: SafeUrl = '';
  private configDetails: ConfigDetails;
  showcrausal: boolean=false;
  orignalVal: Property[];
  aboutUsVideo: SafeUrl = '';
  featureValue: string[];
  fvalue: string = '';
  
  constructor(private _contactusService: ContactusService, private notifyService: NotificationService, private formBuilder: FormBuilder,
    private _dataService: DataService, private _route: Router, private _defaultService: DefaultService,
    private _propertyService: PropertyService, private _compare: CompareService, private titleService: Title,
    private _agentsrvc: AgentsService, private _appconfigService: AppconfigService, private _utilityService: UtilityService, 
    private _configurationService:ConfigurationService,private renderer: Renderer2, private _favoriteService:FavoriteService, private sanitizer: DomSanitizer,
    private modalService: NgbModal) {
  }

  addItem(ev) {
    this.isLoadFotter = false;
    setTimeout( ()=> {      
      this.isLoadFotter = true;
      
    }, 100);
  }

  ngOnInit() {
    
    this.formSearch = this.formBuilder.group({
      office: [''],
      MinArea: [''],
      MaxArea: [''],
      MinBedrooms: [''],
      MaxBedrooms: [''],
      MinBathrooms: [''],
      MaxBathrooms: [''],
      nonVacant: [''],
    });
    this.formpop = this.formBuilder.group({
      fname: ['', [Validators.required]],
      phone: ['', [Validators.required]],
      email: ['', [Validators.required, Validators.email]],
      comment: ['', [Validators.required]]
    })
    this.getPrice();
    this.getPricemx();
    this.featureValue = [];

    this.langId = this._configurationService.getLanguageValue();
    this.currencyCode = this._configurationService.getSeletedCurrencyCode();
    this.countryCode = this._configurationService.getSeletedCountryCode();
    this.initialValue = this._configurationService.getInitialValues();

    this._utilityService.sendClickInfo("5", "", "", this.initialValue?.branchID,this.initialValue?.branchID.toString(),this.countryCode);
    this.GetInitialValues();
  }
  
  ngOnDestroy(): void {
    $('#myModal').modal('hide');
  }

  

  ShowHide() {
    this.isShowproperty = !this.isShowproperty;
  }


  resetFormSearch() {
    this.formSearch.reset();
    this._typesValue = "";
    this._TypesSortVal = "";
    this._typesValue = "";
    this._Types2Val = "";
    this._minTransactionsVal = "";
    this._maxTransactionsVal = "";
    this._ElectricityVal = "";
    this._FurnicherVal = "";
  }

  prepareFormSearch() {
    return this.propertySearchParams = {
      branchId: this.initialValue.branchID,
      countryCode: this.countryCode,
      contactId: '0', currentLang: this.langId, cAID: 0,
      groupedBranches: this.initialValue.groupedBranchesId, page: '',
      sort: '', type: this._typesValue, limit: '10000',
      searchTerm: isNullOrUndefined(this.formSearch.controls["office"].value) ? '' : this.formSearch.controls["office"].value,
      propertyType2: isNullOrUndefined(this._TypesSortVal) ? '' : this._TypesSortVal,
      propertyType3: isNullOrUndefined(this._Types2Val) ? '' : this._Types2Val,
      propertyLocation: isNullOrUndefined(this._CityFinalVal) ? '' : this._CityFinalVal.substring(0, this._CityFinalVal.length - 1),
      minArea: isNullOrUndefined(this.formSearch.controls["MinArea"].value) ? '' : this.formSearch.controls["MinArea"].value,
      maxArea: isNullOrUndefined(this.formSearch.controls["MaxArea"].value) ? '' : this.formSearch.controls["MaxArea"].value,
      minPrice: isNullOrUndefined(this._minTransactionsVal) ? '' : this._minTransactionsVal,
      maxPrice: isNullOrUndefined(this._maxTransactionsVal) ? '' : this._maxTransactionsVal,
      features: this.featureValue.length > 0 ? this.fvalue.slice(1) : '',
      minBeds: isNullOrUndefined(this.formSearch.controls["MinBedrooms"].value) ? '' : this.formSearch.controls["MinBedrooms"].value,
      maxBeds: isNullOrUndefined(this.formSearch.controls["MaxBedrooms"].value) ? '' : this.formSearch.controls["MaxBedrooms"].value,
      minBaths: isNullOrUndefined(this.formSearch.controls["MinBathrooms"].value) ? '' : this.formSearch.controls["MinBathrooms"].value,
      maxBaths: isNullOrUndefined(this.formSearch.controls["MaxBathrooms"].value) ? '' : this.formSearch.controls["MaxBathrooms"].value,
      electricity: isNullOrUndefined(this._ElectricityVal) ? '' : this._ElectricityVal,
      Furnicher: isNullOrUndefined(this._FurnicherVal) ? '' : this._FurnicherVal, count: false,
      nonVacant: this.formSearch.controls["nonVacant"].value,
      agentID: '', parentID: '',
      myProperty: false,
      currencyDetails: {
        selectedCcyCode: '',
        selectedCcyName: '',
        selectedCcySwift: '',
        selectedCcySwiftCodeArb: '',
        selectedCcySwiftCode: '',
        selectedCcyDecimal: '',
      }
    }
  }

  detailSearch() {
    localStorage.setItem('Qsearch', JSON.stringify(this.prepareFormSearch()));
    this._route.navigate(['/properties/0/0']);
  }

  GetInitialValues() {

    if(this.initialValue != null){

      this.userid = "0";
      this.branchId = this.initialValue.branchID;

      this._favoriteService.propertySearchResultsfavSubject.subscribe(resp=>{
        this.propertySearchResultsfav = this.propertySearchitemfav;
        if (this.propertySearchResultsfav?.length > 0) {
          this.loadFavComp(0, "1");
        }
      })


      
      this._favoriteService.propertySearchResultsCompareSubject.subscribe(resp=>{
        if(resp != null){
          this.propertySearchitem = resp;
          this.propertySearchResults = this.propertySearchitem;
          if (this.propertySearchResults.length > 0)
            this.loadFavComp(0,"2");
        }
      })

      // this.GetFavStatus("1", "100", true, "1", this.langId.toString(), 0);
      this.GetAgentsData(this.initialValue.branchID, this.langId.toString());
      this.GetDefaultData(this.initialValue.cmsCompID, this.initialValue.companyID.toString(), this.langId.toString(), this.initialValue.branchID, this.countryCode, this.userid, this.initialValue.groupedBranchesId);
      this.GetSearchFilterData(this.langId.toString(), this.initialValue.branchID, this.initialValue.groupedBranchesId, this.countryCode);
      this.GetAgencyStats(this.initialValue.branchID, this.countryCode, this.initialValue.groupedBranchesId);
      this.GetDefaultProperty1(this.initialValue.cmsCompID, this.initialValue.companyID.toString(), this.langId.toString(), this.initialValue.branchID, this.countryCode, this.userid, this.initialValue.groupedBranchesId);
      this.GetDefaultProperty2(this.initialValue.cmsCompID, this.initialValue.companyID.toString(), this.langId.toString(), this.initialValue.branchID, this.countryCode, this.userid, this.initialValue.groupedBranchesId == "0" ? "" : this.initialValue.groupedBranchesId);
      this.GetSliderProperty(this.initialValue.cmsCompID, this.initialValue.companyID.toString(), this.langId.toString(), this.initialValue.branchID, this.countryCode, this.userid, this.initialValue.groupedBranchesId);
      this.getDivText(this.langId, this.initialValue.cmsCompID);
    }else{
      this._route.navigateByUrl('/pagenotfound')
    }
  }



  GetDefaultProperty1(CmsCompID: number, compId: string, currenLang: string, branchId: number, countryCode: string, userId: string, GroupedBrancheIds: string) {

    this._defaultService.GetLatestProperties(CmsCompID, compId, currenLang, branchId, countryCode, userId, GroupedBrancheIds).then((result : ApiResponseModel<Array<Property>>)=>{
      
      if(result.StatusCode == 200){
        this.latestProperties = result?.Data
        for (var i = 0; i < this.latestProperties.length; i++) {
          this.latestProperties[i].favorite = false;
          this.latestProperties[i].compare = false;
          if (isNullOrUndefined(this.latestProperties[i].mainIMG) || this.latestProperties[i].mainIMG == "" || this.latestProperties[i].mainIMG == '') {
            this.latestProperties[i].mainIMG = 'https://cdn.aqaraats.com/images/NA.jpg';
          }
        }
        var ltPropertyrent = this.latestProperties.filter(x => x.type.id == '13');
        if (ltPropertyrent.length == 0) {
          $('#ptAll').hide();
          $('#ptRent').hide();
        }
        var ltPropertySale = this.latestProperties.filter(x => x.type.id == '14');
        if (ltPropertySale.length == 0) {
          $('#ptAll').hide();
          $('#ptSale').hide();
        }
        this.isLoLoding = false;
      }
    })

    this._defaultService.GetPropertiesByType(CmsCompID, compId, currenLang, branchId, countryCode, userId, GroupedBrancheIds).then((result) => {
      if (result) {
        
        if(result?.StatusCode== 200){
          this.propertyByTypeResponce = result?.Data;
        }
      }
    });
  }

  GetDefaultProperty2(CmsCompID: number, compId: string, currenLang: string, branchId: number, countryCode: string, userId: string, GroupedBrancheIds: string) {
    
    this._defaultService.DefaultProperty2_GetAsync(CmsCompID, compId, currenLang, branchId, countryCode, userId, GroupedBrancheIds,true).then((result) => {
      if (result) {
        //this.loadingSubscription.unsubscribe();
        this.propertyResponce = UtilityService.clone(result);
        this.latestProperties2 = this.propertyResponce.latestProperties2;

        
        this._defaultService.GetPropertiesByType(CmsCompID, compId, currenLang, branchId, countryCode, userId, GroupedBrancheIds).then((result: ApiResponseModel<PropertiesByTypeResponse>) => {
          if (result.StatusCode == 200) {
            
            this.propertyByTypeResponce = result.Data
          }else{
            this.isLoLoding = false
          }
        });
      }
    });
  }

  funcType(type) {    
    if (isNullOrUndefined(this.orignalVal))
      this.orignalVal = this.latestProperties;
    else
      this.latestProperties = this.orignalVal;
    if (!isNullOrUndefined(type) && type != '0') {
      var filterProperties = this.latestProperties.filter(x => x.type.id == type);
      this.latestProperties = filterProperties;
    }
  }

  GetAgencyStats(branchId: number, countryCode: string, GroupedBrancheIds: string) {
    this._defaultService.GetAgencyStats(branchId, countryCode, GroupedBrancheIds).then((result) => {
      if (result?.StatusCode == 200) {
        this.dbStats = result?.Data;
      }
    });
  }

  GetSearchFilterData(currenLang: string, branchId: number, GroupedBrancheIds: string, countryCode: string) {
    this._propertyService.GetSerch_FilterAsync(Number(currenLang), branchId, GroupedBrancheIds, Number(countryCode)).then((result) => {
      if (result) {
        this.propertyFiltersResponse = UtilityService.clone(result);
        //console.log("GetSearchFilterData :,", this.propertyFiltersResponse)
        this.types = this.propertyFiltersResponse.pTTs;
        this.types.unshift({ id: "0", text: "All Types" });
        this.optiontypes = {
          allowClear: false
        };
        this.propertyTypesSort = this.propertyFiltersResponse.propertyTypesSort;
        this.propertyTypesSort.unshift({ id: "0", text: "All Categories", sort: "" });
        this.optionsct = {
          allowClear: false
        };
        this.propertyTypes2 = this.propertyFiltersResponse.propertyTypes2;
        this.propertyTypes2.unshift({ id: "0", text: "All Categories" });
        this.optionsct1 = {
          allowClear: false
        };
        this.propertyCity = this.propertyFiltersResponse.propertyCitys;
        this.propertyCity.unshift({ id: "0", text: "All Areas" });
        this.optionsCity = {
          allowClear: true,
          multiple: true,
          tags: true
        };
        this.availableTypesOfType1 = this.propertyFiltersResponse.availableTypesOfType1;
        this.propertyElectricity = this.propertyFiltersResponse.electricityOptions;
        this.propertyElectricity.unshift({ id: "0", text: "Electricity Incl/excl" });
        this.optionsPE = {
          allowClear: false
        };
        this.propertyFurnicher = this.propertyFiltersResponse.furnicherOptions;
        this.propertyFurnicher.unshift({ id: "0", text: "Furniture Options" });
        this.optionsPf = {
          allowClear: false,
          templateResult: this.FurnicherResult,
          templateSelection: this.FurnicherSelection
        };
        this.pTTs = this.propertyFiltersResponse.pTTs;
        this.optionspTTs = {
          allowClear: false
        };
        this.availableTransactions = this.propertyFiltersResponse.availableTransactions;
        this.propertyFeature = this.propertyFiltersResponse.propertyFeatures;
      }
    });
  }

  GetDefaultData(CmsCompID: number, compId: string, currenLang: string, branchId: number, countryCode: string, userId: string, GroupedBrancheIds: string) {
    this._defaultService.Default_GetAsync(CmsCompID, compId, currenLang, branchId, countryCode, userId, GroupedBrancheIds).then((result : ApiResponse<PropertyResponse>) => {
      if (result?.StatusCode == 200) {
        
         this.propertyResponce = result?.Data
        for (var i = 0; i < this.propertyResponce.sliderImg.length; i++) {
          if (isNullOrUndefined(this.propertyResponce.sliderImg[i].img) || this.propertyResponce.sliderImg[i].img == "" || this.propertyResponce.sliderImg[i].img == '') {
            this.propertyResponce.sliderImg[i].img = 'https://cdn.aqaraats.com/images/NA.jpg';
          }
        }
        this.slideImages = this.propertyResponce.sliderImg
      }
    });
  }

  GetSliderProperty(CmsCompID: number, compId: string, currenLang: string, branchId: number, countryCode: string, userId: string, GroupedBrancheIds: string) {
    this._defaultService.Slider_GetAsync(CmsCompID, compId, currenLang, branchId, countryCode, userId, GroupedBrancheIds,true).then((result) => {
      if (result) {
        this.propertyResponce = UtilityService.clone(result);
        this.sliderProperties = this.propertyResponce.sliderProperties;

        if (this.isLuban){
          this.sliderProperties.forEach(property => {
            property.quickSummary = property.quickSummary.filter(qc => qc.value !== '');
          });
        }
        

        if (!isNullOrUndefined(this.sliderProperties) && this.sliderProperties.length > 0) {
          if (this.sliderProperties.length > 1)
            this.showcrausal = false;
          else
            this.showcrausal = true;          
        }
        for (var i = 0; i < this.sliderProperties.length; i++) {
          if (isNullOrUndefined(this.sliderProperties[i].mainIMG) || this.sliderProperties[i].mainIMG == "" || this.sliderProperties[i].mainIMG == '') {
            this.sliderProperties[i].mainIMG = 'https://cdn.aqaraats.com/images/NA.jpg';           
          }
        }
        this.normalsliderProperties = this.propertyResponce.sliderProperties.filter(f => f.promoIMG?.length <= 4 || f.promoIMG?.length == null);
        //console.log("this.normalsliderProperties :", this.normalsliderProperties)
        this.promosliderProperties = this.propertyResponce.sliderProperties.filter(f => f.promoIMG?.length > 4);
        //console.log("this.promosliderProperties :", this.promosliderProperties)
      }
    });
  }

  getDivText(currenLang: number,CmsCompID: number) {
    
    this._defaultService.getDivText(currenLang, CmsCompID).then((result) => {
      if (result) {
        var aboutus = UtilityService.clone(result);
        
        if (!isNullOrUndefined(aboutus) && (aboutus.messageString3 || aboutus.messageString2 || aboutus.messageString1 )){
          this.aboutUsText = this.sanitizer.bypassSecurityTrustHtml(aboutus.messageString3);        
          this.aboutUsVideo = this.sanitizer.bypassSecurityTrustResourceUrl(aboutus.messageString2);
          this.aboutUsIMG = this.sanitizer.bypassSecurityTrustUrl(aboutus.messageString1);
        }
        
      }
    });
  }

  agentsettingscrue = {
    slidesToShow: 1,
    slidesToScroll: 1,
    arrows: false,
    fade: false,
    infinite: false,
    autoplay: true,
    asNavFor: '.listing-thumbnail-slider-nav',
    autoplaySpeed: 2000,
    /*lazyLoad: 'ondemand'*/
  }

  agentsettingsThumb = {
    slidesToShow: 4,
    slidesToScroll: 1,
    infinite: false,
    asNavFor: '.listing-thumbnail-slider-main',
    dots: false,
    arrows: true,
    centerMode: false,
    focusOnSelect: true,
    autoplay: true,
    autoplaySpeed: 2000,
   /* lazyLoad: 'ondemand',*/
    responsive: [
      {
        breakpoint: 768,
        settings: {
          slidesToShow: 4,
        }
      },
    ]
  }

  settingscrue = {
    slidesToShow: 1,
    slidesToScroll: 1,
    arrows: false,
    fade: false,
    autoplay: true,
    infinite: false,
    asNavFor: '.listing-thumbnail-slider-nav',
    autoplaySpeed: 2000,
    variableWidth: true
  }

  settingsThumb = {
    slidesToShow: 4,
    slidesToScroll: 1,
    infinite: false,
    asNavFor: '.listing-thumbnail-slider-main',
    dots: false,
    arrows: true,
    centerMode: false,
    focusOnSelect: true,
    autoplay: true,
    autoplaySpeed: 2000,
    responsive: [
      {
        breakpoint: 1024,
        settings: {
          slidesToShow: 4,
        }
      },
      {
        breakpoint: 768,
        settings: {
          slidesToShow: 4,
        }
      },
      /*{
        breakpoint:400,
        settings: {
          slidesToShow:2,
        }
      }*/
    ]
  } 

  GetAgentsData(branchId, langId) {
    this._agentsrvc.AgentsListGet(branchId, langId).then((result) => {
      if (result) {
        
        var response = UtilityService.clone(result);
        //console.log("GetAgentsData in agent component :", result);
        
        for (var i = 0; i < response.employee.length; i++) {
          var imgPath = response.employee[i].image.split('agents/');
          if (imgPath[1] == '') {
            this.agentList[i] = response.employee[i];
            this.agentList[i].image = 'https://cdn.aqaraats.com/images/NA.jpg';
          } else {
            this.agentList[i] = response.employee[i];
          }
        }
      }
    });
  }

  settingsDefault = {
    slidesToShow: 1,
    slidesToScroll: 1,
    arrows: false,
    fade: false,
    asNavFor: '.listing-thumbnail-slider-nav',
    autoplay: true,
    infinite: false,
    opacity: 2,
    autoplaySpeed: 20000,
    /*lazyLoad: 'progressive'*/
  }

  OurProperty = {
    slidesToShow: 3,
    slidesToScroll: 1,
    autoplay: false,
    arrows: true,
    fade: false,
    prevArrow: '.listings .slider-prev1',
    nextArrow: '.listings .slider-next1',
    dots: false,
    responsive: [
      {
        breakpoint: 991,
        settings: {
          slidesToShow: 2
        }
      },
      {
        breakpoint: 768,
        settings: {
          slidesToShow: 1,
          dots: true
        }
      }
    ]
  }

  agents = {
    slidesToShow: 3,
    slidesToScroll: 1,
    arrows: true,
    prevArrow: '.listings .slider-prev',
    nextArrow: '.listings .slider-next',
    dots: false,
    autoplay: false,
    fade: false,
    infinite: false,
    responsive: [
      {
        breakpoint: 991,
        settings: {
          slidesToShow: 2
        }
      },
      {
        breakpoint: 768,
        settings: {
          slidesToShow:2,
          dots: true
        }
      },
      {
        breakpoint: 575,
        settings: {
          slidesToShow: 1,
          dots: true
        }
      }
    ]
  }

  hamalaSetting = {
    slidesToShow: 1,
    slidesToScroll: 1,
    arrows: true,
    autoplay: true,
    dots: false,
    dotsClass: "d-flex slick-dots",
  }

  ShowDetail(id) {
    if (id == '1')
      this.isDetailsIcon = true;
    else
      this.isDetailsIcon = false;
  }

  showHide(id) {
    switch (id) {
      case 1:
        $('#lbtab1').addClass('active');
        $('#lbtab11').removeClass('active');
        $('#lbtab111').removeClass('active');
        $('#lbtab2').removeClass('active');
        $('#lbtab3').removeClass('active');
        $('#lbtab4').removeClass('active');
        $('#lbtab5').removeClass('active');
        $('#lbtab6').removeClass('active');
        $('#lbtab7').removeClass('active');

        $('#Properties').addClass('active');
        $('#Buildings').removeClass('active');
        $('#Compounds').removeClass('active');
        $('#Office_Space').removeClass('active');
        $('#Resorts').removeClass('active');
        $('#projects').removeClass('active');
        $('#otherRealstate').removeClass('active');
        break;
      case 2:
        $('#lbtab1').removeClass('active');
        $('#lbtab11').addClass('active');
        $('#lbtab111').removeClass('active');
        $('#lbtab2').removeClass('active');
        $('#lbtab3').removeClass('active');
        $('#lbtab4').removeClass('active');
        $('#lbtab5').removeClass('active');
        $('#lbtab6').removeClass('active');
        $('#lbtab7').removeClass('active');

        $('#Properties').addClass('active');
        $('#Buildings').removeClass('active');
        $('#Compounds').removeClass('active');
        $('#Office_Space').removeClass('active');
        $('#Resorts').removeClass('active');
        $('#projects').removeClass('active');
        $('#otherRealstate').removeClass('active');
        
        break;
      case 3:
        $('#lbtab1').removeClass('active');
        $('#lbtab11').removeClass('active');
        $('#lbtab111').addClass('active');
        $('#lbtab2').removeClass('active');
        $('#lbtab3').removeClass('active');
        $('#lbtab4').removeClass('active');
        $('#lbtab5').removeClass('active');
        $('#lbtab6').removeClass('active');
        $('#lbtab7').removeClass('active');

        $('#Properties').addClass('active');
        $('#Buildings').removeClass('active');
        $('#Compounds').removeClass('active');
        $('#Office_Space').removeClass('active');
        $('#Resorts').removeClass('active');
        $('#projects').removeClass('active');
        $('#otherRealstate').removeClass('active');
        break;
      case 4:
        $('#lbtab1').removeClass('active');
        $('#lbtab11').removeClass('active');
        $('#lbtab111').removeClass('active');
        $('#lbtab2').addClass('active');
        $('#lbtab3').removeClass('active');
        $('#lbtab4').removeClass('active');
        $('#lbtab5').removeClass('active');
        $('#lbtab6').removeClass('active');
        $('#lbtab7').removeClass('active');

        $('#Properties').removeClass('active');
        $('#Buildings').addClass('active');
        $('#Compounds').removeClass('active');
        $('#Office_Space').removeClass('active');
        $('#Resorts').removeClass('active');
        $('#projects').removeClass('active');
        $('#otherRealstate').removeClass('active');
        
        break;
      case 5:
        $('#lbtab1').removeClass('active');
        $('#lbtab11').removeClass('active');
        $('#lbtab111').removeClass('active');
        $('#lbtab2').removeClass('active');
        $('#lbtab3').addClass('active');
        $('#lbtab4').removeClass('active');
        $('#lbtab5').removeClass('active');
        $('#lbtab6').removeClass('active');
        $('#lbtab7').removeClass('active');

        $('#Properties').removeClass('active');
        $('#Buildings').removeClass('active');
        $('#Compounds').addClass('active');
        $('#Office_Space').removeClass('active');
        $('#Resorts').removeClass('active');
        $('#projects').removeClass('active');
        $('#otherRealstate').removeClass('active');
        break;
      case 6:
        $('#lbtab1').removeClass('active');
        $('#lbtab11').removeClass('active');
        $('#lbtab111').removeClass('active');
        $('#lbtab2').removeClass('active');
        $('#lbtab3').removeClass('active');
        $('#lbtab4').addClass('active');
        $('#lbtab5').removeClass('active');
        $('#lbtab6').removeClass('active');
        $('#lbtab7').removeClass('active');

        $('#Properties').removeClass('active');
        $('#Buildings').removeClass('active');
        $('#Compounds').removeClass('active');
        $('#Office_Space').addClass('active');
        $('#Resorts').removeClass('active');
        $('#projects').removeClass('active');
        $('#otherRealstate').removeClass('active');
        break;
      case 7:
        $('#lbtab1').removeClass('active');
        $('#lbtab11').removeClass('active');
        $('#lbtab111').removeClass('active');
        $('#lbtab2').removeClass('active');
        $('#lbtab3').removeClass('active');
        $('#lbtab4').removeClass('active');
        $('#lbtab5').addClass('active');
        $('#lbtab6').removeClass('active');
        $('#lbtab7').removeClass('active');

        $('#Properties').removeClass('active');
        $('#Buildings').removeClass('active');
        $('#Compounds').removeClass('active');
        $('#Office_Space').removeClass('active');
        $('#Resorts').addClass('active');
        $('#projects').removeClass('active');
        $('#otherRealstate').removeClass('active');
        break;
      case 8:
        $('#lbtab1').removeClass('active');
        $('#lbtab11').removeClass('active');
        $('#lbtab111').removeClass('active');
        $('#lbtab2').removeClass('active');
        $('#lbtab3').removeClass('active');
        $('#lbtab4').removeClass('active');
        $('#lbtab5').removeClass('active');
        $('#lbtab6').addClass('active');
        $('#lbtab7').removeClass('active');

        $('#Properties').removeClass('active');
        $('#Buildings').removeClass('active');
        $('#Compounds').removeClass('active');
        $('#Office_Space').removeClass('active');
        $('#Resorts').removeClass('active');
        $('#projects').addClass('active');
        $('#otherRealstate').removeClass('active');
        break;
      case 9:
        $('#lbtab1').removeClass('active');
        $('#lbtab11').removeClass('active');
        $('#lbtab111').removeClass('active');
        $('#lbtab2').removeClass('active');
        $('#lbtab3').removeClass('active');
        $('#lbtab4').removeClass('active');
        $('#lbtab5').removeClass('active');
        $('#lbtab6').removeClass('active');
        $('#lbtab7').addClass('active');

        $('#Properties').removeClass('active');
        $('#Buildings').removeClass('active');
        $('#Compounds').removeClass('active');
        $('#Office_Space').removeClass('active');
        $('#Resorts').removeClass('active');
        $('#projects').removeClass('active');
        $('#otherRealstate').addClass('active');
        
        break;
      default:   
        break;
    }
  }

  ecodeuri(id, fromtype) {
    const queryParams = { id };
  
    if (fromtype == '1') {
      queryParams['fromtype'] = fromtype;
    }
  
    this._route.navigate(['/propertydetail'], { queryParams });
  }

  async GetCompare(Id, type, index, ptype) {
    let addfav = 1;
    if (type == 1) {
      if (!isNullOrUndefined(this.propertySearchResultsfav)) {
        let fp = this.propertySearchResultsfav.filter(p => p.id == Id && p.isFavorite == true)
        if (fp.length > 0) {
          addfav = 0;
          $('#fav_' + Id).removeClass('active-icon');
        }
      }
    }
    else {
      if (!isNullOrUndefined(this.propertySearchResults)) {
        let cmp = this.propertySearchResults.filter(p => p.id == Id && p.isCompare == true)
        if (cmp.length > 0) {
          addfav = 0;
          $('#camp_' + Id).removeClass('active-icon');
        }
      }
    }

    this._favoriteService.addToFavoriteOrCompare(Id,addfav,type,this.initialValue?.branchID, this.langId.toString(), this.initialValue?.currencyDetail?.selectedCcyCode, this.initialValue?.groupedBranchesId,ptype,index);

  }

  //@HostListener('window:load', ['$event'])
  loadFavComp(Id, type) {
    if (type == 1) {
      this.propertySearchResultsfav.forEach((currentValue, index) => {
        if (currentValue.isFavorite)
          $('#fav_' + currentValue.id).addClass('active-icon');
      });
    }
    else if (type == 2) {
      this.propertySearchResults.forEach((currentValue, index) => {
        if (currentValue.isCompare)
          $('#camp_' + currentValue.id).addClass('active-icon');

      });
    }
  }







  toggleLocationDiv() {
    this.isShowDiv = !this.isShowDiv;
  }
  addCallNumber(mobile, i) {
    $('#ane_' + i).hide();
    $('#an_' + i).show();
    $('#sp_' + i).html(mobile);
  }
  addCallagentNumber(mobile, i) {
    $('#ane_' + i).show();
    $('#an_' + i).hide();
    $('#sp_' + i).html('');
  }

  getpropertybyId(id) {
    this.latestPropertiespop = this.latestProperties.filter(x => x.id == id);
    this.formpop.get("comment").setValue('Hi I found your property with reference number: ' + this.latestPropertiespop[0].code + ' on Aqarat website.Please contact me. Thank you.');
  }

  GetFeatureId($event) {
    this.featureValue.push($event.target.value);
    this.fvalue = this.fvalue + ',' + $event.target.value
  }

  get typesValue(): string {
    return this._typesValue;
  }

  set typesValue(value: string) {
    this._typesValue = value;
  }


  get TypesSortVal(): string {
    return this._TypesSortVal;
  }

  set TypesSortVal(value: string) {
    this._TypesSortVal = value;
  }

  get Types2Val(): string {
    return this._Types2Val;
  }

  set Types2Val(value: string) {
    this._Types2Val = value;
  }

  get minTransactionsVal(): string {
    return this._minTransactionsVal;
  }

  set minTransactionsVal(value: string) {
    this._minTransactionsVal = value;
  }

  get FurnicherVal(): string {
    return this._FurnicherVal;
  }

  set FurnicherVal(value: string) {
    this._FurnicherVal = value;
  }

  get maxTransactionsVal(): string {
    return this._maxTransactionsVal;
  }

  set maxTransactionsVal(value: string) {
    this._maxTransactionsVal = value;
  }

  get ElectricityVal(): string {
    return this._ElectricityVal;
  }

  set ElectricityVal(value: string) {
    this._ElectricityVal = value;
  }

  get CityVal(): any {
    return this._CityVal;
  }
  set CityVal(value: any) {
    this._CityVal = value
    if (!isNullOrUndefined(value)) {
      for (let i = 0; i < value.length; i++) {
        this._CityFinalVal += value[i] + ','
      }
    }

  }

  propertyAll() {
    localStorage.setItem('Qsearch', JSON.stringify(this.prepareFormSearchAll()));
    this._route.navigate(['/properties/0/0']);

  }

  prepareFormSearchAll() {
    return this.propertySearchParams = {
      branchId: this.initialValue.branchID,
      countryCode: this.countryCode,
      contactId: '0', currentLang: this.langId, cAID: 0,
      groupedBranches: this.initialValue.groupedBranchesId, page: '',
      sort: '', type: '', limit: '4000',
      searchTerm: '',
      propertyType2: '',
      propertyType3: '',
      propertyLocation: '',
      minArea: '',
      maxArea: '',
      minPrice: '',
      maxPrice: '', features: '',
      minBeds: '',
      maxBeds: '',
      minBaths: '',
      maxBaths: '',
      electricity: '',
      Furnicher: '', count: true,
      nonVacant: '',
      agentID: '', parentID: '',
      myProperty: false,
      currencyDetails: {
        selectedCcyCode: '',
        selectedCcyName: '',
        selectedCcySwift: '',
        selectedCcySwiftCodeArb: '',
        selectedCcySwiftCode: '',
        selectedCcyDecimal: '',
      }
    }
  }

  getPrice() {
    this._dataService.getpriceList(this.langId.toString()).subscribe((result) => {
      if (result) {
        this.price = UtilityService.clone(result);
        this.optionstrans = {
          width: '200',
          templateResult: this.PriceResult,
          templateSelection: this.PriceSelection
        };
      }
    })
  }

  getPricemx() {
    this._dataService.getpriceListmax(this.langId.toString()).subscribe((result) => {
      if (result) {
        this.pricemx = UtilityService.clone(result);
        this.optionstransmx = {
          width: '200',
          templateResult: this.PriceResultmx,
          templateSelection: this.PriceSelectionmx
        };
      }
    })
  }

  get f2() {
    return this.formpop.controls;
  }

  submit(aname, aemail, pid, pcode, ptype, aarea, curl,price,agentId,saleOrRent,branchId) {
    
    this.submitted = true;
    this.disableSubmit = true;
    this.prepareEmail(aname, aemail, pid, pcode, ptype, aarea, curl,price,price,agentId,1,saleOrRent,branchId);
    if (this.formpop.invalid) {
      this.disableSubmit = false;
      return;
    }
    this._contactusService.SenemailSubmit(this.prepareEmail(aname, aemail, pid, pcode, ptype, aarea, curl,price,price,agentId,1,saleOrRent,branchId))
    .then((result) => {
      this.disableSubmit = false;
      if (result) {
        this.simpleResponseemil = UtilityService.clone(result);
        if (this.simpleResponseemil.messageBool) {
          this.notifyService.showSuccess("Mail send successfully !!", "")
          $('#myModal').modal('hide');
          //$('#myModal').hide();
          //$('.modal-backdrop').remove('modal-backdrop');
        }
        else
          this.notifyService.showSuccess("some thing went wrong.", "");

      }
    });
  }

  prepareEmail(aname, aemail, pid, pcode, ptype, aarea, curl, minPrice, maxPrice, agentId, typeOfRequest, saleOrRent,branchID) {

    return this.email = {
      CmName: this.formpop.controls["fname"].value,
      CmEmail: this.formpop.controls["email"].value,
      CmPhone: this.formpop.controls["phone"].value?.number,
      AgentName: aname,
      AgentEmail: aemail,
      PropertyID: pid,
      PropertyCode: pcode,
      PropertyType: ptype,
      PropertyArea: aarea,
      ClientURL: curl,
      Referrer: '',
      branchId: branchID,
      selectedSwiftCode: '',
      currentLangId: this.langId.toString(),
      recapchaResponse: this.token,
      message: this.formpop.controls["comment"].value,
      subscription: true,
      loginId: '1',
      minPrice:minPrice,
      maxPrice:maxPrice,
      agentId:agentId,
      typeOfRequest:"1",
      saleOrRent:saleOrRent,
      subBranch:this.initialValue?.branchID.toString()
    }
  }

  agencyRoute(routeValue) {
    switch (routeValue) {
      case "hamala":        
        localStorage.setItem('hostname', routeValue+'.'+this.setHostName);
        break;
      case "ahproperties":        
        localStorage.setItem('hostname', routeValue + '.' + this.setHostName);
        break;
      case "alhamriya":       
        localStorage.setItem('hostname', routeValue + '.' + this.setHostName);
        break;
      case "alliwan":        
        localStorage.setItem('hostname', routeValue + '.' + this.setHostName);
        break;
      case "aldaaysi":       
        localStorage.setItem('hostname', routeValue + '.' + this.setHostName);        
        break;
      case "askrealestate":
        //this.showcrausal = false;
        localStorage.setItem('hostname', routeValue + '.' + this.setHostName);      
        break;
      case "carltonrealestate":
        //this.showcrausal = false;
        localStorage.setItem('hostname', routeValue + '.' + this.setHostName);      
        break;
      case "mannaiproperties":
        //this.showcrausal = false;
        localStorage.setItem('hostname', routeValue + '.' + this.setHostName);
        break;
      case "realsearch":
        //this.showcrausal = false;
        localStorage.setItem('hostname', routeValue + '.' + this.setHostName);
      
        break;
      case "taiba":
        //this.showcrausal = false;
        localStorage.setItem('hostname', routeValue + '.' + this.setHostName);
        break;
      case "tashyeedproperties":
        //this.showcrausal = true;
        localStorage.setItem('hostname', routeValue + '.' + this.setHostName);
        break;
      case "regus":
        //this.showcrausal = false;
        localStorage.setItem('hostname', routeValue + '.' + this.setHostName);       
      case "myrealestate":
        //this.showcrausal = false;
        localStorage.setItem('hostname', routeValue + '.' + this.setHostName);
        break;
      case "binalsheikh":
        //this.showcrausal = false;
        localStorage.setItem('hostname', routeValue + '.' + this.setHostName);
        break;
      default:
        break;
    }
  }

  // Video Popup
	isOpen = false;
  openPopup(): void {
      this.isOpen = true;
  }
  closePopup(): void {
      this.isOpen = false;
  }

}

