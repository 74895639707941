import { Component, OnInit } from '@angular/core';
import { ActivatedRoute, NavigationEnd, Router } from '@angular/router';
import { Aboutus, AboutusResponse, SideMenuItem } from '../../models/aboutUs';
import { InitialValue, InitialValueResponse } from '../../models/initialValue';
import { AboutusService } from '../../services/aboutus.service';
import { DefaultService } from '../../services/default.service';
import { UtilityService } from '../../shared/utility.service';
import { ConfigurationService } from 'src/app/shared/configuration.service';

@Component({
    selector: 'app-aboutus',
    templateUrl: './aboutus.component.html',
    styleUrls: ['./aboutus.component.css']
})
/** aboutus component*/
export class AboutusComponent implements OnInit {

  aboutusResponse: AboutusResponse
  aboutus: Aboutus;
  sideMenuItem: SideMenuItem[];
  initialValueResponse: InitialValueResponse;
  initialValue: InitialValue;
  paramId: number;
  isParamId: number = 0;
  isaboutusshow: boolean = false;
  langId: number = 1;
  hostName: string = '';
  isloading = true
    /** aboutus ctor */
  constructor(private _route1: Router, private _route: ActivatedRoute, private _aboutusService: AboutusService
    , private _defaultService: DefaultService, private _configurationService : ConfigurationService) {
    
    this.hostName = localStorage.getItem('hostname');
    


    
  }
  ngOnInit() {
    
    // $('html, body').animate({
    //   scrollTop: $("body").offset().top = 0
    // }, 0);
    //this.GetInitialValues("aqaraat");

    this.langId = this._configurationService.getLanguageValue();


    this._route.paramMap.subscribe(async params => {
      if (params.has('ID')) {
        this.paramId = Number(params.get('ID'));
        this.initialValue = this._configurationService.getInitialValues();
        this.GetInitialValues();
      }
    });

  }
  GetAboutusData(id: number, cmsCompID: number, currenLang: string) {
    this._aboutusService.AboutUs_GetAsync(id, cmsCompID, Number(currenLang)).then((result) => {
      if (result) {
        

        this.aboutusResponse = UtilityService.clone(result);

        this.aboutus = this.aboutusResponse.aboutus;
        this.sideMenuItem = this.aboutusResponse.aboutus.sideMenuItems;
        this.isaboutusshow = true;
        this.isloading = false
      }
    });
  }

  GetInitialValues() {
    this.GetAboutusData(this.paramId, this.initialValue.cmsCompID, this.langId.toString());
    this.isParamId = this.paramId;
  }
  ecodeuri(url: string) {
    var urlval =  url;
    if (urlval.indexOf("?") !== -1) {
      this._route1.navigate(['/News'], { queryParams: { 'PropertyNews': true } });
    }
    else
      this._route1.navigate([decodeURI("/" + url)]);
    
  }
}
