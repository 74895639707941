import { Component, OnInit } from '@angular/core';
import { ActivatedRoute, NavigationEnd, Router } from '@angular/router';
import { SideMenuItem } from '../../models/aboutUs';
import { InitialValue, InitialValueResponse } from '../../models/initialValue';
import { Media, MediaResponse, News} from '../../models/News';
import { DefaultService } from '../../services/default.service';
import { NewsService } from '../../services/news.service';
import { UtilityService } from '../../shared/utility.service';
import { ConfigurationService } from 'src/app/shared/configuration.service';

@Component({
    selector: 'app-media',
    templateUrl: './media.component.html',
    styleUrls: ['./media.component.css']
})
/** media component*/
export class MediaComponent implements OnInit {
  mediaResponse: MediaResponse;
  sideMenu: SideMenuItem[];
  mediaDetails: Media[];
  initialValueResponse: InitialValueResponse;
  initialValue: InitialValue;
  paramId: string;
  langId: number = 1;
  hostName: string = '';
    /** media ctor */
  constructor(private _route: ActivatedRoute, 
    private _route1: Router, 
    private _defaultService: DefaultService, 
    private _newsService: NewsService, 
    private _configurationService : ConfigurationService) {

  }
  ngOnInit(): void {

    // $('html, body').animate({
    //   scrollTop: $("body").offset().top = 0
    // }, 0);

    this.langId = this._configurationService.getLanguageValue();
    this.hostName = localStorage.getItem('hostname');
    this._route.paramMap.subscribe(async params => {
      if (params.has('Id')) {

        this.paramId = params.get('Id');
      }      
      this.initialValue = this._configurationService.getInitialValues();
      this.GetInitialValues();
    });


  }

  GetInitialValues() {
    this.GetMediaData(this.paramId, this.initialValue.cmsCompID.toString(), this.langId.toString(), this.initialValue.branchID);
  }

  GetMediaData(id: string, cmsCompID: string, currenLang: string, branchId: number) {
    this._newsService.Media_GetAsync(id, cmsCompID, currenLang, branchId).then((result) => {
      if (result) {
        this.mediaResponse = UtilityService.clone(result);
        this.sideMenu = this.mediaResponse.sideMenu;
        this.mediaDetails = this.mediaResponse.mediaDetails;
      }
    });
  }
}
