import { Component, OnInit } from '@angular/core';
import { FormControl, FormGroup } from '@angular/forms';

@Component({
    selector: 'app-test',
    templateUrl: './test.component.html',
    styleUrls: ['./test.component.css']
})
/** test component*/
export class TestComponent implements OnInit {

  
    /** test ctor */
    constructor() {

  }

  ngOnInit() {
  }

  dateRangeChange(dateRangeStart: HTMLInputElement, dateRangeEnd: HTMLInputElement) {
    var startDt = dateRangeStart.value;
    var endDt = dateRangeEnd.value;   
  }
}
