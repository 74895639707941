import { Component, ElementRef, OnInit, ViewChild } from '@angular/core';
import { ActivatedRoute, NavigationEnd, Router } from '@angular/router';
import { isNullOrUndefined } from 'util';
import { currencyDetails, InitialValue, InitialValueResponse } from '../../models/initialValue';
import { News, NewsResponse } from '../../models/News';
import { DefaultService } from '../../services/default.service';
import { NewsService } from '../../services/news.service';
import { UtilityService } from '../../shared/utility.service';
import { ConfigurationService } from 'src/app/shared/configuration.service';

@Component({
    selector: 'app-newsdetail',
    templateUrl: './newsdetail.component.html',
    styleUrls: ['./newsdetail.component.css']
})
/** newsdetail component*/
export class NewsdetailComponent implements OnInit {
  // @ViewChild('currentNewsRef') currentNewsRef: ElementRef | undefined;
  // @ViewChild('otherNewsRef') otherNewsRef: ElementRef | undefined;

  paramId: string;
  property: string;
  initialValueResponse: InitialValueResponse;
  initialValue: InitialValue;
  currencyDetail: currencyDetails;
  newsResponse: NewsResponse;
  latestNews: News[];
  otherNews: News[];
  news: News[];
  public currencyCode: string = 'BHD';
  langId: number = 1;
  hostName: string = '';
  countryCode:string = '';

  /** newsdetail ctor */
  constructor (
    private _route: ActivatedRoute, 
    private _route1: Router,
    private _defaultService: DefaultService, 
    private _newsService: NewsService,
    private _configurationService : ConfigurationService
  ) {

    this.hostName = localStorage.getItem('hostname');


    
  }

  ngOnInit(): void {
    // this.GotoTop();

    this.langId = this._configurationService.getLanguageValue();

    this._route.paramMap.subscribe(async params => {
      if (params.has('Id')) {

        this.paramId = params.get('Id') == '0' ? '' : params.get('Id');
    }
    if (params.has('PropertyNews')) {

      this.property = params.get('PropertyNews') == '0' ? '' : params.get('PropertyNews');
    }
    this.initialValue = this._configurationService.getInitialValues();
    this.countryCode = this._configurationService.getSeletedCountryCode();
    this.GetInitialValues();
    this.GotoTop();
  });
  }

  GotoTop() {
    $('html, body').animate({
      scrollTop: $("body").offset().top = 0
    }, 0);
  }

  GetInitialValues() {
    
    this.GetNewsData(this.property ? 1 : 0, this.initialValue.branchID, this.langId.toString(), this.countryCode, this.initialValue.cmsCompID, Number(this.paramId));
  }

  GetNewsData(id: number, branchId: number, currenLang: string, countryCode: string, cmsCompID: number, newsId: number) {
    
    this._newsService.News_GetAsync(id, branchId, currenLang, countryCode, cmsCompID, newsId).then((result) => {
      if (result) {
        this.newsResponse = UtilityService.clone(result);
        // if(isNullOrUndefined(this.newsResponse?.news?.body) ){
        //   this._route1.navigateByUrl('/default');
        // }
        this.latestNews = this.newsResponse.latestNews;
        this.otherNews = this.newsResponse.otherNews;

        if (!isNullOrUndefined(this.otherNews))
          this.news = this.otherNews.filter(x => x.id == newsId.toString());
        if(this.news?.length == 0){
          this._route1.navigateByUrl('/default');
        }
      }
    });
  }
}
