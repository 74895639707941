import { AfterViewInit, Component, Input, OnInit, ChangeDetectorRef } from '@angular/core';
import { FormBuilder, FormGroup, Validators } from '@angular/forms';
import { Router } from '@angular/router';
import { CountryISO, SearchCountryField } from 'ngx-intl-tel-input';
import { Email, LubanEmail } from 'src/app/models/EmailContactUs';
import { SimpleResponse } from 'src/app/models/genericResponse.model';
import { InitialValue } from 'src/app/models/initialValue';
import { Property, PropertySearchResults } from 'src/app/models/property';
import { ContactusService } from 'src/app/services/contactus.service';
import { FavoriteService } from 'src/app/services/favorite.service';
import { ConfigurationService } from 'src/app/shared/configuration.service';
import { NotificationService } from 'src/app/shared/notification.service';
import { UtilityService } from 'src/app/shared/utility.service';
import { isNullOrUndefined } from 'util';
declare var $: any;
declare var bootstrap: any;
import { SwiperComponent } from "swiper/angular";
import Swiper from "swiper/types/swiper-class";
import { DefaultService } from 'src/app/services/default.service';

import SwiperCore , {
  Navigation,
  Autoplay,
  Controller,
} from 'swiper';
import { NgbModal } from '@ng-bootstrap/ng-bootstrap';
import { ContactModalComponent } from '../contact-modal/contact-modal.component';
SwiperCore.use([
  Navigation,
  Autoplay,
  Controller
]);
@Component({
  selector: 'app-properties-listing',
  templateUrl: './properties-listing.component.html',
  styleUrls: ['./properties-listing.component.css']
})
export class PropertiesListingComponent implements OnInit, AfterViewInit {

  @Input() propertySearchResultsGrid : PropertySearchResults[];
  @Input() isShowCrousalView : boolean

  propertySearchResultsfav: PropertySearchResults[];
  propertySearchResultsCompair: PropertySearchResults[];
  initialValue: InitialValue;
  langId: number;
  currencyCode: string;
  countryCode: string;
  latestPropertiespop: Property;
  CountryISO = CountryISO;
  preferredCountries: CountryISO[] = [CountryISO.Bahrain];
  SearchCountryField = SearchCountryField;
  token: string;
  disableSubmit:boolean = false;
  settingsDefault = {
    "slidesToShow": 1,
    "slidesToScroll": 1,
    "dots": false,
    "infinite": false,
    "autoplay": false,
  }
  submitted: boolean = false;
  formpop: FormGroup;
  formLubanPop: FormGroup;
  isLuban: boolean = this._configurationService.isLuban;
  nationalityTypes: any[] ;
  nationalityOptions: any;
  nationalityValue: string = '';
  isSupported = false;
  supportInfo : any = null;
  email: Email;
  lubanEmail: LubanEmail;
  simpleResponseemil: SimpleResponse;
  url:string = new URL(window.location.href).hostname
  placetext: string = '';
  urlProp =  `${ `${window.location.origin}/propertydetail?id=`}`


  constructor(private _favoriteService :FavoriteService, private _configurationService:ConfigurationService, 
    private _utilityService: UtilityService, private _route: Router,private _contactusService: ContactusService,
    private notifyService: NotificationService, private formBuilder: FormBuilder,private cdRef: ChangeDetectorRef,
    private _defaultService: DefaultService, private modalService: NgbModal) { }



  ngAfterViewInit(): void {
          if(this.propertySearchResultsGrid?.length > 0 && this.propertySearchResultsfav?.length > 0){
        this.highLightFav(this.propertySearchResultsfav);
      }

      if(this.propertySearchResultsGrid?.length > 0 && this.propertySearchResultsCompair?.length > 0){
        this.highLightFav(this.propertySearchResultsCompair);
      }
  }


  public ReserveProperty(property: any) {
    console.log(property);
    this._utilityService.sendClickInfo("1", property.id, "", this.initialValue?.branchID,"",this.countryCode);
    const modalRef = this.modalService.open(ContactModalComponent,{ windowClass: 'custom-modal' });
    let myProperty: any = {
      "id": property.id,
      "code": null,
      "name": property?.name || '',
      "description": property?.description || '',
      "type": {
          "id": property?.aqarType,
          "text": property?.aqarType === 14 ? "Sales" : "Rental"
      },
      "type2": property?.pType,
      "price": property?.aqarPrice,
      "price2": property?.price2,
      "price3": property?.price3,
      "price4": property?.price4,
      "mainIMG": property?.img || '',
      "address": property?.estateAddress || '',
      "branchName": property?.branchName || '',
      "branchId":  property?.branchId || '',
      "website": property?.website || '',
      "customerId": property?.customerId || '',
      "agent": property?.agent || {},
      "status": property?.status,
    }
    modalRef.componentInstance.myProperty = myProperty;
  }


  get f2() {
    return this.formpop.controls;
  }

  get f3(){
    return this.formLubanPop.controls;
  }

  ngOnInit(): void {
    this.formpop = this.formBuilder.group({
      fname: ['', [Validators.required]],
      phone: ['', [Validators.required]],
      email: ['', [Validators.required, Validators.email]],
      comment: ['', [Validators.required]]
    })

    this.formLubanPop = this.formBuilder.group({
      oid: ['', [Validators.required]],
      omaniNationality: ['0', [Validators.required]],
      address: ['', [Validators.required]],
      fname: ['', [Validators.required]],
      phone: ['', [Validators.required]],
      email: ['', [Validators.required, Validators.email]],
      comment: ['', [Validators.required]]
    })

    this.initialValue = this._configurationService.getInitialValues();

    this.langId = this._configurationService.getLanguageValue();
    this.currencyCode = this._configurationService.getSeletedCurrencyCode();
    this.countryCode = this._configurationService.getSeletedCountryCode();

    this._favoriteService.propertySearchResultsfavSubject.subscribe(resp=>{
      if(resp != null){
        this.propertySearchResultsfav = resp;
        if(this.propertySearchResultsfav?.length > 0 && this.propertySearchResultsGrid?.length >0){
          this.highLightFav(this.propertySearchResultsfav);
        }
      }
    })

    this._favoriteService.propertySearchResultsCompareSubject.subscribe(resp=>{
      if(resp != null){
        this.propertySearchResultsCompair = resp
        if(this.propertySearchResultsCompair?.length > 0 && this.propertySearchResultsGrid?.length > 0){
          this.highLightFav(this.propertySearchResultsCompair);
        }
      }
    })


    this.nationalityTypes = [
      { text: this.langId == 1 ? 'Omani':'عماني', id: '1' },
      { text: this.langId == 1 ? 'None Omani' : 'غير عماني', id: '2' }
    ];

    this.nationalityOptions = {
      placeholder: this.langId == 1 ?'Nationality' : 'الجنسية',
      width: '100%',
      // Other options...
    }

  }

  onOIDChange(oid: string){
    this.isSupported = false;
    this.supportInfo = null
    this.formLubanPop.get('address').enable();
    this.formLubanPop.get("fname").enable();

    if(this.nationalityValue == "1" && oid.length > 6 ){
      this._defaultService.GetSupportInfo(oid).then((result : any) => {
        if (result) {
          this.isSupported = true;
          this.supportInfo = result;
          this.notifyService.showSuccess(this.langId == 1 ? "You are among the list of eligible people" : "انت من ضمن قائمة المستحقين", "");
          
          this.formLubanPop.get("fname").setValue(result?.Name.split(' ')[0]);
          this.formLubanPop.get("address").setValue(result?.Area);
          this.formLubanPop.get('address').disable();
          this.formLubanPop.get("fname").disable();
          
          if(result?.Mobile){
            this.formLubanPop.get("phone").setValue(result?.Mobile);
            this.cdRef.detectChanges();
          }
          
        } else{
          this.isSupported = false;
        }
      });

    }
  }


  onRecaptchaSuccess(token: string) {
    this.token = token;
  }


  async GetCompare(Id, type, index, ptype) {
    
    let addfav = 1;
    if (type == 1) {
      if (!isNullOrUndefined(this.propertySearchResultsfav)) {
        let fp = this.propertySearchResultsfav.filter(p => p.id == Id && p.isFavorite == true)
        if (fp.length > 0) {
          addfav = 0;
          $('#fav_' + Id).removeClass('active-icon');
        }
      }
    }
    else {
      if (!isNullOrUndefined(this.propertySearchResultsCompair)) {
        let cmp = this.propertySearchResultsCompair.filter(p => p.id == Id && p.isCompare == true)
        if (cmp.length > 0) {
          addfav = 0;
          $('#camp_' + Id).removeClass('active-icon');
        }
      }
    }

    this._favoriteService.addToFavoriteOrCompare(Id,addfav,type,this.initialValue?.branchID, this.langId.toString(), this.initialValue?.currencyDetail?.selectedCcyCode, this.initialValue?.groupedBranchesId,ptype,index);
  }

  prepareEmail(aname, aemail, pid, pcode, ptype, aarea, curl, minPrice, maxPrice, agentId, typeOfRequest, saleOrRent,branchId) {

    return this.email = {
      CmName: this.formpop.controls["fname"].value,
      CmEmail: this.formpop.controls["email"].value,
      CmPhone: this.formpop.controls["phone"].value?.dialCode + this.formpop.controls["phone"].value?.number,
      AgentName: aname,
      AgentEmail: aemail,
      PropertyID: pid,
      PropertyCode: pcode,
      PropertyType: ptype,
      PropertyArea: aarea,
      ClientURL: curl,
      Referrer: '',
      branchId: branchId,
      selectedSwiftCode: this.currencyCode,
      currentLangId: this.langId.toString(),
      recapchaResponse: this.token,
      message: isNullOrUndefined(this.formpop.controls["comment"].value) ? this.placetext : this.formpop.controls["comment"].value ,
      subscription: true,
      loginId: '1',
      minPrice:minPrice,
      maxPrice:maxPrice,
      agentId:agentId,
      typeOfRequest:"1",
      saleOrRent:saleOrRent,
      subBranch:this.initialValue.branchID.toString()
    }
  }

  prepareEmailLuban(aname, aemail, pid, pcode, ptype, aarea, curl, minPrice, maxPrice, agentId, typeOfRequest, saleOrRent,branchId) {

    return this.lubanEmail = {
      CmName: this.formLubanPop.controls["fname"].value,
      CmAddress: this.formLubanPop.controls["address"].value,
      Governorate: this.supportInfo?.Governorate,
      CmOmani: this.formLubanPop.controls["omaniNationality"].value == "1",
      CmOID: this.formLubanPop.controls["oid"].value,
      CmEmail: this.formLubanPop.controls["email"].value,
      CmPhone: this.formLubanPop.controls["phone"].value?.dialCode + this.formLubanPop.controls["phone"].value?.number,
      AgentName: aname,
      AgentEmail: aemail,
      AgentMobile: "",
      PropertyID: pid,
      PropertyName: "",
      PropertyCode: pcode,
      PropertyType: ptype,
      PropertyArea: aarea,
      PropertyComplex: '',
      ClientURL: curl,
      Referrer: '',
      branchId: branchId,
      selectedSwiftCode: this.langId == 1 ? this.initialValue?.currencyDetail?.selectedCcySwiftCode : this.initialValue?.currencyDetail?.selectedCcySwiftCodeArb,
      currentLangId: this.langId.toString(),
      recapchaResponse: this.token,
      message: this.formLubanPop.controls["comment"].value,
      subscription: true,
      loginId: '1',
      minPrice:minPrice,
      maxPrice:maxPrice,
      agentId:agentId,
      typeOfRequest:"1",
      saleOrRent:saleOrRent,
      subBranch:this.initialValue?.branchID.toString()
    }
  }

  submit(aname, aemail, pid, pcode, ptype, aarea, curl,price,agentId,saleOrRent,branchId) {
    
    this.disableSubmit = true;
    this.submitted = true;
    this.prepareEmail(aname, aemail, pid, pcode, ptype, aarea, curl,price,price,agentId,1,saleOrRent,branchId);
    if (this.formpop.invalid) {
      this.disableSubmit = false;
      return;
    }
    this._contactusService.SenemailSubmit(this.prepareEmail(aname, aemail, pid, pcode, ptype, aarea, curl,price,price,agentId,1,saleOrRent,branchId))
    .then((result) => {
      if (result) {
        this.disableSubmit = false;
        this.simpleResponseemil = UtilityService.clone(result);
        if (this.simpleResponseemil.messageBool) {
          this.notifyService.showSuccess("Mail send successfully !!", "")
          this.formpop?.reset();
          $('#myModal').modal('hide');
          $('#myModal').hide();
          $('.modal-backdrop').remove('modal-backdrop');
        }
        else{
          this.notifyService.showError("some thing went wrong.", "");
        }


      }else{
        this.disableSubmit = false;
      }
    });
  }

  submitLuban(aname, aemail, pid, pcode, ptype, aarea, curl,price,agentId,saleOrRent,branchId) {
    
    this.disableSubmit = true;
    this.submitted = true;
    //this.prepareEmail(aname, aemail, pid, pcode, ptype, aarea, curl,price,price,agentId,1,saleOrRent,branchId);
    if (this.formLubanPop.invalid) {
      this.disableSubmit = false;
      return;
    }
    this._contactusService.SenemailSubmit(this.prepareEmailLuban(aname, aemail, pid, pcode, ptype, aarea, curl,price,price,agentId,1,saleOrRent,branchId)).then((result) => {
      if (result) {
        this.disableSubmit = false;
        this.submitted = false;
        this.simpleResponseemil = UtilityService.clone(result);
        if (this.simpleResponseemil.messageBool) {
          this.notifyService.showSuccess("Reversation request was sent successfully", "تم إرسال طلب الحجز بنجاح")
          this.formLubanPop.get('address').enable();
          this.formLubanPop.get("fname").enable();
          this.formLubanPop?.reset();
          let model = $('#myModal') 
          model?.modal('hide');
          //$('#myModal').hide();
          //$('.modal-backdrop').remove('modal-backdrop');
        }
        else
          this.notifyService.showSuccess("some thing went wrong.", "");
        
      }else{
        this.disableSubmit = false;
      }
    });
  }

  sendClick(propid) {
    this._utilityService.sendClickInfo("2", propid, "",this.initialValue?.branchID,"",this.countryCode);
  }

  addCallNumber(mobile, i, propid,price,agentId,saleOrRent,branchId) {
    
    $('#ane_' + i).hide();
    $('#an_' + i).show();
    $('#sp_' + i).html(mobile);
    // let item = document.getElementById('ane_' + i);
    // if(item){
    //   item.style.display = "none";
    // }
    this._utilityService.sendClickInfo("3", propid, "",branchId,"",this.countryCode,"",price,agentId,saleOrRent,this.initialValue?.branchID.toString());
  }
  addCallagentNumber(mobile, i, propid,price,agentId,saleOrRent) {
    
    $('#ane_' + i).show();
    $('#an_' + i).hide();
    $('#sp_' + i).html('');
    
  }

  getpropertybyId(id, agencyId) {
    this._utilityService.sendClickInfo("1", id, "", this.initialValue?.branchID, agencyId,this.countryCode);
    
    
    let propertyPop = this.propertySearchResultsGrid.filter(x => x.id == id)[0];
    let latestPropertiespop = {
      realID: null,
      id: propertyPop.id,
      encyId: '',
      code: propertyPop.code,
      formType: propertyPop.formType,
      profitSeg: '',
      name: propertyPop.name,
      description: propertyPop.description,
      type: {
        id: '13',
        text: 'Rent'
      },
      type2: propertyPop.pType,
      type2Id: propertyPop.ptypeId,
      premiumId: propertyPop.premiumId,
      premium: propertyPop.premium,
      price: propertyPop.aqarPrice+'',
      price2: propertyPop.price2,
      price3: propertyPop.price3,
      price4: propertyPop.price4,
      rooms: propertyPop.aqarRooms,
      parks: propertyPop.aqarParks,
      size: propertyPop.aqarSize+'',
      bathrooms: propertyPop.aqarBathrooms,
      floors: '',
      crossStreet: '',
      plumbing: '',
      furnished: '',
      electricity: null,
      features: null,
      features2: null,
      mainIMG: propertyPop.img,
      promoIMG: null,
      floorIMG: null,
      vid: null,
      view360List: null,
      quickSummary: null,
      address: propertyPop.address,
      latitude: '',
      longitude: '',
      buildingYear: '',
      dateAdded: '',
      availableFrom: '',
      branchName: '',
      branchId: propertyPop.branchId,
      website: propertyPop.website,
      customerId: '',
      agent: { id : propertyPop.agent.id,
        prefex:'',
        title: '',
        name: propertyPop.agent.name,
        mobile: propertyPop.agent.mobile,
        whatsApp: propertyPop.agent.whatsApp,
        fax: propertyPop.agent.fax,
        telephone: propertyPop.agent.telephone,
        email: propertyPop.agent.email,
        image: propertyPop.agent.image,
        link: propertyPop.agent.link,
        companyName: propertyPop.agent.companyName,
        code:propertyPop.agent.code },
      favorite: false,
      compare: false,
      cityID: '',
      status: propertyPop.status,
      statusID: propertyPop.statusID,
      clientLogo: propertyPop.ClientLogo
    }

    const modalRef = this.modalService.open(ContactModalComponent,{ windowClass: 'custom-modal' });
    modalRef.componentInstance.myProperty = latestPropertiespop;
  }

  openRegisterModal() {
    this.formLubanPop.get('address').setValue('');
    this.formLubanPop.get("fname").setValue('');
    this.formLubanPop.get("oid").setValue('');
    this.formLubanPop.get("omaniNationality").setValue('0');
    this.formLubanPop.get("phone").setValue('');
    this.formLubanPop.get("email").setValue('');
    this.formLubanPop.get("comment").setValue('');

    const bootstrapModalInstance = new bootstrap.Modal(document.querySelector('#myModal'), {
      backdrop: true
    });

    bootstrapModalInstance.show();
  }


  ecodeuri(event,url: string, fromtype) {

    event?.preventDefault();
    var urlval = url.replace('&type', '').split('=');
    if (url.indexOf("?") !== -1) {
      const queryParams = { 'id': urlval[1] };
  
      if (fromtype == '1') {
        queryParams['fromtype'] = fromtype;
      }
      this._route.navigate(['/propertydetail'], { queryParams });
    }
    else{
      this._route.navigate([decodeURI("/" + url)]);
    }
  }


  highLightFav(propertySerchResult:PropertySearchResults[]){
    ;
    
    if(propertySerchResult?.length > 0){
      propertySerchResult.forEach(item=>{
        if(item?.isFavorite){
          let data = document.getElementById(`fav_${item.id}`);
          if(!isNullOrUndefined(data)){
            data?.classList.add('active-icon');
          }
        }
        if(item?.isCompare){
          
          let data = document.getElementById(`camp_${item.id}`);
          if(!isNullOrUndefined(data)){
            data?.classList.add('active-icon');
          }
        }
      })
    }
  }

}
