import { isNull } from '@angular/compiler/src/output/output_ast';
import { Component, OnInit } from '@angular/core';
import { isNullOrUndefined } from 'util';
import { AuthorizationResponse } from '../../models/login';
import { PaymentReceiptResponse } from '../../models/paymentReciept';
import { DahboardService } from '../../services/dashboard.service';
import { UtilityService } from '../../shared/utility.service';
import { ActivatedRoute } from '@angular/router';
import { ConfigurationService } from 'src/app/shared/configuration.service';
import { Lead } from '../../models/EmailContactUs';
import { ContactusService } from 'src/app/services/contactus.service';

@Component({
  selector: 'app-reciept',
  templateUrl: './reciept.component.html',
  styleUrls: ['./reciept.component.css']
})
export class RecieptComponent implements OnInit {

  langId: number = 1;
  successPayment: boolean = false;
  leadID: string = '';
  tracking: string = '';
  bankrefno: string = '';
  lead: Lead;
  isListed: string;
  public currencyCode: string = 'OMR';

  constructor(private _utilityService: UtilityService,
    private _dashboardService: DahboardService,
    private _route: ActivatedRoute, 
    private _configurationService : ConfigurationService,
    private _contactusService: ContactusService
  ) {}

  ngOnInit(): void {
    this.langId = this._configurationService.getLanguageValue();
    this.currencyCode = this._configurationService.getSeletedCurrencyCode();
    this._route.queryParams.subscribe(async params => {
      console.log('parrams',params)
      if (params.status) {
        this.successPayment = params.status == 'success' ? true : false;
      }

      if (params.leadid) {
        this.leadID = params.leadid;
      }

      if (params.tracking) {
        this.tracking = params.tracking;
      }

      if (params.bankrefno) {
        this.bankrefno = params.bankrefno;
      }

      //call API to get RRecieptInfo
      this.getRecieptInfo();
    });

  }

  getRecieptInfo(){
    this._contactusService.GetLeadInfo(this.leadID,this.langId+'').then((result) => {
      if (result) {
        this.lead = result;
        console.log('result',result);
        if(!this.lead.omani){
          this.isListed = (this.langId == 1 ? "Non-Omani" : "غير عماني");
        }
        else if(this.lead.governorate){
          this.isListed = (this.langId == 1 ? "deserving" : "مستحق");
        }else{
          this.isListed = (this.langId == 1 ? "Undeserved" : "غير مستحق");
        }
      }else{
        
      }
    });
    
  }

  printModalContent() {
    const printWindow = window.open('', '_blank');
    const printContent = document.getElementById('print-table').innerHTML;
    const direction = this.langId === 2 ? 'rtl' : 'ltr';
    const title = this.langId == 1 ? "Residential unit reservation form" : "إستمارة حجز وحدة سكنية"
    const logoUrl = "assets/img/temp/logos/logo-3.png";
    const footerURL = this.langId == 1 ? "assets/img/temp/logos/hai_aluban_footer_portrait_en.png" : "assets/img/temp/logos/hai_aluban_footer_portrait_ar.png";
    const footerText = this.langId == 1 ? "Haialuban is one of the residential neighborhood projects affiliated with the Ministry of Housing and Urban Planning" : "حي اللبان هو أحد مشاريع الأحياء السكنية التابعة لوزارة الإسكان والتخطيط العمراني";

    const printStyle = `<style>
    body {
      direction: ${direction};
      margin-top: 40px;
    }
    .header {
      text-align: center;
      margin-bottom: 20px;
    }
    .logo {
      max-width: 200px;
    }
    .footer {
      position: fixed;
      bottom: 0;
      left: 0;
      width: 100%;
      text-align: center;
      padding: 10px;
    }
    .footer img {
      width: 100%;
      max-height: 100%;
      object-fit: contain;
    }
    @media print {
      /* Hide the URL in the print window */
      @page {
        size: auto;   /* auto is the initial value */
        margin: 0 10px;  /* this affects the margin in the printer settings */
      }
    }
    </style>`

    printWindow.document.open();
    printWindow.document.write(`
      <html>
        <head>
          <title>${title}</title>
          ${printStyle}
        </head>
        <body onload="window.print(); window.close();">
        <div class="header">
          <img class="logo" src="${logoUrl}" alt="Logo">
          <h2>${title}</h2>
        </div>
        ${printContent}
        <div class="footer">
          <p>${footerText}</p>
          <img src="${footerURL}" alt="footerLogo">
        </div>
        </body>
      </html>
    `);
    printWindow.document.close();
  }

}
