import { Injectable, Pipe, PipeTransform } from '@angular/core';
import { DomSanitizer, SafeHtml, SafeStyle, SafeScript, SafeUrl, SafeResourceUrl } from '@angular/platform-browser';


@Pipe({ name: 'sanitizeHtml' })
@Injectable({ providedIn: 'root' })
export class sanitizeHtmlPipe implements PipeTransform {

  constructor(private _sanitizer: DomSanitizer) { }

  transform(value: any, type: string): SafeHtml {
    //switch (type) {
    //  case 'html': return this.sanitizer.bypassSecurityTrustHtml(value);
    //  case 'style': return this.sanitizer.bypassSecurityTrustStyle(value);
    //  case 'script': return this.sanitizer.bypassSecurityTrustScript(value);
    //  case 'url': return this.sanitizer.bypassSecurityTrustUrl(value);
    //  case 'resourceUrl': return this.sanitizer.bypassSecurityTrustResourceUrl(value);
    //  default: throw new Error(`Invalid safe type specified: ${type}`);
    //}
    return this._sanitizer.bypassSecurityTrustHtml(value);
  }
}
