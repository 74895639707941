import { HttpClient, HttpErrorResponse, HttpHeaders } from '@angular/common/http';
import { Inject, Injectable } from '@angular/core';
import { CustInquiryResponse, FilterAccountResponse, FiltersBranchResponse } from '../models/Inquiry';
import { SimpleRequest } from '../models/property';
import { StatementOfAccountResponse } from '../models/StatementOfAccount';
import { delayedRetryHttp } from '../shared/delayedRetry';

@Injectable({ providedIn: 'root' })
export class InquiryService {
  private baseUrl: string;
  private baseUrl1: string;
  private api: string = "api/CustInquiryRealEstate";
  private api1: string = "api/CustDashBoard";
  constructor(private httpClient: HttpClient, @Inject('BASE_URL') _baseUrl: string) {
    this.baseUrl = _baseUrl + this.api;
    this.baseUrl1 = _baseUrl + this.api1;
  }

  private handleErrors(errorResponse: HttpErrorResponse) {
    if (errorResponse.error instanceof ErrorEvent) {
      console.error('default Client Side Error: ', errorResponse.error.message);
    } else {
      throw errorResponse;
    }
  }

  async InquiryData_GetAsync(companyID: string, cmsCompanyID: string, branchId: string, customerId: string, type: string, currentLang: string, caid: string, userId:string, simpleRequest: SimpleRequest, token: string): Promise<CustInquiryResponse | void> {
    const a = await this.httpClient
      .post<CustInquiryResponse>(`${this.baseUrl}/InquiryData_Get/${companyID}/${cmsCompanyID}/${branchId}/${customerId}/${type}/${currentLang}/${caid}/${userId}`, simpleRequest, {
        headers: new HttpHeaders({
          'Content-Type': 'application/json',
          'Authorization': 'Bearer ' + token
        })
      }).pipe(delayedRetryHttp()).toPromise().catch(err => { console.log('default error'); });

    return a;
  }

  async InquiryStatment_GetAsync(simpleRequest: SimpleRequest, caid: string, selectedCompanyID: string, selectedBranchID:string, account: string, customer: string, currentlang: string, token: string): Promise<StatementOfAccountResponse | void> {
    const a = await this.httpClient
      .post<StatementOfAccountResponse>(`${this.baseUrl1}/StatmentOfAccount_GetReportData/${caid}/${selectedCompanyID}/${selectedBranchID}/${account}/${customer}/${currentlang}`, simpleRequest, {
        headers: new HttpHeaders({
          'Content-Type': 'application/json',
          'Authorization': 'Bearer ' + token
        })
      }).pipe(delayedRetryHttp()).toPromise().catch(err => { console.log('default error'); });

    return a;
  }

  async Filter_Account_GetAsync(crid: string, currentLang: string, contactID: string, token: string): Promise<FilterAccountResponse | void> {
    const a = await this.httpClient
      .get<FilterAccountResponse>(`${this.baseUrl}/Filter_Account_Get/${crid}/${currentLang}/${contactID}`, {
        headers: new HttpHeaders({
          'Content-Type': 'application/json',
          'Authorization': 'Bearer ' + token
        })
      }).pipe(delayedRetryHttp()).toPromise().catch(err => { console.log('default error'); });

    return a;
  }

  async Filter_Branch_GetAsync(ct: string, currentLang: string, contactId: number, currentBranchID: number, crid: string, caid: string, companyID: string, cmsCompanyId: string, customerId: string, groupedBranches: string, token: string): Promise<FiltersBranchResponse | void> {
    const a = await this.httpClient
      .get<FiltersBranchResponse>(`${this.baseUrl}/Filter_Branch_Get/${ct}/${currentLang}/${contactId}/${currentBranchID}/${crid}/${caid}/${companyID}/${cmsCompanyId}/${customerId}/${groupedBranches}`, {
        headers: new HttpHeaders({
          'Content-Type': 'application/json',
          'Authorization': 'Bearer ' + token
        })
      }).pipe(delayedRetryHttp()).toPromise().catch(err => { console.log('default error'); });

    return a;
  }
}
