import { Component, Input, OnInit } from '@angular/core';
import { Router } from '@angular/router';
import { Property } from 'src/app/models/property';

@Component({
  selector: 'app-propertit-listing-detail',
  templateUrl: './propertit-listing-detail.component.html',
  styleUrls: ['./propertit-listing-detail.component.css']
})
export class PropertitListingDetailComponent implements OnInit {
  @Input() buildingProperties: Property[];

  constructor(private _route: Router) { }

  ngOnInit(): void {
    
  }


  ecodeuri(id, fromtype) {
    const queryParams = { id };
  
    if (fromtype == '1') {
      queryParams['fromtype'] = fromtype;
    }
  
    this._route.navigate(['/propertydetail'], { queryParams });
  }

}
