import { HttpClient, HttpErrorResponse, HttpHeaders } from '@angular/common/http';
import { Inject, Injectable } from '@angular/core';
import { AboutusResponse } from '../models/aboutUs';
import { delayedRetryHttp } from '../shared/delayedRetry';

@Injectable({ providedIn: 'root' })
export class AboutusService {
  private baseUrl: string;
  private api: string = "api/AboutUs";
  constructor(private httpClient: HttpClient, @Inject('BASE_URL') _baseUrl: string) {
    this.baseUrl = _baseUrl + this.api;
  }

  private handleErrors(errorResponse: HttpErrorResponse) {
    if (errorResponse.error instanceof ErrorEvent) {
      console.error('default Client Side Error: ', errorResponse.error.message);
    } else {
      throw errorResponse;
    }
  }

  async AboutUs_GetAsync(id: number, CmsCompID: number, langId: number): Promise<AboutusResponse | void> {
    const a = await this.httpClient
      .get<AboutusResponse>(`${this.baseUrl}/Aboutus_Get/${id}/${CmsCompID}/${langId}`, {
        headers: new HttpHeaders({
          'Content-Type': 'application/json',
          'Authorization': 'Basic '
        })
      }).pipe(delayedRetryHttp()).toPromise().catch(err => { console.log('default error'); });

    return a;
  }

}
