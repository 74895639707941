import { Component, OnInit } from '@angular/core';
import { FormBuilder, FormGroup, Validators } from '@angular/forms';
import { MatSnackBar } from '@angular/material/snack-bar';
import { isNullOrUndefined } from 'util';
import { EmailMerchant } from '../../models/Agents';
import { SimpleResponse } from '../../models/genericResponse.model';
import { AgentsService } from '../../services/agents.service';
import { UtilityService } from '../../shared/utility.service';
import { ConfigurationService } from 'src/app/shared/configuration.service';

@Component({
  selector: 'app-merchant',
  templateUrl: './merchant.component.html',
  styleUrls: ['./merchant.component.css']
})
/** merchant component*/
export class MerchantComponent implements OnInit {
  form: FormGroup;
  submitted = false;
  emailMerchant: EmailMerchant;
  simpleResponse: SimpleResponse;
  langId: number = 1;
  token: string;
  /** merchant ctor */
  constructor(private formBuilder: FormBuilder, private _agentsService: AgentsService, private _snackBar: MatSnackBar, private _configurationService: ConfigurationService) {
    
  }
  get f() {
    return this.form.controls;
  }
  ngOnInit() {

    this.langId = this._configurationService.getLanguageValue();

    this.form = this.formBuilder.group({
      email: ['', [Validators.required, Validators.email]],
      contactName: ['', [Validators.required]],
      companyName: ['', [Validators.required]],
      phone: ['', [Validators.required, Validators.pattern(/^\d{10}$/)]],
      CRNumber: ['', [Validators.required]],      
      CRCert: ['', [Validators.required]],
      VATCRT: [''],
      Logo: ['']
    });

    // $('html, body').animate({
    //   scrollTop: $("body").offset().top = 0
    // }, 0);
  }

  submit() {
    this.submitted = true;
    this.prepareForm();
    if (this.form.invalid) {
      return;
    }
    this._agentsService.MerchantSubmit(this.langId.toString(),this.prepareForm()).then((result) => {
      if (result) {
        this.simpleResponse = UtilityService.clone(result);
        if (this.simpleResponse.statusCode==0) {
          this.openSnackBar("Mail send successfully", "");
        }
        else
          this.openSnackBar("some thing went wrong", "");
      }
    });
  }

  prepareForm() {
    return this.emailMerchant = {     
      email: this.form.controls["email"].value,
      contactName: this.form.controls["contactName"].value,
      companyName: this.form.controls["companyName"].value,
      phoneNumber: this.form.controls["phone"].value,     
      companyCR: this.form.controls["CRNumber"].value,
      categoryID:'',
      captchResponse:'',
      file_cr_upload: this.form.controls["CRCert"].value,
      file_vat_upload: this.form.controls["VATCRT"].value,
      file_logo_upload: this.form.controls["Logo"].value,
    }
  }

  openSnackBar(message: string, action: string, durationTime: number = 5000) {
    this._snackBar.open(message, action, {
      verticalPosition: 'top',
      horizontalPosition: 'right',
      duration: durationTime,
    });
  }

  onRecaptchaSuccess(token: string) {
    this.token = token;
  }
}
