import { Injectable } from '@angular/core';
import { CompareService } from './compare.service';
import { InitialValue } from '../models/initialValue';
import { PropertySearchResults, PropertySearchResultsResponse } from '../models/property';
import { isNullOrUndefined } from 'util';
import { UtilityService } from '../shared/utility.service';
import { ConfigurationService } from '../shared/configuration.service';
import { BehaviorSubject } from 'rxjs';
import { SimpleResponse } from '../models/genericResponse.model';
import { ConfirmBoxInitializer, DialogLayoutDisplay } from '@costlydeveloper/ngx-awesome-popup';

@Injectable({
  providedIn: 'root'
})
export class FavoriteService {

  initialValue: InitialValue;
  langId = 1
  propertySearchResultsResponse: PropertySearchResultsResponse;
  propertySearchResultsResponsefav: PropertySearchResultsResponse;
  propertySearchitem: PropertySearchResults[];
  propertySearchitemfav: PropertySearchResults[];
  propertySearchResults: PropertySearchResults[];
  propertySearchResultsfav: PropertySearchResults[];
  simpleResponse: SimpleResponse;


  public propertySearchResultsfavSubject = new BehaviorSubject<PropertySearchResults[]>(null);
  public propertySearchResultsCompareSubject = new BehaviorSubject<PropertySearchResults[]>(null);
  constructor(private _compare: CompareService) {

   }




  GetCompareStatus(page: string, limit: string, count: boolean, type: string, currentlang: string, Id, branchId:number, seletedCCYCode:string, groupedBranchId:string) {
    
    this._compare.Compare_GetAsync(page, limit, count, type, branchId, currentlang, seletedCCYCode,groupedBranchId).then((result) => {
      if (result) {
        this.propertySearchResultsResponse = UtilityService.clone(result);
        if (!isNullOrUndefined(this.propertySearchResultsResponse)) {
          this.propertySearchitem = this.propertySearchResultsResponse.propertySearchResults;
          this.propertySearchResults = this.propertySearchitem;
          if(type == '1'){
            this.propertySearchResultsfavSubject.next(this.propertySearchResults);
          }else{
            this.propertySearchResultsCompareSubject.next(this.propertySearchResults);
          }

        }
      }
    });
  }
  GetFavStatus(page: string, limit: string, count: boolean, type: string, currentlang: string, Id, branchId:number, seletedCCYCode:string, groupedBranchId:string) {
    
     this._compare.Compare_GetAsync(page, limit, count, type, branchId, currentlang, seletedCCYCode, groupedBranchId).then((result) => {
      if (result) {
        
        this.propertySearchResultsResponsefav = UtilityService.clone(result);
        if (!isNullOrUndefined(this.propertySearchResultsResponsefav)) {
          this.propertySearchitemfav = this.propertySearchResultsResponsefav.propertySearchResults;
          this.propertySearchResultsfav = this.propertySearchitemfav;
          this.propertySearchResultsfavSubject.next(this.propertySearchitemfav);
        }

      }
    });
  }


  public addToFavoriteOrCompare(Id, addfav,type, branchId, langId, selectedCCY, broupBranchId, ptype,index ){
    
    this._compare.CompareSubmit(Id, addfav, type, branchId, langId).then((result) => {
      if (result) {        
       
        this.simpleResponse = UtilityService.clone(result);
        if (isNullOrUndefined(this.simpleResponse.messageString)) {
          if (type == 2)
            this.GetCompareStatus("1", "100", true, type, this.langId.toString(), Id, branchId,selectedCCY,broupBranchId);
          else
            this.GetFavStatus("1", "100", true, type, this.langId.toString(), Id, branchId,selectedCCY,broupBranchId);
                    
        }
        else {         
          this.confirmBox(Id, type,ptype, index,branchId, selectedCCY, broupBranchId );          
          //this.notifyService.confirmBox('','There are properties of different type in your compare list do you want to clear it to be able to proceed?');
        }
      }
    })
  }



  
  async confirmBox(Id, type, ptype, index,branchId, selectedCCY, broupBranchId ) {    
    const confirmBox = new ConfirmBoxInitializer();
    confirmBox.setTitle('');
    confirmBox.setMessage('There are properties of different type in your compare list do you want to clear it to be able to proceed?');
    confirmBox.setButtonLabels('YES', 'NO');

    // Choose layout color type
    confirmBox.setConfig({
      layoutType: DialogLayoutDisplay.DANGER, // SUCCESS | INFO | NONE | DANGER | WARNING
    });

    // Simply open the popup and listen which button is clicked
    confirmBox.openConfirmBox$().subscribe(resp => {
      
      if (resp.success) {        
        if (!isNullOrUndefined(this.propertySearchitem)) {
          let totpropfilter = this.propertySearchitem.filter(x => x.id != Id);
          this.ClearCopare(Id, type,branchId, selectedCCY, broupBranchId,this.langId,true );
          totpropfilter.forEach((currentValue, index) => {
            //this.ClearCopare(currentValue.id, type);
            $('#camp_' + currentValue.id).removeClass('active-icon');
          });          
        }
      }
      // else {
      //   this.ClearCopare(Id, type,branchId ,selectedCCY, broupBranchId, this.langId);
      // }
      // IConfirmBoxPublicResponse
    });

    return confirm;
  }


  ClearCopare(id, type,branchId, selectedCCY, broupBranchId, langId, isClearAll ) {
    this._compare.Clear_GetAsync(id, type, branchId, langId, isClearAll).then((result) => {
      if (result) {
          this.simpleResponse = UtilityService.clone(result);
          this.GetCompareStatus("1", "100", true, type, langId, id,branchId,selectedCCY,broupBranchId);
      }
    });
  }

  public getFavoritesList(){
    return this.propertySearchitemfav
  }

  public getCompareList(){
    return this.propertySearchResults;
  }
}
