import { Component, Input, OnInit } from '@angular/core';
import { Router } from '@angular/router';
import { Property } from 'src/app/models/property';
import { SwiperComponent } from "swiper/angular";
import Swiper from "swiper/types/swiper-class";

import SwiperCore , {
  Navigation,
  Autoplay,
  Controller,Thumbs
} from 'swiper';
import { ConfigurationService } from 'src/app/shared/configuration.service';
SwiperCore.use([
  Navigation,
  Autoplay,
  Controller,Thumbs
]);

@Component({
  selector: 'app-properties-tooltip-list-detail',
  templateUrl: './properties-tooltip-list-detail.component.html',
  styleUrls: ['./properties-tooltip-list-detail.component.css']
})
export class PropertiesTooltipListDetailComponent implements OnInit {

  @Input() bp: Property;
  langId:number = 1;
  thumbsSwiper: any;

  breakpoints = {
    375: { slidesPerView: 3.5, spaceBetween: 5 },
    650: { slidesPerView: 4, spaceBetween: 5 },
  };

  constructor( private _route: Router,private _configurationService:ConfigurationService) {
    this.langId =  this._configurationService.getLanguageValue();
   }

  ngOnInit(): void {
  }

  ecodeuri(id, fromtype) {
    const queryParams = { id };
  
    if (fromtype == '1') {
      queryParams['fromtype'] = fromtype;
    }
  
    this._route.navigate(['/propertydetail'], { queryParams });
  }

}
