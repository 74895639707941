import { Component } from '@angular/core';
import { ActivatedRoute } from '@angular/router';
import { Employee } from '../../models/Employee';
import { AgentsService } from '../../services/agents.service';
import { UtilityService } from '../../shared/utility.service';

@Component({
    selector: 'app-agents',
    templateUrl: './agents.component.html',
    styleUrls: ['./agents.component.css']
})
/** agents component*/
export class AgentsComponent {
  langId: number = 1;
  branchId: number;
  agentList: Employee[] = [];

    /** agents ctor */
  constructor(private _route: ActivatedRoute, private _agentsrvc: AgentsService, private _utilityService: UtilityService) {
    
  }

  ngOnInit() {

    this.langId = Number(localStorage.getItem('language')) == 0 ? 1 : Number(localStorage.getItem('language'));
    this._route.paramMap.subscribe(async params => {
      if (params.has('branchId')) {
        this.branchId = +params.get('branchId');
      }
    });

    this.GetAgentsData(this.branchId, this.langId.toString());
    this._utilityService.sendClickInfo("5", "", "", "","","");
    // $('html, body').animate({
    //   scrollTop: $("body").offset().top = 0
    // }, 0);
  }


  GetAgentsData(branchId, langId) {
    this._agentsrvc.AgentsListGet(branchId, langId).then((result) => {
      if (result) {
        var response = UtilityService.clone(result);
        //console.log("GetAgentsData in agent component :", result);
      /*  response.employee.forEach(e => {*/
          //var imgPath = e.image.split('agents/');
          //console.log(imgPath);
          for (var i = 0; i < response.employee.length; i++) {
            var imgPath = response.employee[i].image.split('agents/');
            if (imgPath[1] == '') {
              this.agentList[i] = response.employee[i];
              this.agentList[i].image = 'https://cdn.aqaraats.com/images/NA.jpg';
            } else {
              this.agentList[i] = response.employee[i];
            }
          }
       /* })*/

        //console.log("this.agentList :", this.agentList)
      }
    });
  }
}
