import { Component, OnInit } from '@angular/core';
import { FormBuilder, FormGroup, Validators } from '@angular/forms';
import { RouteReuseStrategy, Router } from '@angular/router';
import { CountryISO, SearchCountryField } from 'ngx-intl-tel-input';
import { ToastrService } from 'ngx-toastr';
import { SignUpOtpVerify } from 'src/app/models/Signup';
import { InitialValue } from 'src/app/models/initialValue';
import { LoginService } from 'src/app/services/login.service';
import { ConfigurationService } from 'src/app/shared/configuration.service';

@Component({
  selector: 'app-signup',
  templateUrl: './signup.component.html',
  styleUrls: ['./signup.component.css']
})
export class SignupComponent implements OnInit {

  public form: FormGroup;
  public formOTP: FormGroup;
  public langId: number = 1;
  public submitted = false;
  public loading:boolean = false;
  public otpReady:boolean = false;
  public initialValues : InitialValue;

  public recaptchaResponse: any;

  public preferredCountries: CountryISO[] = [CountryISO.Bahrain];
  public CountryISO = CountryISO;
  public SearchCountryField = SearchCountryField;

  constructor(private formBuilder: FormBuilder, 
    private loginService: LoginService, private _toasterService: ToastrService, private _router: Router, private _configurationService: ConfigurationService) { 
    
  }

  ngOnInit(): void {
    //

    this.langId = this._configurationService.getLanguageValue();
    this.initialValues = this._configurationService.getInitialValues();
    this.form = this.formBuilder.group({
      personalId: ['', [Validators.required]],
      name: ['', [Validators.required]],
      email: ['', [Validators.required], Validators.email],
      password: ['', [Validators.required]],
      confirmPassword: ['', [Validators.required]],
      phone: ['', [Validators.required]]
    });

    this.formOTP = this.formBuilder.group({
      otp: ['', [Validators.required]],
    });
  }


  SignUpsubmit(){
    
    this.submitted = true;
    this.loading = true;

    if (true) {
      this.loginService.SignUp({
        Name: this.form.controls.name.value,
        Email: this.form.controls.email.value,
        Mobile: this.form.controls.phone.value?.e164Number,
        ID: this.form.controls.personalId.value,
        Password: this.form.controls.password.value,
        IsSocialMedia: 'false',
        SocialMediaID: null,
        SocialMediaResponse: null,
        RecaptchaResponse: this.recaptchaResponse,
        SelectedCcySwiftCode: this.initialValues.currencyDetail.selectedCcySwiftCode,
        LanguageId: this.langId.toString(),
        RequestHostDetialsModel: {
          SelectedBranchID: this.initialValues.branchID.toString(),
          GroupedBranches: this.initialValues.groupedBranchesId.toString(),
          BranchID: this.initialValues.branchID.toString(),
          CompanyID: this.initialValues.companyID.toString(),
          CmsCompID: this.initialValues.cmsCompID.toString()
        }
      })
      .then((res) => {
        if(res && res?.executed == true){
          this._toasterService.success("Your Account has been created Successfully", "SignUp");
          window.scrollTo({
            top: 0,
            behavior: 'instant' as any
          })
          this.otpReady = true;
          this.loading = false;
        }else{
          this._toasterService.warning(res?.message, "Error SignUp");
          this.loading = false;
        }

      })
      .catch((error) => {console.log(error); this.loading = false;});
    }
  }

  submit() {
    
    this.loading = true;

    if(true){
      let otpRequest : SignUpOtpVerify = {
        Email: this.form.controls.email.value,
        PersonalID: this.form.controls.personalId.value,
        OTP: this.formOTP.controls.otp.value,
        RecaptchaResponse : this.recaptchaResponse,
        LanguageId: this.langId.toString(),
        RequestHostDetials: {
          SelectedBranchID: "14",
          GroupedBranches: "",
          BranchID: "14",
          CompanyID: "001",
          CmsCompID: "14"
        }
      }
      this.loginService.SignUpOtpVerify(otpRequest).then(resp=>{
        if(resp && resp.status){
          this.loading = false;
          this._toasterService.success("Your Opt is verified", "SignUp")
          this._router.navigateByUrl("/");
        }else{
          this._toasterService.error(resp?.message, "SignUp");
          this.loading = false;
        }
      })
    }

  }

  onRecaptchaSuccess(recaptchaResponse: string) {
    this.recaptchaResponse = recaptchaResponse;
  }

  get f() {
    return this.form.controls;
  }
}
