import { Component, Inject, OnDestroy, OnInit } from '@angular/core';
import { FormBuilder, FormGroup, Validators } from '@angular/forms';
import { ActivatedRoute, Router } from '@angular/router';
import { ConfirmBoxInitializer, DialogLayoutDisplay } from '@costlydeveloper/ngx-awesome-popup';
import { Select2OptionData } from 'ng-select2';
import { Subscription } from 'rxjs';
import { Options } from 'select2';
import { isNullOrUndefined } from 'util';
import { Compare } from '../../models/Company';
import { Email } from '../../models/EmailContactUs';
import { SimpleResponse } from '../../models/genericResponse.model';
import { currencyDetails, InitialValue, InitialValueResponse } from '../../models/initialValue';
import { ItemPropByArea, Property, PropertyResponse, PropertySearchParams, PropertySearchResults, PropertySearchResultsResponse } from '../../models/property';
import { CompareService } from '../../services/compare.service';
import { ContactusService } from '../../services/contactus.service';
import { DefaultService } from '../../services/default.service';
import { PropertyService } from '../../services/property.service';
import { NotificationService } from '../../shared/notification.service';
import { SearchService } from '../../shared/search.service';
import { UtilityService } from '../../shared/utility.service';
import { NgbModal } from '@ng-bootstrap/ng-bootstrap';
import { CountryISO, SearchCountryField } from 'ngx-intl-tel-input';
import { AuthorizationResponse } from '../../models/login';
import { DOCUMENT } from '@angular/common';
import { ConfigurationService } from 'src/app/shared/configuration.service';
import { FavoriteService } from 'src/app/services/favorite.service';
//import { LazyLoadImageModule, LAZYLOAD_IMAGE_HOOKS, ScrollHooks } from 'ng-lazyload-image';
import { SwiperComponent } from "swiper/angular";
import Swiper from "swiper/types/swiper-class";

import SwiperCore , {
  Navigation,
  Autoplay,
  Controller,Thumbs
} from 'swiper';
import { ApiResponse } from 'src/app/models/ApiResponseModel';
SwiperCore.use([
  Navigation,
  Autoplay,
  Controller,Thumbs
]);
declare var $: any;

@Component({
    selector: 'app-property',
    templateUrl: './property.component.html',
    //providers: [{ provide: LAZYLOAD_IMAGE_HOOKS, useClass: ScrollHooks }],
    styleUrls: ['./property.component.css']
})
/** property component*/
export class PropertyComponent implements OnInit, OnDestroy {
  thumbsSwiper: any;
  isShowDiv: boolean = false;
  propertySearchParams: PropertySearchParams;
  propertySearchParamsVal: PropertySearchParams;
  propertySearchResultsResponse: PropertySearchResultsResponse;
  propertySearchResults : PropertySearchResults[];
  propertySearchResultsGrid : PropertySearchResults[];
  propertySearchResultsGridSort : PropertySearchResults[];
  propertySearchitem: PropertySearchResults[];
  propertySearchitemPage: PropertySearchResults[];
  propertySearchCounter: PropertySearchResults[];
  SearchCountryField = SearchCountryField;
  propertyResponce: PropertyResponse;
  isLoLoding: boolean = true;
  CountryISO = CountryISO;
  preferredCountries: CountryISO[] = [CountryISO.Bahrain];
  token: string;
  submitted = false;
  formpop: FormGroup;
  email: Email;
  initialValueResponse: InitialValueResponse;
  latestPropertiespop: PropertySearchResults[];
  initialValue: InitialValue;
  simpleResponse: SimpleResponse;
  placetext: string = '';
  currencyDetail: currencyDetails;
  locationlist: ItemPropByArea[];
  propertySearchResultsResponsefav: PropertySearchResultsResponse;
  propertySearchResultsfav: PropertySearchResults[];
  propertySearchResultsComp: PropertySearchResults[];
  propertySearchitemfav: PropertySearchResults[];
  propertySearchitemComp: PropertySearchResults[];
  public currencyCode: string = 'BHD';
  userid: string;
  paramId: string;
  locationId: string;
  public totalCount: number=0;
  favoriteval: Compare[];
  Compareval: Compare[];
  subscription: Subscription;
  langId: number = 1;
  public options: Options;
  public countryCode = 'BH';
  // hostName: string = '';
  simpleResponseemil: SimpleResponse;
  simpleResponseCount: SimpleResponse;
  isShowCrousalView: boolean;
  isShowFilterView: boolean = true;
  isSort: boolean = true;
  isFromCrousalView: boolean = false;
  isPropCount: boolean = false;
  isNofound: boolean = false;
  fromCrousalView: PropertySearchParams;
  authorizationResponse: AuthorizationResponse;
  sliderImagePath:string;
  isAlreadyInitiated:boolean = false;
  isDisableSubmit:boolean = false;
    /** property ctor */
  constructor(@Inject(DOCUMENT) private document: Document, private route: ActivatedRoute, private notifyService: NotificationService, private _contactusService: ContactusService, private formBuilder: FormBuilder, private _route: Router, private _defaultService: DefaultService,
    private _propertyService: PropertyService, private _SearchService: SearchService,
     private modalService: NgbModal, private _compare: CompareService, private _utilityService: UtilityService, 
     private _configurationService:ConfigurationService, private _favoriteService :FavoriteService
     ) {
      
    this.fromCrousalView = JSON.parse(localStorage.getItem('Qsearch'));
    // this.hostName = isNullOrUndefined(localStorage.getItem('hostname')) ? 'aqaraats.com' : localStorage.getItem('hostname');

    this.route.paramMap.subscribe(async params => {
      
      if (params.has('ID')) {
        this.paramId = params.get('ID') == '0' ? '' : params.get('ID');
      }
      
      if (params.has('Location')) {

        this.locationId = params.get('Location') == '0' ? '' : params.get('Location');
      }
      this.loadData();
      // this.GetInitialValues(this.hostName, 0);
      if (this.locationId != '')
      {
        this.isShowFilterView = false;
        this.isShowCrousalView = false;
      }
      else if (this.paramId != '') {
        this.isShowFilterView = true;
        this.isShowCrousalView = false;
      }
      else if (this.locationId == "" && this.fromCrousalView?.limit == '50') {
        this.isShowFilterView = false;
        this.isShowCrousalView = true;
      } else if (this.locationId != "" && this.fromCrousalView?.limit != '50') {
        this.isShowFilterView = true;
        this.isShowCrousalView = false;
      } else {
        this.isShowFilterView = true;
        this.isShowCrousalView = false;
      }
    });  
  }
  
  private loadData(){
    
    this.isLoLoding = true;
    if(this.initialValue != null && this.isAlreadyInitiated){
      this.GetInitialValues();
    }
    else if(this.isAlreadyInitiated){
      this.initialValue = this._configurationService.getInitialValues();

      this.langId = this._configurationService.getLanguageValue();
      this.currencyCode = this._configurationService.getSeletedCurrencyCode();
      this.countryCode = this._configurationService.getSeletedCountryCode();
  
      this.GetInitialValues();
    }
  }
  
  ngOnInit(): void {  
    
    this.isAlreadyInitiated = true;
    this.sliderImagePath = 'http://angular.aqaraats.com:4200/asserts/img/agency-all-banner.jpg';
    this.formpop = this.formBuilder.group({
      fname: ['', [Validators.required]],
      phone: ['', [Validators.required]],
      email: ['', [Validators.required, Validators.email]],
      comment: ['', [Validators.required]]
    })
    if (!isNullOrUndefined(localStorage.getItem("User"))) {
      this.authorizationResponse = JSON.parse(localStorage.getItem("User"));
      this._utilityService.isUserLogin.next(true);
      this._utilityService.UserName.next(this.authorizationResponse.loginName);
    }
    else {
      this._utilityService.isUserLogin.next(false);
    }

    this._configurationService.isSearch.subscribe(res=>{
      if(res){
        this.GetPropertyData('0');
      }
    })

    this._favoriteService.propertySearchResultsfavSubject.subscribe(resp=>{
      
      if(resp != null){
        this.propertySearchitemfav = resp
        //this.ClearCopare(propertyId, type)
        this.propertySearchResultsfav = this.propertySearchitemfav;
        if (this.propertySearchResultsfav.length > 0) {
          this.loadFavComp(0, "1");
        }
      }

    })

    this._favoriteService.propertySearchResultsCompareSubject.subscribe(resp=>{
      if(resp != null){
        this.propertySearchitem = resp;
        //let ps = this.propertySearchitem.filter(c=>c.);
        //this.ClearCopare(propertyId, type)
        this.propertySearchResults = this.propertySearchitem;
        if (this.propertySearchResults.length > 0)
          this.loadFavComp(0,"2");
      }
    })
    //  this.initialValue = this._configurationService.getInitialValues();

    //  this.langId = this._configurationService.getLanguageValue();
    //  this.currencyCode = this._configurationService.getSeletedCurrencyCode();
    //  this.countryCode = this._configurationService.getSeletedCountryCode();

    // this.GetInitialValues();

    this.loadData();

    // $('html, body').animate({
    //   scrollTop: $("body").offset().top = 0
    // }, 0);
  }

  ngOnDestroy(): void {
    $('#myModal').modal('hide');
  }

  settingscrue = {
    slidesToShow: 1,
    slidesToScroll: 1,
    arrows: false,
    fade: false,
    infinite: false,
    asNavFor: '.listing-thumbnail-slider-nav',
    autoplaySpeed: 2000
  }

  settingsThumb = {
    slidesToShow: 4,
    slidesToScroll: 1,
    infinite: false,
    asNavFor: '.listing-thumbnail-slider-main',
    dots: false,
    arrows: true,
    centerMode: false,
    focusOnSelect: true,
    autoplay: true,
    autoplaySpeed: 2000,
    responsive: [
      {
        breakpoint: 1024,
        settings: {
          slidesToShow: 4,
        }
      },
      {
        breakpoint: 768,
        settings: {
          slidesToShow: 1,
        }
      },
      {
        breakpoint: 400,
        settings: {
          slidesToShow: 2,
        }
      }
    ]
  }

  GetInitialValues() {

    this.userid = "zj5gitx5411oab2quguwsecq-14";
    this.GetDefauilData(this.initialValue?.cmsCompID, this.initialValue?.companyID.toString(), this.langId.toString(), this.initialValue?.branchID, this.countryCode, this.userid, this.initialValue?.groupedBranchesId);
    this.GetPropertyData('0');

    // this._defaultService.InitialValue_GetAsync(hostName, isCustomer).then((result) => {
    //   if (result) {
    //     this.initialValueResponse = UtilityService.clone(result);
    //     //console.log("initialValueResponse_property: ",this.initialValueResponse);
    //     this.initialValue = this.initialValueResponse.initialValue;
    //     //if (isNullOrUndefined(this.initialValueResponse.initialValue.currencyDetail)) {
    //     //  this.currencyDetail = this.initialValueResponse.initialValue.currencyDetail;
    //     //  console.log("currency" + this.currencyDetail);
    //     //  if (!isNullOrUndefined(this.currencyDetail))
    //     //    this.currencyCode = this.currencyDetail?.selectedCcySwiftCode;
    //     //}

        
    //     //console.log('param-' + this.paramId);

    //   }
    // });
  }

 public  GetPropertyData(sortBy) {
  
  this.isLoLoding = true;
    let viewAll: Number = 0;
    this.propertySearchParamsVal = JSON.parse(localStorage.getItem('Qsearch'));
    if (this.locationId == '' && this.paramId == '' && this.propertySearchParamsVal ) {
      if (this.propertySearchParamsVal?.limit == '40')
        viewAll = 5;
      else
        this.propertySearchParamsVal?.limit == '4000' ? viewAll = 1 : viewAll = 0;
    }    
    else {
      if (this.locationId != '')
        viewAll = 0;
      else if (this.paramId == '13' || this.paramId == '14')
        viewAll = 2;
      
      this.propertySearchParamsVal = this.propertySearchParams = {
        branchId: this.initialValue?.branchID,
        countryCode: this.countryCode,
        contactId: this.userid, currentLang: this.langId, cAID: 0,
        groupedBranches: this.initialValue?.groupedBranchesId, page: '',
        sort: '', type: this.paramId, limit: '2000', searchTerm: '', propertyType2: '',
        propertyType3: '', propertyLocation: this.locationId, minArea: '', maxArea: '', minPrice: '',
        maxPrice: '', features: '', minBeds: '', maxBeds: '', minBaths: '', maxBaths: '', electricity: '',
        Furnicher: '', count: true, nonVacant: '', agentID: '', parentID: '', myProperty: false,
        currencyDetails: {
          selectedCcyCode: '',
          selectedCcyName: '',
          selectedCcySwift: '',
          selectedCcySwiftCodeArb: '',
          selectedCcySwiftCode: '',
          selectedCcyDecimal: '',
        }
      };
   }

   this.propertySearchParamsVal.sort = sortBy != '0' ? sortBy : this.propertySearchParamsVal.sort
   this.propertySearchParamsVal.currentLang = this.langId
   this.propertySearchParamsVal.countryCode = this.countryCode;
   
    //console.log('serch value',this.propertySearchParamsVal);
    this._propertyService.Property_SearchAsync(viewAll, sortBy, this.propertySearchParamsVal).then((result) => {
      if (result) {
        this.propertySearchResultsResponse = UtilityService.clone(result);
        
        this.propertySearchitemPage = this.propertySearchResultsResponse.propertySearchResults;  

        
        this.propertySearchResultsGrid = this.propertySearchitemPage.filter(x => x.seqId <= 9);
        this.propertySearchResultsGridSort = this.propertySearchitemPage.filter(x => x.seqId <= 9);
        for (var i = 0; i < this.propertySearchResultsGrid.length; i++) {
          if (isNullOrUndefined(this.propertySearchResultsGrid[i].img) || this.propertySearchResultsGrid[i].img == "" || this.propertySearchResultsGrid[i].img == '') {
            this.propertySearchResultsGrid[i].img = 'https://cdn.aqaraats.com/images/NA.jpg';
          }
        }

        $('#loadValue').hide();
        this.isLoLoding = false;

        setTimeout(() => {
          if (this.langId != 1) {
            $('.next-item .page-link').text('التالي');
            $('.last-item .page-link').text('الأخير');
            $('.previous-item .page-link').text('السابق');
            $('.first-item .page-link').text('الأول');
          } else {
            $('.next-item .page-link').text('Next');
            $('.last-item .page-link').text('Last');
            $('.previous-item .page-link').text('Previous');
            $('.first-item .page-link').text('First');
          }
        }, 500);
      }


      window.scrollTo({
        top: 0,
        behavior: 'instant' as any
      })

    });

   this.propertyCount();

    this._propertyService.PropertyArea_SearchAsync(this.propertySearchParamsVal).then((result) => {
      if (result) {
        this.locationlist = UtilityService.clone(result);
      }
    });

  }

  propertyCount() {
    this._propertyService.Property_Count(this.propertySearchParamsVal).then((result) => {
      if (result) {
        
        this.simpleResponseCount = UtilityService.clone(result);
        this.totalCount = this.simpleResponseCount.messageInt;
        if (this.totalCount == 0 && this.isNofound) {

          this.isShowFilterView = false;
          this.isSort = false;
          this.isPropCount = true;
        }
        else {
          this.isSort = true;
          this.isPropCount = false;
          this.isNofound = true;
        }
      }
      window.scrollTo({
        top: 0,
        behavior: 'instant' as any
      })
    });
  }

  getPropertyData(locId, propType, propType3, minBed = '', maxBad = '') {
    this.propertySearchParams = {
      branchId: this.initialValue?.branchID,
      countryCode: this.countryCode,
      contactId: '0',
      currentLang: this.langId,
      cAID: 0,
      groupedBranches: this.initialValue?.groupedBranchesId,
      page: '',
      sort: '',
      type: propType,
      limit: '10000',
      searchTerm: '',
      propertyType2: '',
      propertyType3: propType3,
      propertyLocation: locId,
      minArea: '',
      maxArea: '',
      minPrice: '',
      maxPrice: '',
      features: '',
      minBeds: minBed,
      maxBeds: maxBad,
      minBaths: '',
      maxBaths: '',
      electricity: '',
      Furnicher: '',
      count: false,
      nonVacant: '',
      agentID: '',
      parentID: '',
      myProperty: false,
      currencyDetails: {
        selectedCcyCode: '',
        selectedCcyName: '',
        selectedCcySwift: '',
        selectedCcySwiftCodeArb: '',
        selectedCcySwiftCode: '',
        selectedCcyDecimal: '',
      }
    }
    localStorage.setItem('Qsearch', JSON.stringify(this.propertySearchParams));
    this.GetPropertyData('0');
  }

  GetDefauilData(CmsCompID: number, compId: string, currenLang: string, branchId: number, countryCode: string, userId: string, GroupedBrancheIds: string) {
    this._defaultService.Default_GetAsync(CmsCompID, compId, currenLang, branchId, countryCode, userId, GroupedBrancheIds).then((result : ApiResponse<PropertyResponse>) => {
      if (result.StatusCode == 200) {
        //this.loadingSubscription.unsubscribe();
        this.propertyResponce = result?.Data
        //console.log("GetDefauilData_property: ", this.propertyResponce);
        //this.locationlist = this.propertyResponce.filterPropertiesByArea;
        this.options = {
          width: '300',
          templateResult: this.LocationResult,
          templateSelection: this.LocationSelection
        };        
      }
    });
  }

  public LocationResult = (state: Select2OptionData): JQuery | string => {

    let counter = '';
    this.locationlist.forEach((currentValue, index) => {
      if (currentValue.text == state.text) {
        counter = currentValue.counter;
      }
    });
    return jQuery('<span>' + state.text + '(' + (counter) + ')' + '</span>');
  }
  public LocationSelection = (state: Select2OptionData): JQuery | string => {
    return jQuery('<span><a routerLink=/property/0/' + state.id + '>' + state.text + '</a></span>');
  }

  ecodeuri(url: string, fromtype) {
    var urlval = url.replace('&type', '').split('=');
    if (url.indexOf("?") !== -1) {
      const queryParams = { 'id': urlval[1] };
  
      if (fromtype == '1') {
        queryParams['fromtype'] = fromtype;
      }
      this._route.navigate(['/propertydetail'], { queryParams });
    }
    else{
      this._route.navigate([decodeURI("/" + url)]);
    }
  }


  onChangePage(pageOfItems: Array<any>) {
    // update current page of items
    this.propertySearchResultsGrid = pageOfItems;
    window.scrollTo({
      top: 0,
      behavior: 'instant' as any
    })
  }

  toggleLocationDiv() {
    this.isShowDiv = !this.isShowDiv;
  }

  settingsDefault = {
    "slidesToShow": 1,
    "slidesToScroll": 1,
    "dots": false,
    "infinite": false,
    "autoplay": false,
  }


  locationSearch(value) {
        let pid;
        if (this.paramId == '')
      pid = 0;
    else
      pid = this.paramId;
    var url = "/properties/" + (this.paramId == '' ? 0 : + this.paramId) +'/' + value;
    this._route.navigate([url]);

  }
  
  async GetCompare(Id, type, index, ptype) {
    ;
    let addfav = 1;
    if (type == 1) {
      if (!isNullOrUndefined(this.propertySearchResultsfav)) {
        let fp = this.propertySearchResultsfav.filter(p => p.id == Id && p.isFavorite == true)
        if (fp.length > 0) {
          addfav = 0;
          $('#fav_' + Id).removeClass('active-icon');
        }
      }
    }
    else {
      if (!isNullOrUndefined(this.propertySearchResults)) {
        let cmp = this.propertySearchResults.filter(p => p.id == Id && p.isCompare == true)
        if (cmp.length > 0) {
          addfav = 0;
          $('#camp_' + Id).removeClass('active-icon');
        }
      }
    }

    this._favoriteService.addToFavoriteOrCompare(Id,addfav,type,this.initialValue?.branchID, this.langId.toString(), this.initialValue?.currencyDetail?.selectedCcyCode, this.initialValue?.groupedBranchesId,ptype,index);
  }

  loadFavComp(Id, type) {
    if (type == 1) {
      this.propertySearchResultsfav.forEach((currentValue, index) => {
        if (currentValue.isFavorite)
          $('#fav_' + currentValue.id).addClass('active-icon');
      });

    }
    else if (type == 2) {
      this.propertySearchResults.forEach((currentValue, index) => {
        if (currentValue.isCompare)
          $('#camp_' + currentValue.id).addClass('active-icon');

      });

    }
  }





  sendClick(propid) {
    this._utilityService.sendClickInfo("2", propid, "", this.initialValue?.branchID,"",this.countryCode);
  }

  addCallNumber(mobile, i, propid) {
    
    $('#ane_' + i).hide();
    $('#an_' + i).show();
    $('#sp_' + i).html(mobile);
  }
  addCallagentNumber(mobile, i, propid) {
    
    $('#ane_' + i).show();
    $('#an_' + i).hide();
    $('#sp_' + i).html('');
    this._utilityService.sendClickInfo("3", propid, "",this.initialValue?.branchID,"",this.countryCode);
  }

  getpropertybyId(id) {
    this.latestPropertiespop = this.propertySearchResultsGrid.filter(x => x.id == id);
    /*this.placetext = 'Hi I found your property with reference number: ' + this.latestPropertiespop[0].code + ' on Aqarat website.Please contact me';*/
    //$('#myModal').modal('show');
  }

  onRecaptchaSuccess(token: string) {
    this.token = token;
  }

  selectedSort = 0;

  propertySort(changeValue) {
    this.selectedSort = changeValue;

    this.GetPropertyData(changeValue);
  }

}
