import { HttpClient, HttpErrorResponse, HttpHeaders } from '@angular/common/http';
import { Inject, Injectable } from '@angular/core';
import { AgentResponse, EmailMerchant } from '../models/Agents';
import { EmployeeResponse } from '../models/Employee';
import { SimpleResponse } from '../models/genericResponse.model';
import { delayedRetryHttp } from '../shared/delayedRetry';

@Injectable({ providedIn: 'root' })
export class AgentsService {
  private baseUrl: string;
  private api: string = "api/Agents";
  constructor(private httpClient: HttpClient, @Inject('BASE_URL') _baseUrl: string) {
    this.baseUrl = _baseUrl + this.api;
  }

  private handleErrors(errorResponse: HttpErrorResponse) {
    if (errorResponse.error instanceof ErrorEvent) {
      console.error('default Client Side Error: ', errorResponse.error.message);
    } else {
      throw errorResponse;
    }
  }

  async Agents_GetAsync(branchID: number, currentLang: string, countryCode: string,searchTerm: string): Promise<AgentResponse | void> {
    const a = await this.httpClient
      .get<AgentResponse>(`${this.baseUrl}/FilterAgency/${branchID}/${currentLang}/${countryCode}/${searchTerm}`, {
        headers: new HttpHeaders({
          'Content-Type': 'application/json',
          'Authorization': 'Basic '
        })
      }).pipe(delayedRetryHttp()).toPromise().catch(err => { console.log('default error'); });

    return a;
  }

  async AgentsListGet(branchId: number, currentLang: string): Promise<EmployeeResponse | void> {
    const a = await this.httpClient
      .get<EmployeeResponse>(`${this.baseUrl}/GetEmployees/${branchId}/${currentLang}`, {
        headers: new HttpHeaders({
          'Content-Type': 'application/json',
          'Authorization': 'Basic '
        })
      }).pipe(delayedRetryHttp()).toPromise().catch(err => { console.log('default error'); });

    return a;
  }

  async MerchantSubmit(currentLang:string,emailMerchant: EmailMerchant): Promise<SimpleResponse | void> {
    const a = await this.httpClient
      .post<SimpleResponse>(`${this.baseUrl}/Merchant_Registration/${currentLang}`, emailMerchant, {
        headers: new HttpHeaders({
          'Content-Type': 'application/json',
          'Authorization': 'Basic '
        })
      }).pipe(delayedRetryHttp()).toPromise().catch(err => { console.log('default error'); });

    return a;
  }

}
