import { Inject, Injectable } from '@angular/core';
import {HttpEvent,HttpInterceptor,HttpHandler,HttpRequest,HttpResponse } from '@angular/common/http';
import { Observable,of } from 'rxjs';
import { tap } from 'rxjs/operators';
import { HttpCasheService } from '../services/http-cashe.service';
import { TimerService } from '../services/timer.service';
import { escapeLeadingUnderscores } from 'typescript/lib/tsserverlibrary';


@Injectable()
export class CacheInterceptor implements HttpInterceptor {
  constructor(
    private cacheService: HttpCasheService,
    private timerService: TimerService
  ) { }

  intercept(
    req: HttpRequest<any>,
    next: HttpHandler
  ): Observable<HttpEvent<any>> {

    this.timerService.startTimer();
    let remainingTimeInMilliseconds = this.timerService.getRemainingTime();

    if (remainingTimeInMilliseconds <= 0) {

      this.timerService.resetTimer();
      this.cacheService.invalidateCache();
    }


    //check if the outgoing calls are GET and MRDH APIs
    if (req.method === 'GET') {
      // attempt to retrieve a cached response
      const cachedResponse:
        | HttpResponse<any>
        | undefined = this.cacheService.get(req.url);

       if (cachedResponse) {
         return of(cachedResponse);
       }

      // send request to server and add response to cache
      return next.handle(req).pipe(       
        tap((event) => {
          
          if (event instanceof HttpResponse) {
            if (req.url.indexOf('GetFavoriteProperties') > -1 || req.url.indexOf('AddFavoriteProperty') > -1 || req.url.indexOf('clearCompareCategory') > -1
              || req.url.indexOf('GetCompanyInfo') > -1 || req.url.indexOf('Dashboard_GetData') > -1
              || req.url.indexOf('PaymentStatements_Get') > -1 || req.url.indexOf('Payment_Get') > -1) {
              var fav = true;
            }            
            else
              this.cacheService.put(req.url, event);
          }
        })
      );
    }
    else {
      // pass along non-cacheable requests 
      return next.handle(req);
    }
  }
}

