import { Component, OnDestroy, OnInit } from '@angular/core';
import { ActivatedRoute, Router } from '@angular/router';
import { ConfirmBoxInitializer, DialogLayoutDisplay } from '@costlydeveloper/ngx-awesome-popup';
import { isNullOrUndefined } from 'util';
import { SimpleResponse } from '../../models/genericResponse.model';
import { currencyDetails, InitialValue, InitialValueResponse } from '../../models/initialValue';
import { Property, PropertySearchResults, PropertySearchResultsResponse } from '../../models/property';
import { CompareService } from '../../services/compare.service';
import { DefaultService } from '../../services/default.service';
import { UtilityService } from '../../shared/utility.service';
import { ConfigurationService } from 'src/app/shared/configuration.service';
import { FavoriteService } from 'src/app/services/favorite.service';
import { NgbModal } from '@ng-bootstrap/ng-bootstrap';
import { ContactModalComponent } from '../contact-modal/contact-modal.component';
declare var $: any;

@Component({
    selector: 'app-propertyfavorites',
    templateUrl: './propertyfavorites.component.html',
    styleUrls: ['./propertyfavorites.component.css']
})
/** propertyfavorites component*/
export class PropertyfavoritesComponent implements OnInit, OnDestroy {

  propertySearchResultsResponse: PropertySearchResultsResponse;
  propertySearchResultscompResponse: PropertySearchResultsResponse;
  propertySearchResults: PropertySearchResults[];
  propertySearchResultscomp: PropertySearchResults[];
  propertySearchitem: PropertySearchResults[];
  propertySearchitemcomp: PropertySearchResults[];
  initialValueResponse: InitialValueResponse;
  initialValue: InitialValue;
  currencyDetail: currencyDetails;
  simpleResponse: SimpleResponse;
  public currencyCode: string = 'BHD';
  userid: string;
  paramId: string;
  langId: number = 1;
  hostName: string = '';
  isPropCount: boolean = false;
  propNotFound: string = '';
  favorite: string = '';
  isDataLoaded:boolean = false;
  countryCode:string = "";
  myProperty: Property;
  isLuban: boolean = this._configurationService.isLuban;
    /** propertyfavorites ctor */
  constructor(private route: ActivatedRoute, private _route: Router, private _defaultService: DefaultService
    , private _compare: CompareService, private _configurationService : ConfigurationService, 
    private _favoriteService:FavoriteService,private _utilityService: UtilityService,
    private modalService: NgbModal) {


  }

  ngOnInit(): void {
    // $('html, body').animate({
    //   scrollTop: $("body").offset().top = 0
    // }, 0);

    this.langId = this._configurationService.getLanguageValue()
    this.propNotFound = this.langId == 1 ? "No property found" : "لم يتم العثور على اي عقار"
    this.favorite = this.langId == 1 ? "Favorites" : "المفضلة";
    this.hostName = localStorage.getItem('hostname');
    this.route.paramMap.subscribe(async params => {
      if (params.has('Id')) {

        this.paramId = params.get('Id');

      }
      this.initialValue = this._configurationService.getInitialValues();
      this.countryCode = this._configurationService.getSeletedCountryCode();
      this._favoriteService.GetFavStatus("1", "100", true, "1", this.langId.toString(), 0,this.initialValue?.branchID,this.initialValue?.currencyDetail?.selectedCcyCode,this.initialValue?.groupedBranchesId);
      this.GetInitialValues();
    });

    this._favoriteService.propertySearchResultsfavSubject.subscribe(resp=>{
      
      if(resp != null){
        this.propertySearchitem = resp
        this.isDataLoaded = true;
        if(this.propertySearchitem?.length == 0){
          this.isPropCount = true
        }else{
          this.isPropCount = false
        }
        //this.ClearCopare(propertyId, type)
        this.propertySearchResults = this.propertySearchitem;

        //this.myProperty = this.propertySearchResults;

        if (this.propertySearchResults?.length > 0) {
          this.loadFavComp(0, "1");
        }
      }

    })

    this._favoriteService.propertySearchResultsCompareSubject.subscribe(resp=>{
      
      if(resp != null){
        this.isDataLoaded = true;
        this.propertySearchResultscomp = resp
        if(this.propertySearchResultscomp?.length > 0){
          this.loadFavComp(0,2)
        }
      }

    })
  }

  ClearCopare(propertyId, type){
    this.isDataLoaded = false;
    this._favoriteService.ClearCopare(propertyId,type, this.initialValue?.branchID,this.initialValue?.currencyDetail?.selectedCcyCode,this.initialValue?.groupedBranchesId,this.langId.toString(),false)
  }


  highLightFav(propertySerchResult:PropertySearchResults[]){

    
    if(propertySerchResult?.length > 0){
      propertySerchResult.forEach(item=>{
        if(item?.isFavorite){
          let data = document.getElementById(`fav_${item.id}`);
          if(!isNullOrUndefined(data)){
            data?.classList.add('active-icon');
          }
        }else if(item?.isCompare){
          
          let data = document.getElementById(`camp_${item.id}`);
          if(!isNullOrUndefined(data)){
            data?.classList.add('active-icon');
          }
        }
      })
    }
  }

  addCallNumber(mobile, i, propid) {
    
    $('#ane_' + i).hide();
    $('#an_' + i).show();
    $('#sp_' + i).html(mobile);
    // let item = document.getElementById('ane_' + i);
    // if(item){
    //   item.style.display = "none";
    // }
  }
  addCallagentNumber(mobile, i, propid) {
    
    $('#ane_' + i).show();
    $('#an_' + i).hide();
    $('#sp_' + i).html('');
    this._utilityService.sendClickInfo("3", propid, "", this.initialValue?.branchID,"",this.countryCode);
  }


  sendClick(event, type, ID, agencyId){
    if(type == 1){
      event?.preventDefault();
    }
    this._utilityService.sendClickInfo(type, ID, "", this.initialValue?.branchID, agencyId,this.countryCode);
  }


  ngOnDestroy(): void {
    $('#myModal').modal('hide');
  }

  GetInitialValues() {
    this.userid = "zj5gitx5411oab2quguwsecq-14";
  }

  getpropertybyId(id) { 
    this._utilityService.sendClickInfo("1", id, "", this.initialValue?.branchID,"",this.countryCode);
    const modalRef = this.modalService.open(ContactModalComponent,{ windowClass: 'custom-modal' });
    let propertyPop = this.propertySearchitem.filter(x => x.id == id)[0];
    let latestPropertiespop = {
      realID: null,
      id: propertyPop.id,
      encyId: '',
      code: propertyPop.code,
      formType: propertyPop.formType,
      profitSeg: '',
      name: propertyPop.name,
      description: propertyPop.description,
      type: {
        id: '13',
        text: 'Rent'
      },
      type2: propertyPop.pType,
      type2Id: propertyPop.ptypeId,
      premiumId: propertyPop.premiumId,
      premium: propertyPop.premium,
      price: propertyPop.aqarPrice+'',
      price2: propertyPop.price2,
      price3: propertyPop.price3,
      price4: propertyPop.price4,
      rooms: propertyPop.aqarRooms,
      parks: propertyPop.aqarParks,
      size: propertyPop.aqarSize+'',
      bathrooms: propertyPop.aqarBathrooms,
      floors: '',
      crossStreet: '',
      plumbing: '',
      furnished: '',
      electricity: null,
      features: null,
      features2: null,
      mainIMG: propertyPop.img,
      promoIMG: null,
      floorIMG: null,
      vid: null,
      view360List: null,
      quickSummary: null,
      address: propertyPop.address,
      latitude: '',
      longitude: '',
      buildingYear: '',
      dateAdded: '',
      availableFrom: '',
      branchName: '',
      branchId: propertyPop.branchId,
      website: propertyPop.website,
      customerId: '',
      agent: { id : propertyPop.agent.id,
        prefex:'',
        title: '',
        name: propertyPop.agent.name,
        mobile: propertyPop.agent.mobile,
        whatsApp: propertyPop.agent.whatsApp,
        fax: propertyPop.agent.fax,
        telephone: propertyPop.agent.telephone,
        email: propertyPop.agent.email,
        image: propertyPop.agent.image,
        link: propertyPop.agent.link,
        companyName: propertyPop.agent.companyName,
        code:propertyPop.agent.code },
      favorite: false,
      compare: false,
      cityID: '',
      status: propertyPop.status,
      statusID: propertyPop.statusID,
      clientLogo: propertyPop.ClientLogo
    }
    
    modalRef.componentInstance.myProperty = latestPropertiespop;
  }

  async SetCompare(Id, type) {
    this.isDataLoaded = false;
    let addfav = 1;
    if (type == 2) {
      if (!isNullOrUndefined(this.propertySearchResultscomp)) {
        let cmp = this.propertySearchResultscomp?.filter(p => p.id == Id && p.isCompare == true)
        if (cmp.length > 0) {
          addfav = 0;
          $('#camp_' + Id).removeClass('active-icon');
        }
      }
    }
    this._favoriteService.addToFavoriteOrCompare(Id,addfav,type,this.initialValue?.branchID, this.langId.toString(), this.initialValue?.currencyDetail?.selectedCcyCode, this.initialValue?.groupedBranchesId,0,0);
  } 

  ecodeuri(url: string) {
    var urlval = url.split('=');
    if (url.indexOf("?") !== -1) {
      this._route.navigate(['/propertydetail'], { queryParams: { 'id': urlval[1] } });
    }
    else
      this._route.navigate([decodeURI("/" + url)]);

  }



  loadFavComp(Id, type) {
   if (type == 2) {
     this.propertySearchResultscomp.forEach((currentValue, index) => {
        if (currentValue.isCompare)
          $('#camp_' + currentValue.id).addClass('active-icon');
      });

    }
  }

}
