import { Component, OnInit, Input, AfterViewInit, ViewChild } from '@angular/core';
import { Router } from '@angular/router';
import { InitialValue } from 'src/app/models/initialValue';
import { Property, PropertySearchResults } from 'src/app/models/property';
import { FavoriteService } from 'src/app/services/favorite.service';
import { ConfigurationService } from 'src/app/shared/configuration.service';
import { isNullOrUndefined } from 'util';
import { SwiperComponent } from "swiper/angular";
import Swiper from "swiper/types/swiper-class";

import SwiperCore , {
  Navigation,
  Autoplay,
  Controller,
} from 'swiper';
import { NgbModal } from '@ng-bootstrap/ng-bootstrap';
import { UtilityService } from 'src/app/shared/utility.service';
import { ContactModalComponent } from '../contact-modal/contact-modal.component';
SwiperCore.use([
  Navigation,
  Autoplay,
  Controller
]);

@Component({
  selector: 'app-property-highlights-slider',
  templateUrl: './property-highlights-slider.component.html',
  styleUrls: ['./property-highlights-slider.component.css']
})
export class PropertyHighlightsSliderComponent implements OnInit, AfterViewInit {

  @Input() sliderProperties: Property[];
  @Input() isMoreProperty: boolean;
  @Input() title: string;
  @ViewChild('swiperRef', { static: false }) swiperRef?: SwiperComponent;
  public currencyCode: string = 'BHD';
  
  url = `${window.location.href}propertydetail?id=`

  propertySearchResults: PropertySearchResults[];
  propertySearchResultsfav: PropertySearchResults[];
  private countryCode: string = '+973'
// settings = {
//     slidesToShow: 3,
//     slidesToScroll: 2,
//     arrows: true,
//     prevArrow: '.listings .slider-prev',
//     nextArrow: '.listings .slider-next',
//     dots: false,
//     mouseDrag: true,
//     speed: 500, // Animation speed in milliseconds
//     swipe: true,
//     swipeToSlide: true,
//     rtl: true,
//     responsive: [
//       {
//         breakpoint: 991,
//         settings: {
//           slidesToShow: 2
//         }
//       },
//       {
//         breakpoint: 768,
//         settings: {
//           slidesToShow: 1
//         }
//       }
//     ]
//   }

swiperConfig:{
  freeMode:true,
  slidesPerView:4
}

breakpoints = {
  650: { slidesPerView: 1, spaceBetween: 10 },
  760: { slidesPerView: 2, spaceBetween: 20 },
  1024: { slidesPerView: 3, spaceBetween: 20 },
};
  langId: number = 1;
  initialValue: InitialValue;
  isLuban: boolean = this._configurationService.isLuban;
  
  constructor(private _configurationService:ConfigurationService, private modalService: NgbModal, private _utilityService:UtilityService, private _favoriteService :FavoriteService, private _route: Router) { }
  ngAfterViewInit(): void {
    
    if(this.sliderProperties?.length > 0 && this.propertySearchResultsfav?.length > 0){
      this.highLightFav(this.propertySearchResultsfav);
    }
  }
  

  ngOnInit(): void {

    this.initialValue = this._configurationService.getInitialValues();
    this.currencyCode = this._configurationService.getSeletedCurrencyCode();
    this.langId =  this._configurationService.getLanguageValue();

    this._favoriteService.propertySearchResultsfavSubject.subscribe(resp=>{
      
      if(resp != null){
        this.propertySearchResultsfav = resp
        if(this.propertySearchResultsfav?.length > 0 && this.sliderProperties?.length >0){
          this.highLightFav(this.propertySearchResultsfav);
        }
      }

    })
  }

  public ReserveProperty(property: any, index: number) {
    this._utilityService.sendClickInfo("1", property.id, "", this.initialValue?.branchID,"",this.countryCode);
    const modalRef = this.modalService.open(ContactModalComponent,{ windowClass: 'custom-modal' });
    console.log(property, "property")
    modalRef.componentInstance.myProperty = property;
  }

  ecodeuri(event,id, fromtype) {
    event?.preventDefault();
    const queryParams = { id };
  
    if (fromtype == '1') {
      queryParams['fromtype'] = fromtype;
    }
  
    this._route.navigate(['/propertydetail'], { queryParams });
  }


  async GetCompare(Id, type, index, ptype, event:any) {
    
    event?.preventDefault()
    let addfav = 1;
    if (type == 1) {
      if (!isNullOrUndefined(this.propertySearchResultsfav)) {
        let fp=this.propertySearchResultsfav.filter(p => p.id == Id && p.isFavorite==true)
        if (fp.length > 0) {
          addfav = 0;
          $('#fav_' + Id).removeClass('active-icon');
        }
      }      
    }
    else {
      if (!isNullOrUndefined(this.propertySearchResults)) {
        let cmp = this.propertySearchResults.filter(p => p.id == Id && p.isCompare == true)
        if (cmp.length > 0) {
          addfav = 0;
          $('#camp_' + Id).removeClass('active-icon');
        }
      }
    }

    this._favoriteService.addToFavoriteOrCompare(Id,addfav,type,this.initialValue?.branchID, this.langId.toString(), this.initialValue?.currencyDetail?.selectedCcyCode, this.initialValue?.groupedBranchesId,ptype,index);
  } 


  highLightFav(propertySerchResult:PropertySearchResults[]){
    
    if(propertySerchResult?.length > 0){
      propertySerchResult.forEach(item=>{
        if(item?.isFavorite){
          let data = document.getElementById(`fav_${item.id}`);
          if(!isNullOrUndefined(data)){
            data?.classList.add('active-icon');
          }
        }
      })
    }
  }

}
