import { HttpErrorResponse } from '@angular/common/http';
import { Component, OnInit } from '@angular/core';
import { FormBuilder, FormGroup } from '@angular/forms';
import { NavigationEnd, Router } from '@angular/router';
import { Agent, AgentResponse } from '../../models/Agents';
import { ConfigDetails } from '../../models/appConfig.model';
import { InitialValue, InitialValueResponse } from '../../models/initialValue';
import { AgentsService } from '../../services/agents.service';
import { DefaultService } from '../../services/default.service';
import { AppconfigService } from '../../shared/appconfig.service';
import { UtilityService } from '../../shared/utility.service';
import { RedirectionService } from 'src/app/services/redirection.service';
import { ConfigurationService } from 'src/app/shared/configuration.service';

@Component({
    selector: 'app-ownersagent',
    templateUrl: './ownersagent.component.html',
    styleUrls: ['./ownersagent.component.css']
})
/** ownersagent component*/
export class OwnersagentComponent implements OnInit {
  form: FormGroup;
  initialValueResponse: InitialValueResponse;
  initialValue: InitialValue;
  agentResponse: AgentResponse;
  agents: Agent[];
  langId: number = 1;
  hostName: string = '';
  agencyurl: string = '';
  private configDetails: ConfigDetails;
  countryCode: string = '973';
    /** ownersagent ctor */
  constructor(private formBuilder: FormBuilder, private _route: Router, private _defaultService: DefaultService,
    private _agentsService: AgentsService, private _appconfigService: AppconfigService, private _utilityService: UtilityService
    ,private _redirectionService: RedirectionService, private _configurationServices : ConfigurationService) {

  }

  ngOnInit(): void {
    this.form = this.formBuilder.group({
      txtSearch: [''],
      
    });

    this.langId = this._configurationServices.getLanguageValue();
    this.hostName = localStorage.getItem('hostname');
    this.countryCode = this._configurationServices.getSeletedCountryCode();
    this.initialValue = this._configurationServices.getInitialValues();
    this.GetAgencyUrl();

    this.GetInitialValues(this.hostName,0)
    // $('html, body').animate({
    //   scrollTop: $("body").offset().top = 0
    // }, 0);
  }
  GetInitialValues(hostName, isCustomer) {
    if (this.initialValue.branchID == 14)
    this.GetAgentsData("", this.initialValue.branchID, this.langId.toString(), this.countryCode);
  else
    this._route.navigate(['/agents/' + this.initialValue.branchID + '/' + this.langId]);
  }

  GetAgentsData(searchTerm: string, branchID: number, currentLang: string, countryCode: string ) {
    this._agentsService.Agents_GetAsync(branchID, currentLang, countryCode, searchTerm).then((result) => {
      if (result) {
        this.agentResponse = UtilityService.clone(result);
        this.agents = this.agentResponse.agents;
      }
    });
  }

  redirectToClient(value:any){
    
    if(value != null){
      this._redirectionService.redirectToClient(value, this.initialValue.environment);
    }
    
  }

  submit() {
    let searchTerm = this.form.controls["txtSearch"].value;
    this.GetAgentsData(searchTerm, this.initialValue.branchID, this.langId.toString(), this.countryCode);
  }

  navigateToPage(url: string,value) {
    let finalUrl = ""
    if(this.langId == 1){
      finalUrl = `${url}/properties/${value}/0`
    }else{
      finalUrl = `${url}/ar/properties/${value}/0`
    }

    window.open(finalUrl,'_blank');
  }

  async GetAgencyUrl() {
    await this._appconfigService.getAppConfigValue("AgencyURL")
      .then(async (result: ConfigDetails) => {
        if (result) {
          this.configDetails = UtilityService.clone(result);
          if (this.configDetails) {
            this.agencyurl = this.configDetails.configValue;           
          } else {
          }
        }
      })
      .catch((err: HttpErrorResponse) => {
        this._utilityService.handleErrorResponse(err);
      });

  }

}
