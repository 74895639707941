import { Injectable } from '@angular/core';
import { Router } from '@angular/router';
import { ConfigurationService } from '../shared/configuration.service';

@Injectable({
  providedIn: 'root'
})
export class RedirectionService {
  langId = 1;
  constructor(private router:Router, private configurationService: ConfigurationService) { }


  public redirectToClient(clientURl:string, environment:string){
    this.langId = this.configurationService.getLanguageValue();    
    let url = ""
    clientURl = clientURl.replace('http://', '').replace('https://', '');

    let urlList = clientURl.split(".");

    
    if(this.langId ==1){
      url = `https://${urlList[0]}.aqaraats.com/en`;
    }else{
      url = `https://${urlList[0]}.aqaraats.com/ar`;
    }


    window.open(url, '_blank');

    // if (environment == "D") {
    //   url = this.router.serializeUrl(
    //     this.router.createUrlTree([`/` + finalUrl.toLowerCase() + `.agencydefault`])
    //   );
    //   var splitUrl = url.replace('https://', '').split('.');
    //   var hostName = splitUrl[0].replace('/', '') + ".aqaraats.com";
    //   localStorage.setItem('hostname', hostName);       
    // } else {
    //   url = "https://" + finalUrl + "." + this.initialValue.agencyURL + "/en";
    // }
    // window.open(url, '_blank');
  }
}
