import { HttpClient, HttpErrorResponse, HttpHeaders } from '@angular/common/http';
import { Inject, Injectable } from '@angular/core';
import { SimpleResponse } from '../models/genericResponse.model';
import { ItemPropByArea, PropertyCity, PropertyDetailResponse, PropertyFiltersResponse, PropertySearchParams, PropertySearchResultsResponse, SimpleRequest } from '../models/property';
import { delayedRetryHttp } from '../shared/delayedRetry';

@Injectable({ providedIn: 'root' })
export class PropertyService {
  private baseUrl: string;
  private api: string = "api/Property";
  constructor(private httpClient: HttpClient, @Inject('BASE_URL') _baseUrl: string) {
    this.baseUrl = _baseUrl + this.api;
  }
  private handleErrors(errorResponse: HttpErrorResponse) {
    if (errorResponse.error instanceof ErrorEvent) {
      console.error('default Client Side Error: ', errorResponse.error.message);
    } else {
      throw errorResponse;
    }
  }
 
  async Property_SearchAsync(isViewAll, sortyBy,propertySearchParams: PropertySearchParams): Promise<PropertySearchResultsResponse | void> {
    const a = await this.httpClient
      .post<PropertySearchResultsResponse>(`${this.baseUrl}/Property_Search/${isViewAll}/${sortyBy}`,  propertySearchParams  , {
        headers: new HttpHeaders({
          'Content-Type': 'application/json',
          'Authorization': 'Basic '
        })
      }).pipe(delayedRetryHttp()).toPromise().catch(err => { console.log('default error'); });

    return a;
  }


   
  async GetPropertiesOnCountry(currentLang, countryCode): Promise<Array<PropertyCity> | void> {
    const a = await this.httpClient
      .get<Array<PropertyCity>>(`${this.baseUrl}/GetAreasOnCountry/${currentLang}/${countryCode}`, {
        headers: new HttpHeaders({
          'Content-Type': 'application/json',
          'Authorization': 'Basic '
        })
      }).pipe(delayedRetryHttp()).toPromise().catch(err => { console.log('default error'); });

    return a;
  }

  async PropertyArea_SearchAsync(propertySearchParams: PropertySearchParams): Promise<ItemPropByArea[] | void> {
    const a = await this.httpClient
      .post<ItemPropByArea[]>(`${this.baseUrl}/LocationFilter_Search`, propertySearchParams, {
        headers: new HttpHeaders({
          'Content-Type': 'application/json',
          'Authorization': 'Basic '
        })
      }).pipe(delayedRetryHttp()).toPromise().catch(err => { console.log('default error'); });

    return a;
  }

  async GetSerch_FilterAsync(currentLang: number, branchId: number, groupedBranches: string, countryCode: number): Promise<PropertyFiltersResponse | void> {
    const a = await this.httpClient
      .get<PropertyFiltersResponse>(`${this.baseUrl}/PropertyFilters_Get/${currentLang}/${branchId}/${countryCode}/${groupedBranches}`, {
        headers: new HttpHeaders({
          'Content-Type': 'application/json',
          'Authorization': 'Basic '
        })
      }).pipe(delayedRetryHttp()).toPromise().catch(err => { console.log('default error'); });

    return a;
  }

  async Property_GetAsync(currentLang: number, groupedBranches: string, branchId: number, countryCode: string, simpleRequest: SimpleRequest): Promise<PropertyDetailResponse | void> {
    const a = await this.httpClient
      .post<PropertyDetailResponse>(`${this.baseUrl}/Property_Get/${currentLang}/${branchId}/${countryCode}/${groupedBranches}`, simpleRequest, {
        headers: new HttpHeaders({
          'Content-Type': 'application/json',
          'Authorization': 'Basic '
        })
      }).pipe(delayedRetryHttp()).toPromise().catch(err => { console.log('default error'); });

    return a;
  }

  async Property_Count(propertySearchParams: PropertySearchParams): Promise<SimpleResponse | void> {
    const a = await this.httpClient
      .post<SimpleResponse>(`${this.baseUrl}/Property_Search_Count`, propertySearchParams, {
        headers: new HttpHeaders({
          'Content-Type': 'application/json',
          'Authorization': 'Basic '
        })
      }).pipe(delayedRetryHttp()).toPromise().catch(err => { console.log('default error'); });

    return a;
  }

  async GetMainProperyName(currentLang: number, subSegID: string): Promise<string | void> {
    try {
      const response = await this.httpClient
        .get<string>(`${this.baseUrl}/GetMainProperyName/${currentLang}/${subSegID}`, {
          headers: new HttpHeaders({
            'Content-Type': 'application/json',
            'Authorization': 'Basic '
          })
        })
        .pipe(delayedRetryHttp())
        .toPromise();
  
      return response;
    } catch (err) {
      console.log('default error');
      return undefined;
    }
  }

}
