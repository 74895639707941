import { Component } from '@angular/core';
import { Router } from '@angular/router';

@Component({
    selector: 'app-page-notfound',
    templateUrl: './page-notfound.component.html',
    styleUrls: ['./page-notfound.component.css']
})
/** page-notfound component*/
export class PageNotfoundComponent {
    /** page-notfound ctor */
  constructor(private _route: Router) {
    //   this._route.navigate(['/dashboard']);
    }
}
