import { Injectable } from '@angular/core';
import { Observable, Subject } from 'rxjs';
import { PropertySearchParams } from '../models/property';

@Injectable({ providedIn: 'root' })
export class SearchService {
  private subject = new Subject<PropertySearchParams>();
  sendMessage(message: PropertySearchParams) {
    this.subject.next({
      branchId: message.branchId,
      countryCode: message.countryCode,
      contactId: message.contactId, currentLang: message.currentLang, cAID:message.cAID,
      groupedBranches: message.groupedBranches, page: message.page,
      sort: message.sort, type: message.type, limit: message.limit, searchTerm: message.searchTerm,
      propertyType2: message.propertyType2,
      propertyType3: message.propertyType3, propertyLocation: message.propertyLocation, minArea: message.minArea,
      maxArea: message.maxArea, minPrice: message.minPrice,
      maxPrice: message.maxArea, features: message.features, minBeds: message.minBeds, maxBeds: message.maxBeds, minBaths: message.minBaths,
      maxBaths: message.maxBaths, electricity: message.electricity,
      Furnicher: message.Furnicher, count: message.count, nonVacant: message.nonVacant, agentID: message.agentID, parentID: message.parentID,
      myProperty: message.myProperty,
      currencyDetails: {
        selectedCcyCode: message.currencyDetails.selectedCcyCode,
        selectedCcyName: message.currencyDetails.selectedCcyName,
        selectedCcySwift: message.currencyDetails.selectedCcySwift,
        selectedCcySwiftCodeArb: message.currencyDetails.selectedCcySwiftCodeArb,
        selectedCcySwiftCode: message.currencyDetails.selectedCcySwiftCode,
        selectedCcyDecimal: message.currencyDetails.selectedCcyDecimal,
      } });
  }

  clearMessages() {
    this.subject.next();
  }

  getMessage(): Observable<PropertySearchParams> {
    return this.subject.asObservable();
  }
}
