import { Component, OnInit } from '@angular/core';
import { Lightbox } from 'ngx-lightbox';
import { ConfigurationService } from 'src/app/shared/configuration.service';
import { ActivatedRoute } from '@angular/router';
import { InitialValue } from 'src/app/models/initialValue';
import { NewsService } from '../../services/news.service';
import { UtilityService } from 'src/app/shared/utility.service';

@Component({
  selector: 'app-gallery',
  templateUrl: './gallery.component.html',
  styleUrls: ['./gallery.component.css']
})
export class GalleryComponent implements OnInit {

  constructor(private _newsService: NewsService, private _lightbox: Lightbox,private _configurationService : ConfigurationService,private _route: ActivatedRoute) { }

  initialValue: InitialValue;
  paramId: string;
  langId: number = 1;
  galleryImages: any = [];

  ngOnInit(): void {
    this.langId = this._configurationService.getLanguageValue();
    this._route.paramMap.subscribe(async params => {
      if (params.has('ID')) {

        this.paramId = params.get('ID');
      }      
      this.initialValue = this._configurationService.getInitialValues();
      this.GetGalleryData();
    });
    
  }

  GetGalleryData() {
    this._newsService.Gallery_GetAsync(this.paramId, this.langId.toString(), this.initialValue.branchID).then((result) => {
      if (result) {
        this.galleryImages = result.map((item) => ({ src: item }));
      }
    });
  }
  open(index: number): void {
    // open lightbox
    this._lightbox.open(this.galleryImages,index);
  }
 //https://www.haialuban.com/api/Media/GalleryGet/130600/1/540
}
