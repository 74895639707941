import { AfterViewInit, Component, ElementRef, Input, OnInit, ViewChild } from '@angular/core';
import { FormBuilder, FormGroup, Validators } from '@angular/forms';
import { Router } from '@angular/router';
import { BlobOptions } from 'buffer';
import { CountryISO, SearchCountryField } from 'ngx-intl-tel-input';
import { Email } from 'src/app/models/EmailContactUs';
import { SimpleResponse } from 'src/app/models/genericResponse.model';
import { InitialValue } from 'src/app/models/initialValue';
import { ItemPropByArea, PropertiesByTypeResponse, Property, PropertySearchParams, PropertySearchResults } from 'src/app/models/property';
import { ContactusService } from 'src/app/services/contactus.service';
import { FavoriteService } from 'src/app/services/favorite.service';
import { ConfigurationService } from 'src/app/shared/configuration.service';
import { NotificationService } from 'src/app/shared/notification.service';
import { UtilityService } from 'src/app/shared/utility.service';
import { isNullOrUndefined } from 'util';
import { ContactModalComponent } from '../contact-modal/contact-modal.component';
import { NgbModal } from '@ng-bootstrap/ng-bootstrap';
declare var $: any;
@Component({
  selector: 'app-properties-tooltip-listing',
  templateUrl: './properties-tooltip-listing.component.html',
  styleUrls: ['./properties-tooltip-listing.component.css']
})
export class PropertiesTooltipListingComponent implements OnInit, AfterViewInit {


  @Input() latestProperties: Property[];
  // @Input() buildingProperties: Property[];
  // @Input() compoundProperties: Property[];
  // @Input() officeSpaceProperties: Property[];
  // @Input() resortsSpaceProperties: Property[];
    @Input() itemPropByArea: ItemPropByArea[];

  @Input() PropertyByType: PropertiesByTypeResponse;

  @ViewChild('property') propertyTab: ElementRef;

  @ViewChild('propertyByType') propertyByTypeTab: ElementRef;


  dataDictionary: Map<string, Property[]> = new Map<string, Property[]>();
  propertiesByAreaCount: ItemPropByArea[]

  keysArray: string[] 

  data:Property[];

  tabType: string = '';
  tabTypeSelected: string = '';
  vacantType: string = '';
  limittype: string = '4000';
  langId: number = 1;
  isShowDivFilter: boolean = false;
  isShowDivCompound: boolean = false;
  isShowDivOffice: boolean = false;
  isShowDivResort: boolean = false;
  propertySearchParams: PropertySearchParams;
  initialValue: InitialValue;
  public currencyCode: string = 'BHD';
  public countryCode = '973';
  isShow: boolean = false;
  latestPropertiespop: Property[];
  placetext: string = '';
  formpop: FormGroup;
  propertySearchResultsComp: PropertySearchResults[];
  propertySearchResultsfav: PropertySearchResults[];
  submitted: boolean = false;
  email: Email;
  token: string;
  simpleResponseemil: SimpleResponse
  CountryISO = CountryISO;
  preferredCountries: CountryISO[] = [CountryISO.Bahrain];
  SearchCountryField = SearchCountryField;
  isDetailsIcon: boolean = true;
  url:string = new URL(window.location.href).hostname
  urlProper = `${window.location.href}/propertydetail?id=`;
  disableSubmit:boolean = false;

  constructor(private _configurationService:ConfigurationService, private _route: Router, 
    private formBuilder: FormBuilder,private _favoriteService :FavoriteService, 
     private _contactusService: ContactusService, private notifyService: NotificationService, private modalService: NgbModal, private _utilityService:UtilityService) { }

  ngAfterViewInit(): void {
    if(this.latestProperties?.length > 0 && this.propertySearchResultsfav?.length > 0){
      this.highLightFav(this.propertySearchResultsfav,true);
    }

      if(this.latestProperties?.length > 0 && this.propertySearchResultsComp?.length > 0){
        this.highLightFav(this.propertySearchResultsComp, false);
      }
    }

  ngOnDestroy(): void {
    $('#myModal')?.modal('hide');
  }

  get f2() {
    return this.formpop.controls;
  }

  ngOnInit(): void {
    
    this.langId =  this._configurationService.getLanguageValue();
    this.initialValue = this._configurationService.getInitialValues();
    this.countryCode = this._configurationService.getSeletedCountryCode();
    this.currencyCode = this._configurationService.getSeletedCurrencyCode();
    this.formpop = this.formBuilder.group({
      fname: ['', [Validators.required]],
      phone: ['', [Validators.required]],
      email: ['', [Validators.required, Validators.email]],
      comment: ['', [Validators.required]]
    });

    this._favoriteService.propertySearchResultsfavSubject.subscribe(resp=>{ 
      if(resp != null){
        this.propertySearchResultsfav = resp
        if(this.latestProperties?.length > 0 && this.propertySearchResultsfav?. length > 0){
          this.highLightFav(this.propertySearchResultsfav, true);
        }
      }
    });

    this._favoriteService.propertySearchResultsCompareSubject.subscribe(resp=>{
      if(resp != null){
        this.propertySearchResultsComp = resp
        if(this.latestProperties?.length > 0 && this.propertySearchResultsComp?. length > 0){
          this.highLightFav(this.propertySearchResultsComp, false);
        }
      }
    });




    if(this.PropertyByType != null){
      this.dataDictionary = this.PropertyByType?.propertiesByType;
      this.keysArray = Object.keys(this.dataDictionary)
    }

  this.ViewAllData("properties", ""); 


  }


  public ReserveProperty(property: any, index: number) {
    console.log(property, "property");
    console.log(index, "index");
    this._utilityService.sendClickInfo("1", property.id, "", this.initialValue?.branchID,"",this.countryCode);
    const modalRef = this.modalService.open(ContactModalComponent,{ windowClass: 'custom-modal' });
    modalRef.componentInstance.myProperty = property;
  }

  async GetCompare(Id, type, index, ptype) {
    
    let addfav = 1;
    if (type == 1) {
      if (!isNullOrUndefined(this.propertySearchResultsfav)) {
        let fp=this.propertySearchResultsfav.filter(p => p.id == Id && p.isFavorite==true)
        if (fp.length > 0) {
          addfav = 0;
          this.latestProperties?.map(x=>x.id == Id ? x.favorite = false : x.favorite = x.favorite);
          // $('#fav_' + Id).removeClass('active-icon');
        }
      }     
    }
    else {
      if (!isNullOrUndefined(this.propertySearchResultsComp)) {
        let cmp = this.propertySearchResultsComp.filter(p => p.id == Id && p.isCompare == true)
        if (cmp.length > 0) {
          addfav = 0;
          this.latestProperties?.map(x=>x.id == Id ? x.compare = false : x.compare = x.compare);
          // $('#camp_' + Id).removeClass('active-icon');
        }
      }
    }

    this._favoriteService.addToFavoriteOrCompare(Id,addfav,type,this.initialValue?.branchID, this.langId.toString(), this.initialValue?.currencyDetail?.selectedCcyCode, this.initialValue?.groupedBranchesId,ptype,index);
  } 

  highLightFav(propertySerchResult:PropertySearchResults[], isFavoirit){
    if(propertySerchResult?.length > 0 && propertySerchResult != null){
      propertySerchResult.forEach(item=>{
        if(isFavoirit){
          this.latestProperties.map(x=>x.id == item.id ? (x.favorite = item.isFavorite) : (x.favorite = x.favorite) )
        }else{
          this.latestProperties.map(x=>x.id == item.id ? (x.compare = item.isCompare) : (x.compare = x.compare) )
        }

      })
    }
  }

  toggleLocationDivFilter() {
    this.isShowDivFilter = !this.isShowDivFilter;
  }

  ViewAllData(typevalue, vacant) {
    
    if(typevalue != 'properties' && typevalue != ""){

      this.data =  this.PropertyByType.propertiesByType[typevalue];
      this.propertyTab?.nativeElement?.classList.remove("active"); 
      this.propertyByTypeTab?.nativeElement?.classList.add("active");

      if(this.data?.length > 0){
        this.tabTypeSelected =  this.data[0].type2Id;
        this.propertiesByAreaCount = this.itemPropByArea.filter(ib=>ib.locationType== this.data[0].type2Id);
      }


    }else{
      this.propertyByTypeTab?.nativeElement?.classList.remove("active"); 
      this.propertyTab?.nativeElement?.classList.add("active");  
    }
    this.tabType =  typevalue.replace(/\s/g, '').toLowerCase(); 

    this.vacantType = vacant;
    if (typevalue == 'properties')
      this.limittype = '4000';
    else
      this.limittype = '50';
  }

  toggleLocationCompound() {
    this.isShowDivCompound = !this.isShowDivCompound;
  }

  toggleLocationOffice() {
    this.isShowDivOffice = !this.isShowDivOffice;
  }

  toggleLocationResort() {
    this.isShowDivResort = !this.isShowDivResort;
  }

  
  ShowDetail(id) {
    if (id == '1')
      this.isDetailsIcon = true;
    else
      this.isDetailsIcon = false;
  }

  propertyAll() {
    localStorage.setItem('Qsearch', JSON.stringify(this.prepareFormSearchAll()));
    this._route.navigate(['/properties/0/0']);

  }

  prepareFormSearchAll() {
    return this.propertySearchParams = {
      branchId: this.initialValue.branchID,
      countryCode: this.countryCode,
      contactId: '0', currentLang: this.langId, cAID: 0,
      groupedBranches: this.initialValue.groupedBranchesId, page: '',
      sort: '', type: '', limit: this.limittype,
      searchTerm: '',
      propertyType2: this.tabTypeSelected,
      propertyType3: '',
      propertyLocation: '',
      minArea: '',
      maxArea: '',
      minPrice: '',
      maxPrice: '', features: '',
      minBeds: '',
      maxBeds: '',
      minBaths: '',
      maxBaths: '',
      electricity: '',
      Furnicher: '', count: true,
      nonVacant: this.vacantType,
      agentID: '', parentID: '',
      myProperty: false,
      currencyDetails: {
        selectedCcyCode: '',
        selectedCcyName: '',
        selectedCcySwift: '',
        selectedCcySwiftCodeArb: '',
        selectedCcySwiftCode: '',
        selectedCcyDecimal: '',
      }
    }
  }

  prepareFormSearchAllFix() {
    return this.propertySearchParams = {
      branchId: 14,
      countryCode: this.countryCode,
      contactId: '0', currentLang: this.langId, cAID: 0,
      groupedBranches: '', page: '',
      sort: '', type: '', limit: '4000',
      searchTerm: '',
      propertyType2: '',
      propertyType3: '',
      propertyLocation: '',
      minArea: '',
      maxArea: '',
      minPrice: '',
      maxPrice: '', features: '',
      minBeds: '',
      maxBeds: '',
      minBaths: '',
      maxBaths: '',
      electricity: '',
      Furnicher: '', count: true,
      nonVacant: '',
      agentID: '', parentID: '',
      myProperty: false,
      currencyDetails: {
        selectedCcyCode: '',
        selectedCcyName: '',
        selectedCcySwift: '',
        selectedCcySwiftCodeArb: '',
        selectedCcySwiftCode: '',
        selectedCcyDecimal: '',
      }
    }
  }
  ecodeuri(event,id, fromtype) {
    event?.preventDefault();
    const queryParams = { id };
  
    if (fromtype == '1') {
      queryParams['fromtype'] = fromtype;
    }
  
    this._route.navigate(['/propertydetail'], { queryParams });
  }

  hideDetails() {
    this.isShow = false;
    $('#add-title').removeClass('active-icon');
    $('#add-title1').addClass('active-icon');
  }
  showDetails() {
    this.isShow = true;
    $('#add-title').addClass('active-icon');
    $('#add-title1').removeClass('active-icon');
  }

  locationSearch(value) {
    this._route.navigate(['/properties/0/' + value]);
  }

  getpropertybyId(id, agencyId) {
    this.latestPropertiespop = this.latestProperties.filter(x => x.id == id);
    this.placetext =  (this.langId == 1 ? "Hi I found your property with reference number: " : "مرحبًا، لقد وجدت عقارك برقم المرجع:") + this.latestPropertiespop[0].code + (this.langId == 1 ? ' on Aqarat website.Please contact me. Thank you.' : "على موقع عقارات. يرجى التواصل معي. شكرًا") ;
    this.formpop.get("comment").setValue(this.placetext);
    this._utilityService.sendClickInfo("1", id, "",this.initialValue?.branchID, agencyId,this.countryCode);
    //$('#myModal').modal('show');
  }


  prepareEmail(aname, aemail, pid, pcode, ptype, aarea, curl, minPrice, maxPrice, agentId, typeOfRequest, saleOrRent,branchId) {

    return this.email = {
      CmName: this.formpop.controls["fname"].value,
      CmEmail: this.formpop.controls["email"].value,
      CmPhone: this.formpop.controls["phone"].value?.number,
      AgentName: aname,
      AgentEmail: aemail,
      PropertyID: pid,
      PropertyCode: pcode,
      PropertyType: ptype,
      PropertyArea: aarea,
      ClientURL: curl,
      Referrer: '',
      branchId: branchId,
      selectedSwiftCode: this.langId == 1 ? this.initialValue.currencyDetail.selectedCcySwiftCode : this.initialValue.currencyDetail.selectedCcySwiftCodeArb,
      currentLangId: this.langId.toString(),
      recapchaResponse: this.token,
      message: this.formpop.controls["comment"].value,
      subscription: true,
      loginId: '1',
      minPrice:minPrice,
      maxPrice:maxPrice,
      agentId:agentId,
      typeOfRequest:"1",
      saleOrRent:saleOrRent,
      subBranch:this.initialValue?.branchID.toString()
    }
  }


  onRecaptchaSuccess(token: string) {
    this.token = token;
  }

  submit(aname, aemail, pid, pcode, ptype, aarea, curl,price,agentId,saleOrRent,branchId) {
    this.submitted = true;
    this.disableSubmit = true;
    this.prepareEmail(aname, aemail, pid, pcode, ptype, aarea, curl,price,price,agentId,1,saleOrRent,branchId);
    if (this.formpop.invalid) {
      this.disableSubmit = false;
      return;
    }
    this._contactusService.SenemailSubmit(this.prepareEmail(aname, aemail, pid, pcode, ptype, aarea, curl,price,price,agentId,1,saleOrRent,branchId))
    .then((result) => {
      if (result) {
        this.disableSubmit = false;
        this.simpleResponseemil = UtilityService.clone(result);
        if (this.simpleResponseemil.messageBool) {
          this.notifyService.showSuccess("Mail send successfully !!", "")
          let model = $('#myModal') 
          model?.modal('hide');
          //$('#myModal').hide();
          //$('.modal-backdrop').remove('modal-backdrop');
        }
        else
          this.notifyService.showSuccess("some thing went wrong.", "");
        
      }
    });
  }


  addCallNumber(mobile, i, propid, agencyId, price,agentId,saleOrRent,branchId) {
    
    $('#ane_' + i).hide();
    $('#an_' + i).show();
    $('#sp_' + i).html(mobile);
    this._utilityService.sendClickInfo("3", propid, "",branchId,agencyId,this.countryCode,"",price,agentId,saleOrRent,this.initialValue?.branchID.toString());
  }
  addCallagentNumber(mobile, i, propid) {
    
    $('#ane_' + i).show();
    $('#an_' + i).hide();
    $('#sp_' + i).html('');
  }

  locationSearchBuilding(value, loc) {
    
    let propId = '';
    this.propertiesByAreaCount.forEach((currentValue, index) => {
      if (currentValue?.locationType == loc)
        propId += currentValue.id + ',';
    });   

    this.propertySearchParams = {
      branchId: this.initialValue.branchID,
      countryCode: this.countryCode,
      contactId: '0', currentLang: this.langId, cAID: 0,
      groupedBranches: this.initialValue.groupedBranchesId, page: '',
      sort: '', type: '', limit: '50',
      searchTerm: '',
      propertyType2: loc,
      propertyType3: '',
      propertyLocation: value,
      minArea: '',
      maxArea: '',
      minPrice: '',
      maxPrice: '', features: '',
      minBeds: '',
      maxBeds: '',
      minBaths: '',
      maxBaths: '',
      electricity: '',
      Furnicher: '', count: true,
      nonVacant: '1',
      agentID: '', parentID: '',
      myProperty: false,
      currencyDetails: {
        selectedCcyCode: '',
        selectedCcyName: '',
        selectedCcySwift: '',
        selectedCcySwiftCodeArb: '',
        selectedCcySwiftCode: '',
        selectedCcyDecimal: '',
      }
    }
    localStorage.setItem('Qsearch', JSON.stringify(this.propertySearchParams));
    this._route.navigate(['/properties/0/0']);
  }

}
