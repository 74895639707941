import { Component, Inject } from '@angular/core';
import { MatDialogRef, MAT_DIALOG_DATA } from '@angular/material/dialog';

@Component({
  selector: 'app-confirmation-dialog',
  templateUrl: './confirmation-dialog.component.html',
  styleUrls: ['./confirmation-dialog.component.css']
})
/** confirmation-dialog component*/
export class ConfirmationDialogComponent {
  /** confirmation-dialog ctor */
  title: string = '';
  message: string = '';
  constructor(
    public dialogRef: MatDialogRef<ConfirmationDialogComponent>,
    @Inject(MAT_DIALOG_DATA) data) {
    this.title = data.header;
    this.message = data.content;
  }

  onNoClick(): void {
    this.dialogRef.close();
  }

}
