import { Component, OnInit } from '@angular/core';
import { Router } from '@angular/router';
import { ConfigurationService } from 'src/app/shared/configuration.service';
import { isNullOrUndefined } from 'util';

@Component({
  selector: 'app-main',
  templateUrl: './main.component.html',
  styleUrls: ['./main.component.css']
})
export class MainComponent implements OnInit {

  isDefaultComponent:boolean = false;

  hostName:string = '';

  constructor(private _configurationService: ConfigurationService) { }

  ngOnInit(): void {
    
    
    this.isDefaultComponent = this._configurationService.getIsDefaultFoorter();

    // this._configurationService.host.subscribe(resp=>{
    // if(resp == "aqaraats.com"){
    //   this.isDefaultComponent = true
    // }else{
    //   this.isDefaultComponent = false
    // }
    // })

    // if(!isNullOrUndefined (localStorage.getItem('hostname')) && this.hostName == ''){
    //   this.hostName = localStorage.getItem('hostname');
    //   if(this.hostName == "aqaraats.com"){
    //     this.isDefaultComponent = true
    //   }else{
    //     this.isDefaultComponent = false
    //   }

    // }

  }

}
