import { Component, OnInit } from '@angular/core';
import { ActivatedRoute } from '@angular/router';
import { CheckoutSessionModelResposne } from '../../models/Payment';
import { PaymentReceipt, PaymentReceiptResponse } from '../../models/paymentReciept';
import { DahboardService } from '../../services/dashboard.service';
import { UtilityService } from '../../shared/utility.service';

@Component({
    selector: 'app-creditpgreturn',
    templateUrl: './creditpgreturn.component.html',
    styleUrls: ['./creditpgreturn.component.css']
})
/** creditpgreturn component*/
export class CreditpgreturnComponent implements OnInit {
  checkoutSessionModelResposne: CheckoutSessionModelResposne;
  paymentReceiptResponse: PaymentReceiptResponse;
  resulIndicater: string = '';
  paymentReceipt: PaymentReceipt;
  isPayment: boolean = false;
    /** creditpgreturn ctor */
  constructor(private route: ActivatedRoute, private _dahboardService: DahboardService) {
    this.route.queryParams
      .subscribe(params => {
        this.resulIndicater = params.resultIndicator;
       
      });    
  }

  ngOnInit() {
    localStorage.removeItem('pgredirct');
    this.checkoutSessionModelResposne = JSON.parse(localStorage.getItem('checkoutSessionModelResposne'));
    this.PgReturn();
  }

  PgReturn() {
    this._dahboardService.PgReturn_Get(this.resulIndicater, this.checkoutSessionModelResposne.successIndicator, this.checkoutSessionModelResposne.pgid, this.checkoutSessionModelResposne.pgtid, this.checkoutSessionModelResposne.token).then((result) => {
      if (result) {
        this.paymentReceiptResponse = UtilityService.clone(result);
        this.paymentReceipt = this.paymentReceiptResponse.paymentReceipt;
        this.isPayment = true;
        //this.paymentDetails = this.paymentResponseAQ.payment;
        //localStorage.setItem('checkoutSessionModelResposne', JSON.stringify(this.checkoutSessionModelResposne));
        //$.post("https://afs.gateway.mastercard.com/checkout/hostedCheckout/" + this.checkoutSessionModelResposne.orderId + "/" + this.checkoutSessionModelResposne.successIndicator + "/" + this.checkoutSessionModelResposne.sessionID);

        //console.log('payment_responce', this.paymentReceiptResponse);
        
        //window.open(window.location.href, "_self");
      }
    });
  }
}
