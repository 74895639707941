import { Injectable } from '@angular/core'
import { BehaviorSubject, Observable, throwError } from 'rxjs';
import { MatDialog } from '@angular/material/dialog';
import { ConfirmationDialogComponent } from '../UI/shared/confirmation-dialog/confirmation-dialog.component';
import { AlertDialogComponent } from '../UI/shared/alert-dialog/alert-dialog.component';
import { HttpClient, HttpErrorResponse } from '@angular/common/http';
import { Router } from '@angular/router';

import * as moment from 'moment';
import { AppConfig } from '../models/appConfig.model';
import { AuthorizationResponse } from '../models/login';
import { InitialValue, InitialValueResponse } from '../models/initialValue';
import { TrackClickInfo } from '../models/trackClickInfo';
import { DefaultService } from '../services/default.service';
import { ConfigurationService } from './configuration.service';


@Injectable({ providedIn: 'root' })
export class UtilityService {

  trackClickInfo: TrackClickInfo;
  trackClickInf: TrackClickInfo
  initialValue: InitialValue;

  constructor(
    private _dialog: MatDialog,
    private _defaultService: DefaultService,
    private _router: Router, 
    private http: HttpClient
    ) {
  }

  public static authorizationResponse: AuthorizationResponse;
  public static initialValueResponse: InitialValueResponse;

  public Get_InitialValueResponse(): InitialValueResponse {
    return UtilityService.initialValueResponse;
  }

  public Set_InitialValueResponse(res: InitialValueResponse): InitialValueResponse {
    return UtilityService.initialValueResponse = res;
  }

  isUserLogin: BehaviorSubject<boolean> = new BehaviorSubject(false);
  UserName: BehaviorSubject<string> = new BehaviorSubject("");
  public Get_AuthorizationResponse(): AuthorizationResponse {
    return UtilityService.authorizationResponse;
  }

  public Set_AuthorizationResponse(res: AuthorizationResponse): AuthorizationResponse {
    return UtilityService.authorizationResponse = res;
  }

  public static readonly StartDateFormat: string = "DD-MMM-YYYY 00.00.00";
  public static readonly EndDateFormat: string = "DD-MMM-YYYY 23.59.59";

  private static _appConfig: AppConfig = { homeURL: '', portalURL: '' };

  public static get AppConfig(): AppConfig {
    return this._appConfig
  }

  public static set AppConfig(value: AppConfig) {
    this._appConfig = value;
  }

  // Create a deep copy of object 
  public static clone<T>(a: T): T {
    return JSON.parse(JSON.stringify(a));
  }

  public static validateDates(sDate: moment.Moment, eDate: moment.Moment): string {
    let sRetVal: string = '';

    if (sDate && eDate) {
      if (sDate > eDate) {
        sRetVal = 'greater';
      }
    } else {
      sRetVal = 'empty';
    }

    return sRetVal;
  }



  async fetchIpAddress(typeId, propId, ShareId, branchId, agencyID = "", countryCode, socialMediaId, price,agentId,saleOrRent,subBranch): Promise<any> {
    await this.http.get<any>('https://api.ipify.org/?format=json').subscribe(res=>{
      
      if(res){
        let trc: TrackClickInfo = {
          typeID : typeId.toString(),
          propertyID: propId.toString(),
          cmPhone: ShareId.toString(),
          referrer : window.location.href,
          branchID : branchId.toString(),
          browser : "",
          clientURL : window.location.href,
          cmEmail : "",
          cmName : "",
          ipAddress : res?.ip,
          machineName : "",
          message : "",
          name : "",
          platform : "",
          userAgent : "",
          userHostAddress : "",
          userHostName : "",
          agentBranch: agencyID,
          countryCode:countryCode,
          socialMediaId:socialMediaId,
          propertyPrice:price,
          saleOrRent:saleOrRent,
          agentId:agentId,
          subBranch:subBranch
        }
    
    
        this._defaultService.SendClick(trc).then((result) => {
          if (result) {
          }
        });
      }else{
        let trc: TrackClickInfo = {
          typeID : typeId.toString(),
          propertyID: propId.toString(),
          cmPhone: ShareId.toString(),
          referrer : window.location.href,
          branchID : branchId.toString(),
          browser : "",
          clientURL : window.location.href,
          cmEmail : "",
          cmName : "",
          ipAddress : "",
          machineName : "",
          message : "",
          name : "",
          platform : "",
          userAgent : "",
          userHostAddress : "",
          userHostName : "",
          agentBranch: agencyID,
          countryCode:countryCode,
          socialMediaId:socialMediaId,
          propertyPrice:price,
          saleOrRent:saleOrRent,
          agentId:agentId,
          subBranch:subBranch
        }
    
    
        this._defaultService.SendClick(trc).then((result) => {
          if (result) {
          }
        });
      }
    });
  }
  
  


  public async sendClickInfo(typeId, propId, ShareId, branchId, agencyID = "", countryCode,socialMediaId="", price="",agentId="",saleOrRent="",subBranch="") {
    

    this.fetchIpAddress(typeId, propId, ShareId, branchId, agencyID, countryCode, socialMediaId, price,agentId,saleOrRent,subBranch);

  }

  public handleErrorResponse(err: HttpErrorResponse) {
    if (err.status == 401) {
      this._router.navigate(['/unauthorized']);
    } else if (err.status == 403) {
      this._router.navigate(['/access-denied']);
    } else {
      console.error(err);
    }
  }

  public handleError(statusCode: number, errorMsg: string) {
    if (statusCode == 401) {
      this._router.navigate(['/unauthorized']);
    } else if (statusCode == 403) {
      this._router.navigate(['/access-denied']);
    } else {
      console.error(statusCode, errorMsg);
    }
  }

  public showConfirmMsg(title: string, msg: string): Observable<any> {

    const dialogRef = this._dialog.open(ConfirmationDialogComponent, {
      width: 'auto',
      disableClose: true,
      data: {
        header: title,
        content: msg
      }
    });

    return dialogRef.afterClosed();
  }

  public showAlertMsg(title: string, msg: string): Observable<boolean> {

    const dialogRef = this._dialog.open(AlertDialogComponent, {
      width: 'auto',
      disableClose: true,
      data: {
        header: title,
        content: msg
      }
    });

    return dialogRef.afterClosed();
  }

  public getObjectPropertiesInstring(obj: any): string {
    let objectPropertyString = "";
    if (obj == null) {
      return "";
    }
    Object.keys(obj)
      .forEach(key => {
        if (obj.hasOwnProperty(key)) {
          if (Array.isArray(obj[key])) {
            if (obj[key] != null) {
              if (obj[key].length > 0) {
                obj[key].forEach(i => {
                  objectPropertyString = objectPropertyString + this.getObjectPropertiesInstring(i);
                }
                );
              }
              else {

                objectPropertyString = objectPropertyString + key + " - <br>";
              }
            }
          }
          else {
            if (typeof obj[key] == "object") {
              if (obj[key] != null) {
                objectPropertyString = objectPropertyString + this.getObjectPropertiesInstring(obj[key]);
              }
              else {
                objectPropertyString = objectPropertyString + key + " - <br>";
              }
            }
            else {
              objectPropertyString = objectPropertyString + key + " - " + obj[key] + "<br>";
            }
          }
        }
      })
    return objectPropertyString;
  }
   
 
}
