import { AfterViewInit, ChangeDetectorRef, ElementRef, HostListener, Input, OnChanges, OnDestroy, OnInit, SimpleChanges, ViewChild } from '@angular/core';
import { Component } from '@angular/core';
import { DomSanitizer, SafeHtml, disableDebugTools } from "@angular/platform-browser";
import { currencyDetails, InitialValue, InitialValueResponse } from '../../models/initialValue';
import { DbStats, Item, ItemPropByArea, Property, PropertyFiltersResponse, PropertyResponse, Stats, PropertyCity, PropertyType, PropertyFurnicher, PropertyElectricity, PropertyTransactionType, PropertyTypeSort, PropertySearchParams, QuickSummaryItem, PropertySearchResultsResponse, PropertySearchResults, PropertyFeature, PropertiesByTypeResponse, AgencyAndStats, AgencyModel, PropertiesListing } from '../../models/property';
import { DefaultService } from '../../services/default.service';
import { DefaultNewsModel, News } from '../../models/News';
import { Select2OptionData } from 'ng-select2';
import { Options } from 'select2';
import { UtilityService } from '../../shared/utility.service';
import { ActivatedRoute, Router, NavigationEnd } from '@angular/router';
import { PropertyService } from '../../services/property.service';
import * as jq from 'jquery';
import { FormBuilder, FormGroup, Validators } from '@angular/forms';
import { SearchService } from '../../shared/search.service';
import { isNullOrUndefined } from 'util';
import { DataService } from '../../services/data.service';
import { LazyLoadScriptService } from '../../services/lazy-load-script.service';
import { map, filter, take, switchMap } from 'rxjs/operators';
import { CompareService } from '../../services/compare.service';
import { SimpleResponse } from '../../models/genericResponse.model';
import { ContactusService } from '../../services/contactus.service';
import { Email } from '../../models/EmailContactUs';
import { NotificationService } from '../../shared/notification.service';
import { NgbModal } from '@ng-bootstrap/ng-bootstrap';
import { Compare } from '../../models/Company';
import { escapeLeadingUnderscores } from 'typescript/lib/tsserverlibrary';
import { Observable, of } from 'rxjs';
import { SearchCountryField, PhoneNumberFormat, CountryISO } from 'ngx-intl-tel-input';

//import { OwlOptions } from 'ngx-owl-carousel-o';
//import { LazyLoadImageModule, LAZYLOAD_IMAGE_HOOKS, ScrollHooks } from 'ng-lazyload-image';

import { DialogLayoutDisplay, ConfirmBoxInitializer} from '@costlydeveloper/ngx-awesome-popup';
//import { ImageService } from '../../services/image1.servicee';
import { Image1Service } from '../../services/image1.service';
import { ConfigDetails, ThemeColorModel, ThemeColorResponseModel } from '../../models/appConfig.model';
import { AppconfigService } from '../../shared/appconfig.service';
import { HttpErrorResponse } from '@angular/common/http';
import { RedirectionService } from 'src/app/services/redirection.service';
import { ConfigurationService } from 'src/app/shared/configuration.service';
import { stat } from 'fs';
import { FavoriteService } from 'src/app/services/favorite.service';
import { FooterDataModel } from 'src/app/models/footerdataModel';
import { ApiResponseModel } from 'src/app/models/ApiResponseModel';
declare var $: any;
@Component({
  selector: 'app-default',
  templateUrl: './default.component.html',
  //providers: [{ provide: LAZYLOAD_IMAGE_HOOKS, useClass: ScrollHooks }],
  styleUrls: ['./default.component.css']
})
/** default component*/
export class DefaultComponent implements OnInit, AfterViewInit, OnChanges, OnDestroy {
  @ViewChild('SliderImageThumbnail', { static: true }) SliderImageThumbnail: ElementRef;

  @Input() duration: number;
  @Input() digit: number;
  @Input() steps: number;
  @ViewChild("animatedDigit") animatedDigit: ElementRef;
  @ViewChild("animatedDigitSale") animatedDigitSale: ElementRef;
  @ViewChild("animatedDigitCutomer") animatedDigitCutomer: ElementRef;
  @ViewChild("animatedDigitAgent") animatedDigitAgent: ElementRef;
  public options: Options;
  public options1: Options;
  public optionsPf: Options;
  public optionsPE: Options;
  public optionsCity: Options;
  isShowDiv: boolean = false;
  isShowDivSrc: boolean = false;
  public optionsct: Options;
  public optionsct1: Options;
  public optionspTTs: Options;
  public optionstrans: Options;
  public optionstransmx: Options;
  public optiontypes: Options;
  SearchCountryField = SearchCountryField;
  CountryISO = CountryISO;
  preferredCountries: CountryISO[] = [CountryISO.Bahrain];
  public price: Select2OptionData[] = [];
  public pricemx: Select2OptionData[] = [];
  loading: boolean = false;
  submitted = false;
  initialValueResponse: InitialValueResponse;

  initialValue: InitialValue;
  currencyDetail: currencyDetails;
  propertyResponce: PropertyResponse;
  propertyByTypeResponce: PropertiesByTypeResponse;
  sliderProperties: Property[];
  latestProperties: Property[];
  latestProperties2: Property[];
  types: PropertyTransactionType[];
  latestPropertiespop: Property[];
  buildingProperties: Property[];
  compoundProperties: Property[];
  officeSpaceProperties: Property[];
  resortsSpaceProperties: Property[];
  isShowDivFilter: boolean = false;
  isShowDivCompound: boolean = false;
  isShowDivOffice: boolean = false;
  isShowDivResort: boolean = false;
  quikSummary: QuickSummaryItem[];
  pTTs: PropertyTransactionType[];
  agenciesList: AgencyModel[];
  agency: Array<Select2OptionData> = [];
  locationlist: ItemPropByArea[];
  agencyNews: News[];
  propertyNews: News[];
  buildingDetails: any[] = [];
  propertyFiltersResponse: PropertyFiltersResponse;
  propertyTypesSort: PropertyTypeSort[];
  propertyTypes2: PropertyType[];
  availableTypesOfType1: PropertyType[];
  propertyCity: PropertyCity[];
  propertyFurnicher: PropertyFurnicher[];
  propertyElectricity: PropertyElectricity[];
  availableTransactions: PropertyTransactionType[];
  simpleResponse: SimpleResponse;
  imagePath: any;
  userid: string;
  stats: Stats[];
  dbStats: DbStats;
  buildingPropLoc: [] = [];
  propertySearchResultsResponse: PropertySearchResultsResponse;
  propertySearchResults: PropertySearchResults[];
  propertySearchitem: PropertySearchResults[];
  propertySearchResultsResponsefav: PropertySearchResultsResponse;
  propertySearchResultsfav: PropertySearchResults[];
  propertySearchitemfav: PropertySearchResults[];
  propertyFeature: PropertyFeature[];
  featureValue: string[];
  //displayStats: DbStats[]=[];
  rentcount: number;
  saleCount: number;
  customerCount: number;
  agentsCount: number;
  private configDetails: ConfigDetails;
  isstats: boolean = true;  
  private _value: string = '14';
  private _valuerent: string = '13';
  private _valueLocation: string;
  private _valueAgency: string = "";
  valueAgency:string;
  private _valueBed: string;
  private _TypesSortVal: string;
  private _Types2Val: string;
  private _CityVal: any;
  private _CityFinalVal: string = '';
  public _typesValue: string;
  public _agencyValue: string;
  private _minTransactionsVal: string;
  private _maxTransactionsVal: string;
  private _ElectricityVal: string;
  private _FurnicherVal: string;
  phoneNumber: string = '';
  //private _valuerent: string='13';
  public currencyCode: string = 'BHD';
  public countryCode = '973';
  form: FormGroup;
  formpop: FormGroup;
  formrent: FormGroup;
  formSearch: FormGroup;
  propertySearchParams: PropertySearchParams;
  email: Email;
  orignalVal: Property[];
  isShow: boolean = false;
  salerentValue: string = '';
  isSlice: string = '....';
  langId: number = 1;
  token: string;
  simpleResponseemil: SimpleResponse
  isLatestPropertieLoaded:boolean = false
  isAllDataLoaded:boolean = false
  //public options1: Select2OptionData;
  "bedslist": string[] = [
    "1",
    "2",
    "3",
    "4",
    "5+"
  ];
  isLocationShowDiv = false;
  isFavorite: boolean = false;
  bannerText: string = 'BAHRAIN';
  favoriteval: Compare[];
  Compareval: Compare[];
  url: any;
  propUrl: string = '/propertydetail?id='
  isLoLoding: boolean = true;
  isDetailsIcon: boolean = true;
  toScrrol: number = 1;
  itemPropByArea: ItemPropByArea[];
  itemPropByAreaBuilding: ItemPropByArea[];
  itemPropByAreaCompound: ItemPropByArea[] = [];
  itemPropByAreaOffice: ItemPropByArea[] = [];
  itemPropByAreaResort: ItemPropByArea[] = [];
  areaLocationValue:string = "";
  settingstop: any;
  isViewAll: boolean = true;
  placetext: string = '';
  bldId: string = '';
  hostName: string = '';
  fvalue: string = '';
  tabType: string = '';
  vacantType: string = '';
  limittype: string = '4000';
  isHighlited:boolean = false;
  isLoadFotter: boolean = true;
  footerData:FooterDataModel;
  dataLoaded : boolean = false;
  disableSubmit:boolean = false;
  /** default ctor */
  constructor(private _route1: Router, private activatedRoute: ActivatedRoute, private router: Router, private formBuilder: FormBuilder, private _route: Router, private _defaultService: DefaultService,
    private sanitizer: DomSanitizer, private _propertyService: PropertyService, private _SearchService: SearchService,
    private _dataService: DataService, private lazyLoadService: LazyLoadScriptService, private _compare: CompareService,
    private _contactusService: ContactusService, private notifyService: NotificationService, private modalService: NgbModal,
    private _imageService: Image1Service, private _appconfigService: AppconfigService, private _utilityService:UtilityService,
    private _redirectionService: RedirectionService, private _configurationService:ConfigurationService,private _favoriteService :FavoriteService, private cdref: ChangeDetectorRef  ) {
      
      
    localStorage.removeItem("pgredirct");

    this.url = window.location.href + 'propertydetail?id=';
    
    
  }

  @HostListener('window:beforeunload', ['$event'])
  public clearLocalStorage(event: any): void {
    localStorage.removeItem("pgredirct");
    //localStorage.clear();    
  }


  
  addItem(ev) {
    this.isLoadFotter = false;
    setTimeout( ()=> {      
      this.isLoadFotter = true;
      
    }, 100);
  }
  animateCount() {
    if (!this.duration) {
      this.duration = 3000;
    }

    if (typeof this.rentcount === "number") {
      this.rentcountcounterFunc(this.rentcount+1, this.duration, this.animatedDigit);
      this.salecountcounterFunc(this.saleCount+1, this.duration, this.animatedDigitSale);
      this.customerCountcounterFunc(this.customerCount+1, this.duration, this.animatedDigitCutomer);
      this.agentsCountcounterFunc(this.agentsCount+1, this.duration, this.animatedDigitAgent);
    }
  }
  get f() {
    return this.form.controls;
  }
  get f1() {
    return this.formrent.controls;
  }
  get f2() {
    return this.formpop.controls;
  }

  advanceMethod: boolean = true;

  rentcountcounterFunc(endValue, durationMs, element) {
    if (!this.steps) {
      this.steps = 12;
    }

    const stepCount = Math.abs(durationMs / this.steps);
    const valueIncrement = (endValue - 0) / stepCount;
    const sinValueIncrement = Math.PI / stepCount;

    let currentValue = 0;
    let currentSinValue = 0;

    function step() {
      currentSinValue += sinValueIncrement;
      currentValue += valueIncrement * Math.sin(currentSinValue) ** 2 * 2;

      element.nativeElement.textContent = Math.abs(Math.floor(currentValue));

      if (currentSinValue < Math.PI) {
        window.requestAnimationFrame(step);
      }
    }

    step();
  }

  salecountcounterFunc(endValue, durationMs, element) {
    if (!this.steps) {
      this.steps = 12;
    }

    const stepCount = Math.abs(durationMs / this.steps);
    const valueIncrement = (endValue - 0) / stepCount;
    const sinValueIncrement = Math.PI / stepCount;

    let currentValue = 0;
    let currentSinValue = 0;

    function step() {
      currentSinValue += sinValueIncrement;
      currentValue += valueIncrement * Math.sin(currentSinValue) ** 2 * 2;

      element.nativeElement.textContent = Math.abs(Math.floor(currentValue));

      if (currentSinValue < Math.PI) {
        window.requestAnimationFrame(step);
      }
    }

    step();
  }

  customerCountcounterFunc(endValue, durationMs, element) {
    if (!this.steps) {
      this.steps = 12;
    }

    const stepCount = Math.abs(durationMs / this.steps);
    const valueIncrement = (endValue - 0) / stepCount;
    const sinValueIncrement = Math.PI / stepCount;

    let currentValue = 0;
    let currentSinValue = 0;

    function step() {
      currentSinValue += sinValueIncrement;
      currentValue += valueIncrement * Math.sin(currentSinValue) ** 2 * 2;

      element.nativeElement.textContent = Math.abs(Math.floor(currentValue));

      if (currentSinValue < Math.PI) {
        window.requestAnimationFrame(step);
      }
    }

    step();
  }

  agentsCountcounterFunc(endValue, durationMs, element) {
    if (!this.steps) {
      this.steps = 12;
    }

    const stepCount = Math.abs(durationMs / this.steps);
    const valueIncrement = (endValue - 0) / stepCount;
    const sinValueIncrement = Math.PI / stepCount;

    let currentValue = 0;
    let currentSinValue = 0;

    function step() {
      currentSinValue += sinValueIncrement;
      currentValue += valueIncrement * Math.sin(currentSinValue) ** 2 * 2;

      element.nativeElement.textContent = Math.abs(Math.floor(currentValue));

      if (currentSinValue < Math.PI) {
        window.requestAnimationFrame(step);
      }
    }

    step();
  }

  ngAfterViewInit() {

    if(this.SliderImageThumbnail){

    }
  }

  ngOnDestroy(): void {
    $('#myModal').modal('hide');
    $('.select2-container--open').removeClass('select2-container--open');
  }

  ngOnChanges(changes: SimpleChanges) {
    if (changes["rentcount"]) {
      this.animateCount();
    }
    if (changes["saleCount"]) {
      this.animateCount();
    }
    if (changes["customerCount"]) {
      this.animateCount();
    }
    if (changes["agentsCount"]) {
      this.animateCount();
    }
  }

  advanceBtn() {
    this.advanceMethod = !this.advanceMethod;
    let winTop: number = 0;
    //if ($(window).height() > 900)
    //winHeight =
    //alert($(window).height());

    if (!this.advanceMethod) {
      $('html, body').animate({
        scrollTop: $(".search-bar-panel").offset().top - 150
      }, 20);
    } else {
      $("html, body").animate({ scrollTop: 0 }, 20);
    }

  }
  
  settings = {
    slidesToShow: 3,
    slidesToScroll: 1,
    arrows: true,
    prevArrow: '.listings .slider-prev',
    nextArrow: '.listings .slider-next',
    dots: false,
    mouseDrag: true,
    rtl: true,
    responsive: [
      {
        breakpoint: 991,
        settings: {
          slidesToShow: 2
        }
      },
      {
        breakpoint: 768,
        settings: {
          slidesToShow: 1
        }
      }
    ]
  }

  settingsDefault = {
    "slidesToShow": 1,
    "slidesToScroll": 1,
    "dots": false,
    "infinite": false,
    "autoplay": false,
  }
  
  settingsDefaultbld = {
    slidesToShow: 1,
    slidesToScroll: 1,
    arrows: false,
    fade: false,
    asNavFor: '.listing-thumbnail-slider-nav',
    autoplay: false,
    infinite: false,
    //opacity: 10,
    //autoplaySpeed: 20000,
   /* lazyLoad: 'ondemand'*/
  }

  settingsbld = {
    slidesToShow: 1,
    slidesToScroll: 1,
    arrows: false,
    fade: false,
    autoplay: false,
    asNavFor: '.listing-thumbnail-slider-nav_bld',
    responsive: [
      {
        breakpoint: 768,
        settings: {
          slidesToShow: 2,
        }
      },
    ]
  }

  settingsThumbbld = {
    slidesToShow: 4,
    slidesToScroll: 1,
    asNavFor: '.listing-thumbnail-slider-main_bld',
    dots: false,
    centerMode: false,
    focusOnSelect: true,
    autoplay: true,
    responsive: [
      {
        breakpoint: 768,
        settings: {
          slidesToShow: 4,
        }
      },
    ]
  }

  settingscrue = {
    slidesToShow: 1,
    slidesToScroll: 1,
    arrows: false,
    fade: false,
    infinite: true,
    centerMode: false,   
    asNavFor: '.listing-thumbnail-slider-nav',       
    autoplaySpeed: 2000
  }

  settingsThumb = {
    slidesToShow: 4,
    slidesToScroll: 1,
    infinite: false,
    asNavFor: '.listing-thumbnail-slider-main',
    dots: false,
    arrows: true,
    centerMode: false,   
    focusOnSelect: true,
    autoplay: true,   
    autoplaySpeed: 2000,
    responsive: [
      {
        breakpoint:1024,
        settings: {
          slidesToShow: 4,
        }
      },
      {
        breakpoint: 768,
        settings: {
          slidesToShow:4,
        }
      },
      /*{
        breakpoint:400,
        settings: {
          slidesToShow:2,
        }
      }*/
    ]
  } 


  GetFooterData(){
    this._defaultService.GetFooterData(this.countryCode).then((result)=>{
      
      if(result && result?.status == 200){
        this.dataLoaded = true;
        this.footerData = {
          latestClickAreas:result?.latestClickAreas,
          latestSearchAreas:result?.latestSearchAreas,
          popularClickAreas:result?.popularClickAreas,
          popularSearchAreas:result?.popularSearchAreas,
          status:result?.status,
          message:result?.message
        }
      }
    })
  }


  ngOnInit() {
    
    this.initialValue = this._configurationService.getInitialValues();

    this.countryCode = this._configurationService.getSeletedCountryCode();
    this.currencyCode = this._configurationService.getSeletedCurrencyCode();
    this.langId =  this._configurationService.getLanguageValue();

    this.valueAgency = "";
    this.form = this.formBuilder.group({
      bedSale: ['']
    });
    this.formrent = this.formBuilder.group({
      bedRent: ['']
    });
    this.formSearch = this.formBuilder.group({
      office: [''],
      MinArea: [''],
      MaxArea: [''],
      MinBedrooms: [''],
      MaxBedrooms: [''],
      MinBathrooms: [''],
      MaxBathrooms: [''],
      nonVacant: [''],
    });

    this.formpop = this.formBuilder.group({
      fname: ['', [Validators.required]],
      phone: ['', [Validators.required]],
      email: ['', [Validators.required, Validators.email]],
      comment: ['', [Validators.required]]
    })

    this.activatedRoute.fragment.subscribe((fragment: string) => {
    })
    this.featureValue = [];
    var width = $(window).width();
    var height = $(window).height();
    if ((width < 1000) && (height < 700)) {
      //do something
      this.nextPre(1);
    } else {
      this.nextPre(2);
    }
    this.nextPre(6);
    this.getPrice();
    this.getPricemax();



    this.bannerText = this.langId == 1 ?  `${this._configurationService.getSeletedCountryNaeme().toLocaleUpperCase()}'S` : this._configurationService.getSeletedCountryNaeme();

    this.GetAllProperty();
    this.GetDefaultNew(this.langId,this.countryCode, this.initialValue?.branchID);

    this.GetStats(this.initialValue.cmsCompID, this.initialValue.companyID.toString(), this.langId.toString(), this.initialValue.branchID, this.countryCode, this.userid, this.initialValue.groupedBranchesId);
        // this.GetDefaultProperty1(this.initialValue.cmsCompID, this.initialValue.companyID.toString(), this.langId.toString(), this.initialValue.branchID, this.countryCode, this.userid, this.initialValue.groupedBranchesId);
        this.GetDefaultProperty2(this.initialValue.cmsCompID, this.initialValue.companyID.toString(), this.langId.toString(), this.initialValue.branchID, this.countryCode, this.userid, this.initialValue.groupedBranchesId);

        this._favoriteService.propertySearchResultsfavSubject.subscribe(resp=>{
          
          if(resp != null){
            this.propertySearchitemfav = resp
            //this.ClearCopare(propertyId, type)
            this.propertySearchResultsfav = this.propertySearchitemfav;
            if(this.propertySearchResultsfav?.length > 0 && this.sliderProperties?.length > 0 && this.latestProperties?.length > 0){
              // this.loadFavComp(0,1)
              // this.highLightFav(this.propertySearchResultsfav);
            }
          }

        })

        this._favoriteService.propertySearchResultsCompareSubject.subscribe(resp=>{
          
          if(resp != null){
            this.propertySearchitem = resp;
            //let ps = this.propertySearchitem.filter(c=>c.);
            //this.ClearCopare(propertyId, type)
            this.propertySearchResults = this.propertySearchitem;
            if(this.propertySearchResults?.length > 0 && this.sliderProperties?.length > 0 && this.latestProperties?.length > 0){
              // this.loadFavComp(0,2)
              // this.highLightFav(this.propertySearchResults);
            }
          }
        })

        if(isNullOrUndefined(this.propertySearchitemfav)){
          this.propertySearchitemfav = this._favoriteService.getFavoritesList();
        }

        if(isNullOrUndefined(this.propertySearchResults)){
          this.propertySearchResults =  this._favoriteService.getCompareList()
        }
        // this.GetFavStatus("1", "100", true, "1", this.langId.toString(),0);
        // this.GetDefauilData(this.initialValue.cmsCompID, this.initialValue.companyID.toString(), this.langId.toString(), this.initialValue.branchID, this.countryCode, this.userid, this.initialValue.groupedBranchesId);
        this.GetSliderProperty(this.initialValue.cmsCompID, this.initialValue.companyID.toString(), this.langId.toString(), this.initialValue.branchID, this.countryCode, this.userid, this.initialValue.groupedBranchesId);
        
        this.GetSerchFilterData(this.langId.toString(), this.initialValue.branchID, this.initialValue.groupedBranchesId, this.countryCode);
        this.GetFooterData()


    this._utilityService.sendClickInfo("5", "", "", this.initialValue?.branchID,"",this.countryCode);

  }  
  
  nextPre(scroolno) {
    this.toScrrol = 1;
    this.settingstop = {
      slidesToShow:7,
      slidesToScroll: scroolno,
      mouseDrag: true,
      arrows: true,
      prevArrow: '<a class="counter-left-arrow"><i class="fas fa-arrow-left" (click)="nextPre(1)"></i></a>',
      nextArrow: '<a class="counter-right-arrow"><i class="fas fa-arrow-right" (click)="nextPre(1)"></i></a>',
      dots: false,
      autoPlay: true,
      responsive: [
        {
          breakpoint: 991,
          settings: {
            slidesToShow: 4
          }
        },
        {
          breakpoint: 768,
          settings: {
            slidesToShow: 1
          }
        },
      ]
    }
  }

  toggleDisplayDiv() {
    this.isLocationShowDiv = false;
    this.isShowDiv = !this.isShowDiv;
  }
  toggleLocationDiv() {
    this.isShowDiv = false;
    this.isLocationShowDiv = !this.isLocationShowDiv;
  }

  onRecaptchaSuccess(token: string) {
    this.token = token;
  }
  getPrice() {
    
    this._dataService.getpriceList(this.langId.toString()).subscribe((result) => {
      if (result) {
        
        this.price = UtilityService.clone(result);
        this.optionstrans = {
          width: '200',
          tags:true,
          // placeholder: this.langId ==1 ? "Select Min Price:" : "إختر السعر الأدنى",
          templateResult: this.PriceResult,
          templateSelection: this.PriceSelection
        };
      }
    })
  }

  getPricemax() {
    this._dataService.getpriceListmax(this.langId.toString()).subscribe((result) => {
      if (result) {
        this.pricemx = UtilityService.clone(result);
        this.optionstransmx = {
          width: '200',
          tags:true,
          templateResult: this.PriceResultmx,
          templateSelection: this.PriceSelectionmx
        };
      }
    })
  }

  GetPropertyLocation(branchId: number, countryCode: string, GroupedBrancheIds: string) {
    
    this._defaultService.propertiesByAreaFilter_Get(branchId, countryCode,this.langId.toString(), GroupedBrancheIds).then((result) => {
      
      if (result) {
        this.itemPropByArea = UtilityService.clone(result);
      }
    });
  }

  toggleLocationDivFilter() {
    this.isShowDivFilter = !this.isShowDivFilter;
  }

  toggleLocationCompound() {
    this.isShowDivCompound = !this.isShowDivCompound;
  }

  toggleLocationOffice() {
    this.isShowDivOffice = !this.isShowDivOffice;
  }

  toggleLocationResort() {
    this.isShowDivResort = !this.isShowDivResort;
  }

  locationSearchBuilding(value, loc) {
    let propId = '';
    this.buildingProperties.forEach((currentValue, index) => {
      if (currentValue.type2Id == loc)
        propId += currentValue.type2Id + ',';
    });   

    this.propertySearchParams = {
      branchId: this.initialValue.branchID,
      countryCode: this.countryCode,
      contactId: '0', currentLang: this.langId, cAID: 0,
      groupedBranches: this.initialValue.groupedBranchesId, page: '',
      sort: '', type: '', limit: '50',
      searchTerm: '',
      propertyType2: loc,
      propertyType3: '',
      propertyLocation: value,
      minArea: '',
      maxArea: '',
      minPrice: '',
      maxPrice: '', features: '',
      minBeds: '',
      maxBeds: '',
      minBaths: '',
      maxBaths: '',
      electricity: '',
      Furnicher: '', count: true,
      nonVacant: '1',
      agentID: '', parentID: '',
      myProperty: false,
      currencyDetails: {
        selectedCcyCode: '',
        selectedCcyName: '',
        selectedCcySwift: '',
        selectedCcySwiftCodeArb: '',
        selectedCcySwiftCode: '',
        selectedCcyDecimal: '',
      }
    }
    localStorage.setItem('Qsearch', JSON.stringify(this.propertySearchParams));
    this._route.navigate(['/properties/0/0']);
  }

  GetMinBedValue($event) {
    
    this._valueBed = $event.target.value;
  }

  GetDefaultProperty2(CmsCompID: number, compId: string, currenLang: string, branchId: number, countryCode: string, userId: string, GroupedBrancheIds: string) {

    this._defaultService.GetLatestProperties(CmsCompID, compId, currenLang, branchId, countryCode, userId, GroupedBrancheIds).then((result : ApiResponseModel<Array<Property>>)=>{
      
      if(result.StatusCode == 200){
        this.latestProperties = result?.Data
        console.log('latestProperties',this.latestProperties)
        for (var i = 0; i < this.latestProperties.length; i++) {
          // Cant get type2Id in other langage because propertyTypes2 is null as its value not ready yet
          //this.latestProperties[i].type2
          //console.log('this.latestProperties[i].type2Id',this.latestProperties[i].type2Id);
          //console.log('this.propertyTypes2',this.propertyTypes2);
          
          this.latestProperties[i].favorite = false;
          this.latestProperties[i].compare = false;
          if (isNullOrUndefined(this.latestProperties[i].mainIMG) || this.latestProperties[i].mainIMG == "" || this.latestProperties[i].mainIMG == '') {
            this.latestProperties[i].mainIMG = 'https://cdn.aqaraats.com/images/NA.jpg';
          }
        }
        var ltPropertyrent = this.latestProperties.filter(x => x.type.id == '13');
        if (ltPropertyrent.length == 0) {
          $('#ptAll').hide();
          $('#ptRent').hide();
        }
        var ltPropertySale = this.latestProperties.filter(x => x.type.id == '14');
        if (ltPropertySale.length == 0) {
          $('#ptAll').hide();
          $('#ptSale').hide();
        }
        if(ltPropertyrent.length == 0 || ltPropertySale.length == 0){
          $('#ptAll').hide();
          $('#ptSale').hide();
          $('#ptRent').hide();

        }
        this.isLoLoding = false;
        this.isAllDataLoaded = true;
        this.GetPropertyLocation(this.initialValue.branchID, this.countryCode, this.initialValue.groupedBranchesId);

      }
    })

    this._defaultService.GetPropertiesByType(CmsCompID, compId, currenLang, branchId, countryCode, userId, GroupedBrancheIds).then((result) => {
      if (result) {
        
        if(result?.StatusCode== 200){
          this.propertyByTypeResponce = result?.Data;
        }
      }
    });
  }

  GetDefaultNew(langId:number, countryCode:string,branchId: number){
    
    this._defaultService.GetDefaultNews(langId.toString(), countryCode,branchId.toString()).then((result:ApiResponseModel<DefaultNewsModel>)=>{
      if(result?.StatusCode == 200){
        this.agencyNews = result?.Data?.agencyNews;
        this.propertyNews = result?.Data?.propertyNews
      }
    })
  }


  GetFeatureId($event) {

    this.featureValue.push($event.target.value);
    this.fvalue = this.fvalue+','+$event.target.value
  }

  //get stats
  GetStats(CmsCompID: number, compId: string, currenLang: string, branchId: number, countryCode: string, userId: string, GroupedBrancheIds: string) {
    
    this._defaultService.Stats_GetAsync(CmsCompID, compId, currenLang, branchId, countryCode, isNullOrUndefined(userId) ? '0' : userId, GroupedBrancheIds).then((result:ApiResponseModel<AgencyAndStats>) => {
      if (result.StatusCode == 200) {
        
        this.dbStats = result?.Data?.dbStats;
        this.agenciesList = result?.Data?.agencies
        this.rentcount = this.dbStats.rent;
        this.saleCount = this.dbStats.sale;
        this.customerCount = (this.dbStats.rent) + (this.dbStats.sale);
        this.agentsCount = this.dbStats.agent;
        this.propertyCity = result?.Data?.filteredAreas;

        if(!this.propertyCity.some(city => city.id === "0")){
          if (this.langId == 1){
            this.propertyCity.unshift({ id: "0", text: "All Areas" });
          }
          else{
            this.propertyCity.unshift({ id: "0", text: "كل المناطق" });
          }
        }
        
        this.locationlist = result?.Data?.filteredAreas;
        this.animateCount();

        console.log('agency',this.agency);
        
        if(this.agenciesList.length > 0){
          this.agenciesList.forEach(x=>{
            var data = {
              id:x.id,
              text:x.name,
              additional:x.image
            }
            this.agency.push(data);
          })
          this.options = {
            width: '300',
            templateResult: this.templateResultAgency,
          };
          
        }

      }
    });
  }
  GetSliderProperty(CmsCompID: number, compId: string, currenLang: string, branchId: number, countryCode: string, userId: string, GroupedBrancheIds: string) {
    this._defaultService.Slider_GetAsync(CmsCompID, compId, currenLang, branchId, countryCode, userId, GroupedBrancheIds).then((result) => {
      if (result) {
        //this.loadingSubscription.unsubscribe();
        this.propertyResponce = UtilityService.clone(result);       
        this.sliderProperties = this.propertyResponce.sliderProperties;
        for (var i = 0; i < this.sliderProperties.length; i++) {
          if (isNullOrUndefined(this.sliderProperties[i].mainIMG) || this.sliderProperties[i].mainIMG == "" || this.sliderProperties[i].mainIMG == '') {
            this.sliderProperties[i].mainIMG = 'https://cdn.aqaraats.com/images/NA.jpg';
          }
        }

        if(this.sliderProperties?.length > 0){
          if(this.propertySearchResultsfav?.length > 0){
            // this.loadFavComp(0,1)
            // this.highLightFav(this.propertySearchResultsfav)
          }
          if(this.propertySearchResults?.length > 0){
            // this.loadFavComp(0,2)
            // this.highLightFav(this.propertySearchResults)
          }
        }
      }
    });
  }

 

  funcType(type) {
    $("#tab1").prop("checked", true);
    if (isNullOrUndefined(this.orignalVal))
      this.orignalVal = this.latestProperties;
    else
      this.latestProperties = this.orignalVal;
    if (!isNullOrUndefined(type) && type != '0') {
      var filterProperties = this.latestProperties.filter(x => x.type.id == type);
      this.latestProperties = filterProperties;
    }

    $('#tab1').addClass('active');
    $('#tab2').removeClass('active');
    $('#tab3').removeClass('active');
    $('#tab4').removeClass('active');
    $('#tab5').removeClass('active');

    $('#Properties').addClass('active');
    $('#Buildings').removeClass('active');
    $('#Compounds').removeClass('active');
    $('#Office_Space').removeClass('active');
    $('#Resorts').removeClass('active');
  }

  GetSerchFilterData(currenLang: string, branchId: number, GroupedBrancheIds: string, countryCode: string) {
    
    this._propertyService.GetSerch_FilterAsync(Number(currenLang), branchId, GroupedBrancheIds, Number(countryCode)).then((result) => {
      if (result) {
        this.propertyFiltersResponse = UtilityService.clone(result);
        this.propertyTypesSort = this.propertyFiltersResponse.propertyTypesSort;
        if(this.langId==1)
          this.propertyTypesSort.unshift({ id: "0", text: "All Categories", sort: "" });
        else
          this.propertyTypesSort.unshift({ id: "0", text: "كل الفئات", sort: "" });

        this.types = this.propertyFiltersResponse.pTTs;
        
        this.salerentValue = this.propertyFiltersResponse.pTTs[0].id;

        this.cdref.markForCheck();
        
        if (this.langId == 1)
          this.types.unshift({ id: "0", text: "All Types" });
        else
          this.types.unshift({ id: "0", text: "كل الأنواع"});
        
          this.optiontypes = {
          allowClear: false
        };
        this.optionsct = {
          allowClear: false
        };

        this.propertyTypes2 = this.propertyFiltersResponse.propertyTypes2;
        if (this.langId == 1)
          this.propertyTypes2.unshift({ id: "0", text: "All Categories" });
        else
          this.propertyTypes2.unshift({ id: "0", text: "كل الفئات" });

        this.optionsct1 = {
          allowClear: false
        };
        // this.propertyCity = this.propertyFiltersResponse.propertyCitys;

        this.availableTypesOfType1 = this.propertyFiltersResponse.availableTypesOfType1;
        this.propertyElectricity = this.propertyFiltersResponse.electricityOptions;
        if (this.langId == 1)
          this.propertyElectricity.unshift({ id: "0", text: "Electricity Incl/excl" });
        else
          this.propertyElectricity.unshift({ id: "0", text: "خدمات الكهرباء" });
        this.optionsPE = {
          allowClear: false
        };
        this.propertyFurnicher = this.propertyFiltersResponse.furnicherOptions;
        if (this.langId == 1)
          this.propertyFurnicher.unshift({ id: "0", text: "Furniture Options" });
        else
          this.propertyFurnicher.unshift({ id: "0", text: "خيارات التأثيث" });
        this.optionsPf = {
          allowClear: false,
          templateResult: this.FurnicherResult,
          templateSelection: this.FurnicherSelection
        };
        this.pTTs = this.propertyFiltersResponse.pTTs;
        this.optionspTTs = {
          allowClear: false
        };
        this.availableTransactions = this.propertyFiltersResponse.availableTransactions;
        this.propertyFeature = this.propertyFiltersResponse.propertyFeatures;
      }
    });

    // this._propertyService.GetPropertiesOnCountry(currenLang, countryCode).then(result=>{
      
    //   if(result){
    //     this.propertyCity = result;
    //     if(this.propertyCity[1].id == "0"){
    //       this.propertyCity.shift();
    //     }
    //     if (this.langId == 1)
    //     this.propertyCity.unshift({ id: "0", text: "All Areas" });
    //   else
    //     this.propertyCity.unshift({ id: "0", text: "كل المناطق" });
    //   this.optionsCity = {
    //     allowClear: false,
    //     multiple: true,
    //     tags: true
    //   };
    //   }
    // })
  }

  resetFormSearch() {
    this.formSearch.reset();
    this._typesValue = "";
    this._TypesSortVal = "";
    this._typesValue = "";
    this._Types2Val = "";
    this._minTransactionsVal = "";
    this._maxTransactionsVal = "";
    this._ElectricityVal = "";
    this._FurnicherVal = "";
    
    this.featureValue.forEach(item=>{
      let checkBox = document.getElementById(item);
      if (checkBox) {
        (checkBox as HTMLInputElement).checked = false;
      }
    })
    this.featureValue = [];
  }

  onSearch(event:any){
    
  }

  public templateResultAgency = (state: Select2OptionData): JQuery | string => {
    let image = '<span class="image"></span>';
    this.agency.forEach((currentValue, index) => {
      
      if (currentValue.text == state.text) {
        image = '<span class="image"><img src="' + currentValue.additional + '"></span>';
      }
    });
    return jQuery('<div class="agency-drop-down">' + '<span>' + state.text + '</span>' + image + '</div>');
  }


  public templateSelectionAgency = (state: AgencyModel): JQuery | string => {
    if(!isNullOrUndefined(state.name) ){
      return jQuery('<span>' + state.name + '</span>');
    }else{
      return jQuery('<span> </span>');
    }

  }

  public LocationResult = (state: Select2OptionData): JQuery | string => {

    let counter = '';
    this.locationlist.forEach((currentValue, index) => {
      if (currentValue.text == state.text) {
        counter = currentValue.counter;
      }
    });
    return jQuery('<span>' + state.text + '(' + (counter) + ')' + '</span>');
  }
  public LocationSelection = (state: Select2OptionData): JQuery | string => {
    return jQuery('<span><a routerLink=/properties/0/' + state.id + '>' + state.text + '</a></span>');
  }

  public PriceResult = (state: Select2OptionData): JQuery | string => {
    if (state.text == 'Select Min Price')
      return jQuery('<span>' + state.text + '</span>');
    else
      return jQuery('<span>' + state.text + '</span>');
  }
  public PriceSelection = (state: Select2OptionData): JQuery | string => {
    return jQuery('<span>' + state.text + '</span>');
  }

  public PriceResultmx = (state: Select2OptionData): JQuery | string => {
    if (state.text == 'Select Max Price')
      return jQuery('<span>' + state.text + '</span>');
    else
      return jQuery('<span>' + state.text + '</span>');
  }
  public PriceSelectionmx = (state: Select2OptionData): JQuery | string => {
    return jQuery('<span>' + state.text + '</span>');
  }

  public FurnicherResult = (state: Select2OptionData): JQuery | string => {
    return jQuery('<span>' + state.text + '</span>');
  }

  public FurnicherSelection = (state: Select2OptionData): JQuery | string => {
    return jQuery('<span>' + state.text + '</a></span>');
  }

  //For serch section
  QuickSearch() {
    localStorage.setItem('Qsearch', JSON.stringify(this.prepareForm()));
    this._route.navigate(['/properties/0/0']);
  }

  toggleDiv() {
    this.isShowDivSrc = !this.isShowDivSrc;
  }



  detailSearch() {
    localStorage.setItem('Qsearch', JSON.stringify(this.prepareFormSearch()));
    this._route.navigate(['/properties/0/0']);
  }


  tabSwitch(number){
    this.salerentValue = number;
  }

  getAreaValue(){
    if(this.areaLocationValue.includes("'")){
      this.areaLocationValue =  this.areaLocationValue.replace("'","")
    }
    return this.areaLocationValue;
  }

  prepareForm() {
    return this.propertySearchParams = {
      branchId: this.initialValue.branchID,
      countryCode: this.countryCode,
      contactId: 'zj5gitx5411oab2quguwsecq-14', currentLang: this.langId, cAID: 0,
      groupedBranches: this.initialValue.groupedBranchesId, page: '',
      sort: '', type: this.salerentValue, limit: '2500', searchTerm: '',
      propertyType2: '',
      propertyType3: isNullOrUndefined(this._Types2Val) ? '' : (this._Types2Val == "0") ? '' : this._Types2Val,
      propertyLocation: isNullOrUndefined(this._valueLocation) ? '' : (this._valueLocation == "0") ? '': this._valueLocation, 
      minArea: '',
      maxArea: '',
      minPrice: isNullOrUndefined(this._minTransactionsVal) ? '' : this._minTransactionsVal,
      maxPrice: isNullOrUndefined(this._maxTransactionsVal) ? '' : this._maxTransactionsVal,
      features: '',
      minBeds: isNullOrUndefined(this._valueBed) ? '' : this._valueBed,
      maxBeds: '',
      minBaths: '',
      maxBaths: '', electricity: '',
      Furnicher: '', count: true, nonVacant: '', agentID: '', parentID: '',
      myProperty: false,
      currencyDetails: {
        selectedCcyCode: '',
        selectedCcyName: '',
        selectedCcySwift: '',
        selectedCcySwiftCodeArb: '',
        selectedCcySwiftCode: '',
        selectedCcyDecimal: '',
      }
    }
  }

  getPropertyData(locId, propType, propType3, minBed = '', maxBad = '') {
    
    this.propertySearchParams = {
      branchId: this.initialValue.branchID,
      countryCode: this.countryCode,
      contactId: '0',
      currentLang: this.langId,
      cAID: 0,
      groupedBranches: this.initialValue.groupedBranchesId,
      page: '',
      sort: '',
      type: propType,
      limit: '10000',
      searchTerm: '',
      propertyType2: '',
      propertyType3: propType3,
      propertyLocation: locId,
      minArea: '',
      maxArea: '',
      minPrice: '',
      maxPrice: '',
      features: '',
      minBeds: minBed,
      maxBeds: maxBad,
      minBaths: '',
      maxBaths: '',
      electricity: '',
      Furnicher: '',
      count: true,
      nonVacant: '',
      agentID: '',
      parentID: '',
      myProperty: false,
      currencyDetails: {
        selectedCcyCode: this.currencyDetail?.selectedCcyCode,
        selectedCcyName: this.currencyDetail?.selectedCcyName,
        selectedCcySwift: this.currencyDetail?.selectedCcySwift,
        selectedCcySwiftCodeArb: this.currencyDetail?.selectedCcySwiftCodeArb,
        selectedCcySwiftCode: this.currencyDetail?.selectedCcySwiftCode,
        selectedCcyDecimal: this.currencyDetail?.selectedCcyDecimal,
      }
    }
    localStorage.setItem('Qsearch', JSON.stringify(this.propertySearchParams));
    this._route.navigate(['/properties/0/0']);
  }

  submit(aname, aemail, pid, pcode, ptype, aarea, curl,price,agentId,saleOrRent,branchId) {
    this.submitted = true;
    this.disableSubmit = true
    this.prepareEmail(aname, aemail, pid, pcode, ptype, aarea, curl,price,price,agentId,1,saleOrRent,branchId);
    if (this.formpop.invalid) {
      this.disableSubmit = false
      return;
    }
    this._contactusService.SenemailSubmit(this.prepareEmail(aname, aemail, pid, pcode, ptype, aarea, curl,price,price,agentId,1,saleOrRent,branchId)).then((result) => {
      if (result) {
        this.disableSubmit = false
        this.simpleResponseemil = UtilityService.clone(result);
        if (this.simpleResponseemil.messageBool) {
          this.notifyService.showSuccess("Mail send successfully !!", "")
          $('#myModal').modal('hide');
          //$('#myModal').hide();
          //$('.modal-backdrop').remove('modal-backdrop');
        }
        else
          this.notifyService.showSuccess("some thing went wrong.", "");
        
      }
    });
  }

  prepareEmail(aname, aemail, pid, pcode, ptype, aarea, curl, minPrice, maxPrice, agentId, typeOfRequest, saleOrRent,branchId) {

    return this.email = {
      CmName: this.formpop.controls["fname"].value,
      CmEmail: this.formpop.controls["email"].value,
      CmPhone: this.formpop.controls["phone"].value?.dialCode + this.formpop.controls["phone"].value?.number,
      AgentName: aname,
      AgentEmail: aemail,
      PropertyID: pid,
      PropertyCode: pcode,
      PropertyType: ptype,
      PropertyArea: aarea,
      ClientURL: curl,
      Referrer: '',
      branchId: branchId,
      selectedSwiftCode: this.langId == 1 ? this.initialValue.currencyDetail.selectedCcySwiftCode : this.initialValue.currencyDetail.selectedCcySwiftCodeArb,
      currentLangId: this.langId.toString(),
      recapchaResponse: this.token,
      message: this.formpop.controls["comment"].value,
      subscription: true,
      loginId: '1',
      minPrice:minPrice,
      maxPrice:maxPrice,
      agentId:agentId,
      typeOfRequest:"1",
      saleOrRent:saleOrRent,
      subBranch:this.initialValue?.branchID.toString()
    }
  }

  prepareFormSearch() {
    return this.propertySearchParams = {
      branchId: this.initialValue.branchID,
      countryCode: this.countryCode,
      contactId: '0', currentLang: this.langId, cAID: 0,
      groupedBranches: this.initialValue.groupedBranchesId, page: '',
      sort: '', type: isNullOrUndefined(this._typesValue) ? '' : this._typesValue, limit: '10000',
      searchTerm: isNullOrUndefined(this.formSearch.controls["office"].value) ? '' : this.formSearch.controls["office"].value,
      propertyType2: isNullOrUndefined(this._TypesSortVal) ? '' : this._TypesSortVal,
      propertyType3: isNullOrUndefined(this._Types2Val) ? '' : this._Types2Val,
      propertyLocation: isNullOrUndefined(this._CityFinalVal) ? '' : this._CityFinalVal,
      minArea: isNullOrUndefined(this.formSearch.controls["MinArea"].value) ? '' : this.formSearch.controls["MinArea"].value,
      maxArea: isNullOrUndefined(this.formSearch.controls["MaxArea"].value) ? '' : this.formSearch.controls["MaxArea"].value,
      minPrice: isNullOrUndefined(this._minTransactionsVal) ? '' : this._minTransactionsVal,
      maxPrice: isNullOrUndefined(this._maxTransactionsVal) ? '' : this._maxTransactionsVal,
      features: this.featureValue.length > 0 ? this.fvalue.slice(1):'',
      minBeds: isNullOrUndefined(this.formSearch.controls["MinBedrooms"].value) ? '' : this.formSearch.controls["MinBedrooms"].value,
      maxBeds: isNullOrUndefined(this.formSearch.controls["MaxBedrooms"].value) ? '' : this.formSearch.controls["MaxBedrooms"].value,
      minBaths: isNullOrUndefined(this.formSearch.controls["MinBathrooms"].value) ? '' : this.formSearch.controls["MinBathrooms"].value,
      maxBaths: isNullOrUndefined(this.formSearch.controls["MaxBathrooms"].value) ? '' : this.formSearch.controls["MaxBathrooms"].value,
      electricity: isNullOrUndefined(this._ElectricityVal) ? '' : this._ElectricityVal,
      Furnicher: isNullOrUndefined(this._FurnicherVal) ? '' : this._FurnicherVal, count: false,
      nonVacant: this.formSearch.controls["nonVacant"].value?'1':'0',
      agentID: '', parentID: '',
      myProperty: false,
      currencyDetails: {
        selectedCcyCode: this.currencyDetail?.selectedCcyCode,
        selectedCcyName: this.currencyDetail?.selectedCcyName,
        selectedCcySwift: this.currencyDetail?.selectedCcySwift,
        selectedCcySwiftCodeArb: this.currencyDetail?.selectedCcySwiftCodeArb,
        selectedCcySwiftCode: this.currencyDetail?.selectedCcySwiftCode,
        selectedCcyDecimal: this.currencyDetail?.selectedCcyDecimal,
      }
    }
  }


  ViewAllData(typevalue, vacant) {
    this.tabType = typevalue;
    this.vacantType = vacant;
    if (typevalue == '')
      this.limittype = '4000';
    else
      this.limittype = '50';
  }

  propertyAll() {
    localStorage.setItem('Qsearch', JSON.stringify(this.prepareFormSearchAllFix()));
    this._route.navigate(['/properties/0/0']);

  }

  prepareFormSearchAllFix() {
    return this.propertySearchParams = {
      branchId: 14,
      countryCode: this.countryCode,
      contactId: '0', currentLang: this.langId, cAID: 0,
      groupedBranches: '', page: '',
      sort: '', type: '', limit: '4000',
      searchTerm: '',
      propertyType2: '',
      propertyType3: '',
      propertyLocation: '',
      minArea: '',
      maxArea: '',
      minPrice: '',
      maxPrice: '', features: '',
      minBeds: '',
      maxBeds: '',
      minBaths: '',
      maxBaths: '',
      electricity: '',
      Furnicher: '', count: true,
      nonVacant: '',
      agentID: '', parentID: '',
      myProperty: false,
      currencyDetails: {
        selectedCcyCode: '',
        selectedCcyName: '',
        selectedCcySwift: '',
        selectedCcySwiftCodeArb: '',
        selectedCcySwiftCode: '',
        selectedCcyDecimal: '',
      }
    }
  }

  async GetAllProperty() {
    await this._propertyService.Property_SearchAsync(1,'0', this.prepareFormSearchAllFix()).then((result) => {
      if (result) {
        //this.loadingSubscription.unsubscribe();
        this.propertySearchResultsResponse = UtilityService.clone(result);
        
      }
    });
  }

  async GetCompare(Id, type, index, ptype) {
    
    let addfav = 1;
    if (type == 1) {
      if (!isNullOrUndefined(this.propertySearchResultsfav)) {
        let fp=this.propertySearchResultsfav.filter(p => p.id == Id && p.isFavorite==true)
        if (fp.length > 0) {
          addfav = 0;
          $('#fav_' + Id).removeClass('active-icon');
        }
      }      
    }
    else {
      if (!isNullOrUndefined(this.propertySearchResults)) {
        let cmp = this.propertySearchResults.filter(p => p.id == Id && p.isCompare == true)
        if (cmp.length > 0) {
          addfav = 0;
          $('#camp_' + Id).removeClass('active-icon');
        }
      }
    }

    this._favoriteService.addToFavoriteOrCompare(Id,addfav,type,this.initialValue.branchID, this.langId.toString(), this.initialValue.currencyDetail.selectedCcyCode, this.initialValue.groupedBranchesId,ptype,index);

  } 
  
  
  highLightFav(propertySerchResult:PropertySearchResults[]){
    
    if(propertySerchResult?.length > 0){
      propertySerchResult.forEach(item=>{
        if(item?.isFavorite){
          let data = document.getElementById(`fav_${item.id}`);
          if(!isNullOrUndefined(data)){
            if(!data?.classList.contains('active-icon')){
              data?.classList.add('active-icon')
            }else{
              data?.classList.remove('active-icon')
            }
          }
        }else if(item?.isCompare){
          let data = document.getElementById(`camp_${item.id}`);
          if(!isNullOrUndefined(data)){
            if(!data?.classList.contains('active-icon')){
              data?.classList.add('active-icon')
            }else{
              data?.classList.remove('active-icon')
            } 
          }
        }
      })
    }
  }


  loadFavComp(Id,type) {

        if(type == 1){
          this.propertySearchResultsfav.forEach((currentValue, index) => {
            if (currentValue.isFavorite)
              $('#fav_' + currentValue.id).addClass('active-icon');
          });
        }else if(type == 2){
          this.propertySearchResults.forEach((currentValue, index) => {
            if (currentValue.isCompare)
              $('#camp_' + currentValue.id).addClass('active-icon');
    
          });
        }
  }
  
  ecodeuri(id, fromtype) {
    const queryParams = { id };
  
    if (fromtype == '1') {
      queryParams['fromtype'] = fromtype;
    }
  
    this._route.navigate(['/propertydetail'], { queryParams });
  }

  hideDetails() {
    this.isShow = false;
    $('#add-title').removeClass('active-icon');
    $('#add-title1').addClass('active-icon');
  }
  showDetails() {
    this.isShow = true;
    $('#add-title').addClass('active-icon');
    $('#add-title1').removeClass('active-icon');
  }

  locationSearch(value) {
    this._route.navigate(['/properties/0/' + value]);
  }

  getpropertybyId(id) {
    this.latestPropertiespop = this.latestProperties.filter(x => x.id == id);
    this.placetext =  (this.langId == 1 ? "Hi I found your property with reference number: " : "مرحبًا، لقد وجدت عقارك برقم المرجع:") + this.latestPropertiespop[0].code + (this.langId == 1 ? ' on Aqarat website.Please contact me. Thank you.' : "على موقع عقارات. يرجى التواصل معي. شكرًا") ;
    this.formpop.get("comment").setValue(this.placetext);
    //$('#myModal').modal('show');
  }






  sendClick(propid) {
    this._utilityService.sendClickInfo("2", propid, "", this.initialValue?.branchID,"",this.countryCode);
  }

  addCallNumber(mobile, i, propid) {
    
    $('#ane_' + i).hide();
    $('#an_' + i).show();
    $('#sp_' + i).html(mobile);
  }
  addCallagentNumber(mobile, i, propid) {
    
    $('#ane_' + i).show();
    $('#an_' + i).hide();
    $('#sp_' + i).html('');
    this._utilityService.sendClickInfo("3", propid, "", this.initialValue?.branchID,"",this.countryCode);
  }


  onValueSelect(value:any){
    
    if(value != null && value != ""){
      
      this._redirectionService.redirectToClient(this.agenciesList.find(x=>x.id == value).website, this.initialValue.environment);
      this.valueAgency = '';
    
      setTimeout(() => {
        this.valueAgency = null;
      });
    
    }
    
  }

  getURL(url) {
    
    let seturl = url.replace('http://', '').replace('https://', '');
    let finalUrl = seturl.split('.')
    return finalUrl[0];
  }

  get value(): string {
    return this._value;
  }
  set value(value: string) {
    this._value = value;
  }
  get valuerent(): string {
    return this._valuerent;
  }
  set valuerent(value: string) {
    this._valuerent = value;
  }

  get valueLocation(): string {
    return this._valueLocation;
  }
  set valueLocation(value: string) {
    
    this._valueLocation = value;
  }

  get typesValue(): string {
    return this._typesValue;
  }

  set typesValue(value: string) {
    this._typesValue = value;
  }

  get valueBed(): string {
    return this._valueBed;
  }
  set valueBed(value: string) {
    this._valueBed = value;
  }
  get TypesSortVal(): string {
    return this._TypesSortVal;
  }
  set TypesSortVal(value: string) {
    this._TypesSortVal = value;
  }

  get Types2Val(): string {
    return this._Types2Val;
  }
  set Types2Val(value: string) {
    this._Types2Val = value;
  }

  get CityVal(): any {
    return this._CityVal;
  }
  set CityVal(value: any) {
    
    this._CityVal = value
    if (!isNullOrUndefined(this._CityVal)) {
      this._CityFinalVal = this._CityVal;
    }

  }

  get minTransactionsVal(): string {
    return this._minTransactionsVal;
  }
  set minTransactionsVal(value: string) {
    this._minTransactionsVal = value;
  }

  get maxTransactionsVal(): string {
    return this._maxTransactionsVal;
  }
  set maxTransactionsVal(value: string) {
    this._maxTransactionsVal = value;
  }

  get ElectricityVal(): string {
    return this._ElectricityVal;
  }
  set ElectricityVal(value: string) {
    this._ElectricityVal = value;
  }

  get FurnicherVal(): string {
    return this._FurnicherVal;
  }
  set FurnicherVal(value: string) {
    this._FurnicherVal = value;
  }


  ShowDetail(id) {
    if (id == '1')
      this.isDetailsIcon = true;
    else
      this.isDetailsIcon = false;
  }


  goToProperyNews(url: string) {
    this.router.navigate(['/News/true'] );
  }
}
