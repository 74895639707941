import { AfterViewInit, Component, OnInit, EventEmitter, Output, ChangeDetectorRef, HostListener } from '@angular/core';
import { Router, NavigationEnd, NavigationStart } from '@angular/router';
import { isNullOrUndefined } from 'util';
import { AuthorizationResponse } from '../models/login';
import { UtilityService } from '../shared/utility.service';
import { DefaultService } from '../services/default.service';
import { InitialValueResponse, NavigationResponse,Navigation, CountryResponse, CurrencyResponse, InitialValue, Country, Currency } from '../models/initialValue';
import { Property, PropertyResponse } from '../models/property';
import { Company1, Company1Response } from '../models/Company';
import { ConfigDetails, ThemeColorModel, ThemeColorResponseModel } from '../models/appConfig.model';
import { filter } from 'rxjs/operators';
import { ConfigurationService } from '../shared/configuration.service';
import { LoginService } from '../services/login.service';
import { ToastrService } from 'ngx-toastr';
import { AppconfigService } from '../shared/appconfig.service';
import { Location } from '@angular/common';
import { SwiperComponent } from "swiper/angular";
import Swiper from "swiper/types/swiper-class";

declare var bootstrap: any;

import SwiperCore , {
  Autoplay,
  Controller,
} from 'swiper';
import { AgencyLogoModel, ComapnyDefaultInfo } from '../models/defaultInitialValues';
import { ApiResponse, ApiResponseModel } from '../models/ApiResponseModel';
import { NotificationService } from '../shared/notification.service';
import { NgbModal } from '@ng-bootstrap/ng-bootstrap';
import { AnnouncementModalComponent } from '../UI/announcement-modal/announcement-modal.component';
import { Announcement } from '../models/News';

SwiperCore.use([
  Autoplay,
  Controller
]);

declare var $: any;

@Component({
  selector: 'app-nav-menu',
  templateUrl: './nav-menu.component.html',
  styleUrls: ['./nav-menu.component.css']
})
export class NavMenuComponent implements OnInit {
  @Output() rfFotter = new EventEmitter<string>();
  authorizationResponse: AuthorizationResponse;
  initialValueResponse: InitialValueResponse;
  initialValue:InitialValue = null;
  propertyResponce: PropertyResponse;
  propertyResponce2: PropertyResponse;
  latestProperties: Property[];
  countryValue: string = '';
  currencyValue: string = '';
  langValue: number = 1;
  countryName: string = '';
  currencyCont: string = '';
  lanSelected: string = ''
  iUser: boolean = false;
  UserName: string = '';
  navigationResponse: NavigationResponse;
  menuItem: Navigation[];
  countryResponse: CountryResponse;
  currencyResponse: CurrencyResponse;
  countryList : Country[];
  currencyList : Currency[];  
  hostName: string = '';
  isHtmlValue: string = '';
  isSpecificTheme: boolean = this._configService.isSpecificTheme;
  isLuban: boolean = this._configService.isLuban;
  isDefaultFooter: boolean = false;
  isAgentFooter: boolean = false;
  userid: string = '';
  langId: number = 1;
  isHomePage:boolean = false;
  showNavProperty:boolean = false;
  applyBackgroungToNave:boolean = false;
  public countryCode = '';
  setHostName: string = '';
  isShowMenu: boolean = false;
  private configDetails: ConfigDetails;
  isChield: boolean = false;
  themeColorResponse:ThemeColorResponseModel;
  themeColor:ThemeColorModel;
  slideImages: any;
  isHostSite:boolean = false;
  companyDefualtInfo:ComapnyDefaultInfo;
  
  hamalaSetting = {
    slidesToShow: 1,
    slidesToScroll: 1,
    arrows: true,
    rtl: this.langId == 2,
    autoplay: true,
    dots: false,

    dotsClass: "d-flex slick-dots",
  }
  root = [
    {
      title: 'Framework',
      children: [
        {
          title: 'Angular',
          children: [
            { title: 'Typescript' },
            { title: 'RxJs' }
          ]
        },
        { title: 'React' }
      ]
    },
    {
      title: 'Testing',
      children: [
        { title: '<a routerLink="/view/19">Jest</a>' },
        { title: 'Jasmine' }
      ]
    }
  ];

  favIcon: HTMLLinkElement = document.querySelector('#appIcon');
  currentUrl: string = window.location.href;
  domain: string = window.location.origin;
  currentRoute: string = this.currentUrl.replace(this.domain, '');

  constructor(private _route1: Router,  private _route: Router, private _utilityService: UtilityService,
    private _defaultService: DefaultService, private cdRef: ChangeDetectorRef,private _appconfigService: AppconfigService,
     private _configService: ConfigurationService, private _loginService: LoginService, 
     private toasterService:ToastrService, private location:Location,
     private notifyService: NotificationService, private modalService: NgbModal) {
  
      this.langId = _configService.getLanguageValue();
      this._route1.events.pipe(
        filter(event => event instanceof NavigationEnd)
      ).subscribe((event: NavigationEnd) => {
        this.currentRoute = event.urlAfterRedirects;
      });
    }

  @HostListener('window:scroll', ['$event'])
onScroll(event: Event) {
  const scrollPosition = window.pageYOffset || document.documentElement.scrollTop;

  if(scrollPosition > 63){

    $('.navbar').addClass('navbar-fixed');
    $('.main-header').addClass(this.isHostSite ? 'navbar-fixed' : '');
  }
  if(scrollPosition < 64){
    $('.navbar').removeClass('navbar-fixed');
    $('.main-header').removeClass(this.isHostSite ? 'navbar-fixed' : '');
  }
}

  isExpanded = false;

  public showMobileMenu(val) {
    this.isShowMenu = val;
  }

  redirectToSameRoute(url:string) {
    
    
    if(url === "/default"){
      url = ""
    }
    if(url == "/Agents"){
      this._route1.navigateByUrl('/empty').then(() => {
        this._route1.navigateByUrl(url);
      });
      return
    }
  
    // Navigate to the same route
    this._route1.navigateByUrl(url);
  }

  collapse() {
    this.isExpanded = false;
  }

  toggle() {
    this.isExpanded = !this.isExpanded;
  }

  country(con: string) {
    this._configService.countryChange(con);
  }

  Currency(currency: string) {
    this.currencyCont = currency;
    localStorage.setItem('currency', this.langValue == 1 ? currency : currency == 'BHD' ? 'د.ب' : 'ق.ر');
    this.reloadCurrentRoute('');
  }

  LangVal(lng: string) {
    
    this.rfFotter.emit(lng);
    this._configService.setLangauage(lng);
  }


  getThemeStyles(cmsCompID:number){

    const primaryColor = getComputedStyle(document.documentElement).getPropertyValue('--primary-color');
    if(primaryColor != ""){
      this._appconfigService.getVendorThemeColor(cmsCompID).then(result=>{
        if(result){
          
          this.themeColorResponse = UtilityService.clone(result);
          if(this.themeColorResponse?.Status){
            
            this.themeColor = this.themeColorResponse?.ThemeColor;
            document.documentElement.style.setProperty('--primary-color', this.themeColor?.primaryColor != "" ? this.themeColor?.primaryColor : "#d1ae8f");
            document.documentElement.style.setProperty('--primary-text-color', this.themeColor?.primaryText != "" ? this.themeColor?.primaryText : "#000000");
            document.documentElement.style.setProperty('--secondary-color', this.themeColor?.secondaryColor != "" ? this.themeColor?.secondaryColor : "#544747");
            document.documentElement.style.setProperty('--secondary-text-color', this.themeColor?.secondaryText != "" ? this.themeColor?.secondaryText : "#000000");
            document.documentElement.style.setProperty('--themeHeaderBackground-color', this.themeColor?.headerBackground != "" ? this.themeColor?.headerBackground : "#fff");
            document.documentElement.style.setProperty('--themeFooterBackground-color', this.themeColor?.footerBackground != "" ? this.themeColor?.footerBackground : "#fff");
            document.documentElement.style.setProperty('--themeBodyBackground-color', this.themeColor?.bodyBackground != "" ? this.themeColor?.bodyBackground : "#fff");
            document.documentElement.style.setProperty('--primary-opac-color', this.themeColor?.PrimaryColorOpacity != "" ? this.themeColor?.PrimaryColorOpacity : "#d1ae8f");
            document.documentElement.style.setProperty('--secondry-opac-color', this.themeColor?.SecondaryColorOpacity != "" ? this.themeColor?.SecondaryColorOpacity : "#544747");
            // console.log(this.themeColor)
          }
        }
      })

    }


  }

  processURL(url: string) {
    let processedURL = url.replace(/^(https?:\/\/)?(www\.)?localhost\//i, '');
    processedURL = processedURL.replace(/^(https?:\/\/)?(www\.)?/i, '');
    const splitURL = processedURL.split('.');
    if(splitURL[0] != "aqaraats" && splitURL[0] != "angular"){
      this.isHostSite = true;
    }else{
      this.isHostSite = false;
    }
  }

  UrlSpliter(url){
    
    if(url == '' || url == '/'  || url == '/en' || url == '/ar'|| url == '/default'|| url == 'default'){
      this.isHomePage = true;
      this.isChield = false;
      localStorage.setItem('isChield',`${this.isChield}`)
    }else{
      this.isHomePage = false;
      this.isChield = true;
      localStorage.setItem('isChield',`${this.isChield}`)
    }

    if(url.includes("properties") || url.includes("propertydetail")){
      this.showNavProperty = true;
    }else{
      if(this.isHostSite && this.isHomePage){
        this.showNavProperty = true
      }else{
        this.showNavProperty = false;
      }

    }
  }

    findHostSite(NavigationStartUrl:string, CompleteUrl:string){
    
    let url = this.processURL(CompleteUrl);
    this.UrlSpliter(NavigationStartUrl);

    if(this.isHomePage && this.isHostSite){
      this.GetDefaultData(this.initialValue.cmsCompID,`${this.initialValue.companyID}` , this.langValue.toString(), this.initialValue.branchID, this.countryCode, this.userid, this.initialValue.groupedBranchesId);
    }
  }
  getImageUrl(url: any) {
    return url;
  }

  ngOnInit(): void {

    this._configService.InitalValuesSubject.subscribe(resp=>{
      
      this.initialValue = resp
      this.langId = this._configService.getLanguageValue();
      this.langValue = this._configService.getLanguageValue();
      this.lanSelected = this.langId == 1 ? 'English' : 'عربي';
      this.isDefaultFooter = this._configService.getIsDefaultFoorter();
      this.isAgentFooter = this._configService.getIsAgentFoorter();
      this.GetMenu(this.initialValue?.cmsCompID);
    })

    this._configService.ThemeColorSubject.subscribe(resp=>{
      this.themeColor = resp;
      const primaryColor = getComputedStyle(document.documentElement).getPropertyValue('--primary-color');
      if(primaryColor != ""){
              document.documentElement.style.setProperty('--primary-color', this.themeColor?.primaryColor != "" ? this.themeColor?.primaryColor : "#d1ae8f");
              document.documentElement.style.setProperty('--primary-text-color', this.themeColor?.primaryText != "" ? this.themeColor?.primaryText : "#000000");
              document.documentElement.style.setProperty('--secondary-color', this.themeColor?.secondaryColor != "" ? this.themeColor?.secondaryColor : "#544747");
              document.documentElement.style.setProperty('--secondary-text-color', this.themeColor?.secondaryText != "" ? this.themeColor?.secondaryText : "#000000");
              document.documentElement.style.setProperty('--themeHeaderBackground-color', this.themeColor?.headerBackground != "" ? this.themeColor?.headerBackground : "#fff");
              document.documentElement.style.setProperty('--themeFooterBackground-color', this.themeColor?.footerBackground != "" ? this.themeColor?.footerBackground : "#fff");
              document.documentElement.style.setProperty('--themeBodyBackground-color', this.themeColor?.bodyBackground != "" ? this.themeColor?.bodyBackground : "#fff");
              document.documentElement.style.setProperty('--primary-opac-color', this.themeColor?.PrimaryColorOpacity != "" ? this.themeColor?.PrimaryColorOpacity : "#d1ae8f");
              document.documentElement.style.setProperty('--secondry-opac-color', this.themeColor?.SecondaryColorOpacity != "" ? this.themeColor?.SecondaryColorOpacity : "#544747");
      }
    })


    this._configService.CompanyDefualtInfoSubject.subscribe(resp=>{
      
      if(resp != null){
        this.companyDefualtInfo = resp;

        if(this.isHostSite && resp.altLogo){
          this.favIcon.href = resp.altLogo;
        }
      }
      
    })
    
    this._configService.CountryListSubject.subscribe(resp=>{
      
      this.countryList = resp
      let selectdCountryIndex = this.countryList.findIndex(x=>x.countryCode == localStorage.getItem("Country"))
      selectdCountryIndex = selectdCountryIndex == -1 ? 0 : selectdCountryIndex;
      this.countryCode = this.countryList[selectdCountryIndex].id; 
      this.countryName = this.langValue == 1 ?  this.countryList[selectdCountryIndex].countryNameEng : this.countryList[selectdCountryIndex].countryNameAR;
      let url  = window.location.href;
      let urlEnd =  url.match(/\.com(?::\d+)?(\/.*)?$/) != null &&  url.match(/\.com(?::\d+)?(\/.*)?$/)?.length > 1 ?  url.match(/\.com(?::\d+)?(\/.*)?$/)[1] : "" 
      this.findHostSite(urlEnd, url);
    })

    this._configService.CurrencyListSubject.subscribe(resp=>{
      
      this.currencyList = resp;
      let selectdCurrencyIndex = this.currencyList.findIndex(x=>x.countryCode == this.countryCode);

      this.currencyCont = this.langId == 1 ?  this.currencyList[selectdCurrencyIndex].currencyCode : this.currencyList[selectdCurrencyIndex].currencyArabicCode; 
    })

    this._route.events.pipe(filter(event => event instanceof NavigationStart)).subscribe((event: NavigationStart) => {
      
      // let url  = 'https://www.tashyeed.net/';
      this.findHostSite(event.url, window.location.href);
      // this.findHostSite(event.url, url);
    });
    this._utilityService.isUserLogin.subscribe((isUserLogin) => {
      
      this.iUser = isUserLogin; // => true/false
      this.cdRef.detectChanges();
    });
    this._utilityService.UserName.subscribe((UserName) => {
      this.UserName = UserName; // => true/false
      this.cdRef.detectChanges();
    });


    if(this.isLuban){
      this.getAnnouncement();
    }

  }


  getAnnouncement(){
    this._defaultService.GetAnnouncement(this.langValue.toString(), this.initialValue.branchID).then((result : Announcement) => {
      if (result?.Title || result?.Image ) {
        const modalRef = this.modalService.open(AnnouncementModalComponent,{ windowClass: 'custom-modal' });
        modalRef.componentInstance.announcement = result;
      }
    });
  }

  GetDefaultData(CmsCompID: number, compId: string, currenLang: string, branchId: number, countryCode: string, userId: string, GroupedBrancheIds: string) {
    if(countryCode == ""){
      countryCode = "973"
    }
    if(userId == ""){
      userId = "0"
    }
    this._defaultService.Default_GetAsync(CmsCompID, compId, currenLang, branchId, countryCode, userId, GroupedBrancheIds).then((result : ApiResponse<PropertyResponse>) => {
      if (result.StatusCode == 200) {
        
        this.propertyResponce2 = result.Data;
        for (var i = 0; i < this.propertyResponce2.sliderImg.length; i++) {
          if (isNullOrUndefined(this.propertyResponce2.sliderImg[i].img) || this.propertyResponce2.sliderImg[i].img == "" || this.propertyResponce2.sliderImg[i].img == '') {
            this.propertyResponce2.sliderImg[i].img = 'https://cdn.aqaraats.com/images/NA.jpg';
          }
        }
        this.slideImages = this.propertyResponce2.sliderImg
      }
    });
  }


  addclass(lng) {
    if (lng == '2')
      $('body').addClass('language-reverse');
    else
      $('body').removeClass('language-reverse');
  }

  reloadCurrentRoute(value) {
    
    let currentUrl = this._route.url;
    this._route.navigateByUrl('/', { skipLocationChange: true }).then(() => {
      if (value == 'lang') {
        if (currentUrl == '/' || currentUrl == '/ar' || currentUrl == '/en'){
          if(this.langValue == 1){
            this._route.navigate(['/en']);
          }else{
            this._route.navigate(['/ar']);
          }
        }        else {
          if (currentUrl.indexOf("?") !== -1) {
            const queryParams = new URLSearchParams(currentUrl.split('?')[1]);
            // Dynamically extract all query parameters
            const queryParamsObj = {};
            queryParams.forEach((value, key) => {
              queryParamsObj[key] = value;
            });
            this._route.navigate([currentUrl.split('?')[0]], { queryParams: queryParamsObj });
          }
          else{
            this._route.navigate([currentUrl]);
          }
        }
      }
      else {
        if (currentUrl == '/'){
          window.location.reload();
                   
        }  
        else {
          if (currentUrl.indexOf("?") !== -1) {
            const queryParams = new URLSearchParams(currentUrl.split('?')[1]);
            // Dynamically extract all query parameters
            const queryParamsObj = {};
            queryParams.forEach((value, key) => {
              queryParamsObj[key] = value;
            });
            this._route.navigate([currentUrl.split('?')[0]], { queryParams: queryParamsObj });
          }
          else{
            window.location.reload();
          }
        }
      }
    });
  }

  // GetInitialValues() {
  //   this.GetMenu(this.initialValue?.cmsCompID);
  //   this.GetAgencyLogo(this.initialValue?.branchID.toString())
  //   this.GetCompanyInfo();
  // }

  GetMenu(companyId) {
    this._defaultService.navigationMenuGet(0, false, companyId, this.langValue).then((result:ApiResponseModel<NavigationResponse>) => {
      if (result.StatusCode == 200) {

        this.menuItem = result?.Data?.navigation;
      }
    });

  }

  // GeCountry() {
  //   this._defaultService.CountryGet(this.initialValue?.branchID.toString(), this.langValue.toString(), this.initialValue?.groupedBranchesId).then((result) => {
  //     if (result) {
  //       this.countryResponse = UtilityService.clone(result);
  //       this.countryList = this.countryResponse?.countryList;
  //       if(isNullOrUndefined(localStorage.getItem("Country"))){
  //         this.countryList.forEach(item=>{
  //           if(item.countryName.toLowerCase() == "bahrain" || item.countryName == "البحرين"){
  //             this.countryName = item.countryName; 
  //             this.countryCode = item?.id;
  //             localStorage.setItem("Country", item.id)
  //           }
  //         })
  //       }else{
  //         this.countryList.forEach(item=>{
  //           if(item.id == localStorage.getItem("Country")){
  //             this.countryName = item.countryName; 
  //             this.countryCode = item.id;
  //           }
  //         })
  //       }
  //       this.GetCurrecy(false);
  //       this.GetCompanyInfo();
  //     }
  //   });

  // }

  GetCurrecy(isCountryChange:Boolean) {
    this._defaultService.CurrencyGet(this.initialValue?.branchID.toString(), this.langValue.toString(), +this.countryCode).then((result) => {
      if (result) {
        //
        this.currencyResponse = UtilityService.clone(result);
        this.currencyList = this.currencyResponse?.currencyList;
        if(!isCountryChange){
          if(isNullOrUndefined(localStorage.getItem("currency"))){
            this.currencyList?.forEach(item => {
                if(item?.currencyName.toLowerCase() == "bahraini dinar" || item?.currencyName == "دينـــار بحرينى"){
                  this.currencyCont = item?.currencyCode;
                  localStorage?.setItem("currency", item?.currencyCode)
                }
            });
          }else{
            this.currencyList.forEach(item=>{
              if(item?.currencyCode == localStorage.getItem("currency")){
                this.currencyCont = this.langValue == 1 ? item?.currencyCode :  item?.id
              }
            })
          }
        }else{
          if(this.currencyList?.length > 0){
            this.currencyCont = this.currencyList[0]?.currencyCode;
            localStorage?.setItem("currency", this.currencyList[0]?.currencyCode)
            this.reloadCurrentRoute('');
          }
        }

      }
    });

  }


  logout(){
    this._loginService.LogOut().then(result=>{
      if(result){
        localStorage.removeItem("User");
        this._utilityService.isUserLogin.next(false);
        this._route.navigateByUrl("login");
        this.toasterService.success("LogOut Successful", "LogOut");        
      }else{
        this.toasterService.error("Internal Error", "LogOut");        
      }
      
    })
  }
 
  redirectToMap(){
    let url = `http://maps.google.com/?q=${this.companyDefualtInfo?.latitudeid},${this.companyDefualtInfo?.longitude}`;
    window.open(url, '_blank')
  }


  MenuChield(id) {
    if ($('#sub_menu' + id).hasClass('open'))
      $('#sub_menu' + id).removeClass("open");
    else
      $('#sub_menu' + id).addClass("open");    
  } 

  closeToggle() {
    $('#main_drawer').removeClass("open");
  }

  navigateToPage(url: string) {
    
    var urlval = url;
    
    if (urlval.indexOf("?") !== -1) {
      debugger;
      this._route1.navigate(['/News/true']);
    }
    else
      this._route1.navigate([decodeURI("/" + url)]);
  }

  EligName: any = null;
  EligOID: any = null;
  EligMobile: any = null;

  checkElig(input: HTMLInputElement, button: HTMLButtonElement) {
    if(!input.value) {
      if(this.langId == 1) {
        this.notifyService.showError('Sorry, you are not on the list of eligible people ' +
        'You can view the options offered for owning a suitable home for your family in a fully-serviced residential neighborhood in the Halban area', '', 'toast-top-center');
      } else {
        this.notifyService.showError('عفوا لعدم وجودكم ضمن قائمة المستحقين '+
     ' ويمكنكم الاطلاع على الخيارات المطروحه لامتلاك المسكن الملائم لأسرتك في حي سكني متكامل الخدمات في منطقة حلبان', '', 'toast-top-center');
      }

      button.disabled = false;

      return;
    }

    button.disabled = true;

    this._defaultService.GetSupportInfo(input.value).then((result: any) => {
      if (result) {
        this.EligMobile = result.Mobile;
        this.EligName = result.Name;
        this.EligOID = result.OID;

        const bootstrapModalInstance = new bootstrap.Modal(document.querySelector('#eligModal'), {
          backdrop: true
        });
    
        bootstrapModalInstance.show();
      } else{
        if(this.langId == 1) {
          this.notifyService.showError('Sorry, you are not on the list of eligible people ' +
          'You can view the options offered for owning a suitable home for your family in a fully-serviced residential neighborhood in the Halban area', '', 'toast-top-center');
        } else {
          this.notifyService.showErrorUnlimited('عفوا لعدم وجودكم ضمن قائمة المستحقين '+
       ' ويمكنكم الاطلاع على الخيارات المطروحه لامتلاك المسكن الملائم لأسرتك في حي سكني متكامل الخدمات في منطقة حلبان', '', 'toast-top-center');
        }
      }
      
      button.disabled = false;
    });
  }
}






