import { Injectable } from '@angular/core';
import { Meta } from '@angular/platform-browser';
import { MetaTag } from '../models/MetaTag';

@Injectable({ providedIn: 'root'})
export class SetMetaTagSocialService { 
  private titleMeta: string = "og:title";
  private typeMeta: string = "og:type";
  private imageMeta: string = "og:image";
  private urlMeta: string = "og:url";
  private descriptionMeta: string = "og:description";
  
  constructor(private metaService: Meta) {

  }

  public setFacebookTags(url: string, title: string, description: string, image: string): void {
    var imageUrl = `${image}`;
    var tags = [      
      new MetaTag(this.titleMeta, title),
      new MetaTag(this.typeMeta, 'angular.aqaraats.com'),
      new MetaTag(this.imageMeta, imageUrl),
      new MetaTag(this.urlMeta, url),
      new MetaTag(this.descriptionMeta, description)
    ];
    this.setTags(tags);
  }

  private setTags(tags: MetaTag[]): void {
    tags.forEach(siteTag => {
      this.metaService.updateTag({ property: siteTag.name, content: siteTag.value });
    });
  }
}
