import { Injectable } from '@angular/core';
import { Subject } from 'rxjs';

@Injectable({
  providedIn: 'root'
})
export class SimpleStateService {
  pageName = '';
  clientUrlSegment = 'newClient';
  marketAreaID = 18;
  propertyTypeID = 1;

  // Events
  private pageChangeSubject: Subject<any> = new Subject<any>();
  pageChange$ = this.pageChangeSubject.asObservable();
  pageChanged(newPage: string) {
    this.pageChangeSubject.next(newPage);
  }

  refreshAddThis() {
    // From: https://gist.github.com/jonathanconway/9925286
    if (window["addthis"] != null) {
      // Load addThis, if it hasn't already been loaded.
      window['addthis_config'] = { 'data_track_addressbar': false, 'pubid': 'ra-6228bf376dc619dd' };
      let bodyElem = document.getElementsByTagName('body')[0];
      let scriptElem = document.createElement('script');
      scriptElem.src = '//s7.addthis.com/js/300/addthis_widget.js#pubid=ra-6228bf376dc619dd';
      scriptElem.type = 'text/javascript'
      bodyElem.appendChild(scriptElem);
      if (window["addthis"] != null) {
      } else {
      }
    } else {
    }
  }
}
