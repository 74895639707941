import { Component, OnInit } from '@angular/core';
import { isNullOrUndefined } from 'util';
import { ConfigDetails } from '../models/appConfig.model';
import { Company1, Company1Response } from '../models/Company';
import { InitialValueResponse } from '../models/initialValue';
import { Property, PropertyResponse } from '../models/property';
import { DefaultService } from '../services/default.service';
import { UtilityService } from '../shared/utility.service';
import { ConfigurationService } from '../shared/configuration.service';
import { FooterDataModel } from '../models/footerdataModel';
import { AgencyLogoModel, ComapnyDefaultInfo } from '../models/defaultInitialValues';
//For jquery
declare var $: any;
@Component({
    selector: 'app-footer',
    templateUrl: './footer.component.html',
    styleUrls: ['./footer.component.css']
})
/** footer component*/
export class FooterComponent implements OnInit {
  initialValueResponse: InitialValueResponse;
  initialValue = null;
  propertyResponce: PropertyResponse;
  latestProperties: Property[];
  langId: number = 1;
  hostName = null;
  isDefaultFooter: boolean = false;
  isAgentFooter: boolean = false;
  logoName: string = '';
  userid: string = '';
  countryValue: string = '973';
  setHostName: string = '';
  public countryCode = '973';
  countryName: string = 'Bahrain';
  currencyCont: string = 'BHD';
  islogoLoaded : boolean = false;
  companyDefualtInfo: ComapnyDefaultInfo;
  private configDetails: ConfigDetails;
  agencyText: string='';
  isSpecificTheme: boolean = this._configService.isSpecificTheme;
  isLuban: boolean = this._configService.isLuban;
  isElla: boolean = window.location.host == 'ella.aqaraats.com';
    /** footer ctor */
  constructor(private _configService: ConfigurationService,private _defaultService: DefaultService, private _configurationService:ConfigurationService) {}

  ngOnInit(): void {
console.log('isElla',this.isElla);
    this.langId = this._configurationService.getLanguageValue();
    this.countryCode = this._configurationService.getSeletedCountryCode();
    this._configurationService.CompanyDefualtInfoSubject.subscribe(resp=>{
      
      if(resp != null){
        this.companyDefualtInfo = resp;
        this.logoName = this.hostName?.split('.')[0];
      }
    })

    if(!isNullOrUndefined( localStorage.getItem("hostname")) && this.hostName == ''){
      this.hostName = localStorage.getItem("hostname");
    }

    if(this.initialValue == null){
      this.initialValue = this._configurationService.getInitialValues();
      this.isDefaultFooter =this._configurationService.getIsDefaultFoorter();
      this.isAgentFooter = this._configurationService.getIsAgentFoorter();
      this._configurationService.getCompanyInfo();
      this.GetInitialValues()
    }else{
      this.GetInitialValues()
    }

  }


  GetInitialValues() {

    this.userid = 'zj5gitx5411oab2quguwsecq-14';
    this.GetObjectText(this.langId,this.initialValue?.cmsCompID);
  }




  GetObjectText(currenLang: number, CmsCompID: number) {
    this._defaultService.GetObjectText(currenLang, CmsCompID).then((result) => {
      if (result) {
        var aboutus = UtilityService.clone(result);
        if (!isNullOrUndefined(aboutus))
          this.agencyText = aboutus.messageString;
      }
    });
  }

 
}
