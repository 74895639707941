import { AfterViewInit, ChangeDetectorRef, Component, ElementRef, HostListener, OnInit, ViewChild } from '@angular/core';
import { FormGroup, FormControl, Validators, FormBuilder } from '@angular/forms';
import { Company, CompanyResponse } from '../../models/Company';
import { EmailContactUs } from '../../models/EmailContactUs';
import { InitialValue, InitialValueResponse } from '../../models/initialValue';
import { ContactusService } from '../../services/contactus.service';
import { DefaultService } from '../../services/default.service';
import { UtilityService } from '../../shared/utility.service';
import { MatSnackBar } from '@angular/material/snack-bar';
import { MapInfoWindow, MapMarker } from "@angular/google-maps";
import { SearchCountryField, CountryISO } from 'ngx-intl-tel-input';

import { isNullOrUndefined } from 'util';
import { NotificationService } from '../../shared/notification.service';
import { NavigationEnd, Router } from '@angular/router';

import { TrackClickInfo } from '../../models/trackClickInfo';
import { ConfigurationService } from 'src/app/shared/configuration.service';

@Component({
    selector: 'app-contactus',
    templateUrl: './contactus.component.html',
    styleUrls: ['./contactus.component.css']
})
/** contactus component*/
export class ContactusComponent implements OnInit, AfterViewInit {
  @ViewChild('map', { static: true }) mapElement: any;
  map: google.maps.Map;
  form: FormGroup; 
  submitted = false;
  emailContactUs: EmailContactUs;
  initialValueResponse: InitialValueResponse;
  initialValue: InitialValue;
  companyResponse: CompanyResponse;
  trackClickInfo: TrackClickInfo;
  companyInfo: Company
  SearchCountryField = SearchCountryField;  
  CountryISO = CountryISO;
  isLuban: boolean = this._configurationService.isLuban;
  preferredCountries: CountryISO[] = [CountryISO.Bahrain]; 
  token: string;
  isSent: boolean = false;
  langId: number = 1;
  hostName: string = '';
  markers: any = [];
  disableSubmit:boolean = false;
  countryCode:string = "";
  zoom = 15
  center: google.maps.LatLngLiteral
  options: google.maps.MapOptions = {
    mapTypeId: 'roadmap',
    zoomControl: true,
    scrollwheel: false,
    disableDoubleClickZoom: true,   
  }
  constructor(private formBuilder: FormBuilder, private _route: Router, private _defaultService: DefaultService, private _utilityService: UtilityService
    , private _contactusService: ContactusService, private _snackBar: MatSnackBar,
    private cdr: ChangeDetectorRef, private notifyService: NotificationService, private _configurationService:ConfigurationService) {
    
    this.hostName = localStorage.getItem('hostname');
  }
  get f() {
    return this.form.controls;
  }  
  ngOnInit(){
    this.form = this.formBuilder.group({
      fname: ['', [Validators.required]],
      lname: ['', [Validators.required]],
      phone: ['', [Validators.required]],
      email: ['', [Validators.required, Validators.email]],
      subject: ['', [Validators.required]],
      comment: ['', [Validators.required]]
    });
    this.langId = this._configurationService.getLanguageValue();
    this.initialValue = this._configurationService.getInitialValues();
    this.countryCode = this._configurationService.getSeletedCountryCode()
    this.GetInitialValues();
    this._utilityService.sendClickInfo("5", "", "",this.initialValue?.branchID,"",this.countryCode);


    // $('html, body').animate({
    //   scrollTop: $("body").offset().top = 0
    // }, 0);
  }

  renderMap() {
    const mapProperties = {
      center: new google.maps.LatLng(+this.companyInfo.latitude, +this.companyInfo.longitude),
      zoom: 15,
      mapTypeId: google.maps.MapTypeId.ROADMAP
    };
    this.map = new google.maps.Map(this.mapElement.nativeElement, mapProperties);
  }

  addMarker() {
    this.markers.push({
      position: {
        lat: +this.companyInfo.latitude,
        lng: +this.companyInfo.longitude,
      },
      label: {
        color: 'red',        
      },
      title: "Aqaraats Marketplace",      
      options: { animation: google.maps.Animation.BOUNCE},
    })
  }

  onRecaptchaSuccess(token: string) {
    this.token = token;
  }
  ngAfterViewInit(): void {
   
  }

  GetInitialValues() {
    this.GetCompany();
  }

  GetCompany() {
    this._contactusService.GetCompany(this.initialValue.branchID, this.langId.toString()).then((result) => {
      if (result) {
        //console.log(result);
        this.companyResponse = UtilityService.clone(result);
        if (!isNullOrUndefined(this.companyResponse)) {
          this.companyInfo = this.companyResponse.companyInfo;
          //navigator.geolocation.getCurrentPosition((position) => {
            this.center = {
              lat: +this.companyInfo?.latitude,
              lng: +this.companyInfo.longitude,
            };
          //});
          setTimeout(() => {
            this.addMarker();
          }, 1000); 
        }
      }
    });
  }

  submit() {
    this.disableSubmit  = true
    this.submitted = true;
    this.prepareForm(1);
    if (this.form.invalid) {
      return;
    }
    this._contactusService.ContactUsSubmit(this.prepareForm(1)).then((result) => {
      if (result) {
        this.disableSubmit =false;
        this.isSent = UtilityService.clone(result);
        if (this.isSent)
        {
          this.notifyService.showSuccess("Mail send successfully !!", "")
          this.form.reset();          
        }
        else
          this.notifyService.showSuccess("some thing went wrong.", "")        
      }else{
        this.disableSubmit = false;
      }
    });
    //console.log(this.prepareForm(1));
  }

  prepareForm(langId) {
    return this.emailContactUs = {
      branchId: this.initialValue.branchID.toString(),
      cmEmail: this.form.controls["email"].value,
      cmName: this.form.controls["fname"].value + ' '+this.form.controls["lname"].value,
      CmPhone: this.form.controls["phone"].value.number,
      messageSubject: this.form.controls["subject"].value,
      messageContent: this.form.controls["comment"].value,
      contactID: '',
      currentLangId: langId,
      customer: '',
      loginId: '',
      recapchaResponse: this.token,
      selectedSwiftCode:''
    }
  }
 }

