import { Component, OnInit } from '@angular/core';
import { ActivatedRoute, NavigationEnd, Router } from '@angular/router';
import { InitialValue, InitialValueResponse } from '../../models/initialValue';
import { News, NewsResponse } from '../../models/News';
import { DefaultService } from '../../services/default.service';
import { NewsService } from '../../services/news.service';
import { UtilityService } from '../../shared/utility.service';
import { ConfigurationService } from 'src/app/shared/configuration.service';
import { isNullOrUndefined } from 'util';

@Component({
    selector: 'app-news',
    templateUrl: './news.component.html',
    styleUrls: ['./news.component.css']
})
/** news component*/
export class NewsComponent implements OnInit {

  newsResponse: NewsResponse;
  latestNews: News[];
  otherNews: News[];
  news: News;
  initialValueResponse: InitialValueResponse;
  initialValue: InitialValue;
  isPropertyNews: number = 0;
  langId: number = 1;
  hostName: string = '';
  public countryCode = '973';
    /** news ctor */
  constructor(private _route: ActivatedRoute, private _route1: Router, private _defaultService: DefaultService, 
    private _newsService: NewsService, private _utilityService: UtilityService, private _configurationServie : ConfigurationService) {

      /*
    this._route.queryParams
    
      .subscribe(params => {
        this.isPropertyNews = params.PropertyNews;
      });
      */

      this._route.paramMap.subscribe(params => {
        if (params.has('ID')) {
          const id = params.get('ID');
          this.isPropertyNews = 1;
        } else {
          // Handle the case when the 'ID' parameter is not present
          this.isPropertyNews = 0;
        }
        
      });

  }

  ngOnInit(): void {
    
    this.initialValue = this._configurationServie.getInitialValues();

    if (this.hostName.toLowerCase().indexOf("aqaraats") === -1){
      this._utilityService.sendClickInfo("5", "", "", this.initialValue?.branchID,"",this.countryCode);
    }

    this.langId = this._configurationServie.getLanguageValue();
    this.hostName = localStorage.getItem('hostname');
    this.countryCode = this._configurationServie.getSeletedCountryCode();
    this.GetInitialValues();

    // $('html, body').animate({
    //   scrollTop: $("body").offset().top = 0
    // }, 0);

  }

  GetInitialValues() {
    this.GetNewsData(this.isPropertyNews ? 1 : 0, this.initialValue?.branchID, this.langId.toString(), this.countryCode, this.initialValue?.cmsCompID,-1);
  }

  GetNewsData(id: number, branchId: number, currenLang: string, countryCode: string, cmsCompID:number,newsId:number) {
    this._newsService.News_GetAsync(id, branchId, currenLang, countryCode, cmsCompID, newsId).then((result) => {
      if (result) {
        
        this.newsResponse = UtilityService.clone(result);
        this.latestNews = this.newsResponse.latestNews;
        this.otherNews = this.newsResponse.otherNews;
        this.news = this.newsResponse.news;

        this.latestNews = this.latestNews.filter(x=>x.title != "");
      }
    });
  }

}
