import { Injectable } from '@angular/core';
import { HttpClient } from '@angular/common/http';
import { Observable, of } from 'rxjs';
import { tap } from 'rxjs/operators';

interface CachedImage {
  url: string;
  blob: Blob;
}

@Injectable({
  providedIn: 'root'
})
export class Image1Service {

  private _cacheUrls: string[] = [];
  private _cachedImages: CachedImage[] = [];

  constructor(private http: HttpClient) { }

  set cacheUrls(urls: any) {
    this._cacheUrls.push(urls);// = [...urls];
  }

  get cacheUrls(): any {
    return this._cacheUrls;
  }

  set cachedImages(image: CachedImage) {
    this._cachedImages.push(image);
  }
  createimageblob(url: string) {
    const index = this._cachedImages.findIndex(image => image.url === url);
    if (index > -1) {
      const image = this._cachedImages[index];
      return of(URL.createObjectURL(image.blob));
    }
  }

  getImage(url: string):Observable<Blob> {
    //const index = this._cachedImages.findIndex(image => image.url === url);
    //if (index > -1) {
    //  const image = this._cachedImages[index];
    //  return of(URL.createObjectURL(image.blob));
    //}
    return this.http.get(url, { responseType: 'blob' }).pipe(
      tap(blob => this.checkAndCacheImage(url, blob))
    );
  }

  checkAndCacheImage(url: string, blob: Blob) {
    if (this._cacheUrls.indexOf(url) > -1) {
      this._cachedImages.push({ url, blob });
    }
  } 

}
