import { HttpClient, HttpErrorResponse, HttpHeaders } from '@angular/common/http';
import { Inject, Injectable } from '@angular/core';
import { Company1Response } from '../models/Company';
import { CountryResponse, CurrencyResponse, InitialValueResponse, NavigationResponse } from '../models/initialValue';
import { TrackClickInfo } from '../models/trackClickInfo';
import { AgencyAndStats, DbStats, ItemPropByArea, PropertiesByTypeResponse, PropertiesListing, Property, PropertyResponse } from '../models/property';
import { delayedRetryHttp } from '../shared/delayedRetry';
import { SimpleRequest, SimpleResponse } from '../models/genericResponse.model';
import { AgencyLogoModel, ComapnyDefaultInfo, DefaultInitialValues, DefaultInitialValuesResponse } from '../models/defaultInitialValues';
import { FooterDataModel } from '../models/footerdataModel';
import { ApiResponse, ApiResponseModel } from '../models/ApiResponseModel';
import { Observable } from 'rxjs';
import { Announcement, DefaultNewsModel } from '../models/News';


@Injectable({ providedIn: 'root' })
export class DefaultService {
  private baseUrl: string;
  private api: string = "api/Default";
  constructor(private httpClient: HttpClient, @Inject('BASE_URL') _baseUrl: string) {
    this.baseUrl = _baseUrl + this.api;
  }

  private handleError(error: any): void {
    let errorMessage = 'An error occurred';
    if (error instanceof HttpErrorResponse) {
      if (error.error instanceof ErrorEvent) {
        // Client-side error
        errorMessage = error.error.message;
      } else {
        // Server-side error
        errorMessage = error.status + ' - ' + error.statusText;
      }
    }
    throw new Error(errorMessage);
  }



  async InitialValue_GetAsync(hostName: string, isCustomer:number): Promise<InitialValueResponse | void> {
    const a = await this.httpClient
      .get<InitialValueResponse>(`${this.baseUrl}/GetInitialValues/${hostName}/${isCustomer}`, {
        headers: new HttpHeaders({
          'Content-Type': 'application/json',
          'Authorization': 'Basic '
        })
      }).pipe(delayedRetryHttp()).toPromise().catch(err => { console.log('default error'); });

    return a;
  }


   async GetInitalConfiguration(configKey: string, languageId : number, countryCode:string ): Promise<InitialValueResponse | void> {
    const a = await this.httpClient
      .get<InitialValueResponse>(`${this.baseUrl}/GetinitalConfiguration/${configKey}/${languageId}/${countryCode}`, {
        headers: new HttpHeaders({
          'Content-Type': 'application/json',
          'Authorization': 'Basic '
        })
      }).pipe(delayedRetryHttp()).toPromise().catch(err => { console.log('default error'); });

    return a;
  }


  async Default_GetAsync(CmsCompID: number, compId: string, currenLang: string, branchId: number, countryCode: string, userId: string, GroupedBrancheIds: string): Promise<ApiResponse<PropertyResponse> | void> {
    const a = await this.httpClient
      .get<ApiResponse<PropertyResponse>>(`${this.baseUrl}/GetDefaultData/${CmsCompID}/${compId}/${currenLang}/${branchId}/${countryCode}/${userId}/${GroupedBrancheIds}`, {
        headers: new HttpHeaders({
          'Content-Type': 'application/json',
          'Authorization': 'Basic '
        })
      }).pipe(delayedRetryHttp()).toPromise().catch(err => { console.log('default error'); });

    return a;
  }

  async GetAnnouncement(currenLang: string, branchId: number): Promise<Announcement | void> {
    const a = await this.httpClient
      .get<ApiResponse<Announcement>>(`${this.baseUrl}/GetAnnouncement/${currenLang}/${branchId}`, {
        headers: new HttpHeaders({
          'Content-Type': 'application/json',
          'Authorization': 'Basic '
        })
      }).pipe(delayedRetryHttp()).toPromise().catch(err => { console.log('default error'); });

    return a;
  }

  async DefaultProperty1_GetAsync(CmsCompID: number, compId: string, currenLang: string, branchId: number, countryCode: string, userId: string, GroupedBrancheIds: string, isAgency: boolean = false): Promise<PropertyResponse | void> {
    const a = await this.httpClient
      .get<PropertyResponse>(`${this.baseUrl}/GetDefaultData_Properties1/${CmsCompID}/${compId}/${currenLang}/${branchId}/${countryCode}/${userId}/${isAgency}/${GroupedBrancheIds}`, {
        headers: new HttpHeaders({
          'Content-Type': 'application/json',
          'Authorization': 'Basic '
        })
      }).pipe(delayedRetryHttp()).toPromise().catch(err => { console.log('default error'); });

    return a;
  }

  async DefaultProperty2_GetAsync(CmsCompID: number, compId: string, currenLang: string, branchId: number, countryCode: string, userId: string, GroupedBrancheIds: string, isAgency: boolean = false): Promise<PropertyResponse | void> {
    const a = await this.httpClient
      .get<PropertyResponse>(`${this.baseUrl}/GetDefaultData_Properties2/${CmsCompID}/${compId}/${currenLang}/${branchId}/${countryCode}/${userId}/${isAgency}/${GroupedBrancheIds}`, {
        headers: new HttpHeaders({
          'Content-Type': 'application/json',
          'Authorization': 'Basic '
        })
      }).pipe(delayedRetryHttp()).toPromise().catch(err => { console.log('default error'); });

    return a;
  }



  async Slider_GetAsync(CmsCompID: number, compId: string, currenLang: string, branchId: number, countryCode: string, userId: string, GroupedBrancheIds: string, isAgency: boolean = false): Promise<PropertyResponse | void> {
    const a = await this.httpClient
      .get<PropertyResponse>(`${this.baseUrl}/GetDefaultData_GetSliderProperties/${CmsCompID}/${compId}/${currenLang}/${branchId}/${countryCode}/${userId}/${isAgency}/${GroupedBrancheIds}`, {
        headers: new HttpHeaders({
          'Content-Type': 'application/json',
          'Authorization': 'Basic '
        })
      }).pipe(delayedRetryHttp()).toPromise().catch(err => { console.log('default error'); });

    return a;
  }

  // async Stats_GetAsync(CmsCompID: number, compId: string, currenLang: string, branchId: number, countryCode: string, userId: string, GroupedBrancheIds: string): Promise<PropertyResponse | void> {
  //   const a = await this.httpClient
  //     .get<PropertyResponse>(`${this.baseUrl}/GetDefaultData_getStats/${CmsCompID}/${compId}/${currenLang}/${branchId}/${countryCode}/${userId}/${GroupedBrancheIds}`, {
  //       headers: new HttpHeaders({
  //         'Content-Type': 'application/json',
  //         'Authorization': 'Basic '
  //       })
  //     }).pipe(delayedRetryHttp()).toPromise().catch(err => { console.log('default error'); });

  //   return a;
  // }


  async GetAgencyStats(branchId: number, countryCode: string,GroupedBrancheIds: string): Promise<ApiResponse<DbStats>> {
    const a = await this.httpClient
      .get<ApiResponse<DbStats>>(`${this.baseUrl}/GetAgencyStats/${branchId}/${countryCode}/${GroupedBrancheIds}`, {
        headers: new HttpHeaders({
          'Content-Type': 'application/json',
          'Authorization': 'Basic '
        })
      }).pipe(delayedRetryHttp()).toPromise().catch(err => { console.log('default error'); });

    return a;
  }



  async CountryGet(branchId: string, langId: string, GroupedBrancheIds:string): Promise<CountryResponse | void> {
    const a = await this.httpClient
      .get<CountryResponse>(`${this.baseUrl}/GetCountry/${branchId}/${langId}/${GroupedBrancheIds}`, {
        headers: new HttpHeaders({
          'Content-Type': 'application/json',
          'Authorization': 'Basic '
        })
      }).pipe(delayedRetryHttp()).toPromise().catch(err => { console.log('default error'); });

    return a;
  }

  async CurrencyGet(branchId: string, langId: string, countryId: number): Promise<CurrencyResponse | void> {
    const a = await this.httpClient
      .get<CurrencyResponse>(`${this.baseUrl}/GetCurrency/${branchId}/${langId}/${countryId}`, {
        headers: new HttpHeaders({
          'Content-Type': 'application/json',
          'Authorization': 'Basic '
        })
      }).pipe(delayedRetryHttp()).toPromise().catch(err => { console.log('default error'); });

    return a;
  }

  // async companyData_Get(branchID: string, currentLang: string, countryCode: string): Promise<Company1Response | void> {
  //   const a = await this.httpClient
  //     .get<Company1Response>(`${this.baseUrl}/GetCompanyInfo/${branchID}/${currentLang}/${countryCode}`, {
  //       headers: new HttpHeaders({
  //         'Content-Type': 'application/json',
  //         'Authorization': 'Basic '
  //       })
  //     }).pipe(delayedRetryHttp()).toPromise().catch(err => { console.log('default error'); });

  //   return a;
  // }


  async GetFooterData(countryCode:string): Promise<FooterDataModel | void> {
    const a = await this.httpClient
      .get<FooterDataModel>(`${this.baseUrl}/GetLatestSearch/${countryCode}`, {
        headers: new HttpHeaders({
          'Content-Type': 'application/json',
          'Authorization': 'Basic '
        })
      }).pipe(delayedRetryHttp()).toPromise().catch(err => { console.log('default error'); });

    return a;
  }

  //New Implementation
  async GetDefualtInitialValues(hostName: string, isCustomer: boolean, languageId: string, countryName:string): Promise<ApiResponseModel<DefaultInitialValues> | void> {
    const url = `${this.baseUrl}/GetDefaultInitialValues/${hostName}/${isCustomer}/${languageId}/${countryName}`;
    try {
      const response = await this.httpClient.get<ApiResponse<DefaultInitialValues>>(url).toPromise();
      return response;
    } catch (error) {
      this.handleError(error);
    }
  }

  async propertiesByAreaFilter_Get(branchId: number, countryCode: string, cuurentLang:string, GroupedBrancheIds: string): Promise<ItemPropByArea[] | void> {
    const a = await this.httpClient
      .get<ItemPropByArea[]>(`${this.baseUrl}/ItemPropByAreaNew_Getlist/${branchId}/${countryCode}/${cuurentLang}/${GroupedBrancheIds}`, {
        headers: new HttpHeaders({
          'Content-Type': 'application/json',
          'Authorization': 'Basic '
        })
      }).pipe(delayedRetryHttp()).toPromise().catch(err => { console.log('default error'); });

    return a;
  }

  // async Agency_GetLogo(branchID: string,langId:number): Promise<AgencyLogoModel | void> {
  //   const a = await this.httpClient
  //     .get<AgencyLogoModel>(`${this.baseUrl}/Agency_Get/${branchID}/${langId}`, {
  //       headers: new HttpHeaders({
  //         'Content-Type': 'application/json',
  //         'Authorization': 'Basic '
  //       })
  //     }).pipe(delayedRetryHttp()).toPromise().catch(err => { console.log('default error'); });

  //   return a;
  // }

  async SendClick(trackClickInfo: TrackClickInfo): Promise<SimpleResponse | void> {
    const a = await this.httpClient
      .post<SimpleResponse>(`${this.baseUrl}/TrackClickInfo`, trackClickInfo, {
        headers: new HttpHeaders({
          'Content-Type': 'application/json',
          'Authorization': 'Basic '
        })
      }).pipe(delayedRetryHttp()).toPromise().catch(err => { console.log('default error'); });

    return a;
  }

  async getDivText(lanngId: number, cmsCompanyId: number): Promise<SimpleRequest | void> {
    const a = await this.httpClient
      .get<SimpleRequest>(`${this.baseUrl}/getDivText/${lanngId}/${cmsCompanyId}`, {
        headers: new HttpHeaders({
          'Content-Type': 'application/json',
          'Authorization': 'Basic '
        })
      }).pipe(delayedRetryHttp()).toPromise().catch(err => { console.log('default error'); });

    return a;
  }

  async GetObjectText(lanngId: number, cmsCompanyId: number): Promise<SimpleResponse | void> {
    const a = await this.httpClient
      .get<SimpleResponse>(`${this.baseUrl}/GetObjectText/${lanngId}/${cmsCompanyId}`, {
        headers: new HttpHeaders({
          'Content-Type': 'application/json',
          'Authorization': 'Basic '
        })
      }).pipe(delayedRetryHttp()).toPromise().catch(err => { console.log('default error'); });

    return a;
  }


  async GetSupportInfo(OID: string): Promise<SimpleResponse | void> {
    const a = await this.httpClient
      .get<SimpleResponse>(`${this.baseUrl}/IsSupportedOmani/${OID}`, {
        headers: new HttpHeaders({
          'Content-Type': 'application/json',
          'Authorization': 'Basic '
        })
      }).pipe(delayedRetryHttp()).toPromise().catch(err => { console.log('default error'); });

    return a;
  }



    //New Apis Structure
    async GetCompanyDefaultInfo(branchId: string, languageId: number): Promise<ApiResponseModel<ComapnyDefaultInfo> | void> {
      const url = `${this.baseUrl}/GetCompanyDefaultInfo/${branchId}/${languageId}`;
      try {
        const response = await this.httpClient.get<ApiResponse<ComapnyDefaultInfo>>(url).toPromise();
        return response;
      } catch (error) {
        this.handleError(error);
      }
    }


    async navigationMenuGet(parent: number, isCustomer: Boolean, companyId: number, langId: number): Promise<ApiResponseModel<NavigationResponse> | void> {
      const url = `${this.baseUrl}/GetNavigationMenus/${parent}/${isCustomer}/${companyId}/${langId}`;
      try {
        const response = await this.httpClient.get<ApiResponseModel<NavigationResponse>>(url).toPromise();
        return response;
      } catch (error) {
        this.handleError(error);
      }
    }

    async Stats_GetAsync(CmsCompID: number, compId: string, currenLang: string, branchId: number, countryCode: string, userId: string, GroupedBrancheIds: string): Promise<ApiResponseModel<AgencyAndStats> | void> {
      
      const url = `${this.baseUrl}/GetHomePageStaticData/${CmsCompID}/${compId}/${currenLang}/${branchId}/${countryCode}/${userId}/${GroupedBrancheIds}`;
      try {
        const response = await this.httpClient.get<ApiResponseModel<AgencyAndStats>>(url).toPromise();
        return response;
      } catch (error) {
        this.handleError(error);
      }
    }


    async GetPropertiesByType(CmsCompID: number, compId: string, currenLang: string, branchId: number, countryCode: string, userId: string, GroupedBrancheIds: string, isAgency: boolean = false): Promise<ApiResponseModel<PropertiesByTypeResponse> | void> {
      const a = await this.httpClient
        .get<ApiResponseModel<PropertiesByTypeResponse>>(`${this.baseUrl}/GetPropertiesByType/${CmsCompID}/${compId}/${currenLang}/${branchId}/${countryCode}/${userId}/${isAgency}/${GroupedBrancheIds}`, {
          headers: new HttpHeaders({
            'Content-Type': 'application/json',
            'Authorization': 'Basic '
          })
        }).pipe(delayedRetryHttp()).toPromise().catch(err => { console.log('default error'); });
  
      return a;
    }


    async GetLatestProperties(CmsCompID: number, compId: string, currenLang: string, branchId: number, countryCode: string, userId: string, GroupedBrancheIds: string, isAgency: boolean = false): Promise<ApiResponseModel<Array<Property>> | void> {
      const a = await this.httpClient
        .get<ApiResponseModel<Array<Property>>>(`${this.baseUrl}/GetLatestProperties/${CmsCompID}/${compId}/${currenLang}/${branchId}/${countryCode}/${userId}/${isAgency}/${GroupedBrancheIds}`, {
          headers: new HttpHeaders({
            'Content-Type': 'application/json',
            'Authorization': 'Basic '
          })
        }).pipe(delayedRetryHttp()).toPromise().catch(err => { console.log('default error'); });
  
      return a;
    }

    async GetDefaultNews(currenLang: string,  countryCode: string, branchId: string): Promise<ApiResponseModel<DefaultNewsModel> | void> {
     
      const a = await this.httpClient
        .get<ApiResponseModel<DefaultNewsModel>>(`${this.baseUrl}/GetDefaultNews/${currenLang}/${countryCode}/${branchId}`, {
          headers: new HttpHeaders({
            'Content-Type': 'application/json',
            'Authorization': 'Basic '
          })
        }).pipe(delayedRetryHttp()).toPromise().catch(err => { console.log('default error'); });
  
      return a;
    }
}


