import { Component, OnDestroy, OnInit } from '@angular/core';
import { FormBuilder, FormGroup, Validators } from '@angular/forms';
import { Router } from '@angular/router';
import { ConfirmBoxInitializer, DialogLayoutDisplay } from '@costlydeveloper/ngx-awesome-popup';
import { isNullOrUndefined } from 'util';
import { Email } from '../../models/EmailContactUs';
import { SimpleResponse } from '../../models/genericResponse.model';
import { InitialValue, InitialValueResponse } from '../../models/initialValue';
import { AuthorizationResponse } from '../../models/login';
import { PropertySearchParams, PropertySearchResults, PropertySearchResultsResponse } from '../../models/property';
import { CompareService } from '../../services/compare.service';
import { ContactusService } from '../../services/contactus.service';
import { DefaultService } from '../../services/default.service';
import { PropertyService } from '../../services/property.service';
import { NotificationService } from '../../shared/notification.service';
import { UtilityService } from '../../shared/utility.service';
import { ConfigurationService } from 'src/app/shared/configuration.service';
declare var $: any;

@Component({
    selector: 'app-myproperties',
    templateUrl: './myproperties.component.html',
    styleUrls: ['./myproperties.component.css']
})
/** myproperties component*/
export class MypropertiesComponent implements OnInit, OnDestroy {

  propertySearchResultsResponse: PropertySearchResultsResponse;
  propertySearchResultsResponsecomp: PropertySearchResultsResponse;
  propertySearchResultsResponsefav: PropertySearchResultsResponse;
  propertySearchResults: PropertySearchResults[];
  propertySearchResultsfav: PropertySearchResults[];
  propertySearchResultsGrid: PropertySearchResults[];
  initialValueResponse: InitialValueResponse;
  initialValue: InitialValue;
  propertySearchParamsVal: PropertySearchParams;
  propertySearchParams: PropertySearchParams;
  propertySearchitem : PropertySearchResults[];
  propertySearchitemfav: PropertySearchResults[];
  simpleResponse: SimpleResponse;
  email: Email;
  formpop: FormGroup;
  langId: number = 1; 
  public countryCode = '973';
  public currencyCode: string = 'BHD';
  userid: string='';
  hostName: string = '';
  token: string;
  submitted = false;
  simpleResponseemil: SimpleResponse;
  authorizationResponse: AuthorizationResponse;
  caid: string = '';
  latestPropertiespop: PropertySearchResults[];
    /** myproperties ctor */
  constructor(private formBuilder: FormBuilder, private _defaultService: DefaultService,
    private notifyService: NotificationService, 
    private _propertyService: PropertyService, 
    private _route: Router,
    private _compare: CompareService, 
    private _utilityService: UtilityService,
    private _contactusService: ContactusService, private _configurationService : ConfigurationService  ) {

  }

  ngOnInit(): void {

    this.formpop = this.formBuilder.group({
      fname: ['', [Validators.required]],
      phone: ['', [Validators.required]],
      email: ['', [Validators.required, Validators.email]],
      comment: ['', [Validators.required]]
    })


    this.langId = this._configurationService.getLanguageValue();
    this.currencyCode = this._configurationService.getSeletedCurrencyCode();
    this.countryCode = this._configurationService.getSeletedCountryCode();
    this.hostName = isNullOrUndefined(localStorage.getItem('hostname')) ? 'aqaraats.com' : localStorage.getItem('hostname');
    if (!isNullOrUndefined(localStorage.getItem("User")))
      this.authorizationResponse = JSON.parse(localStorage.getItem("User"));
    if (!isNullOrUndefined(localStorage.getItem("CAID")))
      this.caid = localStorage.getItem("CAID");

    this.initialValue = this._configurationService.getInitialValues();  
    this.GetInitialValues();
  }

  ngOnDestroy(): void {
    $('#myModal').modal('hide');
  }

  GetInitialValues() {
    this.GetCompareStatus("1", "100", true, "2", this.langId.toString(), 0);
    this.GetFavStatus("1", "100", true, "1", this.langId.toString(), 0);        
    this.GetPropertyData('0');
  }

  GetPropertyData(sortBy) {
    let isViewAll = 0;
    this.propertySearchParamsVal = this.propertySearchParams = {
      branchId: this.initialValue?.branchID,
      countryCode: this.countryCode,
      contactId: this.authorizationResponse.contactID.toString(),
      currentLang: this.langId,
      cAID: +this.caid,
      groupedBranches: this.initialValue?.groupedBranchesId, page: '',
      sort: '', type: '', limit: '6', searchTerm: '', propertyType2: '',
      propertyType3: '', propertyLocation: '', minArea: '', maxArea: '', minPrice: '',
      maxPrice: '', features: '', minBeds: '', maxBeds: '', minBaths: '', maxBaths: '', electricity: '',
      Furnicher: '', count: true, nonVacant: '', agentID: '', parentID: '', myProperty: true,
      currencyDetails: {
        selectedCcyCode: '',
        selectedCcyName: '',
        selectedCcySwift: '',
        selectedCcySwiftCodeArb: '',
        selectedCcySwiftCode: '',
        selectedCcyDecimal: '',
      }
    };


    this._propertyService.Property_SearchAsync(isViewAll,sortBy,this.propertySearchParamsVal).then((result) => {
      if (result) {
        this.propertySearchResultsResponse = UtilityService.clone(result);
        this.propertySearchResultsGrid = this.propertySearchResultsResponse.propertySearchResults;
       
      }
    });


  }

  sendClick(propid) {
    this._utilityService.sendClickInfo("2", propid, "", this.initialValue?.branchID, "", this.countryCode);
  }
  addCallNumber(mobile, i, propid, price,agentId,saleOrRent,branchID) {
    $('#ane_' + i).hide();
    $('#an_' + i).show();
    $('#sp_' + i).html(mobile);
    this._utilityService.sendClickInfo("3", propid, "",branchID,"",this.countryCode,"", price,agentId,saleOrRent,this.initialValue?.branchID.toString());
  }
  addCallagentNumber(mobile, i, propid,price,agentId,saleOrRent,branchId) {
    $('#ane_' + i).show();
    $('#an_' + i).hide();
    $('#sp_' + i).html('');
    this._utilityService.sendClickInfo("3", propid, "",branchId,"",this.countryCode,"",price,agentId,saleOrRent,this.initialValue?.branchID.toString());
  }

  getpropertybyId(id) {
    this.latestPropertiespop = this.propertySearchResultsGrid.filter(x => x.id == id);
   
  }

  ecodeuri(url: string, fromtype) {
    var urlval = url.replace('&type', '').split('=');
    if (url.indexOf("?") !== -1) {
      const queryParams = { 'id': urlval[1] };
  
      if (fromtype == '1') {
        queryParams['fromtype'] = fromtype;
      }
      this._route.navigate(['/propertydetail'], { queryParams });
    }
    else{
      this._route.navigate([decodeURI("/" + url)]);
    }
  }

  GetCompareStatus(page: string, limit: string, count: boolean, type: string, currentlang: string, Id) {
    this._compare.Compare_GetAsync(page, limit, count, type, this.initialValue.branchID, currentlang, this.currencyCode, this.initialValue.groupedBranchesId).then((result) => {
      if (result) {
        this.propertySearchResultsResponsecomp = UtilityService.clone(result);
        if (!isNullOrUndefined(this.propertySearchResultsResponsecomp)) {
          this.propertySearchitem = this.propertySearchResultsResponsecomp.propertySearchResults;
          //let ps = this.propertySearchitem.filter(c=>c.);
          //this.ClearCopare(propertyId, type)
          this.propertySearchResults = this.propertySearchitem;
          if (this.propertySearchResults.length > 0)
            this.loadFavComp(Id, type);
        }
      }
    });
  }
  GetFavStatus(page: string, limit: string, count: boolean, type: string, currentlang: string, Id) {
    this._compare.Compare_GetAsync(page, limit, count, type, this.initialValue.branchID, currentlang, this.currencyCode, this.initialValue.groupedBranchesId).then((result) => {
      if (result) {
        this.propertySearchResultsResponsefav = UtilityService.clone(result);
        if (!isNullOrUndefined(this.propertySearchResultsResponsefav)) {
          this.propertySearchitemfav = this.propertySearchResultsResponsefav.propertySearchResults;
          this.propertySearchResultsfav = this.propertySearchitemfav;
          if (this.propertySearchResultsfav.length > 0) {
            this.loadFavComp(Id, type);
          }
        }

      }
    });
  }

  ClearCopare(id, type) {
    this._compare.Clear_GetAsync(id, type, this.initialValue.branchID, this.langId.toString(), false).then((result) => {
      if (result) {
        if (type == '2') {
          this.simpleResponse = UtilityService.clone(result);
          this.GetCompareStatus("1", "100", true, "2", this.langId.toString(), 0);
        }
        else {
          this.simpleResponse = UtilityService.clone(result);

        }
      }
    });
  }

  async confirmBox(Id, type, ptype, index) {
    const confirmBox = new ConfirmBoxInitializer();
    confirmBox.setTitle('');
    confirmBox.setMessage('There are properties of different type in your compare list do you want to clear it to be able to proceed?');
    confirmBox.setButtonLabels('YES', 'NO');

    // Choose layout color type
    confirmBox.setConfig({
      layoutType: DialogLayoutDisplay.DANGER, // SUCCESS | INFO | NONE | DANGER | WARNING
    });

    // Simply open the popup and listen which button is clicked
    confirmBox.openConfirmBox$().subscribe(resp => {
      if (resp.success) {
        if (!isNullOrUndefined(this.propertySearchitem)) {
          let totpropfilter = this.propertySearchitem.filter(x => x.id != Id);
          this.ClearCopare(Id, type);
          totpropfilter.forEach((currentValue, index) => {
            $('#camp_' + currentValue.id).removeClass('active-icon');
          });

        }
      }
      else {
        //this.ClearCopare(Id, type);
      }
      // IConfirmBoxPublicResponse
    });

    return confirm;
  }


  loadFavComp(Id, type) {
    if (type == 1) {
      this.propertySearchResultsfav.forEach((currentValue, index) => {
        if (currentValue.isFavorite)
          $('#fav_' + currentValue.id).addClass('active-icon');
      });

    }
    else if (type == 2) {
      this.propertySearchResults.forEach((currentValue, index) => {
        if (currentValue.isCompare)
          $('#camp_' + currentValue.id).addClass('active-icon');

      });

    }
  }

  async GetCompare(Id, type, index, ptype) {
    let addfav = 1;
    if (type == 1) {
      if (!isNullOrUndefined(this.propertySearchResultsfav)) {
        let fp = this.propertySearchResultsfav.filter(p => p.id == Id && p.isFavorite == true)
        if (fp.length > 0) {
          addfav = 0;
          $('#fav_' + Id).removeClass('active-icon');
        }
      }
    }
    else {
      if (!isNullOrUndefined(this.propertySearchResults)) {
        let cmp = this.propertySearchResults.filter(p => p.id == Id && p.isCompare == true)
        if (cmp.length > 0) {
          addfav = 0;
          $('#camp_' + Id).removeClass('active-icon');
        }
      }
    }
    this._compare.CompareSubmit(Id, addfav, type, this.initialValue.branchID, this.langId.toString()).then((result) => {
      if (result) {

        this.simpleResponse = UtilityService.clone(result);
        if (isNullOrUndefined(this.simpleResponse.messageString)) {
          if (type == 2)
            this.GetCompareStatus("1", "100", true, type, this.langId.toString(), Id);
          else
            this.GetFavStatus("1", "100", true, type, this.langId.toString(), Id);

        }
        else {
          this.confirmBox(Id, type, ptype, index);
          //this.notifyService.confirmBox('','There are properties of different type in your compare list do you want to clear it to be able to proceed?');
        }
      }
    })
  }

  prepareEmail(aname, aemail, pid, pcode, ptype, aarea, curl, minPrice, maxPrice, agentId, typeOfRequest, saleOrRent) {

    return this.email = {
      CmName: this.formpop.controls["fname"].value,
      CmEmail: this.formpop.controls["email"].value,
      CmPhone: this.formpop.controls["phone"].value.number,
      AgentName: aname,
      AgentEmail: aemail,
      PropertyID: pid,
      PropertyCode: pcode,
      PropertyType: ptype,
      PropertyArea: aarea,
      ClientURL: curl,
      Referrer: '',
      branchId: this.initialValue.branchID.toString(),
      selectedSwiftCode: this.currencyCode,
      currentLangId: this.langId.toString(),
      recapchaResponse: this.token,
      message: this.formpop.controls["comment"].value,
      subscription: true,
      loginId: '1',
      minPrice:minPrice,
      maxPrice:maxPrice,
      agentId:agentId,
      typeOfRequest:"1",
      saleOrRent:saleOrRent,
      subBranch:this.initialValue.branchID.toString()
    }
  }

  submit(aname, aemail, pid, pcode, ptype, aarea, curl,price,agentId,saleOrRent) {
    this.submitted = true;
    this.prepareEmail(aname, aemail, pid, pcode, ptype, aarea, curl,price,price,agentId,1,saleOrRent);
    if (this.formpop.invalid) {
      return;
    }
    this._contactusService.SenemailSubmit(this.prepareEmail(aname, aemail, pid, pcode, ptype, aarea, curl,price,price,agentId,1,saleOrRent)).then((result) => {
      if (result) {
        this.simpleResponseemil = UtilityService.clone(result);
        if (this.simpleResponseemil.messageBool) {
          this.notifyService.showSuccess("Mail send successfully !!", "")         
        }
        else
          this.notifyService.showSuccess("some thing went wrong.", "");

      }
    });
  }

  onRecaptchaSuccess(token: string) {
    this.token = token;
  }
}
