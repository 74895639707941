import { AfterViewInit, Component, ElementRef, ChangeDetectorRef, Input, OnInit, ViewChild } from '@angular/core';
import { FormBuilder, FormGroup, Validators } from '@angular/forms';
import { Router } from '@angular/router';
import { CountryISO, SearchCountryField } from 'ngx-intl-tel-input';
import { Email,LubanEmail } from 'src/app/models/EmailContactUs';
import { SimpleResponse } from 'src/app/models/genericResponse.model';
import { InitialValue } from 'src/app/models/initialValue';
import { ItemPropByArea, PropertiesByTypeResponse, Property, PropertySearchParams, PropertySearchResults } from 'src/app/models/property';
import { ContactusService } from 'src/app/services/contactus.service';
import { FavoriteService } from 'src/app/services/favorite.service';
import { ConfigurationService } from 'src/app/shared/configuration.service';
import { NotificationService } from 'src/app/shared/notification.service';
import { UtilityService } from 'src/app/shared/utility.service';
import { isNullOrUndefined } from 'util';
import { Select2OptionData } from 'ng-select2';
import { Options } from 'select2';
import { NgSelect2Component } from 'ng-select2';
import { DefaultService } from 'src/app/services/default.service';
import { NgbModal } from '@ng-bootstrap/ng-bootstrap';
import { ContactModalComponent } from '../contact-modal/contact-modal.component';

declare var $: any;
declare var bootstrap: any;
@Component({
  selector: 'app-agency-properties-tooltip-listing',
  templateUrl: './agency-properties-tooltip-listing.component.html',
  styleUrls: ['./agency-properties-tooltip-listing.component.css']
})
export class AgencyPropertiesTooltipListingComponent implements OnInit, AfterViewInit {
  @ViewChild('property') propertyTab: ElementRef;

  @ViewChild('propertyByType') propertyByTypeTab: ElementRef;

  @Input() latestProperties: Property[];
  @Input() PropertyByType: PropertiesByTypeResponse;
  isLoLoding:boolean = true

  tabType: string = '';
  vacantType: string = '';
  limittype: string = '4000';
  langId: number = 1;
  isShowDivFilter: boolean = false;
  isShowDivCompound: boolean = false;
  isShowDivOffice: boolean = false;
  isShowDivResort: boolean = false;
  propertySearchParams: PropertySearchParams;
  initialValue: InitialValue;
  public currencyCode: string = 'BHD';
  public countryCode = '973';
  isShow: boolean = false;
  latestPropertiespop: Property;
  placetext: string = '';
  formpop: FormGroup;
  formLubanPop: FormGroup;
  propertySearchResultsComp: PropertySearchResults[];
  propertySearchResultsfav: PropertySearchResults[];
  submitted: boolean = false;
  email: Email;
  lubanEmail: LubanEmail;
  token: string;
  simpleResponseemil: SimpleResponse
  CountryISO = CountryISO;
  preferredCountries: CountryISO[] = [CountryISO.Bahrain];
  SearchCountryField = SearchCountryField;
  isDetailsIcon: boolean = true;
  orignalVal: Property[];
  isShowproperty: boolean = false;
  isShowCompounds:boolean = false
  currentURL: string = window.location.href;
  lastIndex: number = this.currentURL.lastIndexOf("/");
  baseURL: string = (this.lastIndex !== -1) ? this.currentURL.substr(0, this.lastIndex) : this.currentURL;
  url = `${this.baseURL }/propertydetail?id=`
  disableSubmit:boolean = false;
  tabTypeSelected: string = '';
  propertiesByAreaCount: ItemPropByArea[]
  isLatestProperties: boolean = true;
  isLuban: boolean = this._configurationService.isLuban;
  nationalityTypes: any[] ;
  nationalityOptions: any;
  nationalityValue: string = '';

  keysArray: string[]  = [];

  data:Property[];
  dataDictionary: Map<string, Property[]> = new Map<string, Property[]>();
  isSupported = false;
  supportInfo : any = null;
  myProperty: Property = null;

  constructor(private _configurationService:ConfigurationService, private _route: Router, 
    private formBuilder: FormBuilder, private _defaultService: DefaultService ,private _favoriteService :FavoriteService,
     private _contactusService: ContactusService, private notifyService: NotificationService, private _utilityService:UtilityService,
     private cdRef: ChangeDetectorRef, private modalService: NgbModal) { }

     ngAfterViewInit(): void {
      
      
      if(this.latestProperties?.length > 0 && this.propertySearchResultsfav?.length > 0){
        this.highLightFav(this.propertySearchResultsfav,true);
      }

      if(this.latestProperties?.length > 0 && this.propertySearchResultsComp?.length > 0){
        this.highLightFav(this.propertySearchResultsComp, false);
      }

    }

    ngOnDestroy(): void {
      $('#registerFormModal')?.modal('hide');
    }

    get f2() {
      return this.formpop.controls;
    }

    get f3(){
      return this.formLubanPop.controls;
    }

  ngOnInit(): void {
    this.langId =  this._configurationService.getLanguageValue();
    this.initialValue = this._configurationService.getInitialValues();
    this.countryCode = this._configurationService.getSeletedCountryCode();
    this.currencyCode = this._configurationService.getSeletedCurrencyCode();
    this.formpop = this.formBuilder.group({
      fname: ['', [Validators.required]],
      phone: ['', [Validators.required]],
      email: ['', [Validators.required, Validators.email]],
      comment: ['', [Validators.required]]
    })
    this.formLubanPop = this.formBuilder.group({
      oid: ['', [Validators.required]],
      omaniNationality: ['0', [Validators.required]],
      address: ['', [Validators.required]],
      fname: ['', [Validators.required]],
      phone: ['', [Validators.required]],
      email: ['', [Validators.required, Validators.email]],
      comment: ['', [Validators.required]]
    })
    
      if(this.latestProperties?.length > 0){
        this.isLoLoding = false
      }

      this.checkLatestPropertiesType();
      if(this.PropertyByType != null){
        this.dataDictionary = this.PropertyByType?.propertiesByType;
        if(!isNullOrUndefined (this.dataDictionary)){
          //according to Hani this is not needed currently
          //this.keysArray.push(...Object.keys(this.dataDictionary));
        }

      }


    this._favoriteService.propertySearchResultsfavSubject.subscribe(resp=>{
      ;
      if(resp != null){
        this.propertySearchResultsfav = resp
        if(this.latestProperties?.length > 0 && this.propertySearchResultsfav?. length > 0){
          this.highLightFav(this.propertySearchResultsfav, true);
        }
      }

    })

    this._favoriteService.propertySearchResultsCompareSubject.subscribe(resp=>{
      
      if(resp != null){
        this.propertySearchResultsComp = resp
        if(this.latestProperties?.length > 0 && this.propertySearchResultsComp?. length > 0){
          this.highLightFav(this.propertySearchResultsComp, false);
        }
      }

    })

    if(this.keysArray.length >0){
      this.ViewAllData(this.keysArray[0],1);

    }

    this.nationalityTypes = [
      { text: this.langId == 1 ? 'Omani':'عماني', id: '1' },
      { text: this.langId == 1 ? 'None Omani' : 'غير عماني', id: '2' }
    ];

    this.nationalityOptions = {
      placeholder: this.langId == 1 ?'Nationality' : 'الجنسية',
      width: '100%',
      // Other options...
    }
    
  }

  successfulRegister: boolean = false;
  notSuccessfulRegister: boolean = false;

  onOIDChange(oid: string){
    this.isSupported = false;
    this.successfulRegister = false;
    this.notSuccessfulRegister = false;
    this.supportInfo = null;
    this.formLubanPop.get('address').setValue('');
    this.formLubanPop.get("fname").setValue('');
    this.formLubanPop.get('address').enable();
    this.formLubanPop.get("fname").enable();

    if(this.nationalityValue == "1" && oid.length > 6 ){
      this._defaultService.GetSupportInfo(oid).then((result : any) => {
        if (result) {
          this.isSupported = true;
          this.supportInfo = result;
          // this.notifyService.showSuccess(this.langId == 1 ? "You are among the list of eligible people" : "انت من ضمن قائمة المستحقين", "");
          


          this.formLubanPop.get("fname").setValue(result?.Name.split(' ')[0]);
          this.formLubanPop.get("address").setValue(result?.Area);
          this.formLubanPop.get('address').disable();
          this.formLubanPop.get("fname").disable();
          
          if(result?.Mobile){
            this.formLubanPop.get("phone").setValue(result?.Mobile);
            this.cdRef.detectChanges();
          }

          this.successfulRegister = true;
         this.notSuccessfulRegister = false;
          
        } else{
          this.isSupported = false;
          this.successfulRegister = false;
         this.notSuccessfulRegister = true;
        }
      });

    } else {
      this.successfulRegister = false;
      this.notSuccessfulRegister = false;
    }
  }
  
  checkLatestPropertiesType(){
    
    const hasRentalProperties = this.latestProperties?.find(x=>x.type?.id == "13");
    const hasSaleProperties = this.latestProperties?.find(x=>x.type?.id == "14");

    if(hasRentalProperties && hasSaleProperties){
      this.keysArray.push(this.langId == 1 ? "All": "الكل")
      this.keysArray.push(this.latestProperties?.find(x=>x.type?.id == "13").type?.text);
      this.keysArray.push(this.latestProperties?.find(x=>x.type?.id == "14").type?.text);
      
      // this.keysArray.push("All","Rental","Sales")
    }else if(hasRentalProperties){
      this.keysArray.push(this.latestProperties[0]?.type?.text);
    }else if(hasSaleProperties){
      this.keysArray.push(this.latestProperties[0]?.type?.text);
    }

  }

  async GetCompare(Id, type, index, ptype) {
    
    let addfav = 1;
    if (type == 1) {
      if (!isNullOrUndefined(this.propertySearchResultsfav)) {
        let fp=this.propertySearchResultsfav.filter(p => p.id == Id && p.isFavorite==true)
        if (fp.length > 0) {
          addfav = 0;
          this.latestProperties?.map(x=>x.id == Id ? x.favorite = false : x.favorite = x.favorite);
          // $('#fav_' + Id).removeClass('active-icon');
        }
      }     
    }
    else {
      if (!isNullOrUndefined(this.propertySearchResultsComp)) {
        let cmp = this.propertySearchResultsComp.filter(p => p.id == Id && p.isCompare == true)
        if (cmp.length > 0) {
          addfav = 0;
          this.latestProperties?.map(x=>x.id == Id ? x.compare = false : x.compare = x.compare);
          // $('#camp_' + Id).removeClass('active-icon');
        }
      }
    }

    this._favoriteService.addToFavoriteOrCompare(Id,addfav,type,this.initialValue?.branchID, this.langId.toString(), this.initialValue?.currencyDetail?.selectedCcyCode, this.initialValue?.groupedBranchesId,ptype,index);
  } 

  highLightFav(propertySerchResult:PropertySearchResults[], isFavoirit){
    
    if(propertySerchResult?.length > 0 && propertySerchResult != null){
      propertySerchResult.forEach(item=>{
        if(isFavoirit){
          this.latestProperties.map(x=>x.id == item.id ? (x.favorite = item.isFavorite) : (x.favorite = x.favorite) )
        }else{
          this.latestProperties.map(x=>x.id == item.id ? (x.compare = item.isCompare) : (x.compare = x.compare) )
        }
      })
    }
  }

  toggleLocationDivFilter() {
    this.isShowDivFilter = !this.isShowDivFilter;
  }
  ViewAllData(typevalue, vacant) {
    
      switch (typevalue){
        case "All":
          this.data = this.latestProperties;
          this.isLatestProperties = true
          this.propertyByTypeTab?.nativeElement?.classList?.remove("active");
          this.propertyTab?.nativeElement?.classList?.add("active")
          this.tabType = ""
        break;
        case "الكل":
          this.data = this.latestProperties;
          this.isLatestProperties = true
          this.propertyByTypeTab?.nativeElement?.classList?.remove("active");
          this.propertyTab?.nativeElement?.classList?.add("active")
          this.tabType = ""
        break;
        
        default:
          if(this.latestProperties?.some(x=>x.type?.text == typevalue)){
            this.data = this.latestProperties.filter(x=>x.type?.text == typevalue);
            this.isLatestProperties = true
            this.tabTypeSelected = this.latestProperties.find(x=>x.type?.text == typevalue)?.type?.id
            this.propertyByTypeTab?.nativeElement?.classList?.remove("active");
            this.propertyTab?.nativeElement?.classList?.add("active")
            this.tabType = ""
          }else{
            this.data =  this.PropertyByType.propertiesByType[typevalue];
            this.isLatestProperties = false;
            if(this.data?.length > 0){
              this.tabTypeSelected =  "";
              this.tabType = this.data[0].type2Id;
              this.propertyByTypeTab?.nativeElement?.classList?.add("active");
              this.propertyTab?.nativeElement?.classList?.remove("active")
            }
          }

          break;
      }

    this.vacantType = vacant;
    if (this.tabType == '' || this.tabType == '13' || this.tabType == '14')
      this.limittype = '4000';
    else
      this.limittype = '50';
  }

  toggleLocationCompound() {
    this.isShowDivCompound = !this.isShowDivCompound;
  }

  toggleLocationOffice() {
    this.isShowDivOffice = !this.isShowDivOffice;
  }

  toggleLocationResort() {
    this.isShowDivResort = !this.isShowDivResort;
  }

  
  ShowDetail(id) {
    if (id == '1')
      this.isDetailsIcon = true;
    else
      this.isDetailsIcon = false;
    if(id="3"){
      this.isShowCompounds = true;
    }
  }

  propertyAll() {
    localStorage.setItem('Qsearch', JSON.stringify(this.prepareFormSearchAll()));
    this._route.navigate(['/properties/0/0']);

  }

  prepareFormSearchAll() {
    
    return this.propertySearchParams = {
      branchId: this.initialValue.branchID,
      countryCode: this.countryCode,
      contactId: '0', currentLang: this.langId, cAID: 0,
      groupedBranches: this.initialValue.groupedBranchesId, page: '',
      sort: '', type: this.tabTypeSelected, limit: this.limittype,
      searchTerm: '',
      propertyType2: this.tabType,
      propertyType3: '',
      propertyLocation: '',
      minArea: '',
      maxArea: '',
      minPrice: '',
      maxPrice: '', features: '',
      minBeds: '',
      maxBeds: '',
      minBaths: '',
      maxBaths: '',
      electricity: '',
      Furnicher: '', count: true,
      nonVacant: this.vacantType,
      agentID: '', parentID: '',
      myProperty: false,
      currencyDetails: {
        selectedCcyCode: '',
        selectedCcyName: '',
        selectedCcySwift: '',
        selectedCcySwiftCodeArb: '',
        selectedCcySwiftCode: '',
        selectedCcyDecimal: '',
      }
    }
  }

  prepareFormSearchAllFix() {
    return this.propertySearchParams = {
      branchId: 14,
      countryCode: this.countryCode,
      contactId: '0', currentLang: this.langId, cAID: 0,
      groupedBranches: '', page: '',
      sort: '', type: '', limit: '4000',
      searchTerm: '',
      propertyType2: '',
      propertyType3: '',
      propertyLocation: '',
      minArea: '',
      maxArea: '',
      minPrice: '',
      maxPrice: '', features: '',
      minBeds: '',
      maxBeds: '',
      minBaths: '',
      maxBaths: '',
      electricity: '',
      Furnicher: '', count: true,
      nonVacant: '',
      agentID: '', parentID: '',
      myProperty: false,
      currencyDetails: {
        selectedCcyCode: '',
        selectedCcyName: '',
        selectedCcySwift: '',
        selectedCcySwiftCodeArb: '',
        selectedCcySwiftCode: '',
        selectedCcyDecimal: '',
      }
    }
  }
  ecodeuri(event,id, fromtype) {
    event?.preventDefault();
    const queryParams = { id };
  
    if (fromtype == '1') {
      queryParams['fromtype'] = fromtype;
    }
  
    this._route.navigate(['/propertydetail'], { queryParams });
  }


  locationSearch(value) {
    this._route.navigate(['/properties/0/' + value]);
  }

  getpropertybyId(id, agencyId) {
    this._utilityService.sendClickInfo("1", id, "", this.initialValue?.branchID, agencyId,this.countryCode);
    this.latestPropertiespop = this.latestProperties.filter(x => x.id == id)[0];

    const modalRef = this.modalService.open(ContactModalComponent, { windowClass: 'custom-modal' });
    modalRef.componentInstance.myProperty = this.latestPropertiespop;

    
  }


  prepareEmail(aname, aemail, pid, pcode, ptype, aarea, curl, minPrice, maxPrice, agentId, typeOfRequest, saleOrRent,branchId) {

    return this.email = {
      CmName: this.formpop.controls["fname"].value,
      CmEmail: this.formpop.controls["email"].value,
      CmPhone: this.formpop.controls["phone"].value?.dialCode + this.formpop.controls["phone"].value?.number,
      AgentName: aname,
      AgentEmail: aemail,
      PropertyID: pid,
      PropertyCode: pcode,
      PropertyType: ptype,
      PropertyArea: aarea,
      ClientURL: curl,
      Referrer: '',
      branchId: branchId,
      selectedSwiftCode: this.langId == 1 ? this.initialValue?.currencyDetail?.selectedCcySwiftCode : this.initialValue?.currencyDetail?.selectedCcySwiftCodeArb,
      currentLangId: this.langId.toString(),
      recapchaResponse: this.token,
      message: this.formpop.controls["comment"].value,
      subscription: true,
      loginId: '1',
      minPrice:minPrice,
      maxPrice:maxPrice,
      agentId:agentId,
      typeOfRequest:"1",
      saleOrRent:saleOrRent,
      subBranch:this.initialValue?.branchID.toString()
    }
  }

  prepareEmailLuban(aname, aemail, pid, pcode, ptype, aarea, curl, minPrice, maxPrice, agentId, typeOfRequest, saleOrRent,branchId) {

    return this.lubanEmail = {
      CmName: this.formLubanPop.controls["fname"].value,
      CmAddress: this.formLubanPop.controls["address"].value,
      Governorate: this.supportInfo ? this.supportInfo.Governorate : '',
      CmOmani: this.formLubanPop.controls["omaniNationality"].value == "1",
      CmOID: this.formLubanPop.controls["oid"].value,
      CmEmail: this.formLubanPop.controls["email"].value,
      CmPhone: this.formLubanPop.controls["phone"].value?.dialCode + this.formLubanPop.controls["phone"].value?.number,
      AgentName: aname,
      AgentEmail: aemail,
      AgentMobile: "",
      PropertyID: pid,
      PropertyName: this.myProperty.name,
      PropertyCode: pcode,
      PropertyType: ptype,
      PropertyArea: aarea,
      PropertyComplex: '',
      ClientURL: curl,
      Referrer: '',
      branchId: branchId,
      selectedSwiftCode: this.langId == 1 ? this.initialValue?.currencyDetail?.selectedCcySwiftCode : this.initialValue?.currencyDetail?.selectedCcySwiftCodeArb,
      currentLangId: this.langId.toString(),
      recapchaResponse: this.token,
      message: this.formLubanPop.controls["comment"].value,
      subscription: true,
      loginId: '1',
      minPrice:minPrice,
      maxPrice:maxPrice,
      agentId:agentId,
      typeOfRequest:"1",
      saleOrRent:saleOrRent,
      subBranch:this.initialValue?.branchID.toString()
    }
  }


  onRecaptchaSuccess(token: string) {
    this.token = token;
  }

  submit(aname, aemail, pid, pcode, ptype, aarea, curl,price,agentId,saleOrRent,branchId) {
    
    this.disableSubmit = true;
    this.submitted = true;
    this.prepareEmail(aname, aemail, pid, pcode, ptype, aarea, curl,price,price,agentId,1,saleOrRent,branchId);
    if (this.formpop.invalid) {
      this.disableSubmit = false;
      return;
    }
    this._contactusService.SenemailSubmit(this.prepareEmail(aname, aemail, pid, pcode, ptype, aarea, curl,price,price,agentId,1,saleOrRent,branchId)).then((result) => {
      if (result) {
        this.disableSubmit = false;
        this.simpleResponseemil = UtilityService.clone(result);
        if (this.simpleResponseemil.messageBool) {
          this.notifyService.showSuccess("Mail send successfully !!", "")
          this.formpop?.reset();
          let model = $('#registerFormModal') 
          model?.modal('hide');
          //$('#registerFormModal').hide();
          //$('.modal-backdrop').remove('modal-backdrop');
        }
        else
          this.notifyService.showSuccess("some thing went wrong.", "");
        
      }else{
        this.disableSubmit = false;
      }
    });
  }

  submitLuban(aname, aemail, pid, pcode, ptype, aarea, curl,price,agentId,saleOrRent,branchId) {
    
    this.disableSubmit = true;
    this.submitted = true;
    //this.prepareEmail(aname, aemail, pid, pcode, ptype, aarea, curl,price,price,agentId,1,saleOrRent,branchId);
    if (this.formLubanPop.invalid) {
      this.disableSubmit = false;
      return;
    }
    this._contactusService.SenemailSubmit(this.prepareEmailLuban(aname, aemail, pid, pcode, ptype, aarea, curl,price,price,agentId,1,saleOrRent,branchId)).then((result) => {
      if (result) {
        this.disableSubmit = false;
        this.submitted = false;
        this.simpleResponseemil = UtilityService.clone(result);
        if (this.simpleResponseemil.messageBool) {
          this.notifyService.showSuccess("Reversation request was sent successfully", "تم إرسال طلب الحجز بنجاح")
          this.formLubanPop.get('address').enable();
          this.formLubanPop.get("fname").enable();
          this.formLubanPop?.reset();
          let model = $('#registerFormModal') 
          model?.modal('hide');
          //$('#registerFormModal').hide();
          //$('.modal-backdrop').remove('modal-backdrop');
        }
        else
          this.notifyService.showSuccess("some thing went wrong.", "");
        
      }else{
        this.disableSubmit = false;
      }
    });
  }


  addCallNumber(mobile, i, propid, agencyId, price, agentId, saleOrRent,branchId) {
    
    $('#ane_' + i).hide();
    $('#an_' + i).show();
    $('#sp_' + i).html(mobile);
    this._utilityService.sendClickInfo("3", propid, "",branchId , agencyId, this.countryCode,"",price, agentId, saleOrRent,this.initialValue?.branchID.toString());
  }
  addCallagentNumber(mobile, i, propid, agencyId,price, agentId, saleOrRent,branchId) {
    
    $('#ane_' + i).show();
    $('#an_' + i).hide();
    $('#sp_' + i).html('');
    this._utilityService.sendClickInfo("3", propid, "", branchId, agencyId,this.countryCode,"",price, agentId, saleOrRent,this.initialValue?.branchID.toString());
  }

  sendClick(event, type, ID, agencyId){
    if(type == 1){
      event?.preventDefault();
    }
    this._utilityService.sendClickInfo(type, ID, "", this.initialValue?.branchID, agencyId,this.countryCode);
  }

  locationSearchBuilding(value, loc) {
    let propId = '';
    // this.buildingProperties.forEach((currentValue, index) => {
    //   if (currentValue.type2Id == loc)
    //     propId += currentValue.type2Id + ',';
    // });   

    this.propertySearchParams = {
      branchId: this.initialValue.branchID,
      countryCode: this.countryCode,
      contactId: '0', currentLang: this.langId, cAID: 0,
      groupedBranches: this.initialValue.groupedBranchesId, page: '',
      sort: '', type: '', limit: '50',
      searchTerm: '',
      propertyType2: loc,
      propertyType3: '',
      propertyLocation: value,
      minArea: '',
      maxArea: '',
      minPrice: '',
      maxPrice: '', features: '',
      minBeds: '',
      maxBeds: '',
      minBaths: '',
      maxBaths: '',
      electricity: '',
      Furnicher: '', count: true,
      nonVacant: '1',
      agentID: '', parentID: '',
      myProperty: false,
      currencyDetails: {
        selectedCcyCode: '',
        selectedCcyName: '',
        selectedCcySwift: '',
        selectedCcySwiftCodeArb: '',
        selectedCcySwiftCode: '',
        selectedCcyDecimal: '',
      }
    }
    localStorage.setItem('Qsearch', JSON.stringify(this.propertySearchParams));
    this._route.navigate(['/properties/0/0']);
  }

  openRegisterModal() {
    this.formLubanPop.get('address').setValue('');
    this.formLubanPop.get("fname").setValue('');
    this.formLubanPop.get("oid").setValue('');
    this.formLubanPop.get("omaniNationality").setValue('0');
    this.formLubanPop.get("phone").setValue('');
    this.formLubanPop.get("email").setValue('');
    this.formLubanPop.get("comment").setValue('');

    const bootstrapModalInstance = new bootstrap.Modal(document.querySelector('#registerFormModal'), {
      backdrop: true
    });

    bootstrapModalInstance.show();
  }





}
