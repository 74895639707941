import { Injectable } from '@angular/core';
import { Select2OptionData } from 'ng-select2';
import { Observable } from 'rxjs';

@Injectable({
  providedIn: 'root',
})
export class DataService {
  getpriceList(langid): Observable<Array<Select2OptionData>> {
    return Observable.create((obs) => {
      obs.next([
        {
          "id": " ",
          "text": langid == '1' ? "Select Min Price" :'إختر السعر الأدنى'
        },
        {
          "id": 100,
          "text": "100"
        },

        {
          "id": 200,
          "text": "200"
        },
        {
          "id": 300,
          "text": "300"
        },
        {
          "id": 400,
          "text": "400"
        },
        {
          "id": 500,
          "text": "500"
        },
        {
          "id": 600,
          "text": "600"
        },
        {
          "id": 700,
          "text": "700"
        },
        {
          "id": 800,
          "text": "800"
        },
        {
          "id": 900,
          "text": "900"
        },
        {
          "id": 1000,
          "text": "1,000"
        },
        {
          "id": 2000,
          "text": "2,000"
        },
        {
          "id": 3000,
          "text": "3,000"
        },
        {
          "id": 4000,
          "text": "4,000"
        },
        {
          "id": 5000,
          "text": "5,000"
        },
        {
          "id": 6000,
          "text": "6,000"
        },
        {
          "id": 7000,
          "text": "7,000"
        },
        {
          "id": 8000,
          "text": "8,000"
        },
        {
          "id": 9000,
          "text": "9,000"
        },
        {
          "id": 10000,
          "text": "10,00"
        },
        {
          "id": 20000,
          "text": "20,000"
        },
        {
          "id": 30000,
          "text": "30,000"
        },
        {
          "id": 40000,
          "text": "40,000"
        },
        {
          "id": 50000,
          "text": "50,000"
        },
        {
          "id": 60000,
          "text": "60,000"
        },
        {
          "id": 70000,
          "text": "70,000"
        },
        {
          "id": 80000,
          "text": "80,000"
        },
        {
          "id": 90000,
          "text": "90,000"
        },
        {
          "id": 100000,
          "text": "100,000"
        },
        {
          "id": 150000,
          "text": "150,000"
        },
        {
          "id": 200000,
          "text": "200,000"
        },
        {
          "id": 250000,
          "text": "250,000"
        },
        {
          "id": 300000,
          "text": "300,000"
        },
        {
          "id": 350000,
          "text": "350,000"
        },
        {
          "id": 400000,
          "text": "400,000"
        },
        {
          "id": 450000,
          "text": "450,000"
        },
        {
          "id": 500000,
          "text": "500,000"
        },
        {
          "id": 550000,
          "text": "550,000"
        },
        {
          "id": 600000,
          "text": "600,000"
        },
        {
          "id": 650000,
          "text": "650,000"
        },
        {
          "id": 700000,
          "text": "700,000"
        },
        {
          "id": 750000,
          "text": "750,000"
        },
        {
          "id": 800000,
          "text": "800,000"
        },
        {
          "id": 850000,
          "text": "850,000"
        },
        {
          "id": 900000,
          "text": "900,000"
        },
        {
          "id": 950000,
          "text": "950,000"
        },
        {
          "id": 1000000,
          "text": "1000,000"
        },
        {
          "id": 2000000,
          "text": "2000,000"
        },
        {
          "id": 3000000,
          "text": "3000,000"
        },
        {
          "id": 4000000,
          "text": "4000,000"
        },
        {
          "id": 5000000,
          "text": "5000,000"
        },
        {
          "id": 6000000,
          "text": "6000,000"
        },
        {
          "id": 7000000,
          "text": "7000,000"
        },
        {
          "id": 8000000,
          "text": "8000,000"
        },
        {
          "id": 9000000,
          "text": "9000,000"
        }
      ]);
      obs.complete();
    });
  }

  getpriceListmax(langid): Observable<Array<Select2OptionData>> {
    return Observable.create((obs) => {
      obs.next([
        {
          "id": " ",
          "text": langid=='1'?"Select Max Price":'إختر السعر الأقصى'
        },
        {
          "id": 100,
          "text": "100"
        },
        {
          "id": 200,
          "text": "200"
        },
        {
          "id": 300,
          "text": "300"
        },
        {
          "id": 400,
          "text": "400"
        },
        {
          "id": 500,
          "text": "500"
        },
        {
          "id": 600,
          "text": "600"
        },
        {
          "id": 700,
          "text": "700"
        },
        {
          "id": 800,
          "text": "800"
        },
        {
          "id": 900,
          "text": "900"
        },
        {
          "id": 1000,
          "text": "1,000"
        },
        {
          "id": 2000,
          "text": "2,000"
        },
        {
          "id": 3000,
          "text": "3,000"
        },
        {
          "id": 4000,
          "text": "4,000"
        },
        {
          "id": 5000,
          "text": "5,000"
        },
        {
          "id": 6000,
          "text": "6,000"
        },
        {
          "id": 7000,
          "text": "7,000"
        },
        {
          "id": 8000,
          "text": "8,000"
        },
        {
          "id": 9000,
          "text": "9,000"
        },
        {
          "id": 10000,
          "text": "10,00"
        },
        {
          "id": 20000,
          "text": "20,000"
        },
        {
          "id": 30000,
          "text": "30,000"
        },
        {
          "id": 40000,
          "text": "40,000"
        },
        {
          "id": 50000,
          "text": "50,000"
        },
        {
          "id": 60000,
          "text": "60,000"
        },
        {
          "id": 70000,
          "text": "70,000"
        },
        {
          "id": 80000,
          "text": "80,000"
        },
        {
          "id": 90000,
          "text": "90,000"
        },
        {
          "id": 100000,
          "text": "100,000"
        },
        {
          "id": 150000,
          "text": "150,000"
        },
        {
          "id": 200000,
          "text": "200,000"
        },
        {
          "id": 250000,
          "text": "250,000"
        },
        {
          "id": 300000,
          "text": "300,000"
        },
        {
          "id": 350000,
          "text": "350,000"
        },
        {
          "id": 400000,
          "text": "400,000"
        },
        {
          "id": 450000,
          "text": "450,000"
        },
        {
          "id": 500000,
          "text": "500,000"
        },
        {
          "id": 550000,
          "text": "550,000"
        },
        {
          "id": 600000,
          "text": "600,000"
        },
        {
          "id": 650000,
          "text": "650,000"
        },
        {
          "id": 700000,
          "text": "700,000"
        },
        {
          "id": 750000,
          "text": "750,000"
        },
        {
          "id": 800000,
          "text": "800,000"
        },
        {
          "id": 850000,
          "text": "850,000"
        },
        {
          "id": 900000,
          "text": "900,000"
        },
        {
          "id": 950000,
          "text": "950,000"
        },
        {
          "id": 1000000,
          "text": "1000,000"
        },
        {
          "id": 2000000,
          "text": "2000,000"
        },
        {
          "id": 3000000,
          "text": "3000,000"
        },
        {
          "id": 4000000,
          "text": "4000,000"
        },
        {
          "id": 5000000,
          "text": "5000,000"
        },
        {
          "id": 6000000,
          "text": "6000,000"
        },
        {
          "id": 7000000,
          "text": "7000,000"
        },
        {
          "id": 8000000,
          "text": "8000,000"
        },
        {
          "id": 9000000,
          "text": "9000,000"
        }
      ]);
      obs.complete();
    });
  }
  //getTemplateList(): Select2OptionData[] {
  //  return [
  //    {
  //      id: 'temp1',
  //      text: 'Template 1',
  //      additional: {
  //        image: 'assets/image0.jpg',
  //        winner: '4'
  //      }
  //    },
  //    {
  //      id: 'temp2',
  //      text: 'Template 2',
  //      additional: {
  //        winner: '3'
  //      }
  //    },
  //    {
  //      id: 'temp3',
  //      text: 'Template 3',
  //      additional: {
  //        image: 'assets/image1.jpg',
  //        winner: '1'
  //      }
  //    },
  //    {
  //      id: 'temp4',
  //      text: 'Template 4',
  //      additional: {
  //        image: 'assets/image2.jpg',
  //        winner: '5'
  //      }
  //    },
  //    {
  //      id: 'temp5',
  //      text: 'Template 5',
  //      additional: {
  //        image: 'assets/image3.jpg',
  //        winner: '2'
  //      }
  //    }
  //  ];
  //}

  //getChangeList(): Select2OptionData[] {
  //  return [
  //    {
  //      id: '0',
  //      text: 'Cars',
  //      children: [
  //        {
  //          id: 'car1',
  //          text: 'Car 1'
  //        },
  //        {
  //          id: 'car2',
  //          text: 'Car 2'
  //        },
  //        {
  //          id: 'car3',
  //          text: 'Car 3'
  //        }
  //      ]
  //    },
  //    {
  //      id: '0',
  //      text: 'Planes',
  //      children: [
  //        {
  //          id: 'plane1',
  //          text: 'Plane 1'
  //        },
  //        {
  //          id: 'plane2',
  //          text: 'Plane 2'
  //        },
  //        {
  //          id: 'plane3',
  //          text: 'Plane 3'
  //        }
  //      ]
  //    }
  //  ];
  //}

  //getChangeListAlternative(): Select2OptionData[] {
  //  return [
  //    {
  //      id: '0',
  //      text: 'Cars',
  //      children: [
  //        {
  //          id: 'car1',
  //          text: 'Car 1 - New'
  //        },
  //        {
  //          id: 'car2',
  //          text: 'Car 2 - New'
  //        },
  //        {
  //          id: 'car3',
  //          text: 'Car 3 - New'
  //        }
  //      ]
  //    },
  //    {
  //      id: '0',
  //      text: 'Planes',
  //      children: [
  //        {
  //          id: 'plane1',
  //          text: 'Plane 1 - New'
  //        },
  //        {
  //          id: 'plane2',
  //          text: 'Plane 2 - New'
  //        },
  //        {
  //          id: 'plane3',
  //          text: 'Plane 3 - New'
  //        }
  //      ]
  //    }
  //  ];
  //}
}
