import { HttpClient, HttpHeaders } from '@angular/common/http';
import { Inject, Injectable } from '@angular/core';
import { SimpleResponse } from '../models/genericResponse.model';
import { CustomerRequest } from '../models/request';
import { delayedRetryHttp } from '../shared/delayedRetry';

@Injectable({ providedIn: 'root' })
export class RequestService {
  private baseUrl: string;
  private api: string = "api/Request";
  constructor(private httpClient: HttpClient, @Inject('BASE_URL') _baseUrl: string) {
    this.baseUrl = _baseUrl + this.api;
  }

  async RequestSubmit(langid: number, countryCode: string, BranchID: number, groupbranchid: string, request: CustomerRequest): Promise<SimpleResponse | void> {
    const a = await this.httpClient
      .post<SimpleResponse>(`${this.baseUrl}/sendContactRequest/${langid}/${countryCode}/${BranchID}/${groupbranchid}`, request, {
        headers: new HttpHeaders({
          'Content-Type': 'application/json',
          'Authorization': 'Basic '
        })
      }).pipe(delayedRetryHttp()).toPromise().catch(err => { console.log('request error'); });

    return a;
  }
}
