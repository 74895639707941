import { HttpClient, HttpErrorResponse, HttpHeaders } from "@angular/common/http";
import { Inject, Injectable } from "@angular/core";
import { SimpleResponse } from "../models/genericResponse.model";
import { AuthorizationResponse, ExtenalLoginModel, User } from "../models/login";
import { delayedRetryHttp } from "../shared/delayedRetry";

import { SignUpModel, SignUpOtpVerify } from '../models/Signup';


@Injectable({ providedIn: 'root' })
export class LoginService {

  private baseUrl: string;  
  private api: string = "api/Login";  
  constructor(private httpClient: HttpClient, @Inject('BASE_URL') _baseUrl: string) {
    this.baseUrl = _baseUrl + this.api;
   
  }

  private handleErrors(errorResponse: HttpErrorResponse) {
    if (errorResponse.error instanceof ErrorEvent) {
      console.error('default Client Side Error: ', errorResponse.error.message);
    } else {
      throw errorResponse;
    }
  }

  async LoginSubmit(languageId: string, brachId: number, RemoteIpAddress: string, isSocialMediaLogin: boolean, user: User): Promise<AuthorizationResponse | void> {
    const a = await this.httpClient
      .post<AuthorizationResponse>(`${this.baseUrl}/Login/${languageId}/${brachId}/${RemoteIpAddress}/${isSocialMediaLogin}`, user, {
        headers: new HttpHeaders({
          'Content-Type': 'application/json',
          'Authorization': 'Basic '
        })
      }).pipe(delayedRetryHttp()).toPromise().catch(err => { console.log('default error'); });

    return a;
  }

  async SignUp(SignUpModel: SignUpModel): Promise<any | void> {
    
    const a = await this.httpClient
      .post<any>(`${this.baseUrl}/SignUp`, SignUpModel, {
        headers: new HttpHeaders({
          'Content-Type': 'application/json',
          'Authorization': 'Basic '
        })
      }).pipe(delayedRetryHttp()).toPromise().catch(err => { console.log('default error'); });

    return a;
  }

  async SignUpOtpVerify(SignUpOtpVerify: SignUpOtpVerify): Promise<any | void> {
    const a = await this.httpClient
      .post<any>(`${this.baseUrl}/SignUpOtpVerify`, SignUpOtpVerify, {
        headers: new HttpHeaders({
          'Content-Type': 'application/json',
          'Authorization': 'Basic '
        })
      }).pipe(delayedRetryHttp()).toPromise().catch(err => { console.log('default error'); });

    return a;
  }


  async SocialLogin(userEmail: string, providerName: string, langId: string, branchID: number, externalLoginResponse: SimpleResponse): Promise<AuthorizationResponse | void> {
    const a = await this.httpClient
      .post<AuthorizationResponse>(`${this.baseUrl}/SocialMediaLogin/${userEmail}/${providerName}/${langId}/${branchID}`, externalLoginResponse, {
        headers: new HttpHeaders({
          'Content-Type': 'application/json',
          'Authorization': 'Basic '
        })
      }).pipe(delayedRetryHttp()).toPromise().catch(err => { console.log('default error'); });

    return a;
  }

  async AuthenticateExternalLogin(externallogin:ExtenalLoginModel): Promise<AuthorizationResponse | void> {
    const a = await this.httpClient
      .post<AuthorizationResponse>(`${this.baseUrl}/AuthenticateExternalLogin`, externallogin, {
        headers: new HttpHeaders({
          'Content-Type': 'application/json',
          'Authorization': 'Basic '
        })
      }).pipe(delayedRetryHttp()).toPromise().catch(err => { console.log('AuthenticateExternalLogin error'); });

    return a;
  }

  async ForgotPassword(email: string, langID: string, selectedBranchID: string, selectedCompanyID: string): Promise<SimpleResponse | void> {
    const a = await this.httpClient
      .get<SimpleResponse>(`${this.baseUrl}/ForgotPassward_ConfirmEmail/${email}/${langID}/${selectedBranchID}/${selectedCompanyID}`, {
        headers: new HttpHeaders({
          'Content-Type': 'application/json',
          'Authorization': 'Basic '
        })
      }).pipe(delayedRetryHttp()).toPromise().catch(err => { console.log('ForgotPassword error'); });

    return a;
  }


  async LogOut(): Promise<SimpleResponse | void> {
    const a = await this.httpClient
      .get<SimpleResponse>(`${this.baseUrl}/LogOut`, {
        headers: new HttpHeaders({
          'Content-Type': 'application/json',
          'Authorization': 'Basic '
        })
      }).pipe(delayedRetryHttp()).toPromise().catch(err => { console.log('Logout error'); });

    return a;
  }

  async validateOTP(providedOtp: string): Promise<SimpleResponse | void> {
    const a = await this.httpClient
      .get<SimpleResponse>(`${this.baseUrl}/ValidateOtp/${providedOtp}`, {
        headers: new HttpHeaders({
          'Content-Type': 'application/json',
          'Authorization': 'Basic '
        })
      }).pipe(delayedRetryHttp()).toPromise().catch(err => { console.log('ForgotPassword error'); });

    return a;
  }

  async ResetPassword(email: string, providedPassword: string, contactID:number, Cont_CPR:string, langId:string): Promise<SimpleResponse | void> {
    const a = await this.httpClient
      .get<SimpleResponse>(`${this.baseUrl}/ResetPassword/${email}/${providedPassword}/${contactID}/${Cont_CPR}/${langId}`, {
        headers: new HttpHeaders({
          'Content-Type': 'application/json',
          'Authorization': 'Basic '
        })
      }).pipe(delayedRetryHttp()).toPromise().catch(err => { console.log('ForgotPassword error'); });

    return a;
  }
}
