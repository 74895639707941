import { AfterViewInit, Component, ElementRef, OnInit, ViewChild } from '@angular/core';
import { FormBuilder, FormGroup } from '@angular/forms';
import { NavigationExtras, Router } from '@angular/router';
import { Select2OptionData } from 'ng-select2';
import { Options } from 'select2';
import { isNullOrUndefined } from 'util';
import { InitialValue, InitialValueResponse } from '../../models/initialValue';
import { PropertyCity, PropertyElectricity, PropertyFeature, PropertyFiltersResponse, PropertyFurnicher, PropertySearchParams, PropertyTransactionType, PropertyType, PropertyTypeSort } from '../../models/property';
import { DataService } from '../../services/data.service';
import { DefaultService } from '../../services/default.service';
import { PropertyService } from '../../services/property.service';
import { UtilityService } from '../../shared/utility.service';
import { NgSelect2Component } from 'ng-select2';
import { PropertyComponent } from '../property/property.component';
import { ConfigurationService } from 'src/app/shared/configuration.service';
@Component({
    selector: 'app-find-property',
    templateUrl: './find-property.component.html',
    styleUrls: ['./find-property.component.css']
})
/** find-property component*/
export class FindPropertyComponent implements OnInit {

  @ViewChild('ngSelectAll', { static: false }) ngSelect: NgSelect2Component;
  @ViewChild('findButton') findButton: ElementRef;

  formSearch: FormGroup;
  propertySearchParams: PropertySearchParams;
  isShowproperty: boolean = false;
  initialValueResponse: InitialValueResponse;
  initialValue: InitialValue;
  propertyFeature: PropertyFeature[];
  propertyFiltersResponse: PropertyFiltersResponse;
  types: PropertyTransactionType[];
  propertyTypesSort: PropertyTypeSort[];
  propertyTypes2: PropertyType[];
  propertyCity: PropertyCity[];
  availableTypesOfType1: PropertyType[];
  propertyElectricity: PropertyElectricity[];
  propertyFurnicher: PropertyFurnicher[];
  pTTs: PropertyTransactionType[];
  availableTransactions: PropertyTransactionType[];
  isSpecificTheme: boolean = this._configurationService.isSpecificTheme;
  isLuban: boolean = this._configurationService.isLuban;
  hostName: string = '';
  langId: number = 1;
  public currencyCode: string = 'BHD';
  public countryCode = '973';
  private _TypesSortVal: string;
  public _typesValue: string;  

  public price: Select2OptionData[] = [];
  public pricemx: Select2OptionData[] = [];
  public optionstrans: Options;
  public optionstransmx: Options;
  private _Types2Val: string;
  private _CityFinalVal: string = '';
  private _CityVal: any;
  private _minTransactionsVal: string;
  private _maxTransactionsVal: string;
  private _ElectricityVal: string;
  private _FurnicherVal: string;
  public optiontypes: Options;
  public optionsct: Options;
  public optionsct1: Options;
  public optionsCity: Options;
  public optionsPf: Options;
  public optionsPE: Options;
  public optionspTTs: Options;

  featureValue: string[];
  fvalue: string = '';
  public PriceResult = (state: Select2OptionData): JQuery | string => {
    if (state.text == 'Select Min Price')
      return jQuery('<span>' + state.text + '</span>');
    else
      return jQuery('<span>' + this.currencyCode + '  ' + state.text + '</span>');
  }
  public PriceSelection = (state: Select2OptionData): JQuery | string => {
    return jQuery('<span>' + state.text + '</span>');
  }

  public PriceResultmx = (state: Select2OptionData): JQuery | string => {
    if (state.text == 'Select Max Price')
      return jQuery('<span>' + state.text + '</span>');
    else
      return jQuery('<span>' + this.currencyCode + '  ' + state.text + '</span>');
  }
  public PriceSelectionmx = (state: Select2OptionData): JQuery | string => {
    return jQuery('<span>' + state.text + '</span>');
  }

  public FurnicherResult = (state: Select2OptionData): JQuery | string => {
    return jQuery('<span>' + state.text + '</span>');
  }
  public FurnicherSelection = (state: Select2OptionData): JQuery | string => {
    return jQuery('<span>' + state.text + '</a></span>');
  }

  isShowDiv: boolean = false;
    /** find-property ctor */
  constructor(private _route: Router, private formBuilder: FormBuilder, private _defaultService: DefaultService,
    private _dataService: DataService, private _propertyService: PropertyService, private _configurationService: ConfigurationService) {

    this.hostName = localStorage.getItem('hostname') ? localStorage.getItem('hostname') : 'aqaraats.com';
  }

  ngOnInit() {
    this.formSearch = this.formBuilder.group({
      office: [''],
      MinArea: [''],
      MaxArea: [''],
      MinBedrooms: [''],
      MaxBedrooms: [''],
      MinBathrooms: [''],
      MaxBathrooms: [''],
      nonVacant: [''],
    });
    this.featureValue = [];


    this.langId = this._configurationService.getLanguageValue()
    this.currencyCode = this._configurationService.getSeletedCurrencyCode()
    this.countryCode =  this._configurationService.getSeletedCountryCode();
    this.initialValue = this._configurationService.getInitialValues();
    this.GetInitialValues();
    this.getPrice();
    this.getPricemx();

  }





  GetInitialValues() {

    this.GetSearchFilterData(this.langId.toString(), this.initialValue.branchID, this.initialValue.groupedBranchesId,this.countryCode);
  }


  getPrice() {
    this._dataService.getpriceList(this.langId.toString()).subscribe((result) => {
      if (result) {
        this.price = UtilityService.clone(result);
        this.optionstrans = {
          width: '200',
          tags:true,
          templateResult: this.PriceResult,
          templateSelection: this.PriceSelection
        };
      }
    })
  }

  getPricemx() {
    this._dataService.getpriceListmax(this.langId.toString()).subscribe((result) => {
      if (result) {
        this.pricemx = UtilityService.clone(result);
        this.optionstransmx = {
          width: '200',
          tags:true,
          templateResult: this.PriceResultmx,
          templateSelection: this.PriceSelectionmx
        };
      }
    })
  }

  GetSearchFilterData(currenLang: string, branchId: number, GroupedBrancheIds: string, countryCode: string) {
    this._propertyService.GetSerch_FilterAsync(Number(currenLang), branchId, GroupedBrancheIds, Number(countryCode)).then((result) => {
      if (result) {
        this.propertyFiltersResponse = UtilityService.clone(result);
        //console.log("GetSearchFilterData :,", this.propertyFiltersResponse)
        this.types = this.propertyFiltersResponse.pTTs;
        if(this.langId == 1){
          this.types.unshift({ id: "0", text: "All Types" });
        }else{
          this.types.unshift({ id: "0", text: "كل الأنواع" });
        }
        
        this.optiontypes = {
          allowClear: false
        };
        this.propertyTypesSort = this.propertyFiltersResponse.propertyTypesSort;
        if(this.langId == 1){
          this.propertyTypesSort.unshift({ id: "0", text: "All Categories", sort: "" });
        }else{
          this.propertyTypesSort.unshift({ id: "0", text: "كل الفئات" , sort: ""});
        }

        this.optionsct = {
          allowClear: false
        };
        this.propertyTypes2 = this.propertyFiltersResponse.propertyTypes2;
        if(this.langId == 1){
          this.propertyTypes2.unshift({ id: "0", text: "All Categories"});
        }else{
          this.propertyTypes2.unshift({ id: "0", text: "كل الفئات"});
        }
        this.optionsct1 = {
          allowClear: false
        };


        this.propertyCity = this.propertyFiltersResponse.propertyCitys;
        if(this.langId == 1){
          this.propertyCity.unshift({ id: "0", text: "All Areas" });
        }else{
          this.propertyCity.unshift({ id: "0",  text: "كل الفئات"  });
        }

        this.optionsCity = {
          allowClear: true,
          multiple: true,
          tags: false
        };
        this.availableTypesOfType1 = this.propertyFiltersResponse.availableTypesOfType1;
        this.propertyElectricity = this.propertyFiltersResponse.electricityOptions;
        if(this.langId == 1){
          this.propertyElectricity.unshift({ id: "0", text: "Electricity Incl/excl" });
        }else{
          this.propertyElectricity.unshift({ id: "0", text: "الكهرباء غير مشمولة/الكهرباء مشمولة" });
        }

        this.optionsPE = {
          allowClear: false
        };
        this.propertyFurnicher = this.propertyFiltersResponse.furnicherOptions;
        if(this.langId == 1){
          this.propertyFurnicher.unshift({ id: "0", text: "Furniture Options" });
        }else{
          this.propertyFurnicher.unshift({ id: "0", text: "خيارات الأثاث" });
        }
        
        this.optionsPf = {
          allowClear: false,
          templateResult: this.FurnicherResult,
          templateSelection: this.FurnicherSelection
        };
        this.pTTs = this.propertyFiltersResponse.pTTs;
        this.optionspTTs = {
          allowClear: false
        };
        this.availableTransactions = this.propertyFiltersResponse.availableTransactions;
        this.propertyFeature = this.propertyFiltersResponse.propertyFeatures;
      }
    });
  }

  ShowHide() {
    this.isShowproperty = !this.isShowproperty;
  }

  GetFeatureId($event) {

    this.featureValue.push($event.target.value);
    this.fvalue = this.fvalue + ',' + $event.target.value
  }

  detailSearch() {
    this.findButton?.nativeElement.click();
    this.ShowHide();
    //
    localStorage.removeItem('Qsearch');
    localStorage.setItem('Qsearch', JSON.stringify(this.prepareFormSearch()));
    if(this._route.url == '/properties/0/0'){
      this._configurationService.isQoutSerched(true);
    }
    const navigationExtras: NavigationExtras = { state: { reRender: true } };
    this._route.navigateByUrl('/properties/0/0', navigationExtras);
  }

  toggleLocationDiv() {
    this.isShowDiv = !this.isShowDiv;
  }

  prepareFormSearch() {
    return this.propertySearchParams = {
      branchId: this.initialValue.branchID,
      countryCode: this.countryCode,
      contactId: '0', currentLang: this.langId, cAID: 0,
      groupedBranches: this.initialValue.groupedBranchesId, page: '',
      sort: '', type: this._typesValue, limit: '10000',
      searchTerm: isNullOrUndefined(this.formSearch.controls["office"].value) ? '' : this.formSearch.controls["office"].value,
      propertyType2: isNullOrUndefined(this._TypesSortVal) ? '' : this._TypesSortVal,
      propertyType3: isNullOrUndefined(this._Types2Val) ? '' : this._Types2Val,
      propertyLocation: isNullOrUndefined(this._CityFinalVal) ? '' : this._CityFinalVal.substring(0, this._CityFinalVal.length - 1),
      minArea: isNullOrUndefined(this.formSearch.controls["MinArea"].value) ? '' : this.formSearch.controls["MinArea"].value,
      maxArea: isNullOrUndefined(this.formSearch.controls["MaxArea"].value) ? '' : this.formSearch.controls["MaxArea"].value,
      minPrice: isNullOrUndefined(this._minTransactionsVal) ? '' : this._minTransactionsVal,
      maxPrice: isNullOrUndefined(this._maxTransactionsVal) ? '' : this._maxTransactionsVal,
      features: this.featureValue.length > 0 ? this.fvalue.slice(1) : '',
      minBeds: isNullOrUndefined(this.formSearch.controls["MinBedrooms"].value) ? '' : this.formSearch.controls["MinBedrooms"].value,
      maxBeds: isNullOrUndefined(this.formSearch.controls["MaxBedrooms"].value) ? '' : this.formSearch.controls["MaxBedrooms"].value,
      minBaths: isNullOrUndefined(this.formSearch.controls["MinBathrooms"].value) ? '' : this.formSearch.controls["MinBathrooms"].value,
      maxBaths: isNullOrUndefined(this.formSearch.controls["MaxBathrooms"].value) ? '' : this.formSearch.controls["MaxBathrooms"].value,
      electricity: isNullOrUndefined(this._ElectricityVal) ? '' : this._ElectricityVal,
      Furnicher: isNullOrUndefined(this._FurnicherVal) ? '' : this._FurnicherVal, count: false,
      nonVacant: this.formSearch.controls["nonVacant"].value,
      agentID: '', parentID: '',
      myProperty: false,
      currencyDetails: {
        selectedCcyCode: '',
        selectedCcyName: '',
        selectedCcySwift: '',
        selectedCcySwiftCodeArb: '',
        selectedCcySwiftCode: '',
        selectedCcyDecimal: '',
      }
    }
  }

  get typesValue(): string {
    return this._typesValue;
  }

  set typesValue(value: string) {
    this._typesValue = value;
  }

  get TypesSortVal(): string {
    return this._TypesSortVal;
  }

  set TypesSortVal(value: string) {
    this._TypesSortVal = value;
  }

  get Types2Val(): string {
    return this._Types2Val;
  }

  set Types2Val(value: string) {
    this._Types2Val = value;
  }

  get CityVal(): any {
    return this._CityVal;
  }
set CityVal(value: any) {
  
    this._CityVal = value
    this._CityFinalVal = "";
    if (!isNullOrUndefined(value)) {
      for (let i = 0; i < value.length; i++) {
        this._CityFinalVal += value[i] + ','
      }
    }
  }

  get minTransactionsVal(): string {
    return this._minTransactionsVal;
  }

  set minTransactionsVal(value: string) {
    this._minTransactionsVal = value;
  }

  get maxTransactionsVal(): string {
    return this._maxTransactionsVal;
  }

  set maxTransactionsVal(value: string) {
    this._maxTransactionsVal = value;
  }

  get ElectricityVal(): string {
    return this._ElectricityVal;
  }

  set ElectricityVal(value: string) {
    this._ElectricityVal = value;
  }

  get FurnicherVal(): string {
    return this._FurnicherVal;
  }

  set FurnicherVal(value: string) {
    this._FurnicherVal = value;
  }

  resetFormSearch() {
    
    this.formSearch.reset();
    this._typesValue = "";
    this._TypesSortVal = "";
    this._typesValue = "";
    this._Types2Val = "";
    this._minTransactionsVal = "";
    this._maxTransactionsVal = "";
    this._ElectricityVal = "";
    this._FurnicherVal = "";
    this._CityVal = "";
    this.fvalue = "";

    this.featureValue.forEach(item=>{
      let checkBox = document.getElementById(item);
      if (checkBox) {
        (checkBox as HTMLInputElement).checked = false;
      }
    })
    this.featureValue = [];
  }
}
