import { Component, Input, OnInit } from '@angular/core';
import { Router } from '@angular/router';
import { SwiperComponent } from "swiper/angular";
import Swiper from "swiper/types/swiper-class";

import SwiperCore , {
  Navigation,
  Autoplay,
  Controller,Thumbs
} from 'swiper';
import { PropertySearchResults } from 'src/app/models/property';
SwiperCore.use([
  Navigation,
  Autoplay,
  Controller,Thumbs
]);

@Component({
  selector: 'app-property-detail-building-listing',
  templateUrl: './property-detail-building-listing.component.html',
  styleUrls: ['./property-detail-building-listing.component.css']
})
export class PropertyDetailBuildingListingComponent implements OnInit {
  thumbsSwiper: any;
  @Input() bp: PropertySearchResults;
  @Input() i:number;
  @Input() langId: number = 1;
  constructor( private _route: Router) { }

  ngOnInit(): void {
  }


  ecodeuri(url: string, fromtype) {
    var urlval = url.replace('&type', '').split('=');
    if (url.indexOf("?") !== -1) {
      const queryParams = { 'id': urlval[1] };
  
      if (fromtype == '1') {
        queryParams['fromtype'] = fromtype;
      }
      this._route.navigate(['/propertydetail'], { queryParams });
    }
    else{
      this._route.navigate([decodeURI("/" + url)]);
    }
  }


}
