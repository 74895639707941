import { Component, OnInit } from '@angular/core';
import { FormBuilder, FormGroup, Validators } from '@angular/forms';
import { ActivatedRoute, Router } from '@angular/router';
import { isNullOrUndefined } from 'util';
import { InitialValue, InitialValueResponse } from '../../models/initialValue';
import { AuthorizationResponse, ExtenalLoginModel, User } from '../../models/login';
import { DefaultService } from '../../services/default.service';
import { LoginService } from '../../services/login.service';
import { UtilityService } from '../../shared/utility.service';
import {
  SocialAuthService,
  FacebookLoginProvider,
  GoogleLoginProvider,
  SocialUser,
} from 'angularx-social-login';
import { SimpleResponse } from '../../models/genericResponse.model';
import { NotificationService } from '../../shared/notification.service';
import { PropertySearchParams } from '../../models/property';
import { ConfigurationService } from 'src/app/shared/configuration.service';

@Component({
  selector: 'app-login',
  templateUrl: './login.component.html',
  styleUrls: ['./login.component.css']
})
/** login component*/
export class LoginComponent implements OnInit {

  form: FormGroup;
  submitted = false;
  user: User;
  exUser: ExtenalLoginModel;
  initialValueResponse: InitialValueResponse;
  initialValue: InitialValue;
  public currencyCode: string = 'BHD';
  public countryCode = '973';
  langId: number = 1;
  authorizationResponse: AuthorizationResponse;
  ipAddress: string;
  loading: boolean = false;
  //private users: any;
  socialUser!: SocialUser;
  isLoggedin?: boolean = undefined;
  simpleResponse: SimpleResponse;
  propertySearchParams: PropertySearchParams;
  externalValues: string = '';
  isExternal: boolean = true;
  /** login ctor */
  constructor(private formBuilder: FormBuilder, private _loginService: LoginService, 
    private socialAuthService: SocialAuthService,
    private _defaultService: DefaultService
    , private _utilityService: UtilityService
    , private _route: Router
    , private notifyService: NotificationService
    , private route: ActivatedRoute,
    private _configurationService:ConfigurationService
  ) {
    

    // localStorage.removeItem("User");
    if (isNullOrUndefined(localStorage.getItem('hostname')))
      localStorage.setItem('hostname', 'aqaraats.com');
      
   
  }
  get f() {
    return this.form.controls;
  }
  images = [
    { img: 'assets/img/login-bg.jpg', title: "We would like also like", text: " to have some text on the picture which would briefly explain what are advantages of being a tenant or customer on Aqaraats.Please keep a provision for it" },
    { img: 'assets/img/login-bg1.jpg', title: "We would like also like", text: " to have some text on the picture which would briefly explain what are advantages of being a tenant or customer on Aqaraats.Please keep a provision for it" },
  ];
  // Settings
  settings = {
    slidesToShow: 1,
    slidesToScroll: 1,
    arrows: false,
    autoplay: true,
    dots: true,
    dotsClass: "d-flex slick-dots",
  }

  ngOnInit(): void {
    ;
    this.socialAuthService.authState.subscribe((user) => {
      this.socialUser = user;
      //console.log('user', this.socialUser);
      var userdeatils = this.prepareSocialUser(JSON.stringify(user.response));
      this.SocialLogin(user.email, user.provider, this.langId.toString(), this.initialValue.branchID, userdeatils)
      //this.isLoggedin = user != null;
    });

    if (!isNullOrUndefined(localStorage.getItem("User"))) {
      this.authorizationResponse = JSON.parse(localStorage.getItem("User"));
      this._utilityService.isUserLogin.next(true);
      this._utilityService.UserName.next(this.authorizationResponse.loginName);
    }
    else {
      this._utilityService.isUserLogin.next(false);
    }
    this.form = this.formBuilder.group({
      username: ['', [Validators.required, Validators.email]],
      password: ['', [Validators.required]]
    });
    

    this.langId = this._configurationService.getLanguageValue();
    this.currencyCode = this._configurationService.getSeletedCurrencyCode();
    this.countryCode = this._configurationService.getSeletedCountryCode();
    this.initialValue  = this._configurationService.getInitialValues();



    this.route.queryParams
    .subscribe(params => {
      
      //console.log(params); // { orderby: "price" }
      this.externalValues = params.EXTERNALLOGINVALUES;
      if (!isNullOrUndefined(this.externalValues) && this.externalValues != '') {
        this.isExternal = false;
        var srcUrl = this.externalValues.split('|');
        if (srcUrl.length > 2) {
          var strUrl = srcUrl[2].split('?');
          var setId = strUrl.length > 1 ? strUrl[1].split('&') : '';
          var finalValue = setId.length > 0 ? setId[0].split('=') : '';
          var finalValueType = setId.length > 1 ? setId[1].split('=') : '';
          this.initialValue  = this._configurationService.getInitialValues();
          //console.log('External values', this.externalValues); // price
          this.GetInitialValues(finalValue, finalValueType);

        }
      }
      else {
        this.initialValue  = this._configurationService.getInitialValues();
        this.isExternal = true;
        this.GetInitialValues();
      }
    });
    //this.getIP();
    // $('html, body').animate({
    //   scrollTop: $("body").offset().top = 0
    // }, 0);
  }
  signInWithGoogle(): void {
    this.socialAuthService.signIn(GoogleLoginProvider.PROVIDER_ID);
  }
  loginWithFacebook(): void {
    this.socialAuthService.signIn(FacebookLoginProvider.PROVIDER_ID);
  }
  signOut(): void {
    this.socialAuthService.signOut();
  }

  externalLogin(externalValue:string,url:string,Type:string) {
    this._loginService.AuthenticateExternalLogin(this.prepareFormEx(externalValue)).then((result) => {
      if (result) {

        this.authorizationResponse = UtilityService.clone(result);
        //console.log(result);
        if (!isNullOrUndefined(this.authorizationResponse.token)) {
          localStorage.setItem("User", JSON.stringify(this.authorizationResponse));
          this._utilityService.Set_AuthorizationResponse(this.authorizationResponse);
          localStorage.setItem('Qsearch', JSON.stringify(this.prepareFormSerch(url, Type)));
          this._route.navigate(['/properties/0/0']);
        }
        else {
          this.notifyService.showError(this.authorizationResponse.messageString, "Error");
        }
        
      }
    });
  }

  GetInitialValues( finalValue: any = [], finalValueType: any = []) {
    if (!isNullOrUndefined(this.externalValues))
          this.externalLogin(this.externalValues, finalValue.length > 1 ? finalValue[1] : '', finalValueType.length > 1 ? finalValueType[1] : '');

  }

  prepareFormSerch(ptype,propType) {
    return this.propertySearchParams = {
      branchId: 14,
      countryCode: this.countryCode,
      contactId: 'zj5gitx5411oab2quguwsecq-14', currentLang: 1, cAID: 0,
      groupedBranches: this.initialValue?.groupedBranchesId, page: '',
      sort: '', type: ptype, limit: '2500', searchTerm: '',
      propertyType2: '',
      propertyType3: propType,
      propertyLocation: '', minArea: '',
      maxArea: '',
      minPrice: '',
      maxPrice: '',
      features: '',
      minBeds: '',
      maxBeds: '',
      minBaths: '',
      maxBaths: '', electricity: '',
      Furnicher: '', count: true, nonVacant: '', agentID: '', parentID: '',
      myProperty: false,
      currencyDetails: {
        selectedCcyCode: '',
        selectedCcyName: '',
        selectedCcySwift: '',
        selectedCcySwiftCodeArb: '',
        selectedCcySwiftCode: '',
        selectedCcyDecimal: '',
      }
    }
  }

  submit() {
    ;
    this.submitted = true;
    this.loading = true;
    if (this.form.invalid) {
      this.loading = false;
      return;
    }
    this._loginService.LoginSubmit(this.langId.toString(), this.initialValue.branchID, '1231', false, this.prepareForm()).then((result) => {
      if (result) {

        this.authorizationResponse = UtilityService.clone(result);
        //console.log(result);
        if (!isNullOrUndefined(this.authorizationResponse) && this.authorizationResponse.messageBool) {
          //this.form.reset();
          //console.log(result);
          localStorage.setItem("User", JSON.stringify(this.authorizationResponse));
          this._utilityService.Set_AuthorizationResponse(this.authorizationResponse);
          this._utilityService.Set_InitialValueResponse(this.initialValueResponse);
          this.loading = false;
          this._route.navigate(['/dashboard']);
          //this.openSnackBar("Mail send successfully", "");
        }
        else {
          this.loading = false;
          this.notifyService.showError(this.authorizationResponse.messageString, "Error");
          //console.log(result);
        }
      }
      else { }
    });
    //console.log(this.prepareForm());

  }

  prepareForm() {
    return this.user = {
      loginid: this.form.controls["username"].value,
      password: this.form.controls["password"].value
    }
  }

  prepareFormEx(exLogin) {
    return this.exUser = {
      externalToken: exLogin,
      brachId: '14',
      languageId:'1'
    }
  }

  prepareSocialUser(response: string) {
    return this.simpleResponse = {
      messageString: response,
      messageBool: false,
      messageInt: 0,
      messageInt2: 0,
      statusCode: 0,
      token: '',
      filter: null,
      listOfString: [],
      errorMsg: ''
    }
  }

  SocialLogin(userEmail: string, providerName: string, langId: string, branchID: number, externalLoginResponse: SimpleResponse) {
    this._loginService.SocialLogin(userEmail, providerName, langId, branchID, externalLoginResponse).then((result) => {
      if (result) {

        this.authorizationResponse = UtilityService.clone(result);
        //console.log(result);
        if (!isNullOrUndefined(this.authorizationResponse)) {
          if (this.authorizationResponse.messageBool) {
            //this.form.reset();
            //console.log(result);
            localStorage.setItem("User", JSON.stringify(this.authorizationResponse));
            this._utilityService.Set_AuthorizationResponse(this.authorizationResponse);
            this._utilityService.Set_InitialValueResponse(this.initialValueResponse);
            this.loading = false;
            this._route.navigate(['/dashboard']);
          }
          else {
            this.notifyService.showInfo("Invalid user", "");
          }
          //this.openSnackBar("Mail send successfully", "");
        }
        else
          console.log(result);
      }
      else { }
    });
  }
}
