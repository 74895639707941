import { Component, OnInit } from '@angular/core';
import { CheckoutSessionModelResposne } from '../../../models/Payment';
import { ConfigurationService } from 'src/app/shared/configuration.service';
//declare function GetSetValue(MerchantId, sessionId, sessionVersion, successIndicator, OrderId, Currency, merchantName): any;
//declare function beforeRedirect(successIndicator, OrderId): any;
//declare function afterRedirect(CheckoutSessionModelResposne): any;
declare function RedirectPay(merchantId, currency, orderId, sessionId, merchantName): any;
@Component({
    selector: 'app-pgredirect',
    templateUrl: './pgredirect.component.html',
    styleUrls: ['./pgredirect.component.css']
})
/** pgredirect component*/
export class PgredirectComponent implements OnInit {

  langId: number = 1;
  currencyCode: string = 'BHD';
  checkoutSessionModelResposne: CheckoutSessionModelResposne;
    /** pgredirect ctor */
  constructor(private _configurationService:ConfigurationService) {
    
  }

  ngOnInit() {

    this.langId = this._configurationService.getLanguageValue()
      this.currencyCode = this._configurationService.getSeletedCurrencyCode();

    localStorage.setItem('pgredirct','true');
    this.checkoutSessionModelResposne = JSON.parse(localStorage.getItem('checkoutSessionModelResposne'));
    RedirectPay(this.checkoutSessionModelResposne.merchantId,
      this.currencyCode, this.checkoutSessionModelResposne.orderId, this.checkoutSessionModelResposne.sessionID, this.checkoutSessionModelResposne.merchantName);
  }
}
