import { Component, ElementRef, HostListener, OnInit, Output, ViewChild } from '@angular/core';
import { ContractsListResponse } from '../../models/Contracts';
import { ContractsDetails, CustomerResponse, DashBoardDetails, DashboardResponse, DisplayDetailsResponse, ePaymentMethod, FormControls, FormDetailsResponse, JSONObjectModel, JSONObjectModelResponse, loginResponse, Payment, PaymentResponseAQ, StatusMessage, UpdateValues, UploadFiles } from '../../models/DashBoard';
import { PaymentStatement, PaymentStatementResponse } from '../../models/PaymentStatement';
import { PaymentResponse, UpdatePayment } from '../../models/Payment';
import { DahboardService } from '../../services/dashboard.service';
import { UtilityService } from '../../shared/utility.service';
import { map, filter, take, switchMap, toArray, startWith } from 'rxjs/operators';
import { isNullOrUndefined } from 'util';
import { InquiryService } from '../../services/inquiry.service';
//import { SimpleRequest } from '../../models/property';
import { KeyValue, SimpleRequest, SimpleResponse } from '../../models/genericResponse.model';
import { CustInquiryInvoice, CustInquiryReciept, CustInquiryResponse, FilterAccountResponse, FiltersBranchResponse } from '../../models/Inquiry';
import { AuthorizationResponse } from '../../models/login';
import { Router } from '@angular/router';
import { DefaultService } from '../../services/default.service';
import { currencyDetails, InitialValue, InitialValueResponse } from '../../models/initialValue';
import { FormBuilder, FormControl, FormGroup } from '@angular/forms';
import { isNull } from '@angular/compiler/src/output/output_ast';
import { CheckoutSessionModelResposne } from '../../models/Payment';
import { PropertySearchParams } from '../../models/property';
import { escapeLeadingUnderscores, forEachChild } from 'typescript/lib/tsserverlibrary';
import { element, EventEmitter } from 'protractor';
import { NotificationService } from '../../shared/notification.service';
import { Observable } from 'rxjs';
import { event } from 'jquery';
import { MatDatepickerInputEvent } from '@angular/material/datepicker';
import { DatePipe } from '@angular/common';
import { Console } from 'console';
import { StatementOfAccount, StatementOfAccountResponse } from '../../models/StatementOfAccount';
import { MAT_DATE_FORMATS } from '@angular/material/core';
import { Moment } from 'moment';
import { ConfigurationService } from 'src/app/shared/configuration.service';
import { ToastrService } from 'ngx-toastr';

declare var require: any;
const moment = require('moment');

export const MY_DATE_FORMATS = {
  parse: {
    dateInput: 'DD/MM/YYYY',
  },
  display: {
    dateInput: 'DD/MM/YYYY',
    monthYearLabel: 'MMMM YYYY',
    dateA11yLabel: 'LL',
    monthYearA11yLabel: 'MMMM YYYY'
  },
};
//import moment = require('moment');

@Component({
  selector: 'app-dashboard',
  templateUrl: './dashboard.component.html',
  styleUrls: ['./dashboard.component.css'],
  providers: [
    { provide: MAT_DATE_FORMATS, useValue: MY_DATE_FORMATS }
  ]
})
/** dashboard component*/
export class DashboardComponent implements OnInit { 
  
  alwaysShowCalendars: boolean;
  contractsListResponse: DashboardResponse;
  contractDetails: ContractsDetails[];
  dashBoardDetails: DashBoardDetails[];
  public countryCode = '973';
  filename: string = "";
  /* const csv: string = reader.result as string;*/
  uploadedFile: any = "";
  title: string = "";
  afuConfig;
  paymentStInvoices: PaymentStatement[];
  currencyDetail: currencyDetails;
  paymentStSettlements: PaymentStatement[];
  paymentStNet: PaymentStatement[];
  propertySearchParams: PropertySearchParams;
  paymentStatements: PaymentStatement;
  paymentStatementResponse: PaymentStatementResponse;
  formType: string = "1";
  custInquiryResponse: CustInquiryResponse;
  custInquiryInvoice: CustInquiryInvoice[];
  custInquiryReciept: CustInquiryReciept[];

  filterAccountResponse: FilterAccountResponse;
  filterAccount: KeyValue[];


  filtersBranchResponse: FiltersBranchResponse;
  filterBranch: KeyValue[];
  isfilterBranchSelect:boolean = true
  isfilterAccountSelect:boolean = true;
  simpleRequest: SimpleRequest;
  customerResponse: CustomerResponse;
  formDetailsResponse: FormDetailsResponse;
  maintenance: FormControls[];
  displayDetailsResponse: DisplayDetailsResponse;
  initialValueResponse: InitialValueResponse;
  initialValue: InitialValue;
  statusMessage: StatusMessage;
  simpleResponse: SimpleResponse;
  filter: KeyValue[];
  accountNo: string;
  paymentResponseAQ: PaymentResponseAQ;
  paymentDetails: Payment[];
  checkoutSessionModelResposne: CheckoutSessionModelResposne;
  paymentResponse: PaymentResponse;
  updatePayment: UpdatePayment;
  statementOfAccountResponse: StatementOfAccountResponse;
  statementOfAccount_list: StatementOfAccount[];
  rptLink: string = '';
  currentLang: string = "1";
  caid: string = '9640';
  customer: string = '335';
  customerDecimal: string = '10';
  shItems: string = 'D'

  user: string = '335';
  branchID: string = '10';
  companyId: string = '001';
  selectedBranchId: string = '10';
  groupedBranches: string = '1';
  displayData: boolean = false;
  displayAcData: boolean = false;
  customerId: string = '00000000';
  rptType: string = 'invoice';
  rptTypeRcpt: string = 'receipt';
  authorizationResponse: AuthorizationResponse;
  token: string = '';
  hostName: string = '';
  customerarray: loginResponse;
  langId: number = 1;
  maintenanceForm: FormGroup;
  showForm: boolean = false;
  showRealEstateMaintenanceForm: boolean = true;
  currencyCode: string = 'BHD';
  invoiceValue: string[];
  paymentValue: string = '0';
  prePaymentValue: string = '0';

  jSONObjectModelResponse: JSONObjectModelResponse;
  jsonObjectModelList: JSONObjectModel[] = [];
  Mycontrol = new FormControl();
  filteredQuery: Observable<JSONObjectModel[]>;
  isPayment: boolean = false;
  range = new FormGroup({
    start: new FormControl(),
    end: new FormControl()
  });
  branchText: string = '';
  accountText: string = '';
  dateStart: string = '';
  dateEnd: string = '';
  dateStartst: string = '';
  dateEndst: string = '';
  msgString: string = '';
  msgString1: string = '';
  SelectedBranch: string = '';
  SelectedAccount: string = '';
  payTypeDisplay: string[] = [];
  loading: boolean = false;
  currentMonth: number = 0;
  TotalInvoice: any = {};
  TotalSettalement: any = {};
  TotalNet: any = {};
  private _valueSt: string;
  private _valueStEnd: string;
  totalDr: number = 0;
  totalCr: number = 0;
  totalBlnc: number = 0;
  isShow: boolean = false;
  dispDateSTM: string = '';
  dispDateSTME: string = '';
  dispDateSTR: string = '';
  dispDateSTRE: string = '';
  actLoad: boolean = false;

  totalInvamt: number = 0;
  totalinvpmt: number = 0;
  totalinvBlnc: number = 0;
  totalrctBlnc: number = 0;
  rangeselected: any;

  Selected:any= { startDate: null, endDate: null };
  Selectedval:any= { startDate: null, endDate: null };
    ruleRangeStart: any;
    ruleRangeEnd: any;
    ruleRange: boolean;
  /** dashboard ctor */
  constructor(private fb: FormBuilder, 
    private _dahboardService: DahboardService, 
    private _inquiryService: InquiryService
    , private _route: Router, 
    private _defaultService: DefaultService,
    private _utilityService: UtilityService, 
    private _notifyService: NotificationService, 
    public datepipe: DatePipe,
    private _configurationService : ConfigurationService, 
    private _toasterService:ToastrService) {
      
    localStorage.removeItem("pgredirct");
    
    //console.log('month name', this.currentMonth);
  }

  ngOnInit() {
    
    this.langId = this._configurationService.getLanguageValue();
    this.currencyCode = this._configurationService.getSeletedCountryCode();
    this.alwaysShowCalendars = true;
    this.initialValue = this._configurationService.getInitialValues();
    // this.hostName = isNullOrUndefined(localStorage.getItem('hostname')) ? 'aqaraats.com' : localStorage.getItem('hostname');
    if (!isNullOrUndefined(localStorage.getItem("User"))) {
      this.authorizationResponse = JSON.parse(localStorage.getItem("User"));
      this.token = this.authorizationResponse.token;
    }
    else {
      this._route.navigate(['/login']);
    }
    this.countryCode = localStorage.getItem('Country') ? localStorage.getItem('Country') : '973';

    this.currentMonth = +this.datepipe.transform((new Date), 'MM');


    this.getUploadConfig();
    this.invoiceValue = [];
    this.maintenanceForm = this.fb.group({

    });

    if (!isNullOrUndefined(localStorage.getItem("User"))) {
      this.authorizationResponse = JSON.parse(localStorage.getItem("User"));
      this._utilityService.isUserLogin.next(true);
      this._utilityService.UserName.next(this.authorizationResponse.loginName);
    }
    else {
      this._utilityService.isUserLogin.next(false);
    }
    this.customerarray = localStorage.getItem("User") ? JSON.parse(localStorage.getItem("User")) : "";
    //console.log("indashboard :", this.customerarray)
    let year = new Date().getFullYear();
    let myDate = this.datepipe.transform(('02/12/' + year), 'MM/dd/yyyy h:mm:ss');
    let myDatee = this.datepipe.transform(('02/19/' + year), 'MM/dd/yyyy h:mm:ss');
    this.dateStart = myDate.toString();
    this.dateEnd = myDatee.toString();
    this.dispDateSTR = this.datepipe.transform((myDate), 'dd/MM/yyyy');
    this.dispDateSTRE = this.datepipe.transform((myDatee), 'dd/MM/yyyy');
    this.GetInitialValues();
    var stdate = new Date();
    //console.log(stdate.toLocaleDateString());
    this.dateEndst = stdate.toLocaleDateString();
    stdate.setMonth(stdate.getMonth() - 1);
    //console.log(stdate.toLocaleDateString());
    
    this.dispDateSTME = this.datepipe.transform((this.dateEndst), 'dd/MM/yyyy');
    this.dateStartst = stdate.toLocaleDateString();
    this._valueSt = this.dateStartst;
    this.dispDateSTM = this.datepipe.transform((this.dateStartst), 'dd/MM/yyyy');
    this.Selected.startDate = moment().subtract(4, 'days').startOf('day');
    this.Selected.endDate = moment().subtract(1, 'days').startOf('day');
    this.Selectedval.startDate = moment().subtract(4, 'days').startOf('day');
    this.Selectedval.endDate = moment().subtract(1, 'days').startOf('day');
    //this.selected = { startDate: this.dispDateSTME, endDate: this.dispDateSTM }
    // $('html, body').animate({
    //   scrollTop: $("body").offset().top = 0
    // }, 0);
  } 

  @HostListener('window:beforeunload', ['$event'])
  public clearLocalStorage(event: any): void {
    localStorage.removeItem("pgredirct");
    //localStorage.clear();    
  }

  dateRangeChange(dateRangeStart: HTMLInputElement, dateRangeEnd: HTMLInputElement) {
    let stdate = dateRangeStart.value.split('/');
    let stedate = dateRangeEnd.value.split('/');
    if (stedate.length > 0) {
      this.dateStart = this.datepipe.transform((stdate[1] + '/' + stdate[0] + '/' + stdate[2]), 'MM/dd/yyyy h:mm:ss');  //dateRangeStart.value.toString();// this.datepipe.transform((dateRangeStart.value), 'dd/MM/yyyy h:mm:ss');
      this.dateEnd = this.datepipe.transform((stedate[1] + '/' + stedate[0] + '/' + stedate[2]), 'MM/dd/yyyy h:mm:ss');//dateRangeEnd.value.toString();//this.datepipe.transform((dateRangeEnd.value), 'dd/MM/yyyy h:mm:ss');
      this.InquiryData_Get(this.authorizationResponse.companyID, this.initialValue.cmsCompID.toString(), this.initialValue.branchID.toString(), this.authorizationResponse.customer, 'invoice', this.currentLang, this.SelectedAccount, this.authorizationResponse.user, this.prepareSampleRqst(), this.token);
      this.InquiryData_Get(this.authorizationResponse.companyID, this.initialValue.cmsCompID.toString(), this.initialValue.branchID.toString(), this.authorizationResponse.customer, 'receipt', this.currentLang, this.SelectedAccount, this.authorizationResponse.user, this.prepareSampleRqst(), this.token);
    }
  }

  dateRangeChangeStatment(dateRangeStStart: HTMLInputElement, dateRangeStEnd: HTMLInputElement) {
    let stdate = dateRangeStStart.value.split('/');
    let stedate = dateRangeStEnd.value.split('/');
    if (stedate.length > 0) {
      this.dateStartst = this.datepipe.transform((stdate[1] + '/' + stdate[0] + '/' + stdate[2]), 'MM/dd/yyyy h:mm:ss');
      this.dateEndst = this.datepipe.transform((stedate[1] + '/' + stedate[0] + '/' + stedate[2]), 'MM/dd/yyyy h:mm:ss');
      this.StetementData_Get(this.prepareSampleRqstSt(), this.SelectedAccount, this.authorizationResponse.companyID, this.accountNo, this.authorizationResponse.customer, this.currentLang, this.token);
    }
  }

  ranges: any = {
    Today: [moment(), moment()],
    Yesterday: [moment().subtract(1, 'days'), moment().subtract(1, 'days')],
    'Last 7 Days': [moment().subtract(6, 'days'), moment()],
    'Last 30 Days': [moment().subtract(29, 'days'), moment()],
    'This Month': [moment().startOf('month'), moment().endOf('month')],
    'Last Month': [
      moment()
        .subtract(1, 'month')
        .startOf('month'),
      moment()
        .subtract(1, 'month')
        .endOf('month')
    ],
    'Last 3 Month': [
      moment()
        .subtract(3, 'month')
        .startOf('month'),
      moment()
        .subtract(1, 'month')
        .endOf('month')
    ]
  };

  getDateRange(e) {
    if (e && e.startDate && e.endDate) {
      // set for checking that ruleRange is true, initially false  
      this.ruleRange = true;
      this.ruleRangeStart = e.startDate.format().split('T'); // to split
      this.ruleRangeEnd = e.endDate.format().split('T');
      let stdate = this.ruleRangeStart[0].split('-');
      let stedate = this.ruleRangeEnd[0].split('-');

      this.dateStart = this.datepipe.transform((stdate[1] + '/' + stdate[2] + '/' + stdate[0]), 'MM/dd/yyyy h:mm:ss');  //dateRangeStart.value.toString();// this.datepipe.transform((dateRangeStart.value), 'dd/MM/yyyy h:mm:ss');
      this.dateEnd = this.datepipe.transform((stedate[1] + '/' + stedate[2] + '/' + stedate[0]), 'MM/dd/yyyy h:mm:ss');//dateRangeEnd.value.toString();//this.datepipe.transform((dateRangeEnd.value), 'dd/MM/yyyy h:mm:ss');
      this.InquiryData_Get(this.authorizationResponse.companyID, this.initialValue.cmsCompID.toString(), this.initialValue.branchID.toString(), this.authorizationResponse.customer, 'invoice', this.currentLang, this.SelectedAccount, this.authorizationResponse.user, this.prepareSampleRqst(), this.token);
      this.InquiryData_Get(this.authorizationResponse.companyID, this.initialValue.cmsCompID.toString(), this.initialValue.branchID.toString(), this.authorizationResponse.customer, 'receipt', this.currentLang, this.SelectedAccount, this.authorizationResponse.user, this.prepareSampleRqst(), this.token);
    }
  }

  getDateRangeStatment(e) {
    if (e && e.startDate && e.endDate) {
      // set for checking that ruleRange is true, initially false  
      this.ruleRange = true;
      this.ruleRangeStart = e.startDate.format().split('T'); // to split
      this.ruleRangeEnd = e.endDate.format().split('T');
      let stdate = this.ruleRangeStart[0].split('-');
      let stedate = this.ruleRangeEnd[0].split('-');

      this.dateStartst = this.datepipe.transform((stdate[1] + '/' + stdate[2] + '/' + stdate[0]), 'MM/dd/yyyy h:mm:ss');  //dateRangeStart.value.toString();// this.datepipe.transform((dateRangeStart.value), 'dd/MM/yyyy h:mm:ss');
      this.dateEndst = this.datepipe.transform((stedate[1] + '/' + stedate[2] + '/' + stedate[0]), 'MM/dd/yyyy h:mm:ss');//dateRangeEnd.value.toString();//this.datepipe.transform((dateRangeEnd.value), 'dd/MM/yyyy h:mm:ss');
      this.StetementData_Get(this.prepareSampleRqstSt(), this.SelectedAccount, this.authorizationResponse.companyID, this.accountNo, this.authorizationResponse.customer, this.currentLang, this.token);
    }
  }

  GetQuerySuggestions(term: string, RecordsCount: string, QueryNumber: string, ParamID: string, ParamID2: string, token: string) {
    this._dahboardService.GetQuerySuggestions(term, RecordsCount, QueryNumber, ParamID, ParamID2, this.initialValue.branchID.toString(), this.authorizationResponse.companyID, token).then((result) => {
      if (result) {
        this.jSONObjectModelResponse = UtilityService.clone(result);
        this.jsonObjectModelList = this.jSONObjectModelResponse.jsonObjectModelList;
        this.filteredQuery = this.Mycontrol.valueChanges.pipe(
          startWith(''),
          map(value => this._filter(value))
        );
        //console.log('query seggession', this.jSONObjectModelResponse);
      }
    });
  }

  private _filter(value: string): JSONObjectModel[] {
    const filterValue = this._normalizeValue(value);
    return this.jsonObjectModelList.filter(query => this._normalizeValue(query.name).includes(filterValue));
  }

  private _normalizeValue(value: string): string {
    return value.toLowerCase().replace(/\s/g, '');
  }

  showNav(typeNav) {
    this.shItems = typeNav;    
    //if (typeNav == 'R') {nav-link active
    //  this.GetQuerySuggestions('0', '100', '1036', '11503', '10076', this.token);
    //}
  }

  openMaintananceForm() {
    this.showRealEstateMaintenanceForm = false;


  }
  createMaintenanceForm() {
    this.maintenance.forEach(e => {
      this.maintenanceForm.addControl(e.category, new FormControl(''))
    })
    this.showForm = true;
    //console.log("this.createMaintenanceForm :", this.maintenanceForm)
  }

  maintenanceFormSubmit() {
    //console.log("this.maintenanceForm.value :", this.maintenanceForm.value)
    if (this.maintenanceForm.valid) {
      var updateValues = <UpdateValues>{};
      var fileDetails = <UploadFiles>{};
      updateValues.datavalues = [];
      updateValues.controlNames = [];
      updateValues.cLCatId = [];
      updateValues.uploadFiles = [];
      this.maintenance.forEach(e => {
        var txt;
        switch (e.controlType) {
          case "text": {
            updateValues.datavalues.push("'" + (this.maintenanceForm?.get(e.category)?.value) + "'");
            break;
          }
          case "upload": {
            updateValues.datavalues.push("'" + this.filename + "'");
            break;
          }
          case "advpick": {
            updateValues.datavalues.push("'" + (this.maintenanceForm?.get(e.category)?.value) + "'");
            break;
          }
          default: {
            updateValues.datavalues.push(this.maintenanceForm?.get(e.category)?.value);
          }
        }
        updateValues.controlNames.push(e.category);
        updateValues.cLCatId.push(e.clCatId);
      })
      updateValues.seqNo = this.formDetailsResponse.formDetailsList[0].seqNo;

      fileDetails.file = this.uploadedFile;
      fileDetails.fileName = this.filename;
      updateValues.uploadFiles.push(fileDetails);
      //console.log("updateValues.datavalues :", updateValues)
      this._dahboardService.maintenanceForm_Submit(this.initialValue?.branchID.toString(), this.customerResponse?.customerFormsList[0].id, this.authorizationResponse?.contactID.toString(), this.authorizationResponse?.caid.toString(), this.authorizationResponse?.contactID.toString(), this.authorizationResponse?.contactEmail.toString(), this.langId.toString(), updateValues).then((result) => {
        if (result) {
          this.statusMessage = UtilityService.clone(result);
          //console.log("maintenanceForm_Submit :", this.statusMessage);
          if (this.statusMessage.stType == '1') {
            this._notifyService.showSuccess(this.statusMessage.stMessage, 'Maintenance form')
            //alert(this.statusMessage.stMessage);
            this.maintenanceForm.reset();
            this.showForm = false;
          } else {
            this._notifyService.showError(this.statusMessage.stMessage, 'Maintenance form')
            //alert(this.statusMessage.stMessage);
            this.showForm = true;
          }
        }
      });
    } else {
      alert("Please fill all fields!!!")
    }

  }

  routeUlr() {
    ;
    this._route.navigate(['/myproperties']);
  }

  NoDataFound(){
    this._toasterService.warning("You do not have any data", "NO Data")
  }

  GetFilterData($event) {
    //console.log('textdate value', $event.endDate);
  }

  prepareSampleRqst() {
    return this.simpleRequest = {
      messageBool: true,
      messageBool2: true,
      messageInt1: 0,
      messageInt2: 0,
      messageString1: '',
      messageString2: '',
      messageString3: '',
      dt1: this.dateStart,
      dt2: this.dateEnd
    }
  }

  prepareSampleRqstSt() {
    return this.simpleRequest = {
      messageBool: true,
      messageBool2: true,
      messageInt1: 0,
      messageInt2: 0,
      messageString1: '',
      messageString2: '',
      messageString3: '',
      dt1: this.dateStartst,
      dt2: this.dateEndst
    }
  }

  GetInitialValues() {

    this.Filter_Branch_Get('2', this.currentLang, this.authorizationResponse.contactID, this.initialValue.branchID, this.authorizationResponse.customerCRID, this.authorizationResponse.caid, this.authorizationResponse.companyID, this.initialValue.cmsCompID.toString(), this.authorizationResponse.customer, this.initialValue.groupedBranchesId, this.token)
    this.getPaymentStatement(this.authorizationResponse.companyID, this.initialValue.branchID, this.currentLang, this.authorizationResponse.contactID.toString(), this.initialValue.groupedBranchesId, this.token);
  }

  GetDashboardData(currentLang, caid, customer, customerDecimal, user, branchID, companyId, token: string,isload:string) {
    this._dahboardService.Dashboard_GetData(currentLang, caid, customer, customerDecimal, user, branchID, companyId, token).then((result) => {
      if (result) {
        this.contractsListResponse = UtilityService.clone(result);
        if (isload != 'Load') {
          this.actLoad = true;
          this.contractDetails = this.contractsListResponse.contractDetails;
        }
        this.dashBoardDetails = this.contractsListResponse.dashBoardDetails;
        this.displayData = true;
        this.loading = false;
        //decodeURI(this.aboutus.sideMenuItems[0].URL)
      }
      else {
        this._route.navigate(['/login']);
      }
    });
  }

  getPaymentStatement(companyId: string, selectedBranchID: number, lang: string, contactId: string, groupedBranches: string, token: string) {
    this._dahboardService.PaymentStatements_GetAsync(companyId, selectedBranchID, lang, contactId, groupedBranches, token).then((result) => {
      if (result) {

        this.paymentStatementResponse = UtilityService.clone(result);
        //console.log('statement payment', this.paymentStatementResponse);
        this.clearInvoiceData();
        this.displayAcData = true;
        if (!isNullOrUndefined(this.paymentStatementResponse)) {
          this.paymentStInvoices = this.paymentStatementResponse.paymentStatements.filter(x => x.amtType == 'Invoice');
          this.paymentStInvoices.forEach((value, index) => {
            this.TotalInvoice.jan = isNullOrUndefined(this.TotalInvoice.jan) ? (0 + value.jan) : (+this.TotalInvoice.jan + (+value.jan));
            this.TotalInvoice.feb = isNullOrUndefined(this.TotalInvoice.feb) ? (0 + value.feb) : (+this.TotalInvoice.feb + (+value.feb));
            this.TotalInvoice.march = isNullOrUndefined(this.TotalInvoice.march) ? (0 + value.march) : (+this.TotalInvoice.march + (+value.march));
            this.TotalInvoice.april = isNullOrUndefined(this.TotalInvoice.april) ? (0 + value.april) : (+this.TotalInvoice.april + (+value.april));
            this.TotalInvoice.may = isNullOrUndefined(this.TotalInvoice.may) ? (0 + value.may) : (+this.TotalInvoice.may + (+value.may));
            this.TotalInvoice.june = isNullOrUndefined(this.TotalInvoice.june) ? (0 + value.june) : (+this.TotalInvoice.june + (+value.june));
            this.TotalInvoice.july = isNullOrUndefined(this.TotalInvoice.july) ? (0 + value.july) : (+this.TotalInvoice.jan + (+value.july));
            this.TotalInvoice.august = isNullOrUndefined(this.TotalInvoice.august) ? (0 + value.august) : (+this.TotalInvoice.august + (+value.august));
            this.TotalInvoice.september = isNullOrUndefined(this.TotalInvoice.september) ? (0 + value.september) : (+this.TotalInvoice.september + (+value.september));
            this.TotalInvoice.october = isNullOrUndefined(this.TotalInvoice.october) ? (0 + value.october) : (+this.TotalInvoice.october + (+value.october));
            this.TotalInvoice.november = isNullOrUndefined(this.TotalInvoice.november) ? (0 + value.november) : (+this.TotalInvoice.november + (+value.november));
            this.TotalInvoice.december = isNullOrUndefined(this.TotalInvoice.december) ? (0 + value.december) : (+this.TotalInvoice.december + (+value.december));
            this.TotalInvoice.overYear = isNullOrUndefined(this.TotalInvoice.overYear) ? (0 + value.overYear) : (+this.TotalInvoice.overYear + (+value.overYear));
            this.TotalInvoice.tot = isNullOrUndefined(this.TotalInvoice.tot) ? (0 + value.tot) : (+this.TotalInvoice.tot + (+value.tot));

          });

          //console.log(this.TotalInvoice);

          this.paymentStSettlements = this.paymentStatementResponse.paymentStatements.filter(x => x.amtType == 'settlement');
          this.paymentStSettlements.forEach((value, index) => {
            this.TotalSettalement.jan = isNullOrUndefined(this.TotalSettalement.jan) ? (0 + parseFloat(value.jan)) : (parseFloat(this.TotalSettalement.jan) + (parseFloat(value.jan)));
            this.TotalSettalement.feb = isNullOrUndefined(this.TotalSettalement.feb) ? (0 + value.feb) : (+this.TotalSettalement.feb + (+value.feb));
            this.TotalSettalement.march = isNullOrUndefined(this.TotalSettalement.march) ? (0 + value.march) : (+this.TotalSettalement.march + (+value.march));
            this.TotalSettalement.april = isNullOrUndefined(this.TotalSettalement.april) ? (0 + value.april) : (+this.TotalSettalement.april + (+value.april));
            this.TotalSettalement.may = isNullOrUndefined(this.TotalSettalement.may) ? (0 + value.may) : (+this.TotalSettalement.may + (+value.may));
            this.TotalSettalement.june = isNullOrUndefined(this.TotalSettalement.june) ? (0 + value.june) : (+this.TotalSettalement.june + (+value.june));
            this.TotalSettalement.july = isNullOrUndefined(this.TotalSettalement.july) ? (0 + value.july) : (+this.TotalSettalement.jan + (+value.july));
            this.TotalSettalement.august = isNullOrUndefined(this.TotalSettalement.august) ? (0 + value.august) : (+this.TotalSettalement.august + (+value.august));
            this.TotalSettalement.september = isNullOrUndefined(this.TotalSettalement.september) ? (0 + value.september) : (+this.TotalSettalement.september + (+value.september));
            this.TotalSettalement.october = isNullOrUndefined(this.TotalSettalement.october) ? (0 + value.october) : (+this.TotalSettalement.october + (+value.october));
            this.TotalSettalement.november = isNullOrUndefined(this.TotalSettalement.november) ? (0 + value.november) : (+this.TotalSettalement.november + (+value.november));
            this.TotalSettalement.december = isNullOrUndefined(this.TotalSettalement.december) ? (0 + value.december) : (+this.TotalSettalement.december + (+value.december));
            this.TotalSettalement.overYear = isNullOrUndefined(this.TotalSettalement.overYear) ? (0 + value.overYear) : (+this.TotalSettalement.overYear + (+value.overYear));
            this.TotalSettalement.tot = isNullOrUndefined(this.TotalSettalement.tot) ? (0 + value.tot) : (+this.TotalSettalement.tot + (+value.tot));

          })


          this.paymentStNet = this.paymentStatementResponse.paymentStatements.filter(x => x.amtType == 'Net');
          this.paymentStNet.forEach((value, index) => {
            this.TotalNet.jan = isNullOrUndefined(this.TotalNet.jan) ? (0 + value.jan) : (+this.TotalNet.jan + (+value.jan));
            this.TotalNet.feb = isNullOrUndefined(this.TotalNet.feb) ? (0 + value.feb) : (+this.TotalNet.feb + (+value.feb));
            this.TotalNet.march = isNullOrUndefined(this.TotalNet.march) ? (0 + value.march) : (+this.TotalNet.march + (+value.march));
            this.TotalNet.april = isNullOrUndefined(this.TotalNet.april) ? (0 + value.april) : (+this.TotalNet.april + (+value.april));
            this.TotalNet.may = isNullOrUndefined(this.TotalNet.may) ? (0 + value.may) : (+this.TotalNet.may + (+value.may));
            this.TotalNet.june = isNullOrUndefined(this.TotalNet.june) ? (0 + value.june) : (+this.TotalNet.june + (+value.june));
            this.TotalNet.july = isNullOrUndefined(this.TotalNet.july) ? (0 + value.july) : (+this.TotalNet.jan + (+value.july));
            this.TotalNet.august = isNullOrUndefined(this.TotalNet.august) ? (0 + value.august) : (+this.TotalNet.august + (+value.august));
            this.TotalNet.september = isNullOrUndefined(this.TotalNet.september) ? (0 + value.september) : (+this.TotalNet.september + (+value.september));
            this.TotalNet.october = isNullOrUndefined(this.TotalNet.october) ? (0 + value.october) : (+this.TotalNet.october + (+value.october));
            this.TotalNet.november = isNullOrUndefined(this.TotalNet.november) ? (0 + value.november) : (+this.TotalNet.november + (+value.november));
            this.TotalNet.december = isNullOrUndefined(this.TotalNet.december) ? (0 + value.december) : (+this.TotalNet.december + (+value.december));
            this.TotalNet.overYear = isNullOrUndefined(this.TotalNet.overYear) ? (0 + value.overYear) : (+this.TotalNet.overYear + (+value.overYear));
            this.TotalNet.tot = isNullOrUndefined(this.TotalNet.tot) ? (0 + value.tot) : (+this.TotalNet.tot + (+value.tot));

          })
          this.payTypeDisplay = [];
          let crntValue = '';
          this.paymentStatementResponse.paymentStatements.forEach((currentValue, index) => {
            if (currentValue.subActivityName) {
              if (crntValue != currentValue.subActivityName) {
                crntValue = currentValue.subActivityName
                this.payTypeDisplay.push(currentValue.subActivityName);
              }
            }
          });

          if ((!isNullOrUndefined(this.paymentStInvoices) && (this.paymentStInvoices.length > 0))
            && (!isNullOrUndefined(this.paymentStSettlements) && (this.paymentStSettlements.length > 0)
              && (!isNullOrUndefined(this.paymentStNet) && (this.paymentStNet.length > 0)
              ))) {
            this.displayAcData = true;
          }
        }

        this.loading = false;
        //console.log(this.paymentStatementResponse);
      }
      else {
        this._route.navigate(['/login']);
      }
    });
  }

  clearInvoiceData() {
    this.TotalInvoice.jan = 0;
    this.TotalInvoice.feb = 0;
    this.TotalInvoice.march = 0;
    this.TotalInvoice.april = 0;
    this.TotalInvoice.may = 0;
    this.TotalInvoice.june = 0;
    this.TotalInvoice.july = 0;
    this.TotalInvoice.august = 0;
    this.TotalInvoice.september = 0;
    this.TotalInvoice.october = 0;
    this.TotalInvoice.november = 0;
    this.TotalInvoice.december = 0;
    this.TotalInvoice.overYear = 0;
    this.TotalInvoice.tot = 0;
    this.TotalSettalement.jan = 0;
    this.TotalSettalement.feb = 0;
    this.TotalSettalement.march = 0;
    this.TotalSettalement.april = 0;
    this.TotalSettalement.may = 0;
    this.TotalSettalement.june = 0;
    this.TotalSettalement.july = 0;
    this.TotalSettalement.august = 0;
    this.TotalSettalement.september = 0;
    this.TotalSettalement.october = 0;
    this.TotalSettalement.november = 0;
    this.TotalSettalement.december = 0;
    this.TotalSettalement.overYear = 0;
    this.TotalSettalement.tot = 0;
    this.TotalNet.jan = 0;
    this.TotalNet.feb = 0;
    this.TotalNet.march = 0;
    this.TotalNet.april = 0;
    this.TotalNet.may = 0;
    this.TotalNet.june = 0;
    this.TotalNet.july = 0;
    this.TotalNet.august = 0;
    this.TotalNet.september = 0;
    this.TotalNet.october = 0;
    this.TotalNet.november = 0;
    this.TotalNet.december = 0;
    this.TotalNet.overYear = 0;
    this.TotalNet.tot = 0;
  }
  

  ViewReciept(type) {
    this.rptType = type;
    /*this.InquiryData_Get(this.companyId, this.branchID, this.branchID, this.customerId, this.rptType, this.currentLang, this.caid, this.prepareSampleRqst(), this.token)*/
  }

  customerPermissionGet() {
    this._dahboardService.CustomerPermissionFormsGet((this.customerarray.contactID).toString(), this.customerarray.companyID, this.formType).then((result) => {
      if (result) {
        this.customerResponse = UtilityService.clone(result);
        //console.log("customerPermissionGet :", this.customerResponse);
        if (!isNullOrUndefined(this.customerResponse.customerFormsList) && this.customerResponse.customerFormsList.length > 0) {
          this.formControlsGet(this.customerResponse.customerFormsList[0].id, (this.customerarray.contactID).toString(), this.customerarray.caid, (this.customerarray.contactID).toString(), (this.langId).toString());
          this.customerFormsGet((this.customerarray.contactID).toString());
          this.GetQuerySuggestions('0', '100', '1036', '11503', '10076', this.token);
        }
      }
    });
  }

  formControlsGet(ProductID: string, contactid: string, caid: string, customerid: string, lang: string) {
    this._dahboardService.FormComponentsGet(ProductID, contactid, caid, true, customerid, lang, "add").then((result) => {
      if (result) {
        this.formDetailsResponse = UtilityService.clone(result);
        this.maintenance = this.formDetailsResponse.formDetailsList[0].fromControls;
        this.title = this.formDetailsResponse.formDetailsList[0].title;
        this.createMaintenanceForm();
        //console.log("this.formControlsGet :", this.formDetailsResponse);
      }
    });
  }

  customerFormsGet(contactid: string) {
    this._dahboardService.CustomerFormsGet(contactid).then((result) => {
      if (result) {
        this.displayDetailsResponse = UtilityService.clone(result);
        //console.log("customerFormsGet :", this.displayDetailsResponse);
      }
    });
  }
  InquiryData_Get(companyID: string, cmsCompanyID: string, branchId: string, customerId: string, type: string, currentLang: string, caid: string, userId:string, simpleRequest: SimpleRequest, token: string) {

    this._inquiryService.InquiryData_GetAsync(companyID, cmsCompanyID, branchId, customerId, type, currentLang, caid, userId, simpleRequest, token).then((result) => {
      if (result) {
        this.custInquiryResponse = UtilityService.clone(result);
        //console.log(this.custInquiryResponse);
        //if (!isNullOrUndefined(this.custInquiryResponse.custInquiryInvoice) && this.custInquiryResponse.custInquiryInvoice.length > 0)
        if (type == 'invoice') {
          this.custInquiryInvoice = this.custInquiryResponse.custInquiryInvoice;
          if (!isNullOrUndefined(this.custInquiryInvoice)) {
            this.msgString1 = '';
            this.totalInvamt = 0;
            this.totalinvpmt = 0;
            this.totalinvBlnc = 0;
            this.custInquiryInvoice.forEach(e => {
              this.totalInvamt = this.totalInvamt + (+e.amount);
              this.totalinvpmt = this.totalInvamt + (+e.payment);
              this.totalinvBlnc = this.totalinvBlnc + (+e.balance);
            });              
          }
          else
            this.msgString1 = 'No data has been found';
        }
        else {   
          this.custInquiryReciept = this.custInquiryResponse.custInquiryReciept;
          if (!isNullOrUndefined(this.custInquiryReciept)) {
            this.msgString = '';
            this.totalrctBlnc = 0;
            this.custInquiryReciept.forEach(e => {
              this.totalrctBlnc = this.totalrctBlnc + (+e.amount);             
            });              
            
          }
          else
            this.msgString = 'No data has been found';
        }
        //console.log('invoice data', this.custInquiryInvoice);
        this.loading = false;
      }
      else {
        this._route.navigate(['/login']);
      }
    });
  }

  StetementData_Get(simpleRequest: SimpleRequest, caid: string, selectedCompanyID: string, account: string, customer: string, currentlang: string, token: string) {

    this._inquiryService.InquiryStatment_GetAsync(simpleRequest,caid, selectedCompanyID,this.initialValue.branchID.toString(), account, customer, currentlang,token).then((result) => {
      if (result) {
        this.statementOfAccountResponse = UtilityService.clone(result);
        //console.log('this.statementOfAccountResponse',this.statementOfAccountResponse);
        if (!isNullOrUndefined(this.statementOfAccountResponse.statementOfAccount_list)) {
          this.statementOfAccount_list = this.statementOfAccountResponse.statementOfAccount_list;
          if (!isNullOrUndefined(this.statementOfAccount_list) && this.statementOfAccount_list.length > 0) {
            this.isShow = true;
            this.rptLink = this.statementOfAccount_list[0].reportLink;
            this.totalDr = 0;
            this.totalCr = 0;
            this.totalBlnc = 0;
            this.statementOfAccount_list.forEach(e => {
              if (e.description != 'Opening Balance') {
                this.totalDr = this.totalDr + (+e.debit);
                this.totalCr = this.totalCr + (+e.credit);
                this.totalBlnc = +e.balance;
              }
              else {
                this.totalBlnc = +e.balance;
              }
            });
          }
          else
            this.isShow = false;
        }
        this.loading = false;
      }
      else {
        this._route.navigate(['/login']);
      }
    });
  }

  Filter_Account_Get(crid: string, currentLang: string, contactID: string, token: string) {
    this._inquiryService.Filter_Account_GetAsync(crid, currentLang, contactID, token).then((result) => {
      if (result) {
        this.filterAccountResponse = UtilityService.clone(result);
        //console.log('Filter_Account_Get', this.filterAccountResponse);
        this.filterAccount = this.filterAccountResponse.filterAccount.reverse();
        if (this.filterAccount.length > 0) {
          this.isfilterAccountSelect = false;
          this.accountText = this.filterAccount[0].key;
          this.SelectedAccount = this.filterAccount[0].value;
          localStorage.setItem("CAID", this.SelectedAccount)
          this.GetDashboardData(this.currentLang, this.SelectedAccount, this.authorizationResponse.customer, this.authorizationResponse.customerDecimal, this.authorizationResponse.user, this.initialValue.branchID, this.authorizationResponse.companyID, this.token,'');
          this.InquiryData_Get(this.authorizationResponse.companyID, this.initialValue.cmsCompID.toString(), this.initialValue.branchID.toString(), this.authorizationResponse.customer, 'invoice', this.currentLang, this.SelectedAccount, this.authorizationResponse.user, this.prepareSampleRqst(), this.token);
          this.InquiryData_Get(this.authorizationResponse.companyID, this.initialValue.cmsCompID.toString(), this.initialValue.branchID.toString(), this.authorizationResponse.customer, 'receipt', this.currentLang, this.SelectedAccount, this.authorizationResponse.user, this.prepareSampleRqst(), this.token);
          this.Get_FilterAccount2_Data(this.authorizationResponse.customer, this.SelectedAccount, this.currentLang, this.token);
          this.Get_Payment_Data(this.initialValue.branchID.toString(), this.authorizationResponse.companyID, this.currentLang, this.SelectedAccount, this.authorizationResponse.customer, this.authorizationResponse.loginID, this.currencyCode, this.token);
         

        }
      }
      else {
        this._route.navigate(['/login']);
      }
    });
  }

  Filter_Branch_Get(ct: string, currentLang: string, contactId: number, currentBranchID: number, crid: string, caid: string, companyID: string, cmsCompanyId: string, customerId: string, groupedBranches: string, token: string) {
    this._inquiryService.Filter_Branch_GetAsync(ct, currentLang, contactId, currentBranchID, crid, caid, companyID, cmsCompanyId, customerId, groupedBranches, token).then((result) => {
      if (result) {
        this.filtersBranchResponse = UtilityService.clone(result);
        //console.log('Breanch', this.filtersBranchResponse);
        this.filterBranch = this.filtersBranchResponse.filtersCustomerInq;
        if (this.filterBranch.length > 0) {
          
          this.isfilterBranchSelect = false
          //this.filterBranch = this.filterBranch.splice(0, 0)
          this.branchText = this.filterBranch[0].value;
          this.SelectedBranch = this.filterBranch[0].key;
          this.Filter_Account_Get(this.filterBranch[0].key, this.currentLang, this.authorizationResponse.contactID.toString(), this.token);
        }
      }
      else {
        this._route.navigate(['/login']);
      }
    });
  }

  invalidDates: moment.Moment[] = [moment().add(2, 'days'), moment().add(3, 'days'), moment().add(5, 'days')];

  isInvalidDate = (m: moment.Moment) => {
    return this.invalidDates.some(d => d.isSame(m, 'day'))
  }

  Get_FilterAccount2_Data(customer, currentLang, caid, token: string) {

    this._dahboardService.FilterAccount2_Get(customer, caid, currentLang, token).then((result) => {
      if (result) {
        this.simpleResponse = UtilityService.clone(result);
        this.filter = this.simpleResponse.filter;
        if (this.filter.length > 0)
          this.accountNo = this.filter[0].key;
        //console.log('FilterAccount2', this.simpleResponse);
        this.StetementData_Get(this.prepareSampleRqstSt(), this.SelectedAccount, this.authorizationResponse.companyID, this.accountNo, this.authorizationResponse.customer, this.currentLang, this.token);
      }
      else {
        this._route.navigate(['/login']);
      }
    });
  }


  FilAcChange($event) {
    this.SelectedAccount = $event.target.value;
    //console.log('accvalue', this.SelectedAccount);
  }

  AccountChange($event) {
    this.accountNo = $event.target.value;
    this.StetementData_Get(this.prepareSampleRqstSt(), this.SelectedAccount, this.authorizationResponse.companyID, $event.target.value, this.authorizationResponse.customer, this.currentLang, this.token);
  }

  Get_Payment_Data(SelectedBranchID: string, companyId: string, currentLang: string, caid: string, customer: string, loginID: string, selectedCCSwiftCode: string, token: string) {

    this._dahboardService.Payment_Get(SelectedBranchID, companyId, currentLang, caid, customer, loginID, selectedCCSwiftCode, token).then((result) => {
      if (result) {
        this.paymentResponseAQ = UtilityService.clone(result);
        this.paymentDetails = this.paymentResponseAQ.payment;
        if (!isNullOrUndefined(this.paymentDetails) && this.paymentDetails.length>0) {
          this.paymentDetails.forEach(element => {
            element['isEdit'] = false;
            this.isPayment = true;
          });
        }
        this.loading = false;
      }
      else {
        this._route.navigate(['/login']);
      }
    });
  }

  GetInvoiceId($event) {

    this.invoiceValue.push($event.target.value);
    //console.log('inv val', this.invoiceValue);
  }

  Credit_Click() {
    if (this.invoiceValue.length > 0) {
      this._dahboardService.CreditCard_Payment(this.authorizationResponse.customer, this.authorizationResponse.loginID, this.currentLang, ePaymentMethod.Credit, this.initialValue.branchID.toString(), this.currencyCode, this.authorizationResponse.companyID, this.authorizationResponse.caid, this.token, this.invoiceValue).then((result) => {
        if (result) {
          this.checkoutSessionModelResposne = UtilityService.clone(result);
          localStorage.setItem('checkoutSessionModelResposne', JSON.stringify(this.checkoutSessionModelResposne));
          //console.log('Credit_payment', this.checkoutSessionModelResposne);
          this._route.navigate(['/pgredirct']);
        }
      });
    }
    else
      this._notifyService.showInfo('Payment processing cannot be empty',"Payment")
  }

  handleFileInput(event, c) {

    if (event.target.files.length > 0) {
      const file = event.target.files[0];
      //console.log("file :", file);
      this.filename = file.name;
      const reader = new FileReader();
      reader.readAsDataURL(file);
      reader.onload = () => {
        this.uploadedFile = reader.result;
      };
      //console.log("this.uploadedFile :", this.uploadedFile.length);

    }
  }

  propertyAll() {
    localStorage.setItem('Qsearch', JSON.stringify(this.prepareFormSearchAll()));
    this._route.navigate(['/properties/0/0']);

  }

  prepareFormSearchAll() {
    return this.propertySearchParams = {
      branchId: this.initialValue.branchID,
      countryCode: this.countryCode,
      contactId: '0', currentLang: this.langId, cAID: 0,
      groupedBranches: this.initialValue.groupedBranchesId, page: '',
      sort: '', type: '', limit: '4000',
      searchTerm: '',
      propertyType2: '',
      propertyType3: '',
      propertyLocation: '',
      minArea: '',
      maxArea: '',
      minPrice: '',
      maxPrice: '', features: '',
      minBeds: '',
      maxBeds: '',
      minBaths: '',
      maxBaths: '',
      electricity: '',
      Furnicher: '', count: false,
      nonVacant: '',
      agentID: '', parentID: '',
      myProperty: true,
      currencyDetails: {
        selectedCcyCode: this.currencyDetail?.selectedCcyCode,
        selectedCcyName: this.currencyDetail?.selectedCcyName,
        selectedCcySwift: this.currencyDetail?.selectedCcySwift,
        selectedCcySwiftCodeArb: this.currencyDetail?.selectedCcySwiftCodeArb,
        selectedCcySwiftCode: this.currencyDetail?.selectedCcySwiftCode,
        selectedCcyDecimal: this.currencyDetail?.selectedCcyDecimal,
      }
    }
  }

  applyFilter(type) {
    switch (type) {
      case 'D':
        this.loading = true;
        this.GetDashboardData(this.currentLang, this.SelectedAccount, this.authorizationResponse.customer, this.authorizationResponse.customerDecimal, this.authorizationResponse.user, this.initialValue.branchID, this.authorizationResponse.companyID, this.token,'Notload');
        break;
      case 'S':
        this.loading = true;
        this.getPaymentStatement(this.authorizationResponse.companyID, +this.SelectedBranch, this.currentLang, this.authorizationResponse.contactID.toString(), this.initialValue.groupedBranchesId, this.token);
        break;
      case 'P': this.loading = true; this.Get_Payment_Data(this.initialValue.branchID.toString(), this.authorizationResponse.companyID, this.currentLang, this.SelectedAccount ? this.SelectedAccount : this.authorizationResponse.caid, this.authorizationResponse.customer, this.authorizationResponse.loginID, this.currencyCode, this.token);
        break;
      case 'I': this.loading = true;
        this.InquiryData_Get(this.authorizationResponse.companyID, this.initialValue.cmsCompID.toString(), this.initialValue.branchID.toString(), this.authorizationResponse.customer, 'invoice', this.currentLang, this.SelectedAccount ? this.SelectedAccount : this.authorizationResponse.caid, this.authorizationResponse.user, this.prepareSampleRqst(), this.token);
        this.InquiryData_Get(this.authorizationResponse.companyID, this.initialValue.cmsCompID.toString(), this.initialValue.branchID.toString(), this.authorizationResponse.customer, 'receipt', this.currentLang, this.SelectedAccount ? this.SelectedAccount : this.authorizationResponse.caid, this.authorizationResponse.user, this.prepareSampleRqst(), this.token);
        break;
      case 'ST': this.loading = true;
        this.StetementData_Get(this.prepareSampleRqstSt(), this.SelectedAccount, this.authorizationResponse.companyID, this.accountNo, this.authorizationResponse.customer, this.currentLang, this.token);
        break;
      default:
        break;
    }
  }

  getUploadConfig() {
    this.afuConfig = {
      theme: 'dragNDrop',
      hideResetBtn: false,
      hideSelectBtn: false,
      maxSize: 10,
      uploadAPI: {
        url: 'https://angular.aqaraats.com/api/FormsController/uploadMaintennaceFile/' + this.authorizationResponse.contactID,

        headers: {
        },
      },
      /*formatsAllowed: '.xlsx, .xls, .csv',*/
      multiple: false,
      replaceTexts: {
        selectFileBtn: 'Select FIle',
        resetBtn: 'Reset',
        uploadBtn: 'Validate File',
        dragNDropBox: 'Drag and Drop your file here',
        attachPinBtn: 'Attach Files...',
        afterUploadMsg_success: 'Successfully Uploaded!',
        afterUploadMsg_error: 'Upload Failed!',
      }
    };
  }

  PaymentCahange($event) {
    this.paymentValue = $event.target.value;
  }

  EditData(data) {
    this.prePaymentValue = data.paid;
    data.isEdit = true;
  }
  SaveData(data) {
    if (Number(data.paid) <= Number(this.prePaymentValue)) {
      data.isEdit = false;
      this._dahboardService.Payment_Update(this.preparePayment(data), this.langId.toString(), this.initialValue.branchID.toString(), this.authorizationResponse.companyID, this.authorizationResponse.caid, this.authorizationResponse.customer, this.authorizationResponse.loginID, this.currencyCode, this.token).then((result) => {
        if (result) {
          this.paymentResponseAQ = UtilityService.clone(result);

          //console.log('update_payment', this.paymentResponseAQ);
        }
      });
    }
    else {
      this._notifyService.showError("", "Amount to pay cannot exceed (" + this.prePaymentValue + ")");
    }
  }
  CancelData(data) {
    data.isEdit = false;
  }

  preparePayment(data) {
    return this.updatePayment = {
      id: data.invoiceID,
      actualPaymentValue: this.prePaymentValue,
      chanagePaymentValue: data.paid
    }
  }

  Debit_Click() {
    if (this.invoiceValue.length > 0) {
      this._dahboardService.DebitCard_Payment(this.authorizationResponse.customer, this.authorizationResponse.loginID, this.currentLang, this.initialValue.branchID.toString(), this.currencyCode, this.authorizationResponse.companyID, this.authorizationResponse.caid, this.initialValue.branchID.toString(), this.token, this.invoiceValue).then((result) => {
        if (result) {
          this.paymentResponse = UtilityService.clone(result);
          localStorage.setItem('paymentResponse', JSON.stringify(this.paymentResponse));
          //console.log('Debit_payment', this.paymentResponse);
          window.open(this.paymentResponse.result, "_self");
        }
      });
    }
    else
      this._notifyService.showInfo('Payment processing cannot be empty', "Payment")
  }

}

