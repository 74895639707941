import { Component, ElementRef } from '@angular/core';
import { Router } from '@angular/router';
import { SimpleStateService } from '../../services/simple-state.service';

@Component({
    selector: 'app-shareaddthis',
    templateUrl: './shareaddthis.component.html',
    styleUrls: ['./shareaddthis.component.css']
})
/** shareaddthis component*/
export class ShareaddthisComponent {
  addThisUrl = "UrlNotSet";
  initSocialComplete: boolean = false;
  socialElements = new Array<ElementRef>(); 
  marketAreaID = 0;
  propertyTypeID = 0;
  siteUrl: string = '';
    /** shareaddthis ctor */
  constructor(private _router: Router,private simpleState: SimpleStateService) {
    this.siteUrl = this._router.url;
    simpleState.pageChange$.subscribe(newPage => {
      this.simpleState.refreshAddThis();

      let url = this.buildUrl();
      this.setUrl(url);
    });
  }

  buildUrl(): string {
    let url = 'http://test.resourceAnalytix.com';
    url += '/' + this.simpleState.clientUrlSegment;
    url += '/' + this.simpleState.pageName;
    url += '?' + 'marketAreaID=' + this.simpleState.marketAreaID;
    url += '&' + 'propertyTypeID=' + this.simpleState.propertyTypeID;
    this.addThisUrl = url;

    return url;
  }

  setUrl(urlIn: string) {
    this.simpleState.refreshAddThis();

    this.socialElements.forEach((el, idx) => {
      if (el.nativeElement != null) {
        el.nativeElement.setAttribute('data-url', urlIn);
        el.nativeElement.setAttribute('addthis:url', urlIn);
      }
    });

    if (this.initSocialComplete) {
      if (window['addthis_share'])
        window['addthis_share']
          = {
          url_transforms:
          {
            add: {
              marketAreaID: this.simpleState.marketAreaID.toString()
              , propertyTypeID: this.simpleState.propertyTypeID.toString()
              , pageName: this.simpleState.pageName
            }
          },
          title: 'My Title',
          description: 'My Description'
        };

      if (window['addthis'] && window['addthis'].layers && window['addthis'].layers.refresh)
        window['addthis'].layers.refresh();
    }

  }
}
