import { HttpClient, HttpErrorResponse, HttpHeaders } from '@angular/common/http';
import { Inject, Injectable } from '@angular/core';
import { SimpleResponse } from '../models/genericResponse.model';
import { PropertySearchResultsResponse } from '../models/property';
import { delayedRetryHttp } from '../shared/delayedRetry';
import { catchError, timeout } from 'rxjs/operators';

@Injectable({ providedIn: 'root' })
export class CompareService {
  private baseUrl: string;
  private api: string = "api/Compare";
  constructor(private httpClient: HttpClient, @Inject('BASE_URL') _baseUrl: string) {
    this.baseUrl = _baseUrl + this.api;

  }

  private handleErrors(errorResponse: HttpErrorResponse) {
    if (errorResponse.error instanceof ErrorEvent) {
      console.error('default Client Side Error: ', errorResponse.error.message);
    } else {
      throw errorResponse;
    }
  }

  Compare_GetAsync(page: string, limit: string, count: boolean, type: string, branchId: number, currentlang: string, currencyCcyCode: string, groupedBranches: string): Promise<PropertySearchResultsResponse | void> {
    const a =  this.httpClient
      .get<PropertySearchResultsResponse>(`${this.baseUrl}/GetFavoriteProperties/${page}/${limit}/${count}/${type}/${branchId}/${currentlang}/${groupedBranches}`, {
        headers: new HttpHeaders({
          'Content-Type': 'application/json',
          'Authorization': 'Basic '
        })
      }).pipe(delayedRetryHttp()).toPromise().catch(err => { console.log('default error'); });

    return a;
  }

  async CompareSubmit(propertyID: string, Isadd: number, type: string, branchId: number, currentLanguage: string): Promise<SimpleResponse | void> {
    const a = await this.httpClient
      .post<SimpleResponse>(`${this.baseUrl}/AddFavoriteProperty/${propertyID}/${Isadd}/${type}/${branchId}/${currentLanguage}`, {
        headers: new HttpHeaders({
          'Content-Type': 'application/json',
          'Authorization': 'Basic '
        })
      }).pipe(delayedRetryHttp()).toPromise().catch(err => { console.log('default error'); });

    return a;
  }

  async Clear_GetAsync(propertyID: string, type: string, branchId: number, currentlang: string, isClearAll:boolean): Promise<SimpleResponse | void> {
    const a = await this.httpClient
      .get<SimpleResponse>(`${this.baseUrl}/clearCompareCategory/${propertyID}/${type}/${branchId}/${currentlang}/${isClearAll}`, {
        headers: new HttpHeaders({
          'Content-Type': 'application/json',
          'Authorization': 'Basic '
        })
      }).pipe(delayedRetryHttp()).toPromise().catch(err => { console.log('default error'); });

    return a;
  }
}
