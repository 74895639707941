import { Component, OnInit } from '@angular/core';
import { FormBuilder, FormGroup, Validators } from '@angular/forms';
import { Router } from '@angular/router';
import { isNullOrUndefined } from 'util';
import { SimpleResponse } from '../../models/genericResponse.model';
import { InitialValue, InitialValueResponse } from '../../models/initialValue';
import { User } from '../../models/login';
import { DefaultService } from '../../services/default.service';
import { LoginService } from '../../services/login.service';
import { NotificationService } from '../../shared/notification.service';
import { UtilityService } from '../../shared/utility.service';
import { ConfigurationService } from 'src/app/shared/configuration.service';

@Component({
    selector: 'app-forgot-password',
    templateUrl: './forgot-password.component.html',
    styleUrls: ['./forgot-password.component.css']
})
/** forgotPassword component*/
export class ForgotPasswordComponent implements OnInit {

  form: FormGroup;
  form1: FormGroup;
  form2: FormGroup;
  submitted = false;
  submittedotp = false;
  submittedNew = false;
  loading: boolean = false;
  loadingotp: boolean = false;
  loadingpwd: boolean = false;
  user: User;
  simpleResponse: SimpleResponse;
  isEmail: boolean = false;
  isOTP: boolean = false;
  isSetPassword: boolean = false;
  langId: number = 1;
  initialValueResponse: InitialValueResponse;
  initialValue: InitialValue;
  hostName: string = '';
  userName: string = '';
  contactId: number=0;
  contCPR: string='0';
    /** forgotPassword ctor */
  constructor(private formBuilder: FormBuilder,
    private _route: Router,
    private notifyService: NotificationService,
    private _loginService: LoginService,
    private _defaultService: DefaultService, private configurationService:ConfigurationService  ) {
    this.langId = Number(localStorage.getItem('language')) == 0 ? 1 : Number(localStorage.getItem('language'));
    this.hostName = localStorage.getItem('hostname');
  }

  get f() {
    return this.form.controls;
  }

  get f1() {
    return this.form1.controls;
  }

  get f2() {
    return this.form2.controls;
  }

  ngOnInit(): void {
    this.form = this.formBuilder.group({
      username: ['', [Validators.required, Validators.email]],
      
    });
    this.form1 = this.formBuilder.group({
      username: [''],
      userOTP: ['', [Validators.required]],

    });

    this.form2 = this.formBuilder.group({
      npwd: ['', [Validators.required]],
      cpwd: ['', [Validators.required]],

    });

    this.initialValue = this.configurationService.getInitialValues();
    // this.GetInitialValues(this.hostName,1);

    // $('html, body').animate({
    //   scrollTop: $("body").offset().top = 0
    // }, 0);
  }

  GetInitialValues(hostName, isCustomer) {
    this._defaultService.InitialValue_GetAsync(hostName, isCustomer).then((result) => {
      if (result) {
        this.initialValueResponse = UtilityService.clone(result);
        this.initialValue = this.initialValueResponse.initialValue;
       
      }
    });
  }

  submit() {
    this.submitted = true;
    this.loading = true;
    if (this.form.invalid) {
      this.loading = false;
      return;
    }
    this._loginService.ForgotPassword(this.form.controls["username"].value, this.langId.toString(), this.initialValue?.branchID.toString(), this.initialValue?.companyID.toString()).then((result) => {
      if (result) {
        

        this.simpleResponse = UtilityService.clone(result);
        //console.log(result);
        if (!isNullOrUndefined(this.simpleResponse) && this.simpleResponse.messageBool) {        
          this.loading = false;
          this.isEmail = true;
          this.isOTP = false;
          this.userName = this.form.controls["username"].value;
          this.contactId = this.simpleResponse.messageInt;
          this.contCPR = this.simpleResponse.messageString;
          this.form1.get("username").patchValue(this.form.controls["username"].value);
        }
        else {
          this.loading = false;
          this.notifyService.showError("Please enter valid email Address", "Error");
          //console.log(result);
        }
      }
      else { this.loading = false; this.notifyService.showError("Please enter valid email Address", "Error"); }
    });
    //console.log(this.prepareForm());

  }

  prepareForm() {
    return this.user = {
      loginid: this.form.controls["username"].value,      
      password: '',      
    }
  }

  submitOtp() {
    this.submittedotp = true;
    this.loadingotp = true;
    if (this.form1.invalid) {
      this.loadingotp = false;
      return;
    }
    this._loginService.validateOTP(this.form1.controls["userOTP"].value).then((result) => {
      if (result) {

        this.simpleResponse = UtilityService.clone(result);
        //console.log(result);
        if (!isNullOrUndefined(this.simpleResponse) && this.simpleResponse.messageBool) {
          this.loadingotp = false;
          this.isEmail = true;
          this.isOTP = true;
          this.isSetPassword = true;
          this.form2.get("npwd").patchValue(this.form1.controls["username"].value);
          //this._route.navigate(['/login']);
          //this.openSnackBar("Mail send successfully", "");
        }
        else {
          this.loadingotp = false;
          this.notifyService.showError("Please enter valid OTP", "Error");
          //console.log(result);
        }
      }
      else { this.loadingotp = false; this.notifyService.showError("Please enter valid OTP", "Error"); }
    });
    //console.log(this.prepareForm());

  }

  submitNewPassword() {
    
    this.submittedNew = true;
    this.loadingpwd = true;
    if (this.form2.invalid) {
      this.loadingpwd = false;
      return;
    }
    this._loginService.ResetPassword(this.form2.controls["npwd"].value, this.form2.controls["cpwd"].value,this.contactId,this.contCPR,this.langId.toString()).then((result) => {
      if (result) {

        this.simpleResponse = UtilityService.clone(result);
        //console.log(result);
        if (!isNullOrUndefined(this.simpleResponse) && this.simpleResponse.messageBool) {
          this.loadingpwd = false;
          this.isEmail = true;
          this.isOTP = true;
          this.isSetPassword = true;
          this.notifyService.showSuccess("Password changed successfully", "Password");
          //this.openSnackBar("Mail send successfully", "");
          this._route.navigate(['/login']);
          //this.openSnackBar("Mail send successfully", "");
        }
        else {
          this.loadingpwd = false;
          this.notifyService.showError("Please enter valid password", "Error");
          //console.log(result);
        }
      }
      else { this.loadingpwd = false; this.notifyService.showError("Please enter valid password", "Error"); }
    });
  }
}
