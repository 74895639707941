import { Component, ElementRef, Renderer2, OnInit, HostListener} from '@angular/core';

import { isNullOrUndefined } from 'util';

import { ConfigurationService } from './shared/configuration.service';
import { NavigationEnd, NavigationStart, Router } from '@angular/router';
import { Title } from '@angular/platform-browser';
import { convertToObject } from 'typescript/lib/tsserverlibrary';

declare var $: any;
declare let fbq:Function; // fbq function declaration 

declare let gtag: Function;

@Component({
  selector: 'app-root',
  styleUrls: ['./app.component.scss'],
  templateUrl: './app.component.html'
})


export class AppComponent {
  currentUrl: string = '';
  previousUrl: string = '';
  windowScrolled: boolean = false;
  isHostLoaded:boolean = false;
  isInitialValues:boolean = false;


  favIcon: HTMLLinkElement = null;

  
  constructor(private _route: Router, private _confiService: ConfigurationService, private _titleServie:Title,
    private renderer: Renderer2, private el: ElementRef) {
  
    if (!isNullOrUndefined(localStorage.getItem('pgredirct'))) {
      this._route.navigate(['/dashboard']);
    }
  }



  showMainTopHeader: boolean = false;

  ngOnInit() {
    
    let url = new URL(window.location.href).hostname;
    this._confiService.getDefaultData(url);
    
    this._confiService.isThemeInitialized.subscribe(resp=>{
      
      this.addGoogleAnalytics();
      this.addFacebookAnalytics();

      this.isHostLoaded  = resp;
      // if(this._confiService.isLuban || window.location.host == 'ella.aqaraats.com'){
        
      // }

    });

    this._route.events.subscribe((event) => {
      if (event instanceof NavigationEnd) {
        fbq('track', 'PageView');

        /* if(!this._confiService.isLuban) {
          gtag('config', 'G-J2R51D9DMP',
                {
                    page_path: event.urlAfterRedirects
                }
            );
        } */
        
        window.scrollTo({
          top: 0,
          behavior: 'instant' as any
        });

        $('.modal').modal('hide');

        $(document).on('click.dismissModal', function(event) {
          if (
            !$(event.target).closest('.modal').length &&
            !$(event.target).hasClass('modal') &&
            !$(event.target).closest('[data-toggle="modal"]').length
          ) {
            $('.modal').modal('hide');
          }
        });
        
        $('.modal').on('hidden.bs.modal', function() {
          $(document).off('click.dismissModal');
        });

        $('.modal').on('shown.bs.modal', function () {
          $(document).on('click.dismissModal', function (event) {
            var modal = $('.modal');
            if (!modal.is(event.target) && modal?.has(event.target).length === 0) {
              modal.modal('hide');
            }
          });
        });
      }

      if (event instanceof NavigationStart) {
        if(event.url.includes('/properties') || event.url.includes('propertydetails')) {
          this.showMainTopHeader = true;
        } else {
          this.showMainTopHeader = false;
        }
      }
    });

    window.addEventListener('scroll', () => {
      this.windowScrolled = window.pageYOffset !== 0;
    });
  }

  addFacebookAnalytics(){
    if(window.location.host == 'ella.aqaraats.com') {
      const script = this.renderer.createElement('script');
      script.text = `
        !function(f,b,e,v,n,t,s)
        {if(f.fbq)return;n=f.fbq=function(){n.callMethod?
        n.callMethod.apply(n,arguments):n.queue.push(arguments)};
        if(!f._fbq)f._fbq=n;n.push=n;n.loaded=!0;n.version='2.0';
        n.queue=[];t=b.createElement(e);t.async=!0;
        t.src=v;s=b.getElementsByTagName(e)[0];
        s.parentNode.insertBefore(t,s)}(window, document,'script',
        'https://connect.facebook.net/en_US/fbevents.js');
        fbq('init', '836748308215294');
        fbq('track', 'PageView');
      `;
      const noscript = this.renderer.createElement('noscript');
      noscript.text = `<img height="1" width="1" style="display:none"
      src="https://www.facebook.com/tr?id=836748308215294&ev=PageView&noscript=1"
      />`;

      this.renderer.appendChild(this.el.nativeElement, script);
      this.renderer.appendChild(this.el.nativeElement, noscript);
    } else if(this._confiService.isLuban){
      const script = this.renderer.createElement('script');
      script.text = `!function(f,b,e,v,n,t,s)
      {if(f.fbq)return;n=f.fbq=function(){n.callMethod?
      n.callMethod.apply(n,arguments):n.queue.push(arguments)};
      if(!f._fbq)f._fbq=n;n.push=n;n.loaded=!0;n.version='2.0';
      n.queue=[];t=b.createElement(e);t.async=!0;
      t.src=v;s=b.getElementsByTagName(e)[0];
      s.parentNode.insertBefore(t,s)}(window, document,'script',
      'https://connect.facebook.net/en_US/fbevents.js');
      fbq('init', '7447801355318460');
      fbq('track', 'PageView');`

      const noscript = this.renderer.createElement('noscript');
      noscript.text = `<img height="1" width="1" style="display:none"
      src="https://www.facebook.com/tr?id=7447801355318460&ev=PageView&noscript=1"
      />`;

      this.renderer.appendChild(this.el.nativeElement, script);
      this.renderer.appendChild(this.el.nativeElement, noscript);
    } else {
      const script = this.renderer.createElement('script');
      script.text = `
        !function(f,b,e,v,n,t,s)
        {if(f.fbq)return;n=f.fbq=function(){n.callMethod?
        n.callMethod.apply(n,arguments):n.queue.push(arguments)};
        if(!f._fbq)f._fbq=n;n.push=n;n.loaded=!0;n.version='2.0';
        n.queue=[];t=b.createElement(e);t.async=!0;
        t.src=v;s=b.getElementsByTagName(e)[0];
        s.parentNode.insertBefore(t,s)}(window, document,'script',
        'https://connect.facebook.net/en_US/fbevents.js');
        fbq('init', '1295174167861708');
        fbq('track', 'PageView');
      `;
      const noscript = this.renderer.createElement('noscript');
      noscript.text = `<img height="1" width="1" style="display:none"
      src="https://www.facebook.com/tr?id=1295174167861708&ev=PageView&noscript=1"
      />`;

      this.renderer.appendChild(this.el.nativeElement, script);
      this.renderer.appendChild(this.el.nativeElement, noscript);
    }
  }

  addGoogleAnalytics(){
    let id = 'G-J2R51D9DMP';

    if(window.location.host == 'ella.aqaraats.com') {
      id = 'G-75XK94G8PG';
    }
    
    if(this._confiService.isLuban) {
      id = 'G-X1H2X7NTEG';
    }

    const script1 = this.renderer.createElement('script');
    script1.src = 'https://www.googletagmanager.com/gtag/js?id=' + id;
    script1.async = true;

    const script2 = this.renderer.createElement('script');
    script2.text = `
      window.dataLayer = window.dataLayer || [];
      function gtag(){dataLayer.push(arguments);}
      gtag('js', new Date());

      gtag('config', '${id}');
    `;

    this.renderer.appendChild(this.el.nativeElement, script1);
    this.renderer.appendChild(this.el.nativeElement, script2);
  }

  isLoadFotter: boolean = true;
  addItem(ev) {
    this.isLoadFotter = false;
    setTimeout( ()=> {      
      this.isLoadFotter = true;
      
    }, 100);
  }

  scrollToTop(): void {
    window.scrollTo(0, 0);
  }

}

