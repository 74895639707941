import { HttpClient, HttpErrorResponse, HttpHeaders } from '@angular/common/http';
import { Inject, Injectable } from '@angular/core';
import { MediaResponse, NewsResponse } from '../models/News';
import { delayedRetryHttp } from '../shared/delayedRetry';

@Injectable({ providedIn: 'root' })
export class NewsService {
  private baseUrl: string;
  private baseUrlMedia: string;
  private api: string = "api/News";
  private apimedia: string = "api/Media";
  constructor(private httpClient: HttpClient, @Inject('BASE_URL') _baseUrl: string) {
    this.baseUrl = _baseUrl + this.api;
    this.baseUrlMedia = _baseUrl + this.apimedia;
  }

  private handleErrors(errorResponse: HttpErrorResponse) {
    if (errorResponse.error instanceof ErrorEvent) {
      console.error('default Client Side Error: ', errorResponse.error.message);
    } else {
      throw errorResponse;
    }
  }

  async News_GetAsync(isPropertyNews: number, branchID: number, currentLang: string, countryCode: string , cmsCompanyID:number, newsId: number): Promise<NewsResponse | void> {
    const a = await this.httpClient
      .get<NewsResponse>(`${this.baseUrl}/News_Get/${isPropertyNews}/${branchID}/${currentLang}/${countryCode}/${cmsCompanyID}/${newsId}`, {
        headers: new HttpHeaders({
          'Content-Type': 'application/json',
          'Authorization': 'Basic '
        })
      }).pipe(delayedRetryHttp()).toPromise().catch(err => { console.log('default error'); });

    return a;
  }

  async Media_GetAsync(id: string, CmsCompID: string, langId: string, branchID: number): Promise<MediaResponse | void> {
    const a = await this.httpClient
      .get<MediaResponse>(`${this.baseUrlMedia}/MediaGet/${id}/${CmsCompID}/${langId}/${branchID}`, {
        headers: new HttpHeaders({
          'Content-Type': 'application/json',
          'Authorization': 'Basic '
        })
      }).pipe(delayedRetryHttp()).toPromise().catch(err => { console.log('default error'); });

    return a;
  }

  async Gallery_GetAsync(id: string, langId: string, branchID: number): Promise<any | void> {
    const a = await this.httpClient
      .get<any>(`${this.baseUrlMedia}/GalleryGet/${id}/${langId}/${branchID}`, {
        headers: new HttpHeaders({
          'Content-Type': 'application/json',
          'Authorization': 'Basic '
        })
      }).pipe(delayedRetryHttp()).toPromise().catch(err => { console.log('default error'); });

    return a;
  }

}
