import { Component, OnInit } from '@angular/core';
import { FormBuilder, FormGroup, Validators } from '@angular/forms';
import { SearchCountryField, PhoneNumberFormat, CountryISO } from 'ngx-intl-tel-input';
import { Select2OptionData } from 'ng-select2';
import { Options } from 'select2';
import { isNullOrUndefined } from 'util';
import { DataService } from '../../services/data.service';
import { UtilityService } from '../../shared/utility.service';
import { Property, PropertyCity, PropertyElectricity, PropertyFeature, PropertyFiltersResponse, PropertyFurnicher, PropertyTransactionType, PropertyType, PropertyTypeSort } from '../../models/property';
import { PropertyService } from '../../services/property.service';
import { CustomerRequest } from '../../models/request';
import { DefaultService } from '../../services/default.service';
import { currencyDetails, InitialValue, InitialValueResponse } from '../../models/initialValue';
import { NavigationEnd, Router } from '@angular/router';
import { RequestService } from '../../services/request.service';
import { SimpleResponse } from '../../models/genericResponse.model';
import { NotificationService } from '../../shared/notification.service';
import { ConfigurationService } from 'src/app/shared/configuration.service';

@Component({
    selector: 'app-customer-request',
    templateUrl: './customer-request.component.html',
    styleUrls: ['./customer-request.component.css']
})
/** customer-request component*/
export class CustomerRequestComponent implements OnInit {
  isShowDiv: boolean = false;
  formRequest: FormGroup;
  requestForm: CustomerRequest;
  SearchCountryField = SearchCountryField;
  simpleResponse: SimpleResponse;
  //TooltipLabel = TooltipLabel;
  CountryISO = CountryISO;
  preferredCountries: CountryISO[] = [CountryISO.Bahrain];
  token: string;
  types: PropertyTransactionType[];
  public optiontypes: Options;
  public price: Select2OptionData[] = [];
  public pricemx: Select2OptionData[] = [];
  public options: Options;
  public options1: Options;
  public optionsPf: Options;
  public optionsPE: Options;
  public optionsCity: Options;
  public optionsct: Options;
  public optionsct1: Options;
  public optionspTTs: Options;
  public optionstrans: Options;
  public optionstransmx: Options;
  public currencyCode: string = 'BHD';
  public countryCode = '973';
  langId: number = 1;
  propertyFiltersResponse: PropertyFiltersResponse;
  propertyTypesSort: PropertyTypeSort[];
  propertyTypes2: PropertyType[];
  availableTypesOfType1: PropertyType[];
  propertyCity: PropertyCity[];
  propertyFurnicher: PropertyFurnicher[];
  propertyElectricity: PropertyElectricity[];
  availableTransactions: PropertyTransactionType[];
  propertyFeature: PropertyFeature[];
  pTTs: PropertyTransactionType[];
  disableSubmit:boolean = false
  submitted = false;
  private _value: string = '14';
  private _valuerent: string = '13';
  private _valueLocation: string;
  private _valueBed: string;
  public _typesValue: string;
  private _TypesSortVal: string;
  private _Types2Val: string;
  private _CityVal: any;
  private _CityFinalVal: string = '';
  private _minTransactionsVal: string;
  private _maxTransactionsVal: string;
  private _ElectricityVal: string;
  private _FurnicherVal: string;
  private _TypesSort1Val: string;
  private _TypesText: string;
  private _Types2Text: string;
  private _Types3Text: string;
  private _CityFinalText: string = '';
  private _ElectricityText: string;
  private _FurnicherText: string;

  initialValueResponse: InitialValueResponse;
  initialValue: InitialValue;
  currencyDetail: currencyDetails;
  hostName: string = '';
  selectedItems: any = null;
  url: string = '';
    /** customer-request ctor */
  constructor(private _route:Router, 
    private formBuilder: FormBuilder, 
    private _dataService: DataService,
    private _defaultService: DefaultService,
    private _propertyService: PropertyService, 
    private _requestService: RequestService,
    private notifyService: NotificationService,
    private _configurationServices : ConfigurationService) {

    this.hostName = localStorage.getItem('hostname');
    this.url = window.location.href;
  }
  get f() {
    return this.formRequest.controls;
  }
  ngOnInit() {
    this.formRequest = this.formBuilder.group({
      office: [''],
      MinArea: [''],
      MaxArea: [''],
      MinBedrooms: [''],
      MaxBedrooms: [''],
      MinBathrooms: [''],
      MaxBathrooms: [''],
      fName: ['', [Validators.required]],
      lName: ['', [Validators.required]],
      phone: ['', [Validators.required]],
      emailreq: ['', [Validators.required, Validators.email]],
      Subject: ['', [Validators.required]],
      Message: ['', [Validators.required]]
    });

    this.langId = this._configurationServices.getLanguageValue();
    this.currencyCode = this._configurationServices.getSeletedCurrencyCode();
    this.countryCode = this._configurationServices.getSeletedCountryCode();

    this.initialValue = this._configurationServices.getInitialValues();
    this.GetInitialValues();
    this.getPrice();
    this.getPricemx();
  }

  toggleLocationDiv() { 
    this.isShowDiv = !this.isShowDiv;
  }

  onRecaptchaSuccess(token: string) {
    this.token = token;
  }

  GetInitialValues() {

    this.GetSerchFilterData(this.langId.toString(), this.initialValue.branchID, this.initialValue.groupedBranchesId, this.countryCode)
  }

  GetSerchFilterData(currenLang: string, branchId: number, GroupedBrancheIds: string, countryCode: string) {
    this._propertyService.GetSerch_FilterAsync(Number(currenLang), branchId, GroupedBrancheIds, Number(countryCode)).then((result) => {
      if (result) {
        this.propertyFiltersResponse = UtilityService.clone(result);
        this.types = this.propertyFiltersResponse.pTTs;
        this.optiontypes = {
          allowClear: false
        };
        this.propertyTypesSort = this.propertyFiltersResponse.propertyTypesSort;
        this.optionsct = {
          allowClear: false
        };
        this.propertyTypes2 = this.propertyFiltersResponse.propertyTypes2;
        this.optionsct1 = {
          allowClear: false
        };
        this.propertyCity = this.propertyFiltersResponse.propertyCitys;
        this.optionsCity = {
          allowClear: false,
          multiple: true,
          tags: true
        };
        this.availableTypesOfType1 = this.propertyFiltersResponse.availableTypesOfType1;
        this.propertyElectricity = this.propertyFiltersResponse.electricityOptions;
        this.optionsPE = {
          allowClear: false
        };
        this.propertyFurnicher = this.propertyFiltersResponse.furnicherOptions;
        this.optionsPf = {
          allowClear: false,
          templateResult: this.FurnicherResult,
          templateSelection: this.FurnicherSelection
        };
        this.pTTs = this.propertyFiltersResponse.pTTs;
        this.optionspTTs = {
          allowClear: false
        };
        this.availableTransactions = this.propertyFiltersResponse.availableTransactions;
        this.propertyFeature = this.propertyFiltersResponse.propertyFeatures;

      }
    });
  }

  locationSearch(value) {
    this._route.navigate(['/property/0/' + value]);
  }

  getPrice() {
    this._dataService.getpriceList(this.langId.toString()).subscribe((result) => {
      if (result) {
        this.price = UtilityService.clone(result);
        this.optionstrans = {
          width: '200',
          tags:true,
          templateResult: this.PriceResult,
          templateSelection: this.PriceSelection
        };
      }
    })
  }

  getPricemx() {
    this._dataService.getpriceListmax(this.langId.toString()).subscribe((result) => {
      if (result) {
        this.pricemx = UtilityService.clone(result);
        this.optionstransmx = {
          width: '200',
          templateResult: this.PriceResultmx,
          templateSelection: this.PriceSelectionmx
        };
      }
    })
  }

  public PriceResult = (state: Select2OptionData): JQuery | string => {
    if (state.text == 'Select Min Price')
      return jQuery('<span>' + state.text + '</span>');
    else
      return jQuery('<span>' + state.text + '</span>');
  }
  public PriceSelection = (state: Select2OptionData): JQuery | string => {
    return jQuery('<span>' + state.text + '</span>');
  }
  public PriceResultmx = (state: Select2OptionData): JQuery | string => {
    if (state.text == 'Select Max Price')
      return jQuery('<span>' + state.text + '</span>');
    else
      return jQuery('<span>'  + state.text + '</span>');
  }
  public PriceSelectionmx = (state: Select2OptionData): JQuery | string => {
    return jQuery('<span>' + state.text + '</span>');
  }

  public FurnicherResult = (state: Select2OptionData): JQuery | string => {


    return jQuery('<span>' + state.text + '</span>');
  }
  public FurnicherSelection = (state: Select2OptionData): JQuery | string => {
    return jQuery('<span>' + state.text + '</a></span>');
  }

  submit() {
    this.disableSubmit = true;
    this.submitted = true;
    this.prepareFormRequest();
    if (this.formRequest.invalid) {
      return;
    }
    this._requestService.RequestSubmit(this.langId, this.countryCode, this.initialValue.branchID, this.initialValue.groupedBranchesId,this.prepareFormRequest()).then((result) => {
      if (result) {
        this.disableSubmit = false;
        this.simpleResponse = UtilityService.clone(result);
        if (this.simpleResponse.messageBool) {
          this.notifyService.showSuccess("Mail send successfully !!", "")
          this.formRequest.reset();
          //this.openSnackBar("Mail send successfully", "");
        }
        else
          this.notifyService.showSuccess("some thing went wrong.", "")
        //this.openSnackBar("some thing went wrong", "");
      }else{
        this.disableSubmit = false;
      }
    });
    //console.log(this.prepareFormRequest());
  }

  prepareFormRequest() {
    return this.requestForm = {      
      propertyType: isNullOrUndefined(this._TypesSort1Val) ? '' : this._TypesSort1Val,
      propertyType2: isNullOrUndefined(this._TypesSortVal) ? '' : this._TypesSortVal,
      propertyType3: isNullOrUndefined(this._Types2Val) ? '' : this._Types2Val,
      features: '',
      location: isNullOrUndefined(this._CityFinalVal) ? '' : this._CityFinalVal.substring(0, this._CityFinalVal.length - 1),
      minArea: isNullOrUndefined(this.formRequest.controls["MinArea"].value) ? '' : this.formRequest.controls["MinArea"].value,
      maxArea: isNullOrUndefined(this.formRequest.controls["MaxArea"].value) ? '' : this.formRequest.controls["MaxArea"].value,
      minPrice: isNullOrUndefined(this._minTransactionsVal) ? '' : this._minTransactionsVal,
      maxPrice: isNullOrUndefined(this._maxTransactionsVal) ? '' : this._maxTransactionsVal,
      minBeds: isNullOrUndefined(this.formRequest.controls["MinBedrooms"].value) ? '' : this.formRequest.controls["MinBedrooms"].value,
      maxBeds: isNullOrUndefined(this.formRequest.controls["MaxBedrooms"].value) ? '' : this.formRequest.controls["MaxBedrooms"].value,
      minBaths: isNullOrUndefined(this.formRequest.controls["MinBathrooms"].value) ? '' : this.formRequest.controls["MinBathrooms"].value,
      maxBaths: isNullOrUndefined(this.formRequest.controls["MaxBathrooms"].value) ? '' : this.formRequest.controls["MaxBathrooms"].value,
      electricity: isNullOrUndefined(this._ElectricityVal) ? '' : this._ElectricityVal,
      furnicher: isNullOrUndefined(this._FurnicherVal) ? '' : this._FurnicherVal,

      cmName: isNullOrUndefined(this.formRequest.controls["fName"].value) ? '' : this.formRequest.controls["fName"].value + ' ' + this.formRequest.controls["lName"].value,
      cmEmail: isNullOrUndefined(this.formRequest.controls["emailreq"].value) ? '' : this.formRequest.controls["emailreq"].value,
      cmPhone: isNullOrUndefined(this.formRequest.controls["phone"].value.number) ? '' : this.formRequest.controls["phone"].value.number,
      messageSubject: isNullOrUndefined(this.formRequest.controls["Subject"].value) ? '' : this.formRequest.controls["Subject"].value,
      messageContent: isNullOrUndefined(this.formRequest.controls["Message"].value) ? '' : this.formRequest.controls["Message"].value,

      propertyTypeText: isNullOrUndefined(this._TypesText) ? '' : this._TypesText,
      propertyType2Text: isNullOrUndefined(this._Types2Text) ? '' : this._Types2Text,
      propertyType3Text: isNullOrUndefined(this._Types3Text) ? '' : this._Types3Text,
      featuresText: '',
      locationText: isNullOrUndefined(this._CityFinalText) ? '' : this._CityFinalText,
      electricityText: isNullOrUndefined(this._ElectricityText) ? '' : this._ElectricityText,
      furnicherText: isNullOrUndefined(this._FurnicherText) ? '' : this._FurnicherText,
      recapchaResponse: this.token,
      clientUrl:this.url
    }
  }

  get value(): string {
    return this._value;
  }
  set value(value: string) {
    //console.log('Set value: ' + value);
    this._value = value;
  }
  get valuerent(): string {
    return this._valuerent;
  }
  set valuerent(value: string) {
    //console.log('Set value: ' + value);
    this._valuerent = value;
  }
  get valueLocation(): string {
    return this._valueLocation;
  }
  set valueLocation(value: string) {
    //console.log('Set value: ' + value);
    this._valueLocation = value;
  }
  get valueBed(): string {
    return this._valueBed;
  }
  set valueBed(value: string) {
    this._valueBed = value;
  }
  get TypesSortVal(): string {
    return this._TypesSortVal;
  }
  set TypesSortVal(value: string) {
    //console.log('Set value: ' + value);
    this._TypesSortVal = value;
  }

  get typesValue(): string {
    return this._typesValue;
  }

  set typesValue(value: string) {
    this._typesValue = value;
  }

  get Types2Val(): string {
    return this._Types2Val;
  }
  set Types2Val(value: string) {
    //console.log('Set value: ' + value);
    this._Types2Val = value;
  }

  get CityVal(): any {
    return this._CityVal;
  }
  set CityVal(value: any) {
    //console.log('city value: ' + value);
    this._CityVal = value
    if (!isNullOrUndefined(value)) {
      for (let i = 0; i < value.length; i++) {

        this._CityFinalVal += value[i] + ','
        //console.log(this._CityFinalVal);
      }
    }

  }


  get minTransactionsVal(): string {
    return this._minTransactionsVal;
  }
  set minTransactionsVal(value: string) {
    //console.log('Set value: ' + value);
    this._minTransactionsVal = value;
  }

  get maxTransactionsVal(): string {
    return this._maxTransactionsVal;
  }
  set maxTransactionsVal(value: string) {
    this._maxTransactionsVal = value;
  }

  get ElectricityVal(): string {
    return this._ElectricityVal;
  }
  set ElectricityVal(value: string) {
    this._ElectricityVal = value;
  }

  get FurnicherVal(): string {
    return this._FurnicherVal;
  }
  set FurnicherVal(value: string) {
    this._FurnicherVal = value;
  }

}
