import { HttpClient, HttpErrorResponse, HttpHeaders } from '@angular/common/http';
import { Inject, Injectable } from '@angular/core';
import { Observable } from 'rxjs';
import { ContractsListResponse } from '../models/Contracts';
import { CustomerResponse, DashboardResponse, DisplayDetailsResponse, ePaymentMethod, FormDetailsResponse, JSONObjectModelResponse, PaymentResponseAQ, StatusMessage, UpdateValues} from '../models/DashBoard';
import { SimpleResponse } from '../models/genericResponse.model';
import { CheckoutSessionModelResposne, UpdatePayment } from '../models/Payment';
import { PaymentReceiptResponse } from '../models/paymentReciept';
import { PaymentStatement, PaymentStatementResponse } from '../models/PaymentStatement';
import { PaymentResponse } from '../models/Payment';

import { delayedRetryHttp } from '../shared/delayedRetry';

@Injectable({ providedIn: 'root' })
export class DahboardService {
  private baseUrl: string;
  private baseUrlForms: string;
  private api: string = "api/CustDashBoard";
  private apiForm: string = "api/Forms"
  constructor(private httpClient: HttpClient, @Inject('BASE_URL') _baseUrl: string) {
    this.baseUrl = _baseUrl + this.api;
    this.baseUrlForms = _baseUrl + this.apiForm;
  }

  private handleErrors(errorResponse: HttpErrorResponse) {
    if (errorResponse.error instanceof ErrorEvent) {
      console.error('default Client Side Error: ', errorResponse.error.message);
    } else {
      throw errorResponse;
    }
  }

  async Dashboard_GetData(currentLang, caid, customer, customerDecimal, user, branchID, companyId, token: string): Promise<DashboardResponse | void> {
    const a = await this.httpClient
      .get<DashboardResponse>(`${this.baseUrl}/Dashboard_GetData/${currentLang}/${caid}/${customer}/${customerDecimal}/${user}/${branchID}/${companyId}`, {
        headers: new HttpHeaders({
          'Content-Type': 'application/json',
          'Authorization': 'Bearer ' + token
        })
      }).pipe(delayedRetryHttp()).toPromise().catch(err => { console.log('default error'); });

    return a;
  }

  async PaymentStatements_GetAsync(companyId: string, selectedBranchID: number, lang: string, contactId: string, groupedBranches: string, token: string): Promise<PaymentStatementResponse | void> {
    const a = await this.httpClient
      .get<PaymentStatementResponse>(`${this.baseUrl}/PaymentStatements_Get/${companyId}/${selectedBranchID}/${lang}/${contactId}/${groupedBranches}`, {
        headers: new HttpHeaders({
          'Content-Type': 'application/json',
          'Authorization': 'Bearer ' + token

        })
      }).pipe(delayedRetryHttp()).toPromise().catch(err => { console.log('default error'); });

    return a;
  }

  async TransactionStatus_Update(caid: string, branchId: string, selectedBranchId: string, companyID: string, selectedCCSwiftCode: string, customer: string, lang: string, token: string): Promise<PaymentReceiptResponse | void> {
    const a = await this.httpClient
      .get<PaymentReceiptResponse>(`${this.baseUrl}/TransactionStatus_Update/${caid}/${branchId}/${selectedBranchId}/${companyID}/${selectedCCSwiftCode}/${customer}/${lang}`, {
        headers: new HttpHeaders({
          'Content-Type': 'application/json',
          'Authorization': 'Bearer ' + token
        })
      }).pipe(delayedRetryHttp()).toPromise().catch(err => { console.log('default error'); });

    return a;
  }

  async CustomerPermissionFormsGet(contactid: string, companyID: string, FormType: string): Promise<CustomerResponse | void> {
    const a = await this.httpClient
      .get<CustomerResponse>(`${this.baseUrlForms}/GetCustomerPermissionForms/${contactid}/${companyID}/${FormType}`, {
        headers: new HttpHeaders({
          'Content-Type': 'application/json'

        })
      }).pipe(delayedRetryHttp()).toPromise().catch(err => { console.log('default error'); });

    return a;
  }

  async FormComponentsGet(ProductID: string, contactid: string, caid: string, external: boolean, customerid: string, lang: string, mode: string): Promise<FormDetailsResponse | void> {
    const a = await this.httpClient
      .get<FormDetailsResponse>(`${this.baseUrlForms}/GetFormComponents/${ProductID}/${contactid}/${caid}/${external}/${customerid}/${lang}/${mode}`, {
        headers: new HttpHeaders({
          'Content-Type': 'application/json'

        })
      }).pipe(delayedRetryHttp()).toPromise().catch(err => { console.log('default error'); });

    return a;
  }

  async CustomerFormsGet(contactid: string): Promise<DisplayDetailsResponse | void> {
    const a = await this.httpClient
      .get<DisplayDetailsResponse>(`${this.baseUrlForms}/GetCustomerForms/${contactid}`, {
        headers: new HttpHeaders({
          'Content-Type': 'application/json'

        })
      }).pipe(delayedRetryHttp()).toPromise().catch(err => { console.log('default error'); });

    return a;
  }

  async maintenanceForm_Submit(branchID: string, productID: string, contactID: string, CAID: string, customerID: string, email: string, lang: string, updateValues: UpdateValues): Promise<StatusMessage | void> {
    const a = await this.httpClient
      .post<StatusMessage>(`${this.baseUrlForms}/SubmitFormData/${branchID}/${productID}/${contactID}/${CAID}/${customerID}/${email}/${lang}`, updateValues, {
        headers: new HttpHeaders({
          'Content-Type': 'application/json'

        })
      }).pipe(delayedRetryHttp()).toPromise().catch(err => { console.log('default error'); });

    return a;
  }

  async FilterAccount2_Get(customer, currentLang, caid, token: string): Promise<SimpleResponse | void> {
    const a = await this.httpClient
      .get<SimpleResponse>(`${this.baseUrl}/StatmentOfAccount_FilterAccount2_Get/${customer}/${caid}/${currentLang}`, {
        headers: new HttpHeaders({
          'Content-Type': 'application/json',
          'Authorization': 'Bearer ' + token
        })
      }).pipe(delayedRetryHttp()).toPromise().catch(err => { console.log('default error'); });

    return a;
  }

  async Payment_Get(SelectedBranchID: string, companyId: string, currentLang: string, caid: string, customer: string, loginID: string, selectedCCSwiftCode: string, token: string): Promise<PaymentResponseAQ | void> {
    const a = await this.httpClient
      .get<PaymentResponseAQ>(`${this.baseUrl}/Payment_Get/${SelectedBranchID}/${companyId}/${currentLang}/${caid}/${customer}/${loginID}/${selectedCCSwiftCode}`, {
        headers: new HttpHeaders({
          'Content-Type': 'application/json',
          'Authorization': 'Bearer ' + token
        })
      }).pipe(delayedRetryHttp()).toPromise().catch(err => { console.log('default error'); });

    return a;
  }

  async CreditCard_Payment(customer: string, loginID: string, currentLang: string, paymentMethod: ePaymentMethod, branchId: string, selectedCCSwiftCode: string, companyID: string, caid:string, token: string,invoiceId:string[]): Promise<CheckoutSessionModelResposne | void> {
    const a = await this.httpClient
      .post<CheckoutSessionModelResposne>(`${this.baseUrl}/CreditCard_Payment/${customer}/${loginID}/${currentLang}/${paymentMethod}/${branchId}/${selectedCCSwiftCode}/${companyID}/${caid}`, invoiceId, {
        headers: new HttpHeaders({
          'Content-Type': 'application/json',
          'Authorization': 'Bearer ' + token
        })
      }).pipe(delayedRetryHttp()).toPromise().catch(err => { console.log('default error'); });

    return a;
  }

  async DebitCard_Payment(customer: string, loginID: string, currentLang: string, branchId: string, selectedCCSwiftCode: string, companyID: string, caid: string, selectedBranchId:string, token: string, invoiceId: string[]): Promise<PaymentResponse | void> {
    const a = await this.httpClient
      .post<PaymentResponse>(`${this.baseUrl}/DebitCard_Payment/${customer}/${loginID}/${currentLang}/${branchId}/${selectedCCSwiftCode}/${companyID}/${caid}/${selectedBranchId}`, invoiceId, {
        headers: new HttpHeaders({
          'Content-Type': 'application/json',
          'Authorization': 'Bearer ' + token
        })
      }).pipe(delayedRetryHttp()).toPromise().catch(err => { console.log('default error'); });

    return a;
  }

  async PgReturn_Get(resultIndicator: string, successIndicator: string, PgId: string, PgtId: string, token: string): Promise<PaymentReceiptResponse | void> {
    const a = await this.httpClient
      .get<PaymentReceiptResponse>(`${this.baseUrl}/PGReturn/${resultIndicator}/${successIndicator}/${PgId}/${PgtId}`, {
        headers: new HttpHeaders({
          'Content-Type': 'application/json',
          'Authorization': 'Bearer ' + token
        })
      }).pipe(delayedRetryHttp()).toPromise().catch(err => { console.log('default error'); });

    return a;
  }

  async Payment_Update(updatePayment: UpdatePayment, currentLang: string, SelectedBranchID: string, companyId: string, caid: string, customer: string, loginID: string, selectedCCSwiftCode: string, token: string): Promise<PaymentResponseAQ | void> {
    const a = await this.httpClient
      .post<PaymentResponseAQ>(`${this.baseUrl}/Payment_Update/${currentLang}/${SelectedBranchID}/${companyId}/${caid}/${customer}/${loginID}/${selectedCCSwiftCode}`, updatePayment, {
        headers: new HttpHeaders({
          'Content-Type': 'application/json',
          'Authorization': 'Bearer ' + token
        })
      }).pipe(delayedRetryHttp()).toPromise().catch(err => { console.log('default error'); });

    return a;
  }
  async GetQuerySuggestions(term: string, RecordsCount: string, QueryNumber: string, ParamID: string, ParamID2: string, branchID: string, compID: string, token: string): Promise<JSONObjectModelResponse | void> {
    const a = await this.httpClient
      .get<JSONObjectModelResponse>(`${this.baseUrlForms}/GetQuerySuggestions/${term}/${RecordsCount}/${QueryNumber}/${ParamID}/${ParamID2}/${branchID}/${compID}`, {
        headers: new HttpHeaders({
          'Content-Type': 'application/json',
          'Authorization': 'Bearer ' + token
        })
      }).pipe(delayedRetryHttp()).toPromise().catch(err => { console.log('default error'); });

    return a;
  }
}
