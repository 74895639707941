import { BrowserModule } from '@angular/platform-browser';
import { NgModule, CUSTOM_ELEMENTS_SCHEMA, NO_ERRORS_SCHEMA } from '@angular/core';
import { MDBBootstrapModule, MdbCardFooterComponent } from 'angular-bootstrap-md';
import { FormsModule, ReactiveFormsModule } from '@angular/forms';
import { HttpClientModule, HTTP_INTERCEPTORS, HttpClient } from '@angular/common/http';
import { RouterModule } from '@angular/router';
import { SlickCarouselModule } from 'ngx-slick-carousel';
import { MatPaginatorModule } from '@angular/material/paginator';
import { MatSnackBarModule } from '@angular/material/snack-bar';
import { BrowserAnimationsModule } from '@angular/platform-browser/animations';
import { NgOptionHighlightModule } from '@ng-select/ng-option-highlight';
import { NgSelect2Module } from 'ng-select2';
import { MatDialogModule } from '@angular/material/dialog';
// import { RecaptchaModule } from 'ng-recaptcha';
import { GoogleMapsModule } from '@angular/google-maps';
import { AlertDialogComponent } from './UI/shared/alert-dialog/alert-dialog.component';
import { ConfirmationDialogComponent } from './UI/shared/confirmation-dialog/confirmation-dialog.component';
import { ToastrModule } from 'ngx-toastr';
import { RecaptchaDirective } from './directives/recaptcha.directive';
import { LazyLoadScriptService } from './services/lazy-load-script.service';
import { NgxIntlTelInputModule } from 'ngx-intl-tel-input';
import { MatSelectModule } from '@angular/material/select';
import { NgxDaterangepickerMd } from 'ngx-daterangepicker-material';
import { NgxAwesomePopupModule, ConfirmBoxConfigModule } from '@costlydeveloper/ngx-awesome-popup';
import { LazyLoadImageModule } from 'ng-lazyload-image';
import { sanitizeHtmlPipe } from './shared/sanitize-html.pipe';
import { MatTabsModule } from '@angular/material/tabs';
import { AngularFileUploaderModule } from "angular-file-uploader";
import { TwoDigitDecimaNumberDirective } from './directives/two-digit-decimanumber-directive';
import { MatDatepickerModule } from '@angular/material/datepicker';
import { MatMomentDateModule } from '@angular/material-moment-adapter';
import { MatNativeDateModule } from '@angular/material/core';
import { MatButtonToggleModule } from '@angular/material/button-toggle';
import { MatSlideToggleModule } from '@angular/material/slide-toggle';
import { MatAutocompleteModule } from '@angular/material/autocomplete';
import { MatInputModule } from '@angular/material/input';
import { MatFormFieldModule } from '@angular/material/form-field';
import { CommonModule, DatePipe } from '@angular/common';
import { ShareButtonsModule } from 'ngx-sharebuttons/buttons';
import { ShareIconsModule } from 'ngx-sharebuttons/icons';
import { LightboxModule } from 'ngx-lightbox';
import { HashLocationStrategy, LocationStrategy } from '@angular/common';
//import { CarouselModule } from 'ngx-owl-carousel-o';



//import { Ng2TelInputModule } from 'ng2-tel-input';

import { AppComponent } from './app.component';
import { NavMenuComponent } from './nav-menu/nav-menu.component';
import { DefaultComponent } from './UI/default/default.component';
import { NewsComponent } from './UI/news/news.component';
import { AboutusComponent } from './UI/aboutus/aboutus.component';
import { PropertyComponent } from './UI/property/property.component';
import { PropertydetailComponent } from './UI/property/propertydetail/propertydetail.component';
import { LoginComponent } from './UI/login/login.component';
import { ContactusComponent } from './UI/contactus/contactus.component';
import { MerchantComponent } from './UI/merchant/merchant.component';
import { NgbModule } from '@ng-bootstrap/ng-bootstrap';
import { NewsdetailComponent } from './UI/newsdetail/newsdetail.component';
import { OwnersagentComponent } from './UI/ownersagent/ownersagent.component';
import { PropertycampareComponent } from './UI/propertycampare/propertycampare.component';
import { MediaComponent } from './UI/media/media.component';
import { PropertyfavoritesComponent } from './UI/propertyfavorites/propertyfavorites.component';
import { AgencydefaultComponent } from './UI/agencydefault/agencydefault.component';
import { CustomerRequestComponent } from './UI/customer-request/customer-request.component';
import { DashboardComponent } from './UI/dashboard/dashboard.component';
import { MypropertiesComponent } from './UI/myproperties/myproperties.component';
import { CacheInterceptor } from './interceptors/cashe.interceptor';
import { FooterComponent } from './footer/footer.component';
import { AgentsComponent } from './UI/agents/agents.component';
import { RecieptComponent } from './UI/Payment/reciept.component';
import { PgredirectComponent } from './UI/Payment/pgredirect/pgredirect.component';
import { CreditpgreturnComponent } from './UI/creditpgreturn/creditpgreturn.component';
import { TestComponent } from './UI/test/test.component';
import { ShareaddthisComponent } from './shared/shareaddthis/shareaddthis.component';
import { FindPropertyComponent } from './UI/find-property/find-property.component';
import { ForgotPasswordComponent } from './UI/forgot-password/forgot-password.component';
import { PageNotfoundComponent } from './UI/page-notfound/page-notfound.component';
import {
  GoogleLoginProvider,
  FacebookLoginProvider,
  SocialLoginModule,
  SocialAuthServiceConfig,
} from 'angularx-social-login';
import { MainComponent } from './UI/main/main.component';
import { LoadingComponent } from './shared/loading/loading.component';
import { SignupComponent } from './UI/signup/signup.component';
import { environment } from 'src/environments/environment';
import { PropertyHighlightsSliderComponent } from './UI/property-highlights-slider/property-highlights-slider.component';
import {AgencyOurPropertiesSliderComponent} from './UI/agency-our-properties-slider/agency-our-properties-slider.component';
import { PropertiesTooltipListingComponent } from './UI/properties-tooltip-listing/properties-tooltip-listing.component';
import { PropertiesListingComponent } from './UI/properties-listing/properties-listing.component';
import { SwiperModule } from "swiper/angular";
import { AgencyPropertiesTooltipListingComponent } from './UI/agency-properties-tooltip-listing/agency-properties-tooltip-listing.component';
import { PropertitListingDetailComponent } from './UI/propertit-listing-detail/propertit-listing-detail.component';
import { PropertiesTooltipListDetailComponent } from './UI/properties-tooltip-list-detail/properties-tooltip-list-detail.component';
import { PropertyDetailBuildingListingComponent } from './UI/property-detail-building-listing/property-detail-building-listing.component';
import { ContactModalComponent } from './UI/contact-modal/contact-modal.component';
import { GalleryComponent } from './UI/gallery/gallery.component';
import { AnnouncementModalComponent } from './UI/announcement-modal/announcement-modal.component';
import { ContactReceiptModalComponent } from './UI/contact-receipt-modal/contact-receipt-modal.component';
//import { NgxPanoViewerModule, NgxSpinViewerModule } from '@egjs/ngx-view360';


@NgModule({
  declarations: [
    AppComponent,
    NavMenuComponent,
    DefaultComponent,
    NewsComponent,
    AboutusComponent,
    PropertyComponent,
    AgentsComponent,
    LoginComponent,
    AlertDialogComponent,
    ConfirmationDialogComponent,
    PropertydetailComponent,
    ContactusComponent,
    MerchantComponent,
    PropertycampareComponent,
    PropertyfavoritesComponent,
    NewsdetailComponent,
    SignupComponent,
    OwnersagentComponent,
    AgencydefaultComponent,
    CustomerRequestComponent,
    PgredirectComponent,
    CreditpgreturnComponent,
    MediaComponent,
    DashboardComponent,
    FooterComponent,
    MypropertiesComponent,
    // JwPaginationComponent,
    TwoDigitDecimaNumberDirective,
    TestComponent,
    FindPropertyComponent,
    RecaptchaDirective, RecieptComponent,
    sanitizeHtmlPipe, ShareaddthisComponent, ForgotPasswordComponent, PageNotfoundComponent,
    MainComponent,
    LoadingComponent,
    SignupComponent,
    PropertyHighlightsSliderComponent,
    AgencyOurPropertiesSliderComponent,
    PropertiesTooltipListingComponent,
    PropertiesListingComponent,
    AgencyPropertiesTooltipListingComponent,
    PropertitListingDetailComponent,
    PropertiesTooltipListDetailComponent,
    PropertyDetailBuildingListingComponent,
    ContactModalComponent,
    GalleryComponent,
    AnnouncementModalComponent,
    ContactReceiptModalComponent
  ],
  entryComponents: [
    ContactModalComponent,
    ContactReceiptModalComponent,
    AnnouncementModalComponent
  ],
  imports: [
    BrowserModule.withServerTransition({ appId: 'ng-cli-universal' }),
    CommonModule,
    HttpClientModule,
    FormsModule,
    ReactiveFormsModule,
    MatDialogModule,
    MatPaginatorModule,
    SwiperModule,
    //MatButtonToggleModule,
    //MatSlideToggleModule,
    MatSnackBarModule,
    BrowserAnimationsModule,
    NgOptionHighlightModule,
    AngularFileUploaderModule,
    NgSelect2Module,
    SlickCarouselModule,
    GoogleMapsModule,
    MatSelectModule,
    SocialLoginModule,
    MatTabsModule,
    MatAutocompleteModule,
    MatFormFieldModule,
    MatDatepickerModule,
    MatFormFieldModule,
    MatMomentDateModule,
    MatInputModule,
    LightboxModule,
    //NgxPanoViewerModule ,
    //NgxSpinViewerModule,
    ShareButtonsModule.withConfig({
      debug: true
    }),
    ShareIconsModule,
    MDBBootstrapModule.forRoot(),
    ToastrModule.forRoot({
      timeOut: 5000,
      positionClass: 'toast-top-right',
      preventDuplicates: true,
    }),
    NgxIntlTelInputModule,
    LazyLoadImageModule,
    
    NgxDaterangepickerMd.forRoot(),
    NgxAwesomePopupModule.forRoot(), // Essential, mandatory main module.
    ConfirmBoxConfigModule.forRoot(), // Essential, mandatory confirm box module.    
    NgbModule,    
    RouterModule.forRoot([
      { path: '', component: MainComponent, pathMatch: 'full' },
      { path: 'default', component: MainComponent },
      { path: 'en', component: MainComponent },
      { path: 'ar', component: MainComponent },
      { path: 'en/properties/:ID/:Location', component: PropertyComponent },
      { path: 'ar/properties/:ID/:Location', component: PropertyComponent },
      { path: 'Gallery/:ID', component: GalleryComponent},
      { path: 'payment', component: RecieptComponent},
      //{ path: 'Gallery/:ID', component: GalleryComponent},
      { path: 'News', component: NewsComponent },
      { path: 'News/:ID', component: NewsComponent },
      { path: 'view/:ID', component: AboutusComponent },
      { path: 'properties/:ID/:Location', component: PropertyComponent },
      { path: 'login', component: LoginComponent },
      { path: 'register', component: SignupComponent },
      { path: 'propertydetail', component: PropertydetailComponent },
      { path: 'contactUs', component: ContactusComponent },
      { path: 'merchant', component: MerchantComponent },
      { path: 'newsdetail/:Id/:PropertyNews', component: NewsdetailComponent },
      { path: 'Agents', component: OwnersagentComponent },
      { path: 'request', component: CustomerRequestComponent },
      { path: 'compare/:Id', component: PropertycampareComponent },
      { path: 'propertycampare', component: PropertycampareComponent },
      { path: 'favorites/:Id', component: PropertyfavoritesComponent },
      { path: 'myproperties', component: MypropertiesComponent },
      { path: 'ViewMedia/:Id', component: MediaComponent },
      { path: 'dashboard', component: DashboardComponent },
      { path: 'footer', component: FooterComponent },
      { path: 'agents/:branchId/:langId', component: AgentsComponent },
      { path: 'reciept', component: RecieptComponent },
      { path: 'pgredirct', component: PgredirectComponent },      
      { path: 'pgreturn', component: CreditpgreturnComponent },
      { path: 'propertyfind', component: FindPropertyComponent },
      { path: 'forgotpassword', component: ForgotPasswordComponent },
      { path: '**', component: PageNotfoundComponent },
    ])
  ],

  providers: [LazyLoadScriptService, DatePipe,
    { provide: HTTP_INTERCEPTORS, useClass: CacheInterceptor, multi: true }, {
    provide: 'SocialAuthServiceConfig',
    useValue: {
      autoLogin: false,
      providers: [
        {
          id: GoogleLoginProvider.PROVIDER_ID,
          provider: new GoogleLoginProvider('145457881272-3rdvfeb4vm44k49bmcak0670m6vhroj8.apps.googleusercontent.com')
        },
        {
          id: FacebookLoginProvider.PROVIDER_ID,
          provider: new FacebookLoginProvider('411312593161246'),
        },
      ],      
    } as SocialAuthServiceConfig,
  }],
  bootstrap: [AppComponent],
  schemas: [NO_ERRORS_SCHEMA,CUSTOM_ELEMENTS_SCHEMA]
  })
export class AppModule { }
