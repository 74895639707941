import { EventEmitter, Inject, Injectable } from '@angular/core';
import { Country, CountryResponse, Currency, CurrencyResponse, InitialValue, InitialValueResponse} from '../models/initialValue';
import { ConfigDetails, ThemeColorModel } from '../models/appConfig.model';
import { DOCUMENT } from '@angular/common';
import { DomSanitizer } from '@angular/platform-browser';
import { ActivatedRoute, NavigationEnd, Router } from '@angular/router';
import { SimpleStateService } from '../services/simple-state.service';
import { ThemeService } from '../services/theme.service';
import { UtilityService } from './utility.service';
import { AppconfigService } from './appconfig.service';
import { DefaultService } from '../services/default.service';
import { HttpClient, HttpErrorResponse } from '@angular/common/http';
import { isNullOrUndefined } from 'util';
import * as geoip from 'geoip-lite';
import { BehaviorSubject, Observable, Subscription } from 'rxjs';
import { ComapnyDefaultInfo, DefaultInitialValues, DefaultInitialValuesResponse } from '../models/defaultInitialValues';
import { lang } from 'moment';
import { Location } from '@angular/common';
import { Title } from '@angular/platform-browser';

import { count, error } from 'console';
import { FavoriteService } from '../services/favorite.service';
import { ApiResponseModel } from '../models/ApiResponseModel';

@Injectable({
  providedIn: 'root'
})
export class ConfigurationService {

  countryValue: string = '';
  currencyValue: string = '';
  langValue: number = 1;
  countryName: string = '';
  currencyCont: string = '';
  lanSelected: string = ''
  public hostName: string = '';
  langId: number = 1;
  public countryCode = '';
  setHostName: string = '';
  private configDetails: ConfigDetails;

  country:string = "";
  CompanyDefualtInfo:ComapnyDefaultInfo;
  countryResponse: CountryResponse;
  currencyResponse: CurrencyResponse;
  DefaultInitialValues:DefaultInitialValues = null;
  CountryList:Country[];
  CurrencyList : Currency[] = null;
  InitialValuse:InitialValue = null;
  ThemeColor:ThemeColorModel = null;
  
  countryFromApi:string = "BH";

  public isSpecificTheme: boolean = false ;
  public isLuban: boolean = false;
  public DefaultInitialValuesSubject = new BehaviorSubject<DefaultInitialValues>(null);
  public CompanyDefualtInfoSubject = new BehaviorSubject<ComapnyDefaultInfo>(null);
  public CountryListSubject = new BehaviorSubject<Country[]>(null);
  public CurrencyListSubject = new BehaviorSubject<Currency[]>(null);
  public InitalValuesSubject = new BehaviorSubject<InitialValue>(null);
  public ThemeColorSubject = new BehaviorSubject<ThemeColorModel>(null);
  public CountryNameSubject = new BehaviorSubject<string>('');
  public CurrencyContSubject = new BehaviorSubject<string>('');
  public CountryCodeSubject = new BehaviorSubject<string>('');
  public isThemeInitialized:EventEmitter<boolean> = new EventEmitter<boolean>();
  public siteUrl : string = "";

  private isAgentFooter:boolean = false;
  private isChield:boolean = false;
  private isDefaultFooter:boolean = false;
  public isShowMenu:EventEmitter<boolean> = new EventEmitter<boolean>();
  public host:EventEmitter<string> = new EventEmitter<string>();
  public isSearch:EventEmitter<boolean> = new EventEmitter<boolean>();


  public isInitialValuesFetched:EventEmitter<boolean> = new EventEmitter<boolean>();
  // public isSearch:EventEmitter<boolean> = new EventEmitter<boolean>();


  constructor(private readonly themeService: ThemeService, private _appconfigService: AppconfigService, 
    private _utilityService: UtilityService,
    private _defaultService: DefaultService,
    private http: HttpClient, private _route : Router, private _favoritesService: FavoriteService, 
    private _locationService:Location, private titleServicec:Title) {
   }

   getCountryByIp(url){
    let country = localStorage.getItem('Country');
    let country2 = ""

    if (country == null || country == "" || country == "null") {
      var countryResponce = this.http.get<any>('https://api.country.is').toPromise().then();
      countryResponce?.then(resp=>{
        country2 = resp?.country;
      })

      setTimeout(() => {
        
        country =  country2 != ""  ? country2 : "BH"
        localStorage.setItem('Country', country);
        this.getinitialDefaultData(url)
      }, 700);
    }else{
      this.countryFromApi = country
      localStorage.setItem('Country', country);
      this.getinitialDefaultData(url)
    }
    
   }



   getinitialDefaultData(url){
    
    url = url.replace("http://",'').replace("https://","")

    if( url.includes('haialuban') && isNullOrUndefined(localStorage.getItem('language'))){
      this.setLangauage('2')
    }

    this.siteUrl = url;
    let langId = !isNullOrUndefined(localStorage.getItem('language')) ? localStorage.getItem('language') : "1";
    this.loadStyling(url)
    this.setLanguage()
    this.langId = parseInt(langId, 10);

    this._defaultService.GetDefualtInitialValues(url,false,langId,this.countryFromApi).then((result:ApiResponseModel<DefaultInitialValues>)=>{
      
      if(result.StatusCode == 200) {
        this.InitialValuse = result?.Data?.initialValue;
        this.CurrencyList = result?.Data?.currencyList;
        this.CountryList = result?.Data?.countryList;
        this.ThemeColor = result?.Data?.ThemeColor;
        this.titleServicec.setTitle(result?.Data?.CountryName);
        this.agencyRoute(url)
        this.InitalValuesSubject.next(this.InitialValuse);
        this.CountryListSubject.next(this.CountryList);
        this.CurrencyListSubject.next(this.CurrencyList);
        this.ThemeColorSubject.next(this.ThemeColor);
        this.setCountry(localStorage.getItem("Country"));
        this.setCurrency();
        
        this.getCompanyInfo();
        this.isSpecificTheme = this.InitialValuse.cmpTempId == 189;
        this.isLuban =  this.InitialValuse.cmpTempId == 190;
        this.isThemeInitialized.emit(true);
        this.getFavorites();
        this.getCompare();
      }else{
        this.isThemeInitialized.emit(false);
      }

    })
   }


   async getDefaultData(url:string)
   {
    
    let oldCash = localStorage.getItem("Country");
    if(oldCash == '973'){
      localStorage.clear();
    }
    this.getCountryByIp(url);


   }
   


  // private async fetchHostName(splitUrl) {
  //   this.loadStyling(splitUrl)
  //   await this._appconfigService.getAppConfigValue("Hostname")
  //     .then(async (result: ConfigDetails) => {
  //       if (result) {
          
  //         this.configDetails = UtilityService.clone(result);
  //         if (this.configDetails) {
  //           this.setHostName = this.configDetails.configValue;
  //           // this.agencyRoute(splitUrl[0].toLowerCase());
  //           if(this.initialValue == null){
  //             this.fetchInitialValues(this.hostName, 0);
  //             return this.hostName;
  //           }
  //         } else {
  //         }
  //       }
  //     })
  //     .catch((err: HttpErrorResponse) => {
  //       this._utilityService.handleErrorResponse(err);
  //     });
  // }


  public getSiteURL(){
    return this.siteUrl;
  }
  
  agencyRoute(url) {
    // let routeValue = window.location.href.replace('https://localhost:44326/', '').replace('http://192.168.1.22/','').replace('https://www', '').replace('http://www', '').replace('http://', '').replace('https://', '').split('.');


    let routeValue = url.replace("www." ,'').split('.');
    if(routeValue[0].toLowerCase() != "" &&  !routeValue[0].includes("localhost") && routeValue[0].toLowerCase() != "angular" && routeValue[0].toLowerCase() != "aqaraats"){
    this.isAgentFooter = true
    this.isDefaultFooter = false;
    }else{
      this.isAgentFooter = false
      this.isDefaultFooter =true;
    }
  }


  addclass(lng) {
    if (lng == '2')
      $('body').addClass('language-reverse');
    else
      $('body').removeClass('language-reverse');
  }



  loadStyling(hostname) {
    hostname = hostname.replace("www." ,'').split('.');
    if (hostname[0] != '' && hostname[0] != 'angular' && hostname[0] != 'aqaraats') {
      this.themeChangeHandler('theme_one');
    }
    else{
      if(hostname[0].toLowerCase() == 'aqaraats'){
        this.themeChangeHandler('style');
      }else{
        this.themeChangeHandler('theme_one');
      }
      
    }

  }

  themeChangeHandler(themeToSet) {
    this.themeService.setTheme(themeToSet);
  }



  public getLanguageValue(){
    return this.langValue
  }

  public getSelectedLang(){
    return this.lanSelected
  }

  public setLangauage(langauageValue){
    
    switch (langauageValue){
      case '1':
        this.langValue = 1;
        this.langId = 1;
        this.lanSelected = "English";
        localStorage.setItem("language", `${this.langValue}`);
      break;
      case '2':
        this.langValue = 2;
        this.langId = 2;
        this.lanSelected = "عربي";
        localStorage.setItem("language", `${this.langValue}`);
      break;
      default:
        this.langValue = 1;
        this.lanSelected = "English";
        localStorage.setItem("language", `${this.langValue}`);
      break;
    }

    this.addclass(this.langValue);
    if(this.CurrencyList){
      this.setCurrency()      
    }

    this.reloadCurrentRoute('lang');
  }

  
  reloadCurrentRoute(value) {
    let currentUrl = this._route.url;
    this._route.navigateByUrl('/', { skipLocationChange: true }).then(() => {
      if (value == 'lang') {
        if (currentUrl == '/' || currentUrl == '/ar' || currentUrl == '/en'){
          if(this.langValue == 1){
            this._route.navigate(['/en']);
          }else{
            this._route.navigate(['/ar']);
          }
        }        else {
          if (currentUrl.indexOf("?") !== -1) {
            const queryParams = new URLSearchParams(currentUrl.split('?')[1]);
            // Dynamically extract all query parameters
            const queryParamsObj = {};
            queryParams.forEach((value, key) => {
              queryParamsObj[key] = value;
            });
            this._route.navigate([currentUrl.split('?')[0]], { queryParams: queryParamsObj });
          }
          else{
            this._route.navigate([currentUrl]);
          }
        }
      }
      else {
        if (currentUrl == '/'){
          window.location.reload()
                   
        }  
        else {
          if (currentUrl.indexOf("?") !== -1 && currentUrl.indexOf("News") == -1) {
            const queryParams = new URLSearchParams(currentUrl.split('?')[1]);
            // Dynamically extract all query parameters
            const queryParamsObj = {};
            queryParams.forEach((value, key) => {
              queryParamsObj[key] = value;
            });
            this._route.navigate([currentUrl.split('?')[0]], { queryParams: queryParamsObj });
          }
          else{
            window.location.reload()
          }
        }
      }
    });
  }


  private setLanguage(){
    
    if(location.pathname.indexOf('/en') != -1){
      localStorage.setItem("language","1");
      this.langValue = 1
      this.lanSelected = "English"
    }else if(location.pathname.indexOf('/ar') != -1){
      localStorage.setItem("language","2");
      this.langValue = 2
      this.lanSelected = "عربي"
    }else{
      if(isNullOrUndefined(localStorage.getItem("language"))){
        
        localStorage.setItem("language","1");

        this.langValue = 1
        this.lanSelected = "English"
      }else{
        this.langValue = parseInt(localStorage.getItem('language')); 
        this.lanSelected = localStorage.getItem('language') == '1' ? 'English' : 'عربي';
      }
    }

    this.addclass(this.langValue);
  }


  
  public getCountryList(){
    return this.CountryList;
  }


  public getIsAgentFoorter(){
  return this.isAgentFooter;
  }

  public getIsDefaultFoorter(){
    return this.isDefaultFooter;
  }

  public getIsChield(){
    return this.isChield;
  }

  public isQoutSerched(value:boolean){
    this.isSearch.emit(value);
  }


  private setCountry(countryCode:string){
    
    let selectdCountryIndex = this.CountryList.findIndex(x=>x.countryCode == countryCode)
    if(selectdCountryIndex != -1){
      this.countryCode = this.CountryList[selectdCountryIndex].id; 
      this.countryName = this.langValue == 1 ?  this.CountryList[selectdCountryIndex].countryNameEng : this.CountryList[selectdCountryIndex].countryNameAR;
    }else{
      this.countryCode = this.CountryList[0].id; 
      this.countryName = this.langValue == 1 ?  this.CountryList[0].countryNameEng : this.CountryList[0].countryNameAR;
    }
    
    this.CountryCodeSubject.next(this.countryCode);
    this.CountryNameSubject.next(this.countryName);
  }

  private setCurrency(){
    let selectdCurrencyIndex = this.CurrencyList.findIndex(x=>x.countryCode == this.countryCode);
    this.currencyCont = this.langValue == 1 ?  this.CurrencyList[selectdCurrencyIndex].currencyCode : this.CurrencyList[selectdCurrencyIndex].currencyArabicCode ;
    this.CurrencyContSubject.next(this.currencyCont);
  }

  public getSeletedCountryCode(){
    return this.countryCode
  }
  public getSeletedCountryNaeme(){
    let index =  this.CountryList.find(x=>x.id == this.countryCode);
    this.countryName =  this.langValue == 1 ?  index.countryNameEng : index.countryNameAR;
    return this.countryName;
  }


  public getSeletedCurrencyCode(){
    return this.currencyCont
  }

  public getInitialValues(){
    return this.InitialValuse;
  }

  public countryChange(id:string){

    if(!isNullOrUndefined(id)){
      let  selectedCountry =  this.CountryList.find(z=>z.id == id);
      localStorage.setItem('Country', selectedCountry?.countryCode);
      localStorage.setItem('CountryCode', selectedCountry?.id);
      this.reloadCurrentRoute("country");
    }

  }


  public getFavorites(){
    this._favoritesService.GetFavStatus("1", "100", true, "1", this.langId.toString(), 0,this.InitialValuse?.branchID,this.InitialValuse?.currencyDetail?.selectedCcyCode,this.InitialValuse?.groupedBranchesId);
  }
  
  public getCompare(){
    
    this._favoritesService.GetCompareStatus("1", "100", true, "2", this.langId.toString(), 0,this.InitialValuse?.branchID,this.InitialValuse?.currencyDetail?.selectedCcyCode,this.InitialValuse?.groupedBranchesId);
  }


  public getCompanyInfo(){
    this._defaultService.GetCompanyDefaultInfo(this.InitialValuse?.branchID.toString(), this.langId).then((result:ApiResponseModel<ComapnyDefaultInfo>)=>{
      if(result.StatusCode == 200){
        this.CompanyDefualtInfo = result?.Data; 
        this.CompanyDefualtInfoSubject.next(this.CompanyDefualtInfo);
      }
    })
  }

  public getComapyDefualtInfo(){
    return this.CompanyDefualtInfo
  }
}
