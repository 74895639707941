import {
  Component,
  OnInit,
  Input,
  ChangeDetectorRef,
  ViewChild,
  ElementRef,
} from "@angular/core";
import { NgbActiveModal } from "@ng-bootstrap/ng-bootstrap";
import { InitialValue } from "src/app/models/initialValue";
import { Property } from "src/app/models/property";
import { ConfigurationService } from "src/app/shared/configuration.service";
import { DefaultService } from "src/app/services/default.service";
import { ContactusService } from "src/app/services/contactus.service";
import { PropertyService } from "src/app/services/property.service";
import { NotificationService } from "src/app/shared/notification.service";
import { UtilityService } from "src/app/shared/utility.service";
import {
  Email,
  LubanEmail,
  QuotationRequest,
} from "../../models/EmailContactUs";
import { SimpleResponse } from "src/app/models/genericResponse.model";
import { FormBuilder, FormGroup, Validators } from "@angular/forms";
import { CountryISO, SearchCountryField } from "ngx-intl-tel-input";
import { NgbModal } from "@ng-bootstrap/ng-bootstrap";
import { ContactReceiptModalComponent } from "../contact-receipt-modal/contact-receipt-modal.component";
declare var bootstrap: any;

@Component({
  selector: "app-contact-modal",
  templateUrl: "./contact-modal.component.html",
  styleUrls: ["./contact-modal.component.css"],
})
export class ContactModalComponent implements OnInit {
  @Input() myProperty: Property;

  @ViewChild("form", { static: false }) form: ElementRef;

  public isEmailSubmitted: boolean = false;

  constructor(
    public activeModal: NgbActiveModal,
    private _configurationService: ConfigurationService,
    private formBuilder: FormBuilder,
    private _defaultService: DefaultService,
    private cdRef: ChangeDetectorRef,
    private _contactusService: ContactusService,
    private notifyService: NotificationService,
    private _utilityService: UtilityService,
    private _propertyService: PropertyService,
    private modalService: NgbModal
  ) {}

  initialValue: InitialValue;
  langId: number = 1;
  public reservePayment: boolean = false;
  submitted: boolean = false;
  gettingQ: boolean = false;
  email: Email;
  // lubanEmail: LubanEmail;
  token: string;
  simpleResponseemil: SimpleResponse;
  formpop: FormGroup;
  // formLubanPop: FormGroup;
  isLuban: boolean = this._configurationService.isLuban;
  nationalityTypes: any[];
  nationalityOptions: any;
  nationalityValue: string = "";
  isSupported = false;
  supportInfo: any = null;
  placetext: string = "";
  disableSubmit: boolean = false;
  CountryISO = CountryISO;
  preferredCountries: CountryISO[] = [CountryISO.Bahrain];
  SearchCountryField = SearchCountryField;
  successfulRegister: boolean = false;
  notSuccessfulRegister: boolean = false;
  isPhoneFocused = false;
  isOIDFocused = false;
  isFnameFocused = false;
  isEmailFocused = false;
  isAddressFocused = false;
  OIDIsChecked = false;
  mainComplexName: string;
  public currencyCode: string = "BHD";
  public isQ: boolean = false;

  inputFocus(fieldName: string) {
    if (fieldName === "phone") {
      this.isPhoneFocused = true;
    } else if (fieldName === "oid") {
      this.isOIDFocused = true;
    } else if (fieldName === "fname") {
      this.isFnameFocused = true;
    } else if (fieldName === "email") {
      this.isEmailFocused = true;
    } else if (fieldName === "address") {
      this.isAddressFocused = true;
    }
  }

  

  public sendEmailRequest(property: any) {
    this.submitted = true;
    this.isEmailSubmitted = true;
    let emailTemplate: any = { };
    emailTemplate = this.prepareEmail(
      property.agent?.name || '',
      property.agent?.email || '',
      property.id,
      property.code,
      property.type2,
      property.address,
      property.website,
      property?.price,
      property?.price,
      property?.agent?.id || '',
      1,
      property?.type.id,
      property?.branchId
    );
    
    if (this.formpop.invalid) {
      this.isEmailSubmitted = false;
      this.notifyService.showError("Please ensure all fields are filled out correctly before proceeding", "");
      return;
    }

    this._contactusService.SenemailSubmit(emailTemplate).then(
      (result) => {
        if (result) {
          this.isEmailSubmitted = false;
          this.simpleResponseemil = UtilityService.clone(result);
          if (this.simpleResponseemil.messageBool) {
            this.notifyService.showSuccess("Mail sent successfully!!", "");
          } else {
            this.notifyService.showError("some thing went wrong.", "");
          }          
        } else {
          this.isEmailSubmitted = false;
          this.notifyService.showError("some thing went wrong.", "");
        }
      });
  }

  public getQuotation() {
    this.gettingQ = true;
    this.submitted = true;
    if (this.formpop.invalid) {
      this.gettingQ = false;
      this.notifyService.showError("Please ensure all fields are filled out correctly before proceeding", "");
      return;
    }

    const request: QuotationRequest = {
      PropertyID: this.myProperty.id, // example value
      LangID: this.langId + "",
      Email: this.formpop.get("email").value,
      OID: this.formpop.get("oid").value,
      Name: this.formpop.get("fname").value,
      IsOmani: this.formpop.get("omaniNationality").value == "1",
      // Address: this.formLubanPop.controls["address"].value,
      Mobile:
        this.formpop.get("phone").value?.dialCode +
        this.formpop.get("phone").value?.number,
    };

    console.log("quotation request", request);
    this._contactusService.SendQuotation(request).then((result) => {
      this.gettingQ = false;
      if (result) {
        this.notifyService.showSuccess(
          this.langId == 1 ? "Quotation was sent" : "تم إرسال التسعيرة",
          ""
        );

        this._contactusService.DownloadQuotation(result).then((result) => {
          console.log("Downloaded");
        });

      } else {
        this.notifyService.showError(
          this.langId == 1
            ? "some thing went wrong."
            : "حدث خطأ الرجال المحاولة مرة أخرى",
          ""
        );
      }
    });
  }

  inputBlur(fieldName: string) {
    if (fieldName === "phone") {
      this.isPhoneFocused = false;
    } else if (fieldName === "oid") {
      this.isOIDFocused = false;
    } else if (fieldName === "fname") {
      this.isFnameFocused = false;
    } else if (fieldName === "email") {
      this.isEmailFocused = false;
    } else if (fieldName === "address") {
      this.isAddressFocused = false;
    }
  }

  ngOnInit(): void {
    this.prepareForm();
    this.getMainComplexName();
  }

  getMainComplexName() {
    this._propertyService
      .GetMainProperyName(this.langId, this.myProperty.id)
      .then((result: string | void) => {
        this.mainComplexName = result as string;
      })
      .catch((error) => {
        console.log("Error:", error);
      });
  }

  prepareForm() {
    this.langId = this._configurationService.getLanguageValue();
    this.initialValue = this._configurationService.getInitialValues();
    this.currencyCode = this._configurationService.getSeletedCurrencyCode();
    this.formpop = this.formBuilder.group({
      address: ['', []],
      fname: [null, [Validators.required]],
      phone: [null, [Validators.required, ]],
      email: [null, [Validators.required, Validators.email]],
      oid: [null, [Validators.required, Validators.pattern(/^\d{7,}$/)]],
      comment: [null, [Validators.required]],
      termsAndConditions: [false, [Validators.requiredTrue]],
      omaniNationality: ["0", [Validators.required]],
    });

    this.nationalityTypes = [
      { text: this.langId == 1 ? "Omani" : "عماني", id: "1" },
      { text: this.langId == 1 ? "None Omani" : "غير عماني", id: "2" },
    ];

    this.nationalityOptions = {
      //placeholder: this.langId == 1 ?'Nationality' : 'الجنسية',
      width: "100%",
      // Other options...
    };

    this.placetext =
      (this.langId == 1
        ? "Hi I found your property with reference number: "
        : "مرحبًا، لقد وجدت عقارك برقم المرجع:") +
      this.myProperty.code +
      (this.langId == 1
        ? " on Aqaraat website. Please contact me. Thank you."
        : "على موقع عقارات. يرجى التواصل معي. شكرًا");
    this.formpop.get("comment").setValue(this.placetext);
    // this.formLubanPop.get("comment").setValue(this.placetext);
    // this.formLubanPop.get("termsAndConditions").setValue(false);
  }

  closeModal() {
    this.activeModal.close();
  }

  get f2() {
    return this.formpop.controls;
  }


  onRecaptchaSuccess(token: string) {
    this.token = token;
  }


  public prepareEmail(
    aname,
    aemail,
    pid,
    pcode,
    ptype,
    aarea,
    curl,
    minPrice,
    maxPrice,
    agentId,
    typeOfRequest,
    saleOrRent,
    branchId
  ) {
    return (this.email = {
      CmName: this.formpop.get("fname").value,
      CmAddress: this.formpop.get("address").value,
      CmEmail: this.formpop.get("email").value,
      Governorate: this.supportInfo ? this.supportInfo.Governorate : "",
      CmOmani: this.isLuban ? this.formpop.get("omaniNationality").value == "1" : false,
      CmOID: this.formpop.get("oid").value,
      CmPhone:
        this.formpop.get("phone").value?.dialCode +
        this.formpop.get("phone").value?.number,
      AgentName: aname,
      AgentEmail: aemail,
      PropertyID: pid,
      PropertyCode: pcode,
      PropertyName: this.myProperty?.name || '',
      PropertyType: ptype,
      PropertyArea: aarea,
      PropertyComplex: (this.mainComplexName + "") || '',
      ClientURL: curl,
      Referrer: "",
      branchId: branchId,
      selectedSwiftCode:
        this.langId == 1
          ? this.initialValue?.currencyDetail?.selectedCcySwiftCode
          : this.initialValue?.currencyDetail?.selectedCcySwiftCodeArb,
      currentLangId: this.langId.toString(),
      recapchaResponse: this.token,
      message: this.formpop.controls["comment"].value,
      subscription: true,
      loginId: "1",
      minPrice: minPrice,
      maxPrice: maxPrice,
      agentId: agentId,
      typeOfRequest: "1",
      saleOrRent: saleOrRent,
      subBranch: this.initialValue?.branchID.toString(),
    });
  }

  reserveProperty(property: any) {
    this.reservePayment = true;
    this.submitted = true;
    if (this.formpop.invalid) {
      this.reservePayment = false;
      this.notifyService.showError("Please ensure all fields are filled out correctly before proceeding", "");
      return;
    }

    let emailTemplate: any = { };
    emailTemplate = this.prepareEmail(
      property.agent?.name || '',
      property.agent?.email || '',
      property.id,
      property.code,
      property.type2,
      property.address,
      property.website,
      property?.price,
      property?.price,
      property?.agent?.id || '',
      1,
      property?.type.id,
      property?.branchId
    );

      this._contactusService.SenemailSubmitNew(emailTemplate)
      .then((result) => {
        if(this.isLuban) {
          if (!result.messageBool && result.messageInt) {
            this._contactusService.InitiatePG(parseInt(property?.branchId),
                result.messageInt,
                this.formpop.get("oid").value
              )
              .then((result: any) => {
                if (result) {
                  // Create a form element
                  const form = document.createElement("form");
                  form.style.display = "none";
                  form.method = "post";
                  form.action =
                    "https://epaymentstrans.soharinternational.com:/transaction.do?command=initiateTransaction";
  
                  // Create hidden input elements for 'encRequest' and 'access_code'
                  const inputEncRequest = document.createElement("input");
                  inputEncRequest.type = "hidden";
                  inputEncRequest.name = "encRequest";
                  inputEncRequest.value = result.enc_request; // Replace this with the actual value
  
                  const inputAccessCode = document.createElement("input");
                  inputAccessCode.type = "hidden";
                  inputAccessCode.name = "access_code";
                  inputAccessCode.value = result.access_code; // Replace this with the actual value
  
                  // Append inputs to the form
                  form.appendChild(inputEncRequest);
                  form.appendChild(inputAccessCode);
  
                  // Append the form to the body (it doesn't have to be visible)
                  document.body.appendChild(form);
  
                  // Submit the form
                  form.submit();
                } else {
                  this.notifyService.showError("some thing went wrong.", "");
                  this.reservePayment = false;
                  this.submitted = false;
                }
              });
          } else {
            this.notifyService.showError("some thing went wrong.", "");
            this.reservePayment = false;
            this.submitted = false;
          }
        } else {
          // this.simpleResponseemil = UtilityService.clone(result);
          // this.openReceiptModal();
          this.notifyService.showSuccess("", this.langId == 1 ? "Reversation request was sent successfully":"تم إرسال طلب الحجز بنجاح")
          // this.formpop.get('address').enable();
          // this.formpop.get("fname").enable();
          this.reservePayment = false;
          this.submitted = false;
          this.closeModal();
        }
        /* 
      if (result && result.messageInt > 0 ) {

      }else{
        this.notifyService.showSuccess("some thing went wrong.", "");
        this.disableSubmit = false;
        this.submitted = false;
      } 
      console.log(result);
      */

      });

  }


  openReceiptModal() {
    // Close the current modal
    this.modalService.dismissAll();

    // Open the new modal with custom HTML
    const modalRef = this.modalService.open(ContactReceiptModalComponent, {
      windowClass: "custom-modal",
    });
    // modalRef.componentInstance.email = this.lubanEmail;
    modalRef.componentInstance.leadID = this.simpleResponseemil.messageInt;
  }



  // submitLuban(
  //   aname,
  //   aemail,
  //   amobile,
  //   pid,
  //   pcode,
  //   ptype,
  //   aarea,
  //   curl,
  //   price,
  //   agentId,
  //   saleOrRent,
  //   branchId
  // ) {
  //   console.log("isQ: " + this.isQ);
  //   this.disableSubmit = true;
  //   this.submitted = true;
  //   if (this.formLubanPop.invalid) {
  //     this.scrollToFirstError();
  //     this.disableSubmit = false;
  //     return;
  //   }
  //   this._contactusService
  //     .SenemailSubmitNew(
  //       this.prepareEmailLuban(
  //         aname,
  //         aemail,
  //         amobile,
  //         pid,
  //         pcode,
  //         ptype,
  //         aarea,
  //         curl,
  //         price,
  //         price,
  //         agentId,
  //         1,
  //         saleOrRent,
  //         branchId
  //       )
  //     )
  //     .then((result) => {
  //       /* 
  //     if (result && result.messageInt > 0 ) {
  //       this.simpleResponseemil = UtilityService.clone(result);
  //       this.openReceiptModal()
  //       this.notifyService.showSuccess("", this.langId == 1 ? "Reversation request was sent successfully":"تم إرسال طلب الحجز بنجاح")
  //       this.formLubanPop.get('address').enable();
  //       this.formLubanPop.get("fname").enable();
  //       this.disableSubmit = false;
  //       this.submitted = false;
  //       this.closeModal();
  //     }else{
  //       this.notifyService.showSuccess("some thing went wrong.", "");
  //       this.disableSubmit = false;
  //       this.submitted = false;
  //     } 
  //     console.log(result);
  //     */
  //       if (!result.messageBool && result.messageInt) {
  //         debugger;
  //         this._contactusService
  //           .InitiatePG(
  //             parseInt(branchId),
  //             result.messageInt,
  //             this.formLubanPop.controls["oid"].value
  //           )
  //           .then((result: any) => {
  //             if (result) {
  //               // Create a form element
  //               const form = document.createElement("form");
  //               form.style.display = "none";
  //               form.method = "post";
  //               form.action =
  //                 "https://epaymentstrans.soharinternational.com:/transaction.do?command=initiateTransaction";

  //               // Create hidden input elements for 'encRequest' and 'access_code'
  //               const inputEncRequest = document.createElement("input");
  //               inputEncRequest.type = "hidden";
  //               inputEncRequest.name = "encRequest";
  //               inputEncRequest.value = result.enc_request; // Replace this with the actual value

  //               const inputAccessCode = document.createElement("input");
  //               inputAccessCode.type = "hidden";
  //               inputAccessCode.name = "access_code";
  //               inputAccessCode.value = result.access_code; // Replace this with the actual value

  //               // Append inputs to the form
  //               form.appendChild(inputEncRequest);
  //               form.appendChild(inputAccessCode);

  //               // Append the form to the body (it doesn't have to be visible)
  //               document.body.appendChild(form);

  //               // Submit the form
  //               form.submit();
  //             } else {
  //               this.notifyService.showError("some thing went wrong.", "");
  //               this.disableSubmit = false;
  //               this.submitted = false;
  //             }
  //           });
  //       } else {
  //         this.notifyService.showError("some thing went wrong.", "");
  //         this.disableSubmit = false;
  //         this.submitted = false;
  //       }
  //     });
  // }

}










  // get f3() {
  //   return this.formLubanPop.controls;
  // }

  // openRegisterModal() {
  //   this.formLubanPop.get("address").setValue("");
  //   this.formLubanPop.get("fname").setValue("");
  //   this.formLubanPop.get("oid").setValue("");
  //   this.formLubanPop.get("omaniNationality").setValue("0");
  //   this.formLubanPop.get("phone").setValue("");
  //   this.formLubanPop.get("email").setValue("");
  //   this.formLubanPop.get("comment").setValue("");

  //   const bootstrapModalInstance = new bootstrap.Modal(
  //     document.querySelector("#registerFormModal"),
  //     {
  //       backdrop: true,
  //     }
  //   );

  //   bootstrapModalInstance.show();
  // }



  // onOIDChange(oid: string) {
  //   this.isSupported = false;
  //   this.successfulRegister = false;
  //   this.notSuccessfulRegister = false;
  //   this.supportInfo = null;
  //   this.formLubanPop.get("address").setValue("");
  //   this.formLubanPop.get("fname").setValue("");
  //   this.formLubanPop.get("address").enable();
  //   this.formLubanPop.get("fname").enable();

  //   if (this.nationalityValue == "1" && oid.length > 6) {
  //     this._defaultService.GetSupportInfo(oid).then((result: any) => {
  //       if (result) {
  //         this.isSupported = true;
  //         this.supportInfo = result;
  //         this.notifyService.showSuccess(
  //           this.langId == 1
  //             ? "You are among the list of eligible people"
  //             : "انت من ضمن قائمة المستحقين",
  //           ""
  //         );

  //         this.formLubanPop
  //           .get("fname")
  //           .setValue(result?.Name /*.split(' ')[0]*/);
  //         this.formLubanPop.get("address").setValue(result?.Area);
  //         this.formLubanPop.get("address").disable();
  //         this.formLubanPop.get("fname").disable();

  //         if (result?.Mobile) {
  //           this.formLubanPop.get("phone").setValue(result?.Mobile);
  //           this.cdRef.detectChanges();
  //         }

  //         this.successfulRegister = true;
  //         this.notSuccessfulRegister = false;
  //       } else {
  //         this.isSupported = false;
  //         this.successfulRegister = false;
  //         this.notSuccessfulRegister = true;
  //       }
  //     });
  //   } else {
  //     this.successfulRegister = false;
  //     this.notSuccessfulRegister = false;
  //   }
  // }

  // OIDSubmit() {
  //   this.isSupported = false;
  //   this.successfulRegister = false;
  //   this.notSuccessfulRegister = false;
  //   this.supportInfo = null;
  //   this.formLubanPop.get("address").setValue("");
  //   this.formLubanPop.get("fname").setValue("");
  //   this.formLubanPop.get("address").enable();
  //   this.formLubanPop.get("fname").enable();
  //   let oid = this.formLubanPop.get("oid").value;
  //   this.OIDIsChecked = true;

  //   if (this.nationalityValue == "1" && oid.length > 6) {
  //     this._defaultService.GetSupportInfo(oid).then((result: any) => {
  //       if (result) {
  //         this.isSupported = true;
  //         this.supportInfo = result;
  //         this.notifyService.showSuccess(
  //           this.langId == 1
  //             ? "You are among the list of eligible people"
  //             : "انت من ضمن قائمة المستحقين",
  //           ""
  //         );

  //         this.formLubanPop
  //           .get("fname")
  //           .setValue(result?.Name /*.split(' ')[0]*/);
  //         this.formLubanPop.get("address").setValue(result?.Area);
  //         this.formLubanPop.get("address").disable();
  //         this.formLubanPop.get("fname").disable();

  //         if (result?.Mobile) {
  //           this.formLubanPop.get("phone").setValue(result?.Mobile);
  //           this.cdRef.detectChanges();
  //         }

  //         this.successfulRegister = true;
  //         this.notSuccessfulRegister = false;
  //       } else {
  //         this.isSupported = false;
  //         this.successfulRegister = false;
  //         this.notSuccessfulRegister = true;
  //       }
  //     });
  //   } else {
  //     this.successfulRegister = false;
  //     this.notSuccessfulRegister = false;
  //   }
  // }



  // getQuotation() {
  //   this.gettingQ = true;
  //   this.submitted = true;
  //   if (this.formpop.invalid) {
  //     this.scrollToFirstError();
  //     this.gettingQ = false;
  //     return;
  //   }

  //   const request: QuotationRequest = {
  //     PropertyID: this.myProperty.id, // example value
  //     LangID: this.langId + "",
  //     Email: this.formpop.controls["email"].value,
  //     OID: this.formLubanPop.controls["oid"].value,
  //     Name: this.formLubanPop.controls["fname"].value,
  //     IsOmani: this.formLubanPop.controls["omaniNationality"].value == "1",
  //     Address: this.formLubanPop.controls["address"].value,
  //     Mobile:
  //       this.formLubanPop.controls["phone"].value?.dialCode +
  //       this.formLubanPop.controls["phone"].value?.number,
  //   };

  //   this._contactusService.SendQuotation(request).then((result) => {
  //     if (result) {
  //       this.notifyService.showSuccess(
  //         this.langId == 1 ? "Quotation was sent" : "تم إرسال التسعيرة",
  //         ""
  //       );
  //       console.log("result", result);
  //       this._contactusService.DownloadQuotation(result).then((result) => {
  //         console.log("Downloaded");
  //       });
  //     } else {
  //       this.notifyService.showError(
  //         this.langId == 1
  //           ? "some thing went wrong."
  //           : "حدث خطأ الرجال المحاولة مرة أخرى",
  //         ""
  //       );
  //     }

  //     this.gettingQ = false;
  //   });
  // }

  // getQ() {
  //   this.gettingQ = true;
  //   this.submitted = true;
  //   if (this.formLubanPop.invalid) {
  //     this.scrollToFirstError();
  //     this.gettingQ = false;
  //     return;
  //   }

  //   const request: QuotationRequest = {
  //     PropertyID: this.myProperty.id, // example value
  //     LangID: this.langId + "",
  //     Email: this.formLubanPop.controls["email"].value,
  //     OID: this.formLubanPop.controls["oid"].value,
  //     Name: this.formLubanPop.controls["fname"].value,
  //     IsOmani: this.formLubanPop.controls["omaniNationality"].value == "1",
  //     Address: this.formLubanPop.controls["address"].value,
  //     Mobile:
  //       this.formLubanPop.controls["phone"].value?.dialCode +
  //       this.formLubanPop.controls["phone"].value?.number,
  //   };

  //   this._contactusService.SendQuotation(request).then((result) => {
  //     if (result) {
  //       this.notifyService.showSuccess(
  //         this.langId == 1 ? "Quotation was sent" : "تم إرسال التسعيرة",
  //         ""
  //       );
  //       console.log("result", result);
  //       this._contactusService.DownloadQuotation(result).then((result) => {
  //         console.log("Downloaded");
  //       });
  //     } else {
  //       this.notifyService.showError(
  //         this.langId == 1
  //           ? "some thing went wrong."
  //           : "حدث خطأ الرجال المحاولة مرة أخرى",
  //         ""
  //       );
  //     }

  //     this.gettingQ = false;
  //   });
  // }

  // private scrollToFirstError() {
  //   console.log("scrolling");

  //   console.log("this.formLubanPop.controls", this.formLubanPop.controls);

  //   const firstInvalidControl = Object.keys(this.formLubanPop.controls).find(
  //     (control) => {
  //       return this.formLubanPop.get(control).invalid;
  //     }
  //   );

  //   console.log("firstInvalidControl", firstInvalidControl);

  //   if (firstInvalidControl) {
  //     const invalidControl = this.formLubanPop.get(firstInvalidControl);
  //     const invalidControlElement = this.form.nativeElement.querySelector(
  //       `[formControlName="${firstInvalidControl}"]`
  //     );

  //     if (invalidControlElement) {
  //       invalidControlElement.scrollIntoView({
  //         behavior: "smooth",
  //         block: "center",
  //       });
  //     }
  //   }
  // }




  // prepareEmailLuban(
  //   aname,
  //   aemail,
  //   amobile,
  //   pid,
  //   pcode,
  //   ptype,
  //   aarea,
  //   curl,
  //   minPrice,
  //   maxPrice,
  //   agentId,
  //   typeOfRequest,
  //   saleOrRent,
  //   branchId
  // ) {
  //   return (this.lubanEmail = {
  //     CmName: this.formLubanPop.controls["fname"].value,
  //     CmAddress: this.formLubanPop.controls["address"].value,
  //     Governorate: this.supportInfo ? this.supportInfo.Governorate : "",
  //     CmOmani: this.formLubanPop.controls["omaniNationality"].value == "1",
  //     CmOID: this.formLubanPop.controls["oid"].value,
  //     CmEmail: this.formLubanPop.controls["email"].value,
  //     CmPhone:
  //       this.formLubanPop.controls["phone"].value?.dialCode +
  //       this.formLubanPop.controls["phone"].value?.number,
  //     AgentName: aname,
  //     AgentEmail: aemail,
  //     AgentMobile: amobile,
  //     PropertyID: pid,
  //     PropertyName: this.myProperty.name,
  //     PropertyCode: pcode,
  //     PropertyType: ptype,
  //     PropertyArea: aarea,
  //     PropertyComplex: this.mainComplexName + "",
  //     ClientURL: curl,
  //     Referrer: "",
  //     branchId: branchId,
  //     selectedSwiftCode:
  //       this.langId == 1
  //         ? this.initialValue?.currencyDetail?.selectedCcySwiftCode
  //         : this.initialValue?.currencyDetail?.selectedCcySwiftCodeArb,
  //     currentLangId: this.langId.toString(),
  //     recapchaResponse: this.token,
  //     message: this.formLubanPop.controls["comment"].value,
  //     subscription: true,
  //     loginId: "1",
  //     minPrice: minPrice,
  //     maxPrice: maxPrice,
  //     agentId: agentId,
  //     typeOfRequest: "1",
  //     saleOrRent: saleOrRent,
  //     subBranch: this.initialValue?.branchID.toString(),
  //   });
  // }

  


  
  // submit(
  //   aname,
  //   aemail,
  //   pid,
  //   pcode,
  //   ptype,
  //   aarea,
  //   curl,
  //   price,
  //   agentId,
  //   saleOrRent,
  //   branchId
  // ) {
  //   debugger;
  //   this.disableSubmit = true;
  //   this.submitted = true;
  //   this.prepareEmail(
  //     aname,
  //     aemail,
  //     pid,
  //     pcode,
  //     ptype,
  //     aarea,
  //     curl,
  //     price,
  //     price,
  //     agentId,
  //     1,
  //     saleOrRent,
  //     branchId
  //   );
  //   if (this.formpop.invalid) {
  //     this.disableSubmit = false;
  //     return;
  //   }
  //   this._contactusService
  //     .SenemailSubmit(
  //       this.prepareEmail(
  //         aname,
  //         aemail,
  //         pid,
  //         pcode,
  //         ptype,
  //         aarea,
  //         curl,
  //         price,
  //         price,
  //         agentId,
  //         1,
  //         saleOrRent,
  //         branchId
  //       )
  //     )
  //     .then((result) => {
  //       if (result) {
  //         this.disableSubmit = false;
  //         this.simpleResponseemil = UtilityService.clone(result);
  //         if (this.simpleResponseemil.messageBool) {
  //           this.notifyService.showSuccess("Mail send successfully !!", "");
  //           this.closeModal();
  //         } else this.notifyService.showSuccess("some thing went wrong.", "");
  //       } else {
  //         this.disableSubmit = false;
  //       }
  //     });
  // }