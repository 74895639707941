import { Component, OnInit, AfterViewInit, ViewChild, Renderer2, ChangeDetectorRef, Inject, ElementRef, OnDestroy } from '@angular/core';
import { DOCUMENT } from '@angular/common';
import { ActivatedRoute, NavigationEnd, Router } from '@angular/router';
import { currencyDetails, InitialValue, InitialValueResponse } from '../../../models/initialValue';
import { DefaultService } from '../../../services/default.service';
import { PropertyService } from '../../../services/property.service';
import { UtilityService } from '../../../shared/utility.service';
import { Advertisement, Property, PropertyDetailResponse, PropertyFeature, PropertyFiltersResponse, PropertySearchParams, PropertySearchResults, PropertySearchResultsResponse, QuickSummaryItem, SimpleRequest } from '../../../models/property';
import { Employee } from '../../../models/Employee';
import { isNullOrUndefined } from 'util';
import { Company, CompanyResponse } from '../../../models/Company';
import { ContactusService } from '../../../services/contactus.service';
import { SimpleResponse } from '../../../models/genericResponse.model';
import { Email, LubanEmail } from '../../../models/EmailContactUs';
import { FormBuilder, FormGroup, Validators } from '@angular/forms';
import { NotificationService } from '../../../shared/notification.service';
import { CountryISO, SearchCountryField } from 'ngx-intl-tel-input';
import { CompareService } from '../../../services/compare.service';
import { ConfirmBoxInitializer, DialogLayoutDisplay } from '@costlydeveloper/ngx-awesome-popup';
import { SimpleStateService } from '../../../services/simple-state.service';
import { Meta, Title  } from '@angular/platform-browser';
import { SetMetaTagSocialService } from '../../../shared/set-meta-tag-social.service';
import { Lightbox } from 'ngx-lightbox';
import { GoogleMap } from '@angular/google-maps';
import { RedirectionService } from 'src/app/services/redirection.service';
import { ConfigurationService } from 'src/app/shared/configuration.service';
import { FavoriteService } from 'src/app/services/favorite.service';
import { SwiperComponent } from "swiper/angular";
import Swiper from "swiper/types/swiper-class";
import { SwiperOptions } from 'swiper';
import { DomSanitizer,SafeUrl } from '@angular/platform-browser';

import SwiperCore , {
  Navigation,
  Autoplay,
  Controller,
} from 'swiper';
import { NgbModal } from '@ng-bootstrap/ng-bootstrap';
import { ContactModalComponent } from '../../contact-modal/contact-modal.component';
//import { ViewChangeEvent } from '@egjs/ngx-view360';
SwiperCore.use([
  Navigation,
  Autoplay,
  Controller
]);

declare let gapi: any;
declare var $: any;

@Component({
    selector: 'app-propertydetail',
    templateUrl: './propertydetail.component.html',
    styleUrls: ['./propertydetail.component.css']
})
/** propertydetail component*/
export class PropertydetailComponent {

  @ViewChild('map', {static:false}) mapElement: any;
  @ViewChild(GoogleMap, { static: false }) gmap: GoogleMap;
  @ViewChild("mainSlider") mainSlider: SwiperComponent;
  @ViewChild("subSlider") subSlider: SwiperComponent;
  @ViewChild("view360") view360: ElementRef;

  view360Comp: any;
  
  swiperConfig: SwiperOptions = {
    slidesPerView: 1,
    spaceBetween: 0,
    autoplay: false,
  };

  swiperThumb: SwiperOptions = {
    slidesPerView: 4,
    spaceBetween: 7.5,
    navigation: true,
    pagination: true,
    slideToClickedSlide: true,
  }

  map: google.maps.Map;
  formpop: FormGroup;
  formLubanPop: FormGroup;
  propertyDetailResponse: PropertyDetailResponse;
  myProperty: Property;
  sliderProperties: Property[];
  agencyInfo2: Employee[];
  mainActivityProperty: Property[];
  ads: Advertisement[];
  prtagent: Employee;
  quickSummary: QuickSummaryItem[];
  simpleResponse: SimpleResponse;
  propertyFeature: PropertyFeature[];
  propertySearchitemfav: PropertySearchResults[];
  propertySearchResultsResponsefav: PropertySearchResultsResponse;
  propertySearchResultsResponse: PropertySearchResultsResponse;
  propertySearchResults: PropertySearchResults[];
  propertySearchitem: PropertySearchResults[];
  promoIMG: string[];
  paramId: string;
  initialValueResponse: InitialValueResponse;
  initialValue: InitialValue;
  public countryCode = '973';
  currencyDetail: currencyDetails;
  simpleRequest: SimpleRequest;
  propertyFiltersResponse: PropertyFiltersResponse;
  propertyFeatures: PropertyFeature[];
  companyResponse: CompanyResponse;
  companyInfo: Company
  propertySearchParams: PropertySearchParams;
  propertySearchResultsfav: PropertySearchResults[];
  simpleResponseemil: SimpleResponse;
  email: Email;
  lubanEmail: LubanEmail;
  SearchCountryField = SearchCountryField;
  CountryISO = CountryISO;
  preferredCountries: CountryISO[] = [CountryISO.Bahrain];
  propertyFeaturesSearch: string[];
  public currencyCode: string = 'BHD';
  iShowproperty: number = 0;
  siteUrl: string = '';
  langId: number = 1;
  hostName: string = '';
  submitted = false;
  token: string;
  propImage: string = '';
  propDesc: string = '';
  displayMap: string = '1';
  isMoreProp: boolean = true;
  ismainactivity: boolean = false;
  isShowproperty: boolean = false;
  isSet360: boolean = false;
  floorImage: any = [];
  markers: any = [];
  zoom = 15;
  isAlreadyInitiated:boolean = false;
  tooltipVisible:boolean = false;
  center: google.maps.LatLngLiteral
  options: google.maps.MapOptions = {
    mapTypeId: 'satellite',
    zoomControl: true,
    scrollwheel: false,
    disableDoubleClickZoom: true,
  }
  isLoading:boolean = false;
    minVal: number=0;
  maxVal: number = 0;
  isMoreProperty: boolean = false;
  isFavSelectd:boolean = false;
  isCompSelected:boolean = false;
  isSubmitted:boolean = false;
  youtubeVid:SafeUrl = "";
  current360View:string = "";
  hotspots: any = [];
  current360ViewIndex:number = 0;
  isLuban: boolean = this._configurationService.isLuban;
  nationalityTypes: any[] ;
  nationalityOptions: any;
  nationalityValue: string = '';
  isSupported = false;
  supportInfo : any = null;
  placetext: string = '';
  disableSubmit:boolean = false;
  fromtype:string = '1';

    /** propertydetail ctor */
  constructor(private route: ActivatedRoute, private _router: Router, private _defaultService: DefaultService,
    private _propertyService: PropertyService, private _contactusService: ContactusService, private _lightbox: Lightbox,
    private elementRef: ElementRef, private _renderer2: Renderer2, private _compare: CompareService,
    private formBuilder: FormBuilder, private notifyService: NotificationService, private _utilityService: UtilityService,
    private _simpleStateService: SimpleStateService, private meta: Meta, private titleService: Title, private _setMetaTagSocialService: SetMetaTagSocialService,
    private _redirectionService: RedirectionService, private _configurationService: ConfigurationService,private _favoriteService :FavoriteService,
    private sanitizer: DomSanitizer, private cdRef: ChangeDetectorRef, private modalService: NgbModal)
  {

    // this.hostName = localStorage.getItem('hostname');
    this.siteUrl = window.location.href;
    //.replace("https://localhost:44326/","https://angular.aqaraats.com/");
    this.route.queryParams
      .subscribe(params => {
        this.fromtype = params.fromtype == '1' ? '1' : '';
        this.displayMap = this.fromtype;
        this.isMoreProperty = this.fromtype == '1';
        this.paramId = params.id;
        this.isLoading = true;
        this.loadData();
      });      
  }

  get f2() {
    return this.formpop.controls;
  }

  get f3(){
    return this.formLubanPop.controls;
  }

  getpropertybyId(id) { 
    this._utilityService.sendClickInfo("1", id, "", this.initialValue?.branchID,"",this.countryCode);
    const modalRef = this.modalService.open(ContactModalComponent,{ windowClass: 'custom-modal' });
    modalRef.componentInstance.myProperty = this.myProperty;
  }

  handleMainSliderChange(swiper: Swiper) {
    this.subSlider.swiperRef.slideTo(swiper.realIndex);
  }

  handleSubSliderNext() {
    if (this.langId == 1) {
      this.mainSlider.swiperRef.slidePrev();
    } else {
      this.mainSlider.swiperRef.slideNext();
    }
  }

  handleSubSliderPrev() {
    if (this.langId == 1) {
      this.mainSlider.swiperRef.slideNext();
    } else {
      this.mainSlider.swiperRef.slidePrev();
    }
  }

  handleSubSliderChange(index: number) {
    this.mainSlider.swiperRef.slideTo(index);
  }
  
  ngAfterViewInit() {

    if(this.myProperty) {
      if (this.propertySearchResultsfav.length > 0) {
        this.highLightFav(this.propertySearchResults, true)
      }
  
      if (this.propertySearchResults.length > 0){
        this.highLightFav(this.propertySearchResults, false)
      }
    }


  }


  set360View() {
    try{
      if(this.view360) {
        this.view360Comp = new View360(this.view360.nativeElement, {
          autoResize: true,
          plugins: [new View360.ControlBar(),new View360.LoadingSpinner()],
          hotspot: {
            zoom: true
          },
          projection: new View360.EquirectProjection({
            src: this.current360View,
            video: false
          })
        });

        //this.view360Comp.renderer.canvas.style.width = "500px";
        //this.view360Comp.renderer.canvas.style.height = "500px";
  
        this.isSet360 = true;
  
      } 
    } catch(error){

    }
  }

  move360To(number) {
    this.current360View = this.myProperty.view360List[number];
    this.current360ViewIndex = number;
    //set hotspots if any
    
    //this.hotspots = [{x:1 , y:1 , z:0 , moveTo:2}]
    
    this.view360Comp.load(new View360.EquirectProjection({
      src: this.current360View
    })).then(async () => {
      // move the camera to the initial point
      await this.view360Comp.camera.animateTo({yaw: 0, pitch: 0, zoom: 1, duration: 1000});
    
      // refresh the hotspots
      //this.view360Comp.hotspot.refresh();
    });

  }

  ngAfterViewChecked() {
    if(!this.isSet360 && this.current360View){
      this.set360View();
    }
  }


  ngOnInit() {
  
    this.isAlreadyInitiated = true;
    this.formpop = this.formBuilder.group({
      fname: ['', [Validators.required]],
      phone: ['', [Validators.required]],
      email: ['', [Validators.required, Validators.email]],
      comment: ['', [Validators.required]]
    });

    this.formLubanPop = this.formBuilder.group({
      oid: ['', [Validators.required]],
      omaniNationality: ['0', [Validators.required]],
      address: ['', [Validators.required]],
      fname: ['', [Validators.required]],
      phone: ['', [Validators.required]],
      email: ['', [Validators.required, Validators.email]],
      comment: ['', [Validators.required]]
    })

    //this._simpleStateService.refreshAddThis();
    window.scrollTo({
      top: 0,
      behavior: 'instant' as any
    })
    this.loadData()

    this._favoriteService.propertySearchResultsfavSubject.subscribe(resp=>{
      
      if(resp != null){
        this.propertySearchitemfav = resp
        //this.ClearCopare(propertyId, type)
        this.propertySearchResultsfav = this.propertySearchitemfav;
        if (this.propertySearchResultsfav.length > 0 && this.myProperty){
          this.isFavSelectd = true
          this.highLightFav(this.propertySearchResultsfav, false)
        }
      }

    })

    this._favoriteService.propertySearchResultsCompareSubject.subscribe(resp=>{
      if(resp != null){
        this.propertySearchitem = resp;
        //let ps = this.propertySearchitem.filter(c=>c.);
        //this.ClearCopare(propertyId, type)
        this.propertySearchResults = this.propertySearchitem;
        if (this.propertySearchResults.length > 0 && this.myProperty){
          this.isCompSelected = true
          this.highLightFav(this.propertySearchResults, false)
        }
      }
    })


    this.nationalityTypes = [
      { text: this.langId == 1 ? 'Omani':'عماني', id: '1' },
      { text: this.langId == 1 ? 'None Omani' : 'غير عماني', id: '2' }
    ];

    this.nationalityOptions = {
      placeholder: this.langId == 1 ?'Nationality' : 'الجنسية',
      width: '100%',
      // Other options...
    }

  }



  
  highLightFav(propertySerchResult:PropertySearchResults[], isFavoirit){
    if(propertySerchResult?.length > 0 && propertySerchResult != null){
      propertySerchResult.forEach(item=>{
        if(isFavoirit){
          this.myProperty.id == item?.id ? this.myProperty.favorite = item?.isFavorite : this.myProperty.favorite = this.myProperty.favorite
        }else{
          this.myProperty.id == item?.id ? this.myProperty.compare = item?.isCompare : this.myProperty.compare = this.myProperty.compare
        }
      })
    }
  }

  


  private loadData(){
    
    if(this.initialValue != null && this.isAlreadyInitiated){
      this.GetInitialValues();
    }
    else if(this.isAlreadyInitiated){
      this.initialValue = this._configurationService.getInitialValues();

      this.langId = this._configurationService.getLanguageValue();
      this.currencyCode = this._configurationService.getSeletedCurrencyCode();
      this.countryCode = this._configurationService.getSeletedCountryCode();
  
      this.GetInitialValues();
    }
  }

  ngOnDestroy(): void {
    $('#myModal').modal('hide');
  }

  GetCompany() {
    this._contactusService.GetCompany(this.initialValue.branchID, this.langId.toString()).then((result) => {
      if (result) {
        this.companyResponse = UtilityService.clone(result);
        if (!isNullOrUndefined(this.companyResponse)) {
          this.companyInfo = this.companyResponse.companyInfo
        }
      }
    });
  }

  ecodeuri(id, fromtype) {
    const queryParams = { id };
  
    if (fromtype == '1') {
      queryParams['fromtype'] = fromtype;
    }
  
    this._router.navigate(['/propertydetail'], { queryParams });
  }
    

  GetInitialValues() {
    if(this.initialValue != null){
      if (this.initialValue.branchID == 14)
      this.ismainactivity = false;
    else
      this.ismainactivity = true;
    //this.GetSerchFilterData(1);
    this.Property_GetAsync(this.langId);
    this.GetCompany();
    }else{
      this.initialValue = this._configurationService.getInitialValues();
    }

  }

  Property_GetAsync(currentLang: number) {
    
    let countryCode = isNullOrUndefined(localStorage.getItem("CountryCode")) ? this.initialValue?.countryCode : localStorage.getItem("CountryCode");
    this._propertyService.Property_GetAsync(currentLang, this.initialValue.groupedBranchesId, this.initialValue.branchID, this.countryCode, this.PrepareForm()).then((result) => {
      if (result) {
        this.isLoading = false;
        this.propertyDetailResponse = UtilityService.clone(result);
        this.myProperty = this.propertyDetailResponse.myProperty;
        if (this.myProperty?.latitude != '') {
            this.center = {
              lat: +this.myProperty?.latitude,
              lng: +this.myProperty?.longitude,
            };
          //});
          setTimeout(() => {
            this.addMarker();
          }, 1000);         
        }
        if (!isNullOrUndefined(this.myProperty.agent)) {
          var imgPath = this.myProperty.agent?.image.split('agents/');
          if (imgPath[1] == '') {
            this.myProperty.agent.image = 'https://cdn.aqaraats.com/images/NA.jpg';
          }
        }
        this.myProperty.promoIMG.push(this.myProperty.mainIMG);
        this.propImage = this.myProperty.mainIMG;
        this.propDesc = this.myProperty.name;
        this._setMetaTagSocialService.setFacebookTags(this.siteUrl, this.myProperty.name, this.myProperty.description, this.myProperty.mainIMG)
        /*this.titleService.setTitle(this.myProperty.name);*/
        this.meta.addTags([
          { name: 'twitter:site', content: '@github' },
          { name: 'twitter:card', content: this.myProperty.description },          
          { name: 'twitter:title', content: this.myProperty.name },
          { name: 'twitter:description', content: this.myProperty.description },
          { name: 'twitter:image:src', content: this.myProperty.mainIMG }
        ]);  

        this.meta.updateTag({ property: 'og:image', content: this.myProperty.mainIMG });
        this.meta.updateTag({ name: 'twitter:image', content: this.myProperty.mainIMG });

        // localStorage.removeItem("fromtype");
        this.promoIMG = this.myProperty.promoIMG;
        if (!isNullOrUndefined(this.myProperty.floorIMG)) {
          for (const img of this.myProperty.floorIMG) {
            this.floorImage.push({ 'src': img });
          }
        }

        if(this.myProperty.vid /*&& this.myProperty.vid[0].includes("youtu")*/){
          this.youtubeVid = this.sanitizer.bypassSecurityTrustResourceUrl(this.myProperty.vid[0]);
        }

        this.sliderProperties = this.propertyDetailResponse.sliderProperties;

        console.log('sliderProperties',this.sliderProperties)

        for (var i = 0; i < this.sliderProperties.length; i++) {
          if (isNullOrUndefined(this.sliderProperties[i].mainIMG) || this.sliderProperties[i].mainIMG == "" || this.sliderProperties[i].mainIMG == '') {
            this.sliderProperties[i].mainIMG = 'https://cdn.aqaraats.com/images/NA.jpg';
          }
        }
        if (!isNullOrUndefined(this.sliderProperties) && this.sliderProperties.length == 0)
          this.isMoreProp = false;
        this.agencyInfo2 = this.propertyDetailResponse.agencyInfo2;
        this.mainActivityProperty = this.propertyDetailResponse.mainActivityProperty;
        this.ads = this.propertyDetailResponse.ads;
        this.prtagent = this.myProperty.agent;
        this.quickSummary = this.propertyDetailResponse.myProperty.quickSummary;

        if(this.isLuban){
          this.quickSummary = this.quickSummary.filter(qc => qc.value !== '');
        }

        this.propertyFeaturesSearch = this.propertyDetailResponse.myProperty.features;
        this.GetSerchFilterData(this.langId);
        this.iShowproperty = 1;
        this.placetext =  (this.langId == 1 ? "Hi I found your property with reference number: " : "مرحبًا، لقد وجدت عقارك برقم المرجع:") + this.myProperty.code + (this.langId == 1 ? ' on Aqarat website.Please contact me. Thank you.' : "على موقع عقارات. يرجى التواصل معي. شكرًا") ;
        this.formpop.get("comment").setValue(this.placetext);this.formLubanPop.get("comment").setValue(this.placetext);
        this._utilityService.sendClickInfo("4", this.myProperty.id, "",this.initialValue?.branchID, this.myProperty?.branchId,this.countryCode);
        // this code block is a tempo fix will needed a permenent solution for this logic but for now it is working
        if(!this.isFavSelectd && this.propertySearchResultsfav?.length > 0){
          this.isFavSelectd = true
          this.highLightFav(this.propertySearchResultsfav, true);
        }

        if(!this.isCompSelected && this.propertySearchResults?.length > 0){
          this.isCompSelected = true
          this.highLightFav(this.propertySearchResults, false);
        }

        if(this.myProperty.view360List){
          //harcoded for testing
          //this.myProperty.view360List = ["https://cdn.aqaraats.com/540/e14030be-87c1-421e-8e1d-9f7513c6f039_1570260.jpeg","https://cdn.aqaraats.com/540/e14030be-87c1-421e-8e1d-9f7513c6f039_1570260.jpeg","https://cdn.aqaraats.com/540/e14030be-87c1-421e-8e1d-9f7513c6f039_1570260.jpeg"];
          this.current360View = this.myProperty.view360List[0];
          this.set360View();
        }
        
        // till here
        $('#loadValue').hide();
      }
    });
  }


  sanitizeHtml(html: string) {
    return this.sanitizer.bypassSecurityTrustHtml(html);
  }

  addCallNumber(mobile, i, propid, branchId,price,agentId,saleOrRent,subBranch) {
    console.log(this.myProperty, "this.myProperty")
    $('#ane_0').hide();
    $('#an_0').show();
    $('#sp_0').html(mobile);
    this._utilityService.sendClickInfo("3", propid, "",subBranch , branchId, this.countryCode,"",price,agentId,saleOrRent,this.initialValue?.branchID.toString());
  }
  addCallagentNumber(mobile, i, propid,price,agentId,saleOrRent,branchID) {
    
    $('#ane_0').show();
    $('#an_0').hide();
    $('#sp_0').html('');
    this._utilityService.sendClickInfo("3", propid, "",branchID ,"",this.countryCode,"",price,agentId,saleOrRent,this.initialValue?.branchID.toString());
  }

  sendClick(event:any,type, propertyId, agencyId,btnName){
    
    var socialMediaId = '';
    if(type == "1"){
      socialMediaId = '75'
      event?.preventDefault();
    }else if(type == "2"){
      socialMediaId = '61'
    }else if(type == "6"){
      switch(btnName){
        case 'whatsapp':
          socialMediaId = '61'
          break;
        case 'facebook':
          socialMediaId = '62'
        break;
        case 'twitter':
          socialMediaId = '63'
        break;
        case 'linkedin':
          socialMediaId = '65'
        break;
        case 'telegram':
          socialMediaId = '74'
        break; 
        case 'email':
          socialMediaId = '75'
        break;   
        case 'print':
          socialMediaId = '64'
        break;        
        default:
          socialMediaId = "";
        break;
      }
    }



    this._utilityService.sendClickInfo(type, propertyId, "", this.initialValue?.branchID, agencyId,this.countryCode,socialMediaId);
  }

  PrepareForm() {
    return this.simpleRequest = {
      messageString1: this.paramId,
      messageString2: this.fromtype,
      messageString3: '',
      messageInt1: 0,
      messageInt2: 0,
      messageBool: false,
      messageBool2:false

    }
  }

  successfulRegister: boolean = false;
  notSuccessfulRegister: boolean = false;

  onOIDChange(oid: string){
    this.isSupported = false;
    this.successfulRegister = false;
    this.notSuccessfulRegister = false;
    this.supportInfo = null;
    this.formLubanPop.get('address').setValue('');
    this.formLubanPop.get("fname").setValue('');
    this.formLubanPop.get('address').enable();
    this.formLubanPop.get("fname").enable();

    if(this.nationalityValue == "1" && oid.length > 6 ){
      this._defaultService.GetSupportInfo(oid).then((result : any) => {
        if (result) {
          this.isSupported = true;
          this.supportInfo = result;
          // this.notifyService.showSuccess(this.langId == 1 ? "You are among the list of eligible people" : "انت من ضمن قائمة المستحقين", "");
          


          this.formLubanPop.get("fname").setValue(result?.Name.split(' ')[0]);
          this.formLubanPop.get("address").setValue(result?.Area);
          this.formLubanPop.get('address').disable();
          this.formLubanPop.get("fname").disable();
          
          if(result?.Mobile){
            this.formLubanPop.get("phone").setValue(result?.Mobile);
            this.cdRef.detectChanges();
          }

          this.successfulRegister = true;
         this.notSuccessfulRegister = false;
          
        } else{
          this.isSupported = false;
          this.successfulRegister = false;
         this.notSuccessfulRegister = true;
        }
      });

    } else {
      this.successfulRegister = false;
      this.notSuccessfulRegister = false;
    }
  }

  prepareFormSearchAll(agentId) {
    return this.propertySearchParams = {
      branchId: this.initialValue?.branchID,
      countryCode: this.initialValue?.countryCode,
      contactId: '0', currentLang: this.langId, cAID: 0,
      groupedBranches: this.initialValue?.groupedBranchesId, page: '',
      sort: '', type: '', limit: '9000',
      searchTerm: '',
      propertyType2: '',
      propertyType3: '',
      propertyLocation: '',
      minArea: '',
      maxArea: '',
      minPrice: '',
      maxPrice: '', features: '',
      minBeds: '',
      maxBeds: '',
      minBaths: '',
      maxBaths: '',
      electricity: '',
      Furnicher: '', count: false,
      nonVacant: '',
      agentID: agentId, parentID: '',
      myProperty: false,
      currencyDetails: {
        selectedCcyCode: '',
        selectedCcyName: '',
        selectedCcySwift: '',
        selectedCcySwiftCodeArb: '',
        selectedCcySwiftCode: '',
        selectedCcyDecimal: '',
      }
    }
  }

  onRecaptchaSuccess(token: string) {
    this.token = token;
  }

  submit(aname, aemail, pid, pcode, ptype, aarea, curl,price,agentId,saleOrRent,branchId) {
    this.submitted = true;
    this.isSubmitted = true
    var phone = this.formpop.controls["phone"].value;
    this.prepareEmail(aname, aemail, pid, pcode, ptype, aarea, curl, price,price,agentId,1,saleOrRent,branchId);
    if (this.formpop.invalid) {
      return;
    }
    this._contactusService.SenemailSubmit(this.prepareEmail(aname, aemail, pid, pcode, ptype, aarea, curl,price,price,agentId,1,saleOrRent,branchId)).then((result) => {
      if (result) {
        this.simpleResponseemil = UtilityService.clone(result);
        this.isSubmitted = false
        if (this.simpleResponseemil.messageBool) {

          this.notifyService.showSuccess("Mail send successfully !!", "")
          $('#myModal').modal('hide');                    
        }
        else
          this.notifyService.showSuccess("some thing went wrong.", "");

      }
    });
  }

  submitLuban(aname, aemail, pid, pcode, ptype, aarea, curl,price,agentId,saleOrRent,branchId) {
    
    this.disableSubmit = true;
    this.submitted = true;
    //this.prepareEmail(aname, aemail, pid, pcode, ptype, aarea, curl,price,price,agentId,1,saleOrRent,branchId);
    if (this.formLubanPop.invalid) {
      this.disableSubmit = false;
      return;
    }
    this._contactusService.SenemailSubmit(this.prepareEmailLuban(aname, aemail, pid, pcode, ptype, aarea, curl,price,price,agentId,1,saleOrRent,branchId)).then((result) => {
      if (result) {
        this.disableSubmit = false;
        this.submitted = false;
        this.simpleResponseemil = UtilityService.clone(result);
        if (this.simpleResponseemil.messageBool) {
          this.notifyService.showSuccess("Reversation request was sent successfully", "تم إرسال طلب الحجز بنجاح")
          this.formLubanPop.get('address').enable();
          this.formLubanPop.get("fname").enable();
          this.formLubanPop?.reset();
          let model = $('#registerFormModal') 
          model?.modal('hide');
          //$('#registerFormModal').hide();
          //$('.modal-backdrop').remove('modal-backdrop');
        }
        else
          this.notifyService.showSuccess("some thing went wrong.", "");
        
      }else{
        this.disableSubmit = false;
        this.submitted = false;
      }
    });
  }

  prepareEmail(aname, aemail, pid, pcode, ptype, aarea, curl, minPrice, maxPrice, agentId, typeOfRequest, saleOrRent,branchID) {

    return this.email = {
      CmName: this.formpop.controls["fname"].value,
      CmEmail: this.formpop.controls["email"].value,
      CmPhone: this.formpop.controls["phone"].value?.dialCode + this.formpop.controls["phone"].value?.number,
      AgentName: aname,
      AgentEmail: aemail,
      PropertyID: pid,
      PropertyCode: pcode,
      PropertyType: ptype,
      PropertyArea: aarea,
      ClientURL: curl,
      Referrer: '',
      branchId: branchID,
      selectedSwiftCode: this.langId == 1 ? this.initialValue?.currencyDetail?.selectedCcySwiftCode : this.initialValue?.currencyDetail?.selectedCcySwiftCodeArb,
      currentLangId: this.langId.toString(),
      recapchaResponse: this.token,
      message: this.formpop.controls["comment"].value,
      subscription: true,
      loginId: '1',
      minPrice:minPrice,
      maxPrice:maxPrice,
      agentId:agentId,
      typeOfRequest:"1",
      saleOrRent:saleOrRent,
      subBranch: this.initialValue.branchID.toString()
    }
  }

  prepareEmailLuban(aname, aemail, pid, pcode, ptype, aarea, curl, minPrice, maxPrice, agentId, typeOfRequest, saleOrRent,branchId) {

    return this.lubanEmail = {
      CmName: this.formLubanPop.controls["fname"].value,
      CmAddress: this.formLubanPop.controls["address"].value,
      Governorate: this.supportInfo?.Governorate,
      CmOmani: this.formLubanPop.controls["omaniNationality"].value == "1",
      CmOID: this.formLubanPop.controls["oid"].value,
      CmEmail: this.formLubanPop.controls["email"].value,
      CmPhone: this.formLubanPop.controls["phone"].value?.dialCode + this.formLubanPop.controls["phone"].value?.number,
      AgentName: aname,
      AgentEmail: aemail,
      AgentMobile: "",
      PropertyID: pid,
      PropertyName: this.myProperty.name,
      PropertyCode: pcode,
      PropertyType: ptype,
      PropertyArea: aarea,
      PropertyComplex: '',
      ClientURL: curl,
      Referrer: '',
      branchId: branchId,
      selectedSwiftCode: this.langId == 1 ? this.initialValue?.currencyDetail?.selectedCcySwiftCode : this.initialValue?.currencyDetail?.selectedCcySwiftCodeArb,
      currentLangId: this.langId.toString(),
      recapchaResponse: this.token,
      message: this.formLubanPop.controls["comment"].value,
      subscription: true,
      loginId: '1',
      minPrice:minPrice,
      maxPrice:maxPrice,
      agentId:agentId,
      typeOfRequest:"1",
      saleOrRent:saleOrRent,
      subBranch:this.initialValue?.branchID.toString()
    }
  }

  propertyAll(agentId:string) {
    localStorage.setItem('Qsearch', JSON.stringify(this.prepareFormSearchAll(agentId)));
    this._router.navigate(['/properties/0/0']);

  }

  redirectToClient(value:any){
    
    if(value != null){
      this._redirectionService.redirectToClient(value, this.initialValue.environment);
    }
    
  }
  // Settings
  settings = {
    slidesToShow: 3,
    slidesToScroll: 2,
    arrows: true,
    prevArrow: '.listings .slider-prev',
    nextArrow: '.listings .slider-next',
    dots: false,
    touchThreshold: 20,
    autoplay: false,
    responsive: [
      {
        breakpoint: 991,
        settings: {
          slidesToShow: 2
        }
      },
      {
        breakpoint: 768,
        settings: {
          slidesToShow: 1
        }
      }
    ]
  }

  settingscrue = {
    "slidesToScroll": 1,
    "dots": false,
    "infinite": true,
    "autoplay": true,
    'arrows': false,
   //  "adaptiveHeight": true,
    "autoplaySpeed": 1500,
    'speed': 500,
    "cssEase": 'ease',
    "useTransform": true,
  }

  GetSerchFilterData(currenLang:number) {
    this._propertyService.GetSerch_FilterAsync(currenLang, this.initialValue.branchID, this.initialValue.groupedBranchesId, Number(this.initialValue.countryCode)).then((result) => {
      if (result) {
        this.propertyFiltersResponse = UtilityService.clone(result);
        this.propertyFeatures = [];
        if (!isNullOrUndefined(this.propertyFeaturesSearch)) {
          let len = this.propertyFeaturesSearch.length;
          for (var i = 0; i < len; i++) {
            let obj = this.propertyFiltersResponse.propertyFeatures.filter(x => x.id == this.propertyFeaturesSearch[i])
            if (!isNullOrUndefined(obj) && obj.length > 0)
              this.propertyFeatures.push({ id: obj[0].id, icon: obj[0].icon, name: obj[0].name });
          }
          if (this.propertyFeatures.length % 2 == 0) {
            this.minVal = this.propertyFeatures.length / 2;
            this.maxVal = this.propertyFeatures.length;
          }
          else {
            this.minVal = +((this.propertyFeatures.length / 2)+(.5));
            this.maxVal = this.propertyFeatures.length;
          }
        }
      }
    });
  }

  renderMap() {
    const mapProperties = {
     center: new google.maps.LatLng(+this.myProperty?.latitude ? +this.myProperty?.latitude : 0, +this.myProperty?.longitude ? +this.myProperty?.longitude:0),
      zoom: 15,
      mapTypeId: google.maps.MapTypeId.SATELLITE

    };
    this.map = new google.maps.Map(this.mapElement.nativeElement, mapProperties);
  }

  async GetCompare(Id, type, index, ptype) {
    let addfav = 1;
    if (type == 1) {
      if (!isNullOrUndefined(this.propertySearchResultsfav)) {
        let fp = this.propertySearchResultsfav.filter(p => p.id == Id && p.isFavorite == true)
        if (fp.length > 0) {
          addfav = 0;
          $('#fav_' + Id).removeClass('active-icon');
        }
      }
    }
    else {
      if (!isNullOrUndefined(this.propertySearchResults)) {
        let cmp = this.propertySearchResults.filter(p => p.id == Id && p.isCompare == true)
        if (cmp.length > 0) {
          addfav = 0;
          $('#camp_' + Id).removeClass('active-icon');
        }
      }
    }

    this._favoriteService.addToFavoriteOrCompare(Id,addfav,type,this.initialValue?.branchID, this.langId.toString(), this.initialValue?.currencyDetail?.selectedCcyCode, this.initialValue?.groupedBranchesId,ptype,index);

  }


  loadFavComp(Id, type) {
    if (type == 1) {
      this.propertySearchResultsfav.forEach((currentValue, index) => {
        if (currentValue.isFavorite)
          $('#fav_' + currentValue.id).addClass('active-icon');
      });
    }
    else if (type == 2) {
      this.propertySearchResults.forEach((currentValue, index) => {
        if (currentValue.isCompare)
          $('#camp_' + currentValue.id).addClass('active-icon');

      });
    }
  }

  showTooltip(){
    
  }

  addMarker() {

    this.markers.push({
      position: {
        lat: +this.myProperty?.latitude,
        lng: +this.myProperty?.longitude,
      },
      label: {
        // color: 'red',
        //text: this.myProperty.name + '\n'+this.myProperty.address,
      },
      title: this.myProperty.name,
      icon: 'assets/img/map_marker.png',
      options: { icon: 'assets/img/map_marker.png' },
      
    });


     
    
  }
  ShowHide() {
    this.isShowproperty = !this.isShowproperty;
  }

  open(index: number): void {
    // open lightbox
    this._lightbox.open(this.floorImage,index);
  }

  close(): void {
    // close lightbox programmatically
    this._lightbox.close();
  }

  getNeededOne() {
    return this.quickSummary.find((x: any) => x.id == "205");
  }
  getNeededTwo() {
    return this.quickSummary.find((x: any) => x.id == "206");
  }

  getNeededThree() {
    return this.quickSummary.find((x: any) => x.id == "207");
  }
}
