import { Component, Input, OnInit } from '@angular/core';
import { NgbActiveModal } from '@ng-bootstrap/ng-bootstrap';
import { ConfigurationService } from 'src/app/shared/configuration.service';
import { LubanEmail } from '../../models/EmailContactUs';

@Component({
  selector: 'app-contact-receipt-modal',
  templateUrl: './contact-receipt-modal.component.html',
  styleUrls: ['./contact-receipt-modal.component.css']
})
export class ContactReceiptModalComponent implements OnInit {
  @Input() email: LubanEmail;
  @Input() leadID: number;
  
  constructor(public activeModal: NgbActiveModal, private _configurationService: ConfigurationService) { }

  langId: number = 1;
  isListed: string;
  public currencyCode: string = 'OMR';

  ngOnInit(): void {
    this.langId =  this._configurationService.getLanguageValue();
    this.currencyCode = this._configurationService.getSeletedCurrencyCode();

    if(!this.email.CmOmani){
      this.isListed = (this.langId == 1 ? "Non-Omani" : "غير عماني");
    }
    else if(this.email.Governorate){
      this.isListed = (this.langId == 1 ? "deserving" : "مستحق");
    }else{
      this.isListed = (this.langId == 1 ? "Undeserved" : "غير مستحق");
    }
  }

  printModalContent() {
    const printWindow = window.open('', '_blank');
    const printContent = document.getElementById('print-table').innerHTML;
    const direction = this.langId === 2 ? 'rtl' : 'ltr';
    const title = this.langId == 1 ? "Residential unit reservation form" : "إستمارة حجز وحدة سكنية"
    const logoUrl = "assets/img/temp/logos/logo-3.png";
    const footerURL = this.langId == 1 ? "assets/img/temp/logos/hai_aluban_footer_portrait_en.png" : "assets/img/temp/logos/hai_aluban_footer_portrait_ar.png";
    const footerText = this.langId == 1 ? "Haialuban is one of the residential neighborhood projects affiliated with the Ministry of Housing and Urban Planning" : "حي اللبان هو أحد مشاريع الأحياء السكنية التابعة لوزارة الإسكان والتخطيط العمراني";

    const printStyle = `<style>
    body {
      direction: ${direction};
      margin-top: 40px;
    }
    .header {
      text-align: center;
      margin-bottom: 20px;
    }
    .logo {
      max-width: 200px;
    }
    .footer {
      position: fixed;
      bottom: 0;
      left: 0;
      width: 100%;
      text-align: center;
      padding: 10px;
    }
    .footer img {
      width: 100%;
      max-height: 100%;
      object-fit: contain;
    }
    @media print {
      /* Hide the URL in the print window */
      @page {
        size: auto;   /* auto is the initial value */
        margin: 0 10px;  /* this affects the margin in the printer settings */
      }
    }
    </style>`

    printWindow.document.open();
    printWindow.document.write(`
      <html>
        <head>
          <title>${title}</title>
          ${printStyle}
        </head>
        <body onload="window.print(); window.close();">
        <div class="header">
          <img class="logo" src="${logoUrl}" alt="Logo">
          <h2>${title}</h2>
        </div>
        ${printContent}
        <div class="footer">
          <p>${footerText}</p>
          <img src="${footerURL}" alt="footerLogo">
        </div>
        </body>
      </html>
    `);
    printWindow.document.close();
  }

  closeModal() {
    this.activeModal.close();
  }

}
